import { Stack, Typography, useMediaQuery } from '@mui/material';
import { formatTotalSecondsToDateFormat } from '../../../../../utils';
import { Property } from '../../content/styled';
import LiftModal from '../../../../LiftModal/LiftModal';

const ChartStats = (props: any) => {
  const {
    noLifts,
    onDashboard,
    totalLifts,
    shortestLiftTimeSec,
    longestLiftTimeSec,
    avgLiftTimeSec,
    totalTimeSec,
    startTime,
    endTime,
  } = props;

  const sm = useMediaQuery('(max-width: 600px)');

  const formattedShortestTime = noLifts
    ? '-'
    : formatTotalSecondsToDateFormat(shortestLiftTimeSec, false);
  const formattedLongestTime = noLifts
    ? '-'
    : formatTotalSecondsToDateFormat(longestLiftTimeSec, false);
  const formattedAvgTime = noLifts
    ? '-'
    : formatTotalSecondsToDateFormat(avgLiftTimeSec, false);
  const liftDurationRange = `${formattedShortestTime} - ${formattedLongestTime}`;
  const totalTime = formatTotalSecondsToDateFormat(totalTimeSec, true);

  if (onDashboard && sm)
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={{ flexWrap: 'wrap', gap: 2 }}
      >
        <Property
          label="Total Lifts"
          value={
            noLifts ? (
              '-'
            ) : (
              <>
                <span style={{ marginRight: '10px' }}>{totalLifts}</span>
                <LiftModal liftIndex={0} />
              </>
            )
          }
          sx={{ minWidth: 'calc(50% - 16px)', whiteSpace: 'nowrap' }} // Assuming the gap is 16px (8px * 2)
        />
        <Property
          label="Working time"
          value={`${startTime} - ${endTime}`}
          sx={{ minWidth: 'calc(50% - 16px)', whiteSpace: 'nowrap' }} // Assuming the gap is 16px (8px * 2)
        />
        <Property
          label="Total working hours"
          value={noLifts ? '-' : `${totalTime}`}
          sx={{ minWidth: 'calc(50% - 16px)', whiteSpace: 'nowrap' }} // Assuming the gap is 16px (8px * 2)
        />
        <Property
          label="Lift duration"
          value={noLifts ? '-' : `${liftDurationRange} (minutes)`}
          sx={{ minWidth: 'calc(50% - 16px)', whiteSpace: 'nowrap' }} // Assuming the gap is 16px (8px * 2)
        />
        <Property
          label="Avg Lift Time"
          value={noLifts ? '-' : `${formattedAvgTime} (minutes)`}
          sx={{ minWidth: 'calc(50% - 16px)', whiteSpace: 'nowrap' }} // Assuming the gap is 16px (8px * 2)
        />
      </Stack>
    );

  return (
    <Stack
      sx={{ width: onDashboard ? '50%' : '20%' }}
      direction="row"
      spacing={2}
    >
      <Stack
        direction="column"
        sx={{
          padding: 4,
          borderRadius: 2,
          border: 'solid 1px',
          borderColor: '#e9ebec',
          flex: 1,
          maxWidth: 350,
        }}
        spacing={4}
      >
        <Typography
          color="#878a99"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <span style={{ marginRight: '10px' }}>
            Total lifts: <b>{totalLifts}</b>
          </span>
          <LiftModal liftIndex={0} />
        </Typography>
        <Typography
          color="#878a99"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <span style={{ marginRight: '10px' }}>
            Working time: <b>{startTime}</b> - <b>{endTime}</b>
          </span>
        </Typography>
        <Typography
          color="#878a99"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <span style={{ marginRight: '10px' }}>
            Total working hours: <b>{totalTime}</b>
          </span>
        </Typography>
        <Typography
          color="#878a99"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Lift duration: <b>{liftDurationRange} (minutes)</b>
        </Typography>
        <Typography
          color="#878a99"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Average lift time: <b>{formattedAvgTime} (minutes)</b>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ChartStats;
