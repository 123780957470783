import React from 'react';
import { Stack, Typography } from '@mui/material';
import ReactImageMagnify from 'react-image-magnify';
import { ChartProperty, ChartStack } from '../../projectReport/content/styled';
import { S3MMSITE_PATH, S3_IMAGES_PATH } from '../../../../variables/endpoints';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../store/hooks';
import { LiftStateType } from '../../../../variables/enums';

const MagnifiedImage = ({ src }: any) => (
  <ReactImageMagnify
    {...{
      enlargedImagePosition: 'over',
      fadeDurationInMs: 50,
      hoverDelayInMs: 50,
      smallImage: {
        isFluidWidth: true,
        src,
      },
      largeImage: {
        src,
        width: 391 * 3,
        height: 220 * 3,
      },
    }}
  />
);

const ImageContainer = ({ src, text, fontSize }: any) => (
  <Stack direction="column" spacing={1} height={260} width="100%">
    <MagnifiedImage src={src} />
    <Stack
      alignItems="center"
      sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}
    >
      <Typography
        variant="smallRegular"
        sx={{ fontSize: fontSize || 'inherit' }}
      >
        {text}
      </Typography>
    </Stack>
  </Stack>
);

const CraneLiftImageChart = ({
  cargoType,
  num,
  startTime,
  endTime,
  states,
}: any) => {
  const timezone =
    useAppSelector((state: any) => state.projectCrane.Timezone) ||
    'Asia/Jerusalem';

  return (
    <ChartStack height={360}>
      <Stack sx={{ paddingBottom: 4, paddingLeft: 4, paddingRight: 4 }}>
        <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
          <Stack>
            <Stack direction="row" spacing={6}>
              <Stack width={80}>
                <Typography
                  variant="h2Medium"
                  sx={{ fontSize: '20px' }}
                >{`Lift ${num}`}</Typography>
              </Stack>
              <ChartProperty
                label="Cargo Type"
                value={cargoType.name}
                width={200}
                fontSize={14}
              />
              <ChartProperty
                label="Start time"
                value={dayjs.utc(startTime).tz(timezone).format('HH:mm:ss')}
                width={200}
                fontSize={14}
              />
              <ChartProperty
                label="End time"
                value={dayjs.utc(endTime).tz(timezone).format('HH:mm:ss')}
                width={200}
                fontSize={14}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={4}
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <ImageContainer
          src={`${S3_IMAGES_PATH}/${
            states.find((state: any) => state.type === LiftStateType.LOAD)
              .imagePath
          }`}
          text={`Load ${num}`}
          fontSize="14px"
        />
        <ImageContainer
          src={`${S3_IMAGES_PATH}/${
            states.find((state: any) => state.type === LiftStateType.UNLOAD)
              .imagePath
          }`}
          text={`Unload ${num}`}
          fontSize="14px"
        />
      </Stack>
    </ChartStack>
  );
};

export default CraneLiftImageChart;
