import React, { ChangeEvent, useEffect, useState } from 'react';
import { Stack, Avatar, Button, Box, Typography, styled } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getImageProps } from './helpers';

const StyledStack = styled(Stack)`
  position: relative;
  
  .mask {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    color: red;
    img {
      filter: blur(5px);
    }
    .mask {
      display: initial;
    }
  }
`;

const ImageUploader = (props: any) => {
  const {
    readOnly,
    isCreateModal,
    imageSrc,
    imageData,
    onChangeImageData,
    uploaderId,
    onClickDeleteImage,
    imageWidth = '150px',
    imageHeight = '100px',
  } = props;

  //#region States
  const [preview, setPreview] = useState<any>(null);

  //#endregion

  //#region Handlers
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onChangeImageData(e.target.files[0]);
      // Clear input type file after set in state, fix bug when you clear and upload same file
      e.target.value = '';
    }
  };

  const handleClickClear = async () => {
    if (isCreateModal) {
      onChangeImageData(undefined);
      URL.revokeObjectURL(preview);
    } else {
      onClickDeleteImage();
    }
  };

  //#endregion

  //#region Effects
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!imageData) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(imageData);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageData]);
  //#endregion

  if (readOnly)
    return (
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '3px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
          }}
        >
          <Avatar
            sx={{
              width: imageWidth,
              height: imageHeight,
            }}
            variant="square"
            {...getImageProps(isCreateModal, readOnly, imageSrc, preview)}
          ></Avatar>
        </Stack>
      </Stack>
    );

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <StyledStack>
        <Avatar
          sx={{
            width: imageWidth,
            height: imageHeight,
            backgroundColor: 'transparent'
          }}
          variant="rounded"
          {...getImageProps(isCreateModal, readOnly, imageSrc, preview)}
        >
          <FileUploadIcon sx={{ fontSize: 96, backgroundColor: '#efefef' }} />
        </Avatar>
        {(preview || imageSrc) && (
          <Stack className="mask">
            <Button
              onClick={handleClickClear}
              color="primary"
              variant="outlined"
            >
              <DeleteOutlineIcon />
            </Button>
          </Stack>
        )}
      </StyledStack>
      <input
        type="file"
        onChange={handleFileChange}
        id={uploaderId}
        accept="image/*"
        style={{ display: 'none' }}
      />
      <Box
        component="label"
        htmlFor={uploaderId}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          color: 'primary.main',
          margin: 0,
          pt: 2,
        }}
      >
        <Typography
          variant="smallRegular"
          sx={{ width: '100%', textTransform: 'uppercase' }}
        >
          Upload file
        </Typography>
      </Box>
    </Stack>
  );
};

export default ImageUploader;
