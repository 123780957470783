import { useDispatch } from 'react-redux';
import {
  createAsset,
  deleteAsset,
  fetchAssets,
  updateAsset,
} from '../store/asset';

const useAssets = () => {
  const dispatch = useDispatch();

  const fetchAssetsApi = async (params: any) => {
    return dispatch(fetchAssets(params));
  };

  const deleteAssetApi = async (id: string | undefined) => {
    return dispatch(deleteAsset(id));
  };

  const createAssetApi = async (asset: any) => {
    return dispatch(createAsset(asset));
  };

  const updateAssetApi = async (id: string | undefined, asset: any) => {
    return dispatch(updateAsset({ id, asset }));
  };

  return {
    fetchAssetsApi,
    deleteAssetApi,
    deleteAssetApiReset: () => dispatch({ type: 'assets/delete/reset' }),
    createAssetApi,
    createAssetApiReset: () => dispatch({ type: 'assets/create/reset' }),
    updateAssetApi,
    updateAssetApiReset: () => dispatch({ type: 'assets/update/reset' }),
  };
};
export default useAssets;
