import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => {
  const { companyId, ...restProps } = props;
  return apiPutAsync(`company/${companyId}/name`, restProps);
};

export const usePutCompanyName = (options: any) => {
  const key = ['PutCompanyName'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
