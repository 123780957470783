import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => {
  const { siteId, zoneId, ...restProps } = props;
  return apiPutAsync(`site/${siteId}/zones/${zoneId}`, restProps);
};

export const usePutZone = (options?: any) => {
  const key = ['PutZone'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
