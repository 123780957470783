import React, { useState } from 'react';
import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
} from '../../../../../../components/styled';
import { Stack } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from 'react-query';
import {
  useDeleteCraneAllowWeight,
  useGetCraneAllowWeight,
} from '../../../../../../queries';
import { useParams } from 'react-router-dom';
import { ErrorState } from '../../../../../../components/alerts';
import { Grid } from '../../../../../../components/grid';
import getColumnsDef from './columns';
import { ConfirmDialog } from '../../../../../../components/confirm';
import CreateCraneAllowWeightDialog from './CreateCraneAllowWeightDialog';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const CraneAllowWeight = () => {
  const { craneId } = useParams();

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>();

  //#region react-query
  const queryClient = useQueryClient();

  const {
    data: craneAllowWeightData,
    error: craneAllowWeightError,
    refetch: craneAllowWeightRefetch,
    isFetching: craneAllowWeightIsFetching,
  } = useGetCraneAllowWeight({
    craneId,
  });

  const {
    mutate: deleteRecordMutate,
    isLoading: deleteRecordIsLoading,
    error: deleteRecordError,
    data: deleteRecordData,
    reset: deleteCraneReset,
  } = useDeleteCraneAllowWeight({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteCraneAllowWeight');
    },
  });

  //#endregion

  //#region Handlers

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    await craneAllowWeightRefetch();
    setCreateOpen(false);
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = () =>
    deleteRecordMutate({ recordId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteCraneReset();
    onDelete();
    setTimeout(async () => await craneAllowWeightRefetch(), 100);
  };

  const onDeleteClose = () => {
    deleteCraneReset();
    onDelete();
  };
  //#endregion

  return (
    <LayoutStack>
      <GridToolbar>
        <Stack>
          <Button
            variant="contained"
            onClick={() => onCreate(true)}
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <Button
            variant="outlined"
            disabled={craneAllowWeightIsFetching}
            onClick={() => craneAllowWeightRefetch()}
          >
            {craneAllowWeightIsFetching ? (
              <CircularProgress size={16} />
            ) : (
              <RefreshIcon />
            )}
          </Button>
        </Stack>
      </GridToolbar>
      <ContentStack>
        {craneAllowWeightIsFetching && !craneAllowWeightError && <Loading />}
        {!craneAllowWeightIsFetching && craneAllowWeightError && (
          <ErrorState error={craneAllowWeightError} />
        )}
        {!craneAllowWeightIsFetching &&
          !craneAllowWeightError &&
          craneAllowWeightData?.data && (
            <Grid
              {...{
                rows: craneAllowWeightData.data,
                columnsDef: getColumnsDef(ROW_HEIGHT, onDelete),
                onDelete,
                ROW_HEIGHT,
                maxVisibleRows,
              }}
            />
          )}

        {createOpen && (
          <CreateCraneAllowWeightDialog
            onClose={() => onCreate(false)}
            onSuccess={onCreateSuccess}
            craneId={craneId}
          />
        )}

        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete allow weight record"
            text="Are you sure you want to delete this allow weight record?"
            onOk={onDeleteConfirmed}
            onCancel={onDeleteClose}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={{
              loading: deleteRecordIsLoading,
              error: deleteRecordError,
              data: deleteRecordData,
            }}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};

export default CraneAllowWeight;
