import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiDeleteAsync, apiPostAsync, apiPutAsync } from '../api/axios';
import { createCrudSlice } from './utils';

export const fetchAssets = createAsyncThunk(
  'assets/fetch',
  async (params: any) => {
    const { data } = await apiPostAsync('dynamicassetsquery', params);
    return data;
  },
);

export const deleteAsset = createAsyncThunk(
  'task',
  async (id: string | undefined) => {
    return await apiDeleteAsync(`task/template/${id}`);
  },
);

export const createAsset = createAsyncThunk(
  'task/template',
  async (asset: any) => {
    return await apiPostAsync('task/template', asset);
  },
);

export const updateAsset = createAsyncThunk(
  'task/template',
  async ({ id, asset }: { id: string | undefined; asset: any }) => {
    return await apiPutAsync(`task/template/${id}`, asset);
  },
);

export interface Asset {
  ID: number;
  UserName: string;
  Barcode: string;
  Quantity: number;
  CargoTypeDesc: string;
}

const assetSlice = createCrudSlice<Asset>('assets');

export const assetActions = assetSlice.actions;

export default assetSlice.reducer;
