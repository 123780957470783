import { useMutation } from 'react-query';
import { apiPostAsync } from '../api/axios';

const api = (props: any) => apiPostAsync(`user`, props);

export const usePostUser = (options?: any) => {
  const key = ['PostUser'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
