// @ts-nocheck
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { getLiftsByHour, getWorkDayWindowHours } from '../../../../../utils';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
  CraneHeader,
} from '../../content/styled';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tooltipMaterialScheduler = (s: any, point: any, timezone: string) => {
  const start = dayjs.tz(point.x, timezone).format('HH:mm:ss');
  const end = dayjs.tz(point.x2, timezone).format('HH:mm:ss');
  return (
    s +
    `<span style="color:${point.color}">\u25CF</span> ${point.yCategory}: <b>${start} - ${end}</b><br/>`
  );
};

const LiftSchedulingGantChart = ({ height, startDate, onDashboard }: any) => {
  const selectedDate = startDate.format('YYYY-MM-DD');
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsByHourStore = useAppSelector(
    (state: any) => state.projectCrane.liftsByHour,
  );
  const hours = useAppSelector(
    (state: any) => state.projectCrane.workDayWindowHours,
  );
  const timezone =
    useAppSelector((state: any) => state.projectCrane.Timezone) ||
    'Asia/Jerusalem';
  const [liftsByHour, setLiftsByHour] = useState([]);
  const [cargoTypes, setCargoTypes] = useState([]);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(0);
  const [colors, setColors] = useState([]);

  const title = 'Lift Scheduling Gant';

  useEffect(() => {
    if (!lifts || lifts.length === 0) return;

    const cargoTypesData = lifts
      .map((item) => item.cargoType)
      .filter((obj, index, self) => {
        return index === self.findIndex((t) => t.name === obj.name);
      });
    setCargoTypes(cargoTypesData);

    setColors(cargoTypesData.map((cargoType) => cargoType.color));
    setLiftsByHour(liftsByHourStore);
  }, [lifts, liftsByHourStore]);

  const seriesData = useMemo(() => {
    const xrangeData = [];

    // add another hour to the end of the day
    const liftSchedulingGantHours = hours.concat(hours[hours.length - 1] + 1);
    setMinHour(
      dayjs
        .tz(selectedDate, timezone)
        .startOf('day')
        .add(hours[0], 'hours')
        .valueOf(),
    );
    setMaxHour(
      dayjs
        .tz(selectedDate, timezone)
        .startOf('day')
        .add(
          liftSchedulingGantHours[liftSchedulingGantHours.length - 1],
          'hours',
        )
        .valueOf(),
    );

    for (const hour of liftSchedulingGantHours) {
      const hourLifts = liftsByHour.find((lift) => lift.label === `${hour}:00`);
      if (!hourLifts) continue;

      hourLifts.cargoTypes.forEach((cargoType) => {
        if (cargoType.lifts.length === 0) return;
        const cargoTypeIndex = cargoTypes.findIndex(
          (cType) => cType.id === cargoType.id,
        );
        let i = 0;
        let start = cargoType.lifts[0].startTime;
        for (const lift of cargoType.lifts) {
          const liftEndTime = dayjs.tz(
            `${selectedDate} ${lift.endTime}`,
            'YYYY-MM-DD HH:mm:ss',
            timezone,
          );
          const nextStartTime = cargoType.lifts[i + 1]
            ? dayjs.tz(
                `${selectedDate} ${cargoType.lifts[i + 1].startTime}`,
                'YYYY-MM-DD HH:mm:ss',
                timezone,
              )
            : null;

          if (!nextStartTime) {
            xrangeData.push({
              x: dayjs
                .tz(`${selectedDate} ${start}`, 'YYYY-MM-DD HH:mm:ss', timezone)
                .valueOf(),
              x2: dayjs
                .tz(
                  `${selectedDate} ${lift.endTime}`,
                  'YYYY-MM-DD HH:mm:ss',
                  timezone,
                )
                .valueOf(),
              y: cargoTypeIndex,
              name: cargoType.name,
            });
            start = cargoType.lifts[i + 1]
              ? cargoType.lifts[i + 1].startTime
              : null;
          } else if (
            liftEndTime.hour() !== nextStartTime.hour() ||
            liftEndTime.minute() !== nextStartTime.minute()
          ) {
            xrangeData.push({
              x: dayjs
                .tz(`${selectedDate} ${start}`, 'YYYY-MM-DD HH:mm:ss', timezone)
                .valueOf(),
              x2: dayjs
                .tz(
                  `${selectedDate} ${lift.endTime}`,
                  'YYYY-MM-DD HH:mm:ss',
                  timezone,
                )
                .valueOf(),
              y: cargoTypeIndex,
              name: cargoType.name,
            });
            start = cargoType.lifts[i + 1]
              ? cargoType.lifts[i + 1].startTime
              : null;
          }
          i++;
        }
      });
    }

    return xrangeData;
  }, [liftsByHour, cargoTypes]);

  const options: Highcharts.Options = {
    time: {
      timezone,
    },
    chart: {
      plotShadow: false,
      type: 'xrange',
      height: height,
      marginTop: 0,
    },

    tooltip: {
      hideDelay: 50,
      formatter: function () {
        return this.points.reduce(
          (acc, point) => tooltipMaterialScheduler(acc, point, timezone),
          '',
        );
      },
      shared: true,
      style: {
        fontSize: '14px',
      },
    },
    exporting: { enabled: false },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      min: minHour,
      max: maxHour,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '14px',
        },
        formatter: function () {
          return this.value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase(),
          );
        },
      },
      categories: cargoTypes.map(({ name }) => name),
    },

    series: [
      {
        type: 'xrange',
        showInLegend: false,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: 'gray',
        dataLabels: {
          padding: 0,
        },
        data: seriesData,
      },
    ],
    colors,
  };

  if (lifts.length === 0) {
    return (
      <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        </ChartStackHeader>
        <ChartStackContent
          sx={{
            padding: 4,
            width: '100%',
            height: height + 32,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          spacing={4}
        >
          <Typography variant="h2Medium" color="#878a99">
            {'No data to display'}
          </Typography>
        </ChartStackContent>
      </ChartStack>
    );
  }

  return (
    <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
      </ChartStackHeader>
      <ChartStackContent onDashboard={onDashboard}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </ChartStackContent>
    </ChartStack>
  );
};

export default LiftSchedulingGantChart;
