import LaunchIcon from '@mui/icons-material/Launch';
import { Dialog, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { formatTotalSecondsToDateFormat } from '../../utils';
import LiftModalContent from './components/LiftModalContent/LiftModalContent';
import LiftModalTitle from './components/LiftModalTitle/LiftModalTitle';
dayjs.extend(utc);
dayjs.extend(timezone);

const LiftModal = ({ liftIndex }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const timezone = useAppSelector((state: any) => state.projectCrane.Timezone);
  const [open, setOpen] = useState<boolean>(false);
  const [currentLiftIndex, setCurrentLiftIndex] = useState<number>(liftIndex);
  const [currentLift, setCurrentLift] = useState<any>();

  useEffect(() => {
    const lift = lifts[currentLiftIndex];
    if (lift && timezone) {
      updateCurrentLift(lift);
    }
  }, [currentLiftIndex, lifts, timezone]);

  const handleClickNext = () => {
    if (currentLiftIndex < lifts.length - 1) {
      const newIndex = currentLiftIndex + 1;
      setCurrentLiftIndex(newIndex);
    }
  };

  const handleClickBack = () => {
    if (currentLiftIndex > 0) {
      const newIndex = currentLiftIndex - 1;
      setCurrentLiftIndex(newIndex);
    }
  };

  const updateCurrentLift = (lift: any) => {
    setCurrentLift({
      ...lift,
      duration: formatTotalSecondsToDateFormat(lift.durationSec, false),
    });
  };

  return (
    <>
      <LaunchIcon
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
        color="info"
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth={sm ? 'md' : 'lg'}
        fullScreen={sm}
        sx={{ margin: sm ? '30px 10px' : '0px', height: sm ? '90%' : '100%' }}
        onClose={() => setOpen(false)}
        style={{ overflow: 'visible' }}
      >
        <LiftModalTitle
          {...{
            currentLiftIndex,
            currentLift,
            handleClickBack,
            handleClickNext,
            setOpen,
          }}
        />
        <LiftModalContent {...{ data: currentLift }} />
      </Dialog>
    </>
  );
};

export default LiftModal;
