import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface DeviceType {
  ID: number;
  DeviceTypeDesc: string;
}
export interface DeviceTypes {
  devicetypes: DeviceType[];
}
const initialState: DeviceTypes = {
  devicetypes: [{ ID: -5, DeviceTypeDesc: '' }],
};

const deviceTypeSlice = createSlice({
  name: 'devicetypes',
  initialState: initialState,
  reducers: {
    setDeviceTypes(state, action: PayloadAction<DeviceType[]>) {
      state.devicetypes = action.payload;
      // console.log (state.tasks);
    },
  },
});

export const deviceTypeActions = deviceTypeSlice.actions;

export default deviceTypeSlice.reducer;
