import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import imageBackground from '../../../assets/images/industrial-cranes.png';

const TasksEmptyState = ({ message = 'There is no task today' }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      flex={1}
      sx={{ height: '100%' }}
    >
      <Avatar
        sx={{
          backgroundColor: 'backgrounds.system',
          width: 140,
          height: 140,
        }}
      >
        <img src={imageBackground} alt="No results found" width={150} />
      </Avatar>
      <Typography variant="mediumRegular" color="typography.negativeDisabled">
        {message}
      </Typography>
    </Stack>
  );
};

export default TasksEmptyState;
