import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => apiPutAsync('gtctm', props);

export const usePutCranebitTask = (options: any) => {
  const key = ['PutCranebitTask'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
