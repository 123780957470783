import { createSlice } from '@reduxjs/toolkit';

export interface ProjectCrane {
  Project: string;
  Crane: string;
  Timezone: string;
  Zones: [];
  lifts: [];
  operationSegments: [];
  idleSegments: [];
  liftsByHour: [];
  liftsStats: [];
  workDayWindowHours: [];
  selectedCrane: {};
}

const initialState: ProjectCrane = {
  Project: '',
  Crane: '',
  Timezone: '',
  Zones: [],
  lifts: [],
  operationSegments: [],
  idleSegments: [],
  liftsByHour: [],
  liftsStats: [],
  workDayWindowHours: [],
  selectedCrane: {},
};

const projectCraneSlice = createSlice({
  name: 'projectCrane',
  initialState: initialState,
  reducers: {
    setCurrentProject(state, action) {
      state.Project = action.payload;
    },
    setCurrentCrane(state, action) {
      state.Crane = action.payload;
    },
    setCurrentTimezone(state, action) {
      state.Timezone = action.payload;
    },
    setZones(state, action) {
      state.Zones = action.payload;
    },
    setLifts(state, action) {
      state.lifts = action.payload;
    },
    setOperationSegments(state, action) {
      state.operationSegments = action.payload;
    },
    setIdleSegments(state, action) {
      state.idleSegments = action.payload;
    },
    setLiftsByHour(state, action) {
      state.liftsByHour = action.payload;
    },
    setLiftsStats(state, action) {
      state.liftsStats = action.payload;
    },
    setWorkDayWindowHours(state, action) {
      state.workDayWindowHours = action.payload;
    },
    setSelectedCrane(state, action) {
      state.selectedCrane = action.payload;
    },
    resetProjectCrane: () => initialState,
  },
});

export const projectCraneActions = projectCraneSlice.actions;

export default projectCraneSlice.reducer;
