import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface IdleTime {
  num: number;
  datesampling: string;
  starttimeday: string;
  startidle: string;
  stopidle: string;
  idleinminute: number;
}
export interface IdleTimes {
  idletimes: IdleTime[];
}

const initialState: IdleTimes = {
  idletimes: [
    {
      num: 0,
      datesampling: '',
      starttimeday: '',
      startidle: '',
      stopidle: '',
      idleinminute: 0,
    },
  ],
};

const idleTimesSlice = createSlice({
  name: 'idletimes',
  initialState: initialState,
  reducers: {
    setIdleTime(state, action: PayloadAction<IdleTime[]>) {
      state.idletimes = action.payload;
      //    console.log(state.idletimes);
    },
    resetIdleTime: () => initialState,
  },
});

export const idleTimesActions = idleTimesSlice.actions;

export default idleTimesSlice.reducer;
