// @ts-nocheck
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { isEqual } from 'lodash';
import { Button, Stack, TextField, styled, Box } from '@mui/material';
import {
  ContentStack,
  LayoutStack,
  Loading,
} from '../../../../../components/styled';
import validate, { validators } from '../../../../../utils/validate';
import { AutoSizer, Size } from 'react-virtualized';
import { ZonesDialog, ZonesImage } from './components';
import {
  useGetProject,
  useGetProjectZonePoints,
  useGetZones,
} from '../../../../../queries';
import ErrorState from '../../../../../components/alerts/ErrorState';
import { FormGroup } from '../../../../../components/inputs';
import SiteGoogleMaps, {
  SiteGoogleMapsMode,
  Zone,
} from '../../../../../components/SiteGoogleMaps/SiteGoogleMaps';
import { convertXYToLatLong } from '../../../../../utils/coordinatesHelper';

const reducer = (state: any, action: any) => {
  if (action.type === 'set_initial_state') return action.payload;
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const ResponsiveStack = styled(Stack)`
  width: 300px;

  @media screen and (min-width: 1280px) {
    width: 350px;
  }
  @media screen and (min-width: 1366px) {
    width: 400px;
  }
  @media screen and (min-width: 1600px) {
    width: 500px;
  }
  @media screen and (min-width: 1920px) {
    width: 600px;
  }
`;

const ProjectAntiCollision = (props: any) => {
  const { projectData, putProjectMutate } = props;
  const {
    ID: projectId,
    ProjectName,
    EPSILON_FRONT_BACK_SLOW,
    EPSILON_FRONT_BACK_STOP,
    EPSILON_RIGHT_LEFT_SLOW,
    EPSILON_RIGHT_LEFT_STOP,
    EPSILON_HEIGHT_SLOW,
    EPSILON_HEIGHT_STOP,
    EPSILON_COLLISION_ORACLE_METER,
    EPSILON_COLLISION_ORACLE_ANGLE,
    HYSTERESIS,
    SiteMap,
    LatCraneRef,
    LongCraneRef,
  } = projectData;

  const initialState = {
    EPSILON_FRONT_BACK_SLOW: EPSILON_FRONT_BACK_SLOW?.toString() ?? '',
    EPSILON_FRONT_BACK_STOP: EPSILON_FRONT_BACK_STOP?.toString() ?? '',
    EPSILON_RIGHT_LEFT_SLOW: EPSILON_RIGHT_LEFT_SLOW?.toString() ?? '',
    EPSILON_RIGHT_LEFT_STOP: EPSILON_RIGHT_LEFT_STOP?.toString() ?? '',
    EPSILON_HEIGHT_SLOW: EPSILON_HEIGHT_SLOW?.toString() ?? '',
    EPSILON_HEIGHT_STOP: EPSILON_HEIGHT_STOP?.toString() ?? '',
    EPSILON_COLLISION_ORACLE_METER:
      EPSILON_COLLISION_ORACLE_METER?.toString() ?? '',
    EPSILON_COLLISION_ORACLE_ANGLE:
      EPSILON_COLLISION_ORACLE_ANGLE?.toString() ?? '',
    HYSTERESIS: HYSTERESIS?.toString() ?? '',
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [openZonesDialog, setOpenZonesDialog] = useState(false);
  const [zones, setZones] = useState<Zone[]>([]);
  const [siteCenter, setSiteCenter] = useState<any>({
    lat: 0,
    lng: 0,
  });

  const { data, error, isLoading } = useGetZones(
    { projectId },
    {
      enabled: !!projectId,
    },
  );

  useEffect(() => {
    if (LatCraneRef && LongCraneRef) {
      setSiteCenter({
        lat: LatCraneRef,
        lng: LongCraneRef,
      });
    }
  }, [LatCraneRef, LongCraneRef]);

  //#endregion

  const onClose = () => {
    dispatch({
      type: 'set_initial_state',
      payload: initialState,
    });
  };

  const onSubmit = async () => {
    if (!valid) return;
    await putProjectMutate({ projectId, ...state });
  };

  const handleOpenZonesDialog = () => {
    setOpenZonesDialog(true);
  };

  const handleCloseZonesDialog = () => {
    setOpenZonesDialog(false);
  };

  const isInitialState = isEqual(state, initialState);

  const validationResults = useMemo(() => {
    const definitions = {
      EPSILON_FRONT_BACK_SLOW: [
        () => validators.isRequired(state.EPSILON_FRONT_BACK_SLOW.toString()),
      ],
      EPSILON_FRONT_BACK_STOP: [
        () => validators.isRequired(state.EPSILON_FRONT_BACK_STOP.toString()),
      ],
      EPSILON_RIGHT_LEFT_SLOW: [
        () => validators.isRequired(state.EPSILON_RIGHT_LEFT_SLOW.toString()),
      ],
      EPSILON_RIGHT_LEFT_STOP: [
        () => validators.isRequired(state.EPSILON_RIGHT_LEFT_STOP.toString()),
      ],
      EPSILON_HEIGHT_SLOW: [
        () => validators.isRequired(state.EPSILON_HEIGHT_SLOW.toString()),
      ],
      EPSILON_HEIGHT_STOP: [
        () => validators.isRequired(state.EPSILON_HEIGHT_STOP.toString()),
      ],
      EPSILON_COLLISION_ORACLE_METER: [
        () =>
          validators.isRequired(
            state.EPSILON_COLLISION_ORACLE_METER.toString(),
          ),
      ],
      EPSILON_COLLISION_ORACLE_ANGLE: [
        () =>
          validators.isRequired(
            state.EPSILON_COLLISION_ORACLE_ANGLE.toString(),
          ),
      ],
      HYSTERESIS: [() => validators.isRequired(state.HYSTERESIS.toString())],
    };
    return validate(state, definitions);
  }, [state]);

  const valid = validationResults.valid;

  const {
    data: projectZonePointsData,
    isError: projectZonePointsIsError,
    isLoading: projectZonePointsIsLoading,
    refetch: refetchProjectZonePoints,
  } = useGetProjectZonePoints({
    projectId,
  });

  // Mapping the data
  const mapZonePointsToZones = (data) => {
    const zonesMap = data.reduce((acc, { zoneid, xpoint, ypoint }) => {
      if (!acc[zoneid]) {
        acc[zoneid] = {
          name: '', // You might want to derive or assign names differently
          height: 0, // Adjust based on your requirements
          zonePoints: [],
        };
      }

      const points = convertXYToLatLong(siteCenter, xpoint, ypoint);
      acc[zoneid].zonePoints.push(points);

      return acc;
    }, {});

    return Object.keys(zonesMap).map((key) => ({
      ...zonesMap[key],
      name: data?.data
        ? data.data.filter((zone: any) => Number(zone.ID) === Number(key))[0]
            ?.ZoneName
        : '',
      active: data?.data
        ? data.data.filter((zone: any) => Number(zone.ID) === Number(key))[0]
            ?.Active
        : true,
    }));
  };

  useEffect(() => {
    refetchProjectZonePoints();
  }, []);

  useEffect(() => {
    if (projectZonePointsData?.data && siteCenter && data?.data) {
      const zonePoints = mapZonePointsToZones(projectZonePointsData.data);
      setZones(zonePoints);
    }
  }, [projectZonePointsData, siteCenter, data]);

  return (
    <Stack width="100%" spacing={4}>
      <LayoutStack>
        <ContentStack>
          <Stack flex={1} width="100%">
            <Stack
              flex={1}
              spacing={8}
              direction="row"
              width="100%"
              sx={{ padding: 4 }}
            >
              <ResponsiveStack alignItems="flex-start" spacing={4}>
                <FormGroup title="Zones">
                  {isLoading && !error && <Loading />}
                  {!isLoading && error && <ErrorState error={error} />}
                  {!isLoading &&
                    !error &&
                    data?.data &&
                    SiteMap &&
                    SiteMap.length > 0 &&
                    zones.length > 0 && (
                      // <AutoSizer>
                      //   {(props: Size) => (
                      //     <Stack spacing={2} width="100%">
                      //       <ZonesImage
                      //         projectId={projectId}
                      //         imgWidthSize={props.width}
                      //         zoneItems={data?.data}
                      //       />
                      //       <Button
                      //         sx={{ width: props.width }}
                      //         variant="contained"
                      //         onClick={handleOpenZonesDialog}
                      //       >
                      //         Edit zones
                      //       </Button>
                      //     </Stack>
                      //   )}
                      // </AutoSizer>
                      <>
                        {/* <SiteGoogleMaps
                          zones={zones}
                          newZone={null}
                          setNewZone={null}
                          siteCenter={siteCenter}
                          mode={SiteGoogleMapsMode.view}
                        />
                        <Button
                          sx={{ width: props.width }}
                          variant="contained"
                          onClick={handleOpenZonesDialog}
                        >
                          Edit zones
                        </Button> */}
                      </>
                    )}
                </FormGroup>
              </ResponsiveStack>
              <Stack flex={1} spacing={12}>
                <Stack spacing={6}>
                  <FormGroup title="Slow epsilons">
                    <Stack spacing={6}>
                      <Stack direction="row" spacing={2} width="100%">
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Height"
                          value={state.EPSILON_HEIGHT_SLOW || ''}
                          {...validationResults.results['EPSILON_HEIGHT_SLOW']}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_HEIGHT_SLOW',
                              payload: e.target.value,
                            })
                          }
                        />
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Length"
                          value={state.EPSILON_FRONT_BACK_SLOW || ''}
                          {...validationResults.results[
                            'EPSILON_FRONT_BACK_SLOW'
                          ]}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_FRONT_BACK_SLOW',
                              payload: e.target.value,
                            })
                          }
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} width="100%">
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Width"
                          value={state.EPSILON_RIGHT_LEFT_SLOW || ''}
                          {...validationResults.results[
                            'EPSILON_RIGHT_LEFT_SLOW'
                          ]}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_RIGHT_LEFT_SLOW',
                              payload: e.target.value,
                            })
                          }
                        />
                        <Box width="100%" />
                      </Stack>
                    </Stack>
                  </FormGroup>
                </Stack>
                <Stack spacing={6}>
                  <FormGroup title="Stop epsilons">
                    <Stack spacing={6}>
                      <Stack direction="row" spacing={2} width="100%">
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Height"
                          value={state.EPSILON_HEIGHT_STOP || ''}
                          {...validationResults.results['EPSILON_HEIGHT_STOP']}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_HEIGHT_STOP',
                              payload: e.target.value,
                            })
                          }
                        />
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Length"
                          value={state.EPSILON_FRONT_BACK_STOP || ''}
                          {...validationResults.results[
                            'EPSILON_FRONT_BACK_STOP'
                          ]}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_FRONT_BACK_STOP',
                              payload: e.target.value,
                            })
                          }
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} width="100%">
                        <TextField
                          fullWidth
                          required
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          label="Width"
                          value={state.EPSILON_RIGHT_LEFT_STOP || ''}
                          {...validationResults.results[
                            'EPSILON_RIGHT_LEFT_STOP'
                          ]}
                          onChange={(e) =>
                            dispatch({
                              type: 'EPSILON_RIGHT_LEFT_STOP',
                              payload: e.target.value,
                            })
                          }
                        />
                        <Box width="100%" />
                      </Stack>
                    </Stack>
                  </FormGroup>
                </Stack>
                <Stack spacing={6}>
                  <Stack direction="row" spacing={2} width="100%">
                    <TextField
                      fullWidth
                      required
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Hysteresis"
                      inputProps={{
                        step: 0.1,
                      }}
                      value={state.HYSTERESIS || ''}
                      {...validationResults.results['HYSTERESIS']}
                      onChange={(e) =>
                        dispatch({
                          type: 'HYSTERESIS',
                          payload: e.target.value,
                        })
                      }
                    />
                    <Box width="100%" />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Button onClick={onClose} disabled={isInitialState}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!valid || isInitialState}
                onClick={onSubmit}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </ContentStack>
        <ZonesDialog
          open={openZonesDialog}
          projectId={projectId}
          handleClose={handleCloseZonesDialog}
          projectName={ProjectName}
        />
      </LayoutStack>
    </Stack>
  );
};

export default ProjectAntiCollision;
