import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GanttTask, GanttTasks } from './gantttasks';

const initialState: GanttTasks = {
  gantttasks: [
    {
      id: 0,
      catid: 0,
      category: '',
      color: '',
      syear: 0,
      smonth: 0,
      sday: 0,
      shour: 0,
      sminute: 0,
      eyear: 0,
      emonth: 0,
      eday: 0,
      ehour: 0,
      eminute: 0,
    },
  ],
};

const ganttTasksRptSlice = createSlice({
  name: 'gantttasksrpt',
  initialState: initialState,
  reducers: {
    setGanttTasksRpt(state, action: PayloadAction<GanttTask[]>) {
      state.gantttasks = action.payload;
      //    console.log(state.idletimes);
    },
    resetGanttTasksRpt: () => initialState,
  },
});

export const ganttTasksRptActions = ganttTasksRptSlice.actions;

export default ganttTasksRptSlice.reducer;
