const Info2 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="Info2"
    fill="currentColor"
    {...props}
  >
    <path d="M12 0c6.617 0 12 5.383 12 12s-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0zm0 1C5.934 1 1 5.934 1 12s4.934 11 11 11 11-4.934 11-11S18.066 1 12 1zm0 8c.3 0 .5.338.5.563v7.874c0 .338-.2.563-.5.563s-.5-.225-.5-.563V9.563c0-.337.2-.562.5-.562zm0-3c.3 0 .5.3.5.5v1c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5z" />
  </svg>
);

export default Info2;
