import { useState } from 'react';

export const useAsyncFunction = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const call = (fn: () => Promise<any>) => {
    setError(undefined);
    setLoading(true);
    fn()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  return {
    loading,
    setLoading,
    error,
    setError,
    data,
    setData,
    call,
  };
};
