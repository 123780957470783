import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHttp } from '../hooks/useHttp/useHttp';
import { useReactToPrint } from 'react-to-print';
import '../components/ObjList.scss';
import { tasksRptActions } from '../store/tasksrpt';
import { telemetriesRptActions } from '../store/telemetriesrpt';
import { idleTimesRptActions } from '../store/idletimesrpt';
import { calcSumTaskRptActions } from '../store/calcsumtaskrpt';
import { tasksInHoursRptActions } from '../store/tasksinhoursrpt';
import { tasksByCategoryRptActions } from '../store/tasksbycategoryrpt';
import { ganttTasksRptActions } from '../store/gantttasksrpt';
import useTasks from '../hooks/usetasks';
import useTelemetries from '../hooks/usetelemetries';
import useIdleTime from '../hooks/useidletimes';
import useCalcSumTask from '../hooks/usecalcsumtask';
import ReportDialogToPdf from '../components/dashboard/report/ReportDialogToPdf';
import useCargoTypes from '../hooks/useCargoTypes';
import { reportHeaderActions } from '../store/reportheader';
import useReportHeader from '../hooks/useReportHeader';
import { ApiMethods } from '../variables/enums';
import { CRANE_REPORT_PATH, CRANE_REPORT_PATHS } from '../variables/endpoints';

interface Crane {
  CraneId: number;
  CraneName: string;
  ProjectName: string;
}

const CreateReports = () => {
  const [recordItems, setRecordItems] = useState<Crane[]>([]);

  const { api } = useHttp();

  const [crane, setCrane] = useState<Crane>({
    CraneId: 0,
    CraneName: '',
    ProjectName: '',
  });

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint2 = async () => {
    if (crane.CraneId > 0) {
      let newPageTitle =
        crane.CraneName + '_' + crane.ProjectName + '_' + PrindDate;
      //@ts-ignore
      document.querySelector('title').textContent = newPageTitle;

      try {
        await api({
          method: ApiMethods.POST,
          url: CRANE_REPORT_PATH,
          data: {
            CraneId: crane.CraneId,
            DateRef,
            FileName: newPageTitle,
          },
        });
      } catch (err) {}

      handlePrint();
    }
  };

  function sleep(milli: number) {
    return new Promise((resolve) => setTimeout(resolve, milli));
  }
  const {
    setHandlerTasksRpt,
    setHandlerTasksInHoursRpt,
    setHandlerTasksByCategoryRpt,
    setHandlerGanttTasksRpt,
  } = useTasks();
  const { setHandlerTelemetriesRpt } = useTelemetries();
  const { setHandlerIdleTimeRpt } = useIdleTime();
  const { setHandlerCalcSumTaskRpt } = useCalcSumTask();
  const { setHandlerReportHeader } = useReportHeader();
  const { fetchCargoTypesApi } = useCargoTypes();
  const dispatch = useDispatch();

  function fixDigit(val: String) {
    return val.toString().length === 1 ? '0' + val.toString() : val.toString();
  }

  var d = new Date();
  d.setDate(d.getDate());
  //@ts-ignore
  let sMonth = fixDigit((d.getMonth() + 1).toString());
  //@ts-ignore
  let sDay = fixDigit(d.getDate());
  let DateRef = d.getFullYear() + '-' + sMonth + '-' + sDay;
  var dp = new Date();
  let sMonth2 = fixDigit((dp.getMonth() + 1).toString());
  let PrindDate =
    dp.getFullYear() +
    sMonth2 +
    fixDigit(dp.getDate().toString()) +
    fixDigit(dp.getHours().toString()) +
    fixDigit(dp.getMinutes().toString());

  useEffect(() => {
    const getRecords = async () => {
      try {
        await fetchCargoTypesApi();

        // FIXME: Refactor this
        await sleep(10000);

        const { data } = await api({
          method: ApiMethods.GET,
          url: CRANE_REPORT_PATHS.getById(DateRef),
        });
        setRecordItems(data);
      } catch {}
    };
    getRecords();
  }, []);

  useEffect(() => {
    const getCranes = async () => {
      if (recordItems.length > 0) {
        for (const obj of recordItems) {
          await setCrane2({
            CraneId: obj.CraneId,
            CraneName: obj.CraneName,
            ProjectName: obj.ProjectName,
          });
        }
      }
    };
    getCranes();
  }, [recordItems]);

  useEffect(() => {
    const createReport = async () => {
      try {
        await craneHandler();
      } catch {}
    };
    createReport();
  }, [crane]);

  const setCrane2 = async (obj: Crane) => {
    setCrane({
      CraneId: obj.CraneId,
      CraneName: obj.CraneName,
      ProjectName: obj.ProjectName,
    });
    // FIXME: Refactor this
    await sleep(21000);
  };

  const craneHandler = async () => {
    // FIXME:
    // ************ tempDR temporary for development***************
    //************** */ replace with DateRef in production******************************
    let tempDR = '';
    dispatch(tasksRptActions.resetTasksRpt());
    dispatch(telemetriesRptActions.resetTelemetriesRpt());
    dispatch(idleTimesRptActions.resetIdleTimeRpt());
    dispatch(calcSumTaskRptActions.resetCalcSumTaskRpt());
    dispatch(tasksInHoursRptActions.resetTasksInHoursRpt());
    dispatch(tasksByCategoryRptActions.resetTasksByCategoryRpt());
    dispatch(ganttTasksRptActions.resetGanttTasksRpt());
    dispatch(reportHeaderActions.resetReportHeader());
    try {
      if (crane.CraneId > 0) {
        if (crane.CraneId < 17) {
          tempDR = '2022-10-18';
          await setHandlerTasksRpt(tempDR, crane.CraneId);
          await setHandlerTelemetriesRpt(tempDR, crane.CraneId);
          await setHandlerIdleTimeRpt(tempDR, crane.CraneId);
          await setHandlerTasksInHoursRpt(tempDR, crane.CraneId);
          await setHandlerCalcSumTaskRpt(tempDR, crane.CraneId);
          await setHandlerTasksByCategoryRpt(tempDR, crane.CraneId);
          await setHandlerGanttTasksRpt(tempDR, crane.CraneId);
          await setHandlerReportHeader(tempDR, crane.CraneId);
        } else {
          await setHandlerTelemetriesRpt(DateRef, crane.CraneId);
          await setHandlerTasksRpt(DateRef, crane.CraneId);

          await setHandlerIdleTimeRpt(DateRef, crane.CraneId);
          await setHandlerTasksInHoursRpt(DateRef, crane.CraneId);
          await setHandlerCalcSumTaskRpt(DateRef, crane.CraneId);
          await setHandlerTasksByCategoryRpt(DateRef, crane.CraneId);
          await setHandlerGanttTasksRpt(DateRef, crane.CraneId);
          await setHandlerReportHeader(DateRef, crane.CraneId);
        }
        await sleep(10000);
        handlePrint2();
        await sleep(10000);
      }
    } catch (err) {}
  };

  return (
    <>
      <div ref={componentRef}>
        <ReportDialogToPdf />
      </div>
    </>
  );
};

export default CreateReports;
