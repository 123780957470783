import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => {
  const { siteId, userId } = props;
  return apiDeleteAsync(`site/${siteId}/user/${userId}`);
};

export const useDeleteProjectUser = (options: any) => {
  const key = ['DeleteProjectUser'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
