import { useMutation } from 'react-query';
import { apiPostAsync } from '../api/axios';

const api = (props: any) => apiPostAsync('craneallowedweight', props);

export const usePostCraneAllowWeight = (options: any) => {
  const key = ['PostCraneAllowWeight'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
