// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { mediaResActions } from '../../../../../store/mediares';
import { S3MMSITE_PATH } from '../../../../../variables/endpoints';
import {
  SitemapChooseLocation,
  SitemapFetchLocation,
} from '../../../../../components/Sitemap';
import { Stack, Typography } from '@mui/material';
import { useGetCrane, useGetProject } from '../../../../../queries';
import { Loading } from '../../../../../components/styled';
import {
  convertLatLongToXY,
  convertXMapPointToXMeter,
  convertXMeterToXMapPoint,
  convertYMapPointToYMeter,
  convertYMeterToYMapPoint,
  isLocationReachable,
} from '../../../../../utils/coordinatesHelper';
import { Marker } from '@react-google-maps/api';
import SiteGoogleMaps, {
  SiteGoogleMapsMode,
} from '../../../../../components/SiteGoogleMaps/SiteGoogleMaps';
import { USER } from '../../../../../variables/constants';
import * as storageHelper from '../../../../../utils/storageHelper';

const LogisticsLocations = (props: any) => {
  const {
    submitStep,
    activeStep,
    siteCenter,
    setSiteCenter,
    source,
    setSource,
    destination,
    setDestination,
    isSubmitStep,
    selectedProject,
    selectedCrane,
    positionCoords,
    actualAccuracy,
    setXyUserLocation,
    setXyDestinationLocation,
    fetchLocationIsLoading,
  } = props;
  //#region Sitemaps logic
  const dispatch = useAppDispatch();

  const [relpxx, setRelpxx] = useState<number>(0);
  const [projectParams, setProjectParams] = useState<any>({});

  const [imgWidth, setImgWidth] = useState<number>(0);
  const [imgWidthSize, setImgWidthSize] = useState(990);

  const [xyMarkUserLocation, setXyMarkUserLocation] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [userLocationWarning, setUserLocationWarning] = useState<any>(null);

  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level,
  );

  const [destinationLocationWarning, setDestinationLocationWarning] =
    useState<any>(null);
  const [xyMarkDestinationLocation, setXyMarkDestinationLocation] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const Project = useAppSelector((state: any) => state.projectCrane.Project);
  const user = storageHelper.getItem(USER);

  useEffect(() => {
    if (user && Project) {
      const site = user.sites.find((site: any) => site.id === Project);
      if (!site) return;
      setSiteCenter({
        lat: site.originLatitude,
        lng: site.originLongitude,
      });
    }
  }, [user, Project]);

  useEffect(() => {
    const SetMediaRes = () => {
      let orientationLandscape = false;
      let medialevel = 0;
      if (window.matchMedia('(orientation: Landscape)').matches) {
        orientationLandscape = true;
      }
      if (
        window.matchMedia('(min-width: 700px)').matches &&
        orientationLandscape
      ) {
        medialevel = 2;
      } //smartphone Rotated
      if (
        window.matchMedia('(min-width: 800px)').matches &&
        !orientationLandscape
      ) {
        medialevel = 3;
      } //tablet
      if (
        window.matchMedia('(min-width: 1280px)').matches &&
        orientationLandscape
      ) {
        medialevel = 4;
      } //screen
      if (medialevel === 0) {
        medialevel = 1;
      }

      dispatch(mediaResActions.setMediaRes({ level: medialevel }));
    };
    window.addEventListener('resize', SetMediaRes);
    SetMediaRes();
    return () => {
      window.removeEventListener('resize', SetMediaRes);
    };
  }, []);

  useEffect(() => {
    switch (mediaResLevel) {
      case 4:
        setImgWidthSize(1000);
        setRelpxx(imgWidth / 1000);
        break;
      case 3:
        setImgWidthSize(700);
        setRelpxx(imgWidth / 700);
        break;
      case 2:
        setImgWidthSize(700);
        setRelpxx(imgWidth / 700);
        break;
      default:
        setImgWidthSize(340);
        setRelpxx(imgWidth / 340);
    }
  }, [mediaResLevel]);

  useEffect(() => {
    if (projectParams.SiteMap && projectParams.SiteMap.length > 0) {
      let img = new Image();
      img.src = `${S3MMSITE_PATH}/${projectParams.SiteMap}`;
      img.onload = () => {
        setImgWidth(img.width);
        setRelpxx(img.width / imgWidthSize);
      };
    }
  }, [projectParams]);

  //#region Set project and crane params

  const { isLoading: projectDataIsLoading } = useGetProject(
    { projectId: selectedProject },
    {
      cacheTime: 0,
      onSuccess: (data: any) => {
        const {
          xPointCraneRef,
          yPointCraneRef,
          xMeterStart,
          yMeterStart,
          LatCraneRef,
          LongCraneRef,
          xMeterCraneRef,
          yMeterCraneRef,
          SiteMap,
          Pixel2Meter: meter2Pixel,
        } = data?.data?.projectData || {};

        setSiteCenter({ lat: LatCraneRef, lng: LongCraneRef });

        setProjectParams({
          xMeterStart,
          yMeterStart,
          LatCraneRef,
          LongCraneRef,
          xMeterCraneRef,
          yMeterCraneRef,
          SiteMap,
          xPointCraneRef,
          yPointCraneRef,
          meter2Pixel,
        });
      },
    },
  );

  const { data: getCraneData } = useGetCrane(
    { craneId: selectedCrane },
    {
      cacheTime: 0,
    },
  );

  //#endregion

  useEffect(() => {
    if (positionCoords && projectParams && relpxx) {
      const { latitude, longitude } = positionCoords;

      const {
        LatCraneRef,
        LongCraneRef,
        meter2Pixel,
        xPointCraneRef,
        yPointCraneRef,
      } = projectParams;

      if (LatCraneRef && LongCraneRef) {
        const latMid = (LatCraneRef + latitude) / 2;
        const m_per_deg_lat =
          111132.954 -
          559.822 * Math.cos(2.0 * latMid) +
          1.175 * Math.cos(4.0 * latMid);
        const m_per_deg_long = (Math.PI / 180) * 6367449 * Math.cos(latMid);
        const deltaLat = latitude - LatCraneRef;
        const deltaLong = longitude - LongCraneRef;

        const xMeter = deltaLong * m_per_deg_long;
        const yMeter = deltaLat * m_per_deg_lat;

        if (meter2Pixel) {
          if (isFinite(xMeter) && isFinite(yMeter)) {
            setXyUserLocation({ x: xMeter, y: yMeter });
            const xPoint = convertXMeterToXMapPoint(
              xMeter,
              meter2Pixel,
              xPointCraneRef,
              relpxx,
            );

            const yPoint = convertYMeterToYMapPoint(
              yMeter,
              meter2Pixel,
              yPointCraneRef,
              relpxx,
            );

            setXyMarkUserLocation({ x: xPoint, y: yPoint });
          }
        }

        const args = {
          xPoint: xMeter,
          yPoint: yMeter,
          xMeter: getCraneData?.data?.xMeter,
          yMeter: getCraneData?.data?.yMeter,
          radius: getCraneData?.data?.craneJibLength,
        };

        if (!isLocationReachable(args)) {
          setUserLocationWarning('Unreachable destination!');
        } else {
          setUserLocationWarning(null);
        }
      }
    }
  }, [positionCoords, projectParams, relpxx, getCraneData]);

  //#endregion

  const handleClickSetDestinationLocationImage = (e: React.MouseEvent) => {
    e.stopPropagation();

    const { xPointCraneRef, yPointCraneRef, meter2Pixel } = projectParams;

    setXyMarkDestinationLocation({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    });

    const xMeter = convertXMapPointToXMeter(
      e.nativeEvent.offsetX,
      relpxx,
      xPointCraneRef,
      meter2Pixel,
    );

    const yMeter = convertYMapPointToYMeter(
      e.nativeEvent.offsetY,
      relpxx,
      yPointCraneRef,
      meter2Pixel,
    );

    setXyDestinationLocation({ x: xMeter, y: yMeter });

    const args = {
      xPoint: xMeter,
      yPoint: yMeter,
      xMeter: getCraneData?.data?.xMeter,
      yMeter: getCraneData?.data?.yMeter,
      radius: getCraneData?.data?.craneJibLength,
    };

    if (!isLocationReachable(args)) {
      setDestinationLocationWarning('Unreachable destination!');
    } else {
      setDestinationLocationWarning(null);
    }
  };

  const onMapClick = useCallback(
    (e) => {
      // show a new marker on the clicked location
      const newPoint = {
        lat: Number(e.latLng.lat().toFixed(8)),
        lng: Number(e.latLng.lng().toFixed(8)),
      };
      const { x, y } = convertLatLongToXY(
        siteCenter,
        newPoint.lat,
        newPoint.lng,
      );
      const isSubmitStep = activeStep === submitStep;

      if (!isSubmitStep) {
        setXyUserLocation({ x, y });
        setXyMarkUserLocation({ x, y });
        setUserLocationWarning(null);
        setSource(newPoint);
      } else {
        setXyDestinationLocation({ x, y });
        setXyMarkDestinationLocation({
          x,
          y,
        });
        setDestination(newPoint);
      }
    },
    [source, destination, activeStep, submitStep],
  );

  if (projectDataIsLoading) return <Loading />;

  if (activeStep === 2)
    return (
      <Stack spacing={2}>
        <Typography variant="h2Regular" color="typography.primary">
          Asset location
        </Typography>
        {/* <SitemapFetchLocation
          {...{
            selectedCrane: true,
            fetchLocationWarning: userLocationWarning,
            xyMarkUserLocation,
            imgWidthSize,
            projectParams,
            actualAccuracy: fetchLocationIsLoading
              ? actualAccuracy
              : positionCoords.accuracy,
            fetchLocationIsLoading,
          }}
        /> */}
        <SiteGoogleMaps
          siteCenter={siteCenter}
          onMapClick={onMapClick}
          mode={SiteGoogleMapsMode.create_source}
          newGoTherePoint={source}
          setNewGoTherePoint={setSource}
          height="500px"
        >
          {source && (
            <Marker
              title="Source"
              position={source}
              icon={{
                url: require('../../../../../assets/icons/source.svg').default, //   scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          )}
        </SiteGoogleMaps>
      </Stack>
    );

  return (
    <Stack spacing={2}>
      <Typography variant="h2Regular" color="typography.primary">
        Set destination
      </Typography>
      {/* <SitemapChooseLocation
        {...{
          selectedCrane: true,
          handleClickOnImage: handleClickSetDestinationLocationImage,
          selectLocationWarning: destinationLocationWarning,
          imgWidthSize,
          projectParams,
          xyMark: xyMarkDestinationLocation,
        }}
      /> */}
      <SiteGoogleMaps
        siteCenter={siteCenter}
        onMapClick={onMapClick}
        mode={SiteGoogleMapsMode.create_destination}
        newGoTherePoint={destination}
        setNewGoTherePoint={setDestination}
        height="500px"
      >
        {destination && (
          <Marker
            title="Destination"
            position={destination}
            icon={{
              url: require('../../../../../assets/icons/destination.svg')
                .default,
              //   scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )}
      </SiteGoogleMaps>
    </Stack>
  );
};

export default LogisticsLocations;
