import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) => apiGetAsync(`craneallowedweight/${props.craneId}`);

export const useGetCraneAllowWeight = (props: any, options?: any) => {
  const key = ['GetCraneAllowWeight', Object.values(props)];
  const fn = () => api(props);

  return useQuery(key, fn, {
    cacheTime: 0,
    enabled: !!props.craneId,
    ...(options || {}),
  });
};
