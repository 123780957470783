import { useDispatch } from 'react-redux';
import {
  fetchCargoTypes,
  deleteCargoTypes,
  createCargoType,
  updateCargoType,
} from '../store/cargotype';

const useCargoTypes = () => {
  const dispatch = useDispatch();

  const fetchCargoTypesApi = async () => {
    return dispatch(fetchCargoTypes());
  };

  const deleteCargoTypeApi = async (name: string | undefined) => {
    return dispatch(deleteCargoTypes(name));
  };

  const createCargoTypeApi = async (cargoType: any) => {
    return dispatch(createCargoType(cargoType));
  };

  const updateCargoTypeApi = async (
    name: string | undefined,
    cargoType: any,
  ) => {
    return dispatch(updateCargoType({ name, cargoType }));
  };

  return {
    fetchCargoTypesApi,
    deleteCargoTypeApi,
    deleteCargoTypeApiReset: () =>
      dispatch({ type: 'cargotypes/delete/reset' }),
    createCargoTypeApi,
    createCargoTypeApiReset: () =>
      dispatch({ type: 'cargotypes/create/reset' }),
    updateCargoTypeApi,
    updateCargoTypeApiReset: () =>
      dispatch({ type: 'cargotypes/update/reset' }),
  };
};

export default useCargoTypes;
