export default () => {
  return [
    {
      headerName: 'Days',
      field: 'days',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Structure Quantity',
      field: 'quantity',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Duration [Minutes]',
      field: 'duration',
      minWidth: 260,
      maxWidth: 260,
    },
  ];
};
