import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import ErrorState from '../../../../../../../../components/alerts/ErrorState';
import { ConfirmDialog } from '../../../../../../../../components/confirm';
import { Grid } from '../../../../../../../../components/grid';
import { FormControl } from '../../../../../../../../components/inputs';
import {
  GridToolbar,
  LayoutStack,
  Loading,
} from '../../../../../../../../components/styled';
import { useHttp } from '../../../../../../../../hooks/useHttp/useHttp';
import {
  useDeleteZone,
  useGetProject,
  useGetProjectZonePoints,
  useGetZoneTypes,
  useGetZones,
  usePutZone,
} from '../../../../../../../../queries';
import {
  convertLatLongToXY,
  convertRadiusMapToRadiusMeter,
  convertXMapPointToXMeter,
  convertXYToLatLong,
  convertYMapPointToYMeter,
} from '../../../../../../../../utils/coordinatesHelper';
import validate, { validators } from '../../../../../../../../utils/validate';
import { REGEX_VALIDATION } from '../../../../../../../../variables/constants';
import {
  ZONE_PATH,
  ZONE_POINT_PATH,
} from '../../../../../../../../variables/endpoints';
import { ApiMethods } from '../../../../../../../../variables/enums';
import EditZone from './EditZone';
import getColumnsDef from './columns';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { projectCraneActions } from '../../../../../../../../store/projectcrane';
import WarningIcon from '@mui/icons-material/Warning';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const ResponsiveStack = styled(Stack)`
  width: 580px;

  @media screen and (min-width: 1920px) {
    width: 600px;
  }
`;

const reducer = (state: any, action: any) => {
  if (action.type === 'set_initial_state') return initialState;
  return {
    ...state,
    [action.type]: action.payload,
  };
};
const initialState = {
  ZoneName: '',
  Height: '',
  DisableInactive: false,
};

const ZonesToolbar = (props: any) => {
  const {
    dialogState,
    setDialogState,
    siteId,
    setFocusedZone,
    Start,
    setStart,
    Radius,
    setRadius,
    PolyPoints,
    setPolyPoints,
    ZoneTypeId,
    setZoneTypeId,
    relpxxAdd,
    handleAddZone,
    startNewZone,
    newZone,
    setNewZone,
    setSiteCenter,
    siteCenter,
  } = props;
  const { api } = useHttp();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [editItemId, setEditItemId] = useState<number | undefined>();
  const [deleteItemId, setDeleteItemId] = useState<number | null>();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const zones = useAppSelector((state: any) => state.projectCrane.Zones);

  //#region react-query
  const queryClient = useQueryClient();

  const dispatchStore = useAppDispatch();

  // const {
  //   data: zonesData,
  //   error: zonesError,
  //   isFetching: zoneIsFetching,
  //   isSuccess: zoneIsSuccess,
  //   refetch: refetchZones,
  // } = useGetZones(
  //   { siteId },
  //   {
  //     enabled: !!siteId || dialogState === 'view',
  //   },
  // );

  // const {
  //   data: usePutZoneData,
  //   error: zoneTypesError,
  //   isLoading: zoneTypesIsLoading,
  // } = useGetZoneTypes({
  //   enabled: !!siteId || dialogState === 'add',
  // });

  const { mutate: updateZone, error: usePutZoneError } = usePutZone({
    // onSuccess: () => refetchZones(),
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutZone');
    },
  });

  const {
    mutate: deleteZoneMutate,
    isLoading: deleteZoneIsLoading,
    error: deleteZoneError,
    data: deleteZoneData,
    reset: deleteZoneReset,
  } = useDeleteZone({
    onSettled: () => {
      queryClient.invalidateQueries('DeleteZone');
    },
  });

  const {
    data: projectData,
    isLoading: projectDataIsLoading,
    error: projectDataError,
  } = useGetProject(
    { siteId },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (projectData?.data) {
      const { originLatitude, originLongitude } = projectData.data;
      setSiteCenter({
        lat: originLatitude,
        lng: originLongitude,
      });
    }
  }, [projectData]);

  //#endregion

  const {
    xPointCraneRef,
    yPointCraneRef,
    Pixel2Meter: meter2Pixel,
  } = projectData?.data?.projectData || {};

  // const {
  //   data: siteZonePointsData,
  //   isError: projectZonePointsIsError,
  //   isLoading: siteZonePointsIsLoading,
  //   refetch: refetchProjectZonePoints,
  // } = useGetProjectZonePoints({
  //   siteId,
  // });

  // Mapping the data
  // const mapZonePointsToZones = (data) => {
  //   const zonesMap = data.reduce((acc, { zoneid, xpoint, ypoint }) => {
  //     if (!acc[zoneid]) {
  //       acc[zoneid] = {
  //         name: '',
  //         height: 0,
  //         points: [],
  //       };
  //     }

  //     const points = convertXYToLatLong(siteCenter, xpoint, ypoint);
  //     acc[zoneid].points.push(points);

  //     return acc;
  //   }, {});

  //   return Object.keys(zonesMap).map((key) => ({
  //     ...zonesMap[key],
  //     name: zones
  //       ? zones.filter((zone: any) => Number(zone.id) === Number(key))[0]?.name
  //       : '',
  //     active: zones
  //       ? zones.filter((zone: any) => Number(zone.id) === Number(key))[0]
  //           ?.isActive
  //       : true,
  //   }));
  // };

  // useEffect(() => {
  //   if (siteZonePointsData?.data && siteCenter) {
  //     dispatch(projectCraneActions.setZones(siteZonePointsData?.data));
  //   }
  // }, [siteZonePointsData, siteCenter]);

  const onRowClicked = (e: any) => {
    setFocusedZone(e.data.ID);
  };

  const onClickActive = async (e: any, data: any) => {
    e.stopPropagation();
    const { id: zoneId, isActive, height, name } = data;
    const parsedHeight = Number(height);
    await updateZone({
      siteId,
      zoneId,
      isActive: !isActive,
      height: parsedHeight,
      name,
    });

    const updatedZones = zones.map((zone: any) => {
      if (zone.id === zoneId) {
        return {
          ...zone,
          isActive: !isActive,
        };
      }
      return zone;
    });
    dispatchStore(projectCraneActions.setZones(updatedZones));
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = async () =>
    deleteZoneMutate({
      siteId,
      zoneId: deleteItemId,
    });

  const onDeleteSuccess = async () => {
    dispatchStore(
      projectCraneActions.setZones(
        zones.filter((zone: any) => zone.id !== deleteItemId),
      ),
    );
    deleteZoneReset();
    onDelete();
    setTimeout(async () => {
      // await refetchZones();
      await queryClient.invalidateQueries('GetProjectZonePoints');
    }, 100);
  };

  const onDeleteClose = () => {
    deleteZoneReset();
    onDelete();
  };

  const onEdit = (editItemId?: number) => {
    setEditItemId(editItemId);
  };

  const onEditSuccess = async () => {
    // await refetchZones();
    onEdit();
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const onClickAdd = () => setDialogState('add');

  //#region Add zone logic

  const resetAddState = () => {
    setZoneTypeId('');
    dispatch({ type: 'set_initial_state' });
    onResetPolygonHandler();
    onResetStartHandler();
  };

  const onResetStartHandler = () => {
    setStart({ lat: 32.07040923, lng: 34.79577723 });
    setRadius(0);
  };

  const onResetPolygonHandler = () => {
    setStart({ lat: 32.07040923, lng: 34.79577723 });
    const polyPointsCopy = Array.from(PolyPoints);
    polyPointsCopy.splice(0, PolyPoints.length);
    setPolyPoints(polyPointsCopy);
  };

  const onDeletePointHandler = () => {
    if (PolyPoints.length > 0) {
      const polyPointsCopy = Array.from(PolyPoints);
      polyPointsCopy.splice(-1);
      setPolyPoints(polyPointsCopy);
    }
  };

  const onCancelAdd = () => {
    resetAddState();
    setDialogState('view');
    setNewZone({
      name: '',
      height: 0,
      active: true,
      points: [],
    });
  };

  // TODO: Use react-query, handle create points with bulkCreate, fix eqeqeq
  const onSubmitAdd = async () => {
    // reset state after in onSuccess!
    await onCreateHandler();
    resetAddState();
    setDialogState('view');
    setTimeout(async () => {
      // await refetchZones();
      await queryClient.invalidateQueries('GetProjectZonePoints');
    }, 100);
  };

  const onCreateHandler = async () => {
    if (!valid) {
      return;
    }
    // if (ZoneTypeId == 1) {
    let id: string = '';
    try {
      const { data } = await api({
        method: ApiMethods.POST,
        url: `${ZONE_PATH}/${siteId}/zones`,
        data: {
          name: newZone.name,
          type: 'POLYGON',
          siteId,
          height: Number(newZone.height),
          points: newZone.points.map((point: any, index: number) => {
            return {
              latitude: Number(point.lat.toFixed(8)),
              longitude: Number(point.lng.toFixed(8)),
              order: index,
            };
          }),
        },
      });
      dispatchStore(
        projectCraneActions.setZones([
          ...zones,
          {
            ...data,
            points: newZone.points.map((point: any, index: number) => {
              return {
                latitude: point.lat,
                longitude: point.lng,
                order: index,
              };
            }),
          },
        ]),
      );
    } catch (err) {}
    for (let i = 0; i < newZone.points.length; i++) {
      // const xPoint = convertXMapPointToXMeter(
      //   PolyPoints[i].x,
      //   relpxxAdd,
      //   xPointCraneRef,
      //   meter2Pixel,
      // );
      // const yPoint = convertYMapPointToYMeter(
      //   PolyPoints[i].y,
      //   relpxxAdd,
      //   yPointCraneRef,
      //   meter2Pixel,
      // );
      // const { lat, lng } = newZone.zonePoints[i];
      // const { x: xPoint, y: yPoint } = convertLatLongToXY(siteCenter, lat, lng);
      // await SetPoint(id, xPoint, yPoint, i);
    }
    setTimeout(() => {
      setNewZone({
        name: '',
        height: 0,
        active: true,
        points: [],
      });
    }, 100);

    // }
    // if (ZoneTypeId == 2) {
    //   const xCenter = convertXMapPointToXMeter(
    //     Start.x,
    //     relpxxAdd,
    //     xPointCraneRef,
    //     meter2Pixel,
    //   );

    //   const yCenter = convertYMapPointToYMeter(
    //     Start.y,
    //     relpxxAdd,
    //     yPointCraneRef,
    //     meter2Pixel,
    //   );

    //   const RadiusMeter = convertRadiusMapToRadiusMeter(
    //     Radius,
    //     relpxxAdd,
    //     meter2Pixel,
    //   );

    //   try {
    //     await api({
    //       method: ApiMethods.POST,
    //       url: ZONE_PATH,
    //       data: {
    //         ZoneName: state.ZoneName,
    //         ZoneTypeId,
    //         ProjectId: projectId,
    //         xCenter,
    //         yCenter,
    //         Radius: RadiusMeter,
    //         Height: state.Height,
    //         DisableInactive: state.DisableInactive,
    //       },
    //     });
    //   } catch (err) {}
    // }
  };

  const SetPoint = async (
    ZoneId: string,
    xPoint: number,
    yPoint: number,
    Order: number,
  ) => {
    try {
      await api({
        method: ApiMethods.POST,
        url: ZONE_POINT_PATH,
        data: {
          ZoneId,
          xPoint,
          yPoint,
          Order,
        },
      });
    } catch {}
  };

  const validationResults = useMemo(() => {
    // const definitions = {
    //   ZoneName: [() => validators.isRequired(state.ZoneName.toString())],
    //   Height: [() => validators.isRequired(state.Height.toString())],
    //   ZoneTypeId: [() => validators.isRequired(ZoneTypeId.toString())],
    // };
    const definitions = {
      ZoneName: [() => validators.isRequired(newZone.name)],
      Height: [() => validators.isRequired(newZone.height)],
      ZoneTypeId: [() => validators.isRequired('1')],
    };
    return validate(state, definitions);
  }, [newZone, ZoneTypeId]);

  const valid = validationResults.valid;

  // TODO: Move this to a separate FC
  let SvgPoints = () => {
    let rows = [];
    if (PolyPoints.length > 0) {
      let elements = PolyPoints.length;
      for (let j = 0; j < elements; j++) {
        rows.push(
          <div key={j} className="form-group row mt-2">
            <label htmlFor="p" className="col-2 col-form-label">
              Point
            </label>
            <div className="col-9">
              <input
                type="text"
                id="p"
                value={
                  'X: ' +
                  convertXMapPointToXMeter(
                    PolyPoints[j].x,
                    relpxxAdd,
                    xPointCraneRef,
                    meter2Pixel,
                  ) +
                  '   Y: ' +
                  convertYMapPointToYMeter(
                    PolyPoints[j].y,
                    relpxxAdd,
                    yPointCraneRef,
                    meter2Pixel,
                  )
                }
                disabled
                className="form-control"
              />
            </div>
          </div>,
        );
      }
    }
    return <>{rows}</>;
  };

  //#endregion

  const error = (usePutZoneError as Error)?.message;
  const pageIsLoading = projectDataIsLoading;
  const pageError = projectDataError;

  if (dialogState === 'add') {
    return (
      <ResponsiveStack alignItems="center" justifyContent="center">
        <LayoutStack>
          <GridToolbar>
            <Stack>
              <Button variant="contained" disabled endIcon={<AddIcon />}>
                Add
              </Button>
            </Stack>
            <Stack>
              <Button variant="outlined" disabled>
                <RefreshIcon />
              </Button>
            </Stack>
          </GridToolbar>
          <Paper
            sx={{
              display: 'flex',
              flex: 1,
              width: '100%',
              height: '100%',
              padding: 4,
              overflow: 'auto',
            }}
            elevation={1}
          >
            {pageIsLoading && !pageError && <Loading />}
            {!pageIsLoading && pageError && <ErrorState error={pageError} />}
            {!pageIsLoading && !pageError && (
              <Stack flex={1} width="100%">
                <Stack spacing={4}>
                  <FormControl label="Name" required>
                    <TextField
                      fullWidth
                      value={newZone.name}
                      {...validationResults.results['ZoneName']}
                      onChange={(e) => {
                        // dispatch({
                        //   type: 'ZoneName',
                        //   payload: e.target.value,
                        // });
                        setNewZone((prevNewZone: any) => {
                          return {
                            ...prevNewZone,
                            name: e.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl label="Height" required>
                    <TextField
                      required
                      fullWidth
                      value={newZone.height}
                      {...validationResults.results['Height']}
                      onChange={(e) => {
                        if (
                          REGEX_VALIDATION.decimalPattern.test(e.target.value)
                        ) {
                          // dispatch({
                          //   type: 'Height',
                          //   payload: e.target.value,
                          // });
                          setNewZone((prevNewZone: any) => {
                            return {
                              ...prevNewZone,
                              height: e.target.value,
                            };
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      padding: '4px 0',
                      // borderBottom: 'solid 1px ',
                    }}
                    variant="h2Medium"
                  >
                    Click on the map to add new points
                  </Typography>
                  <Typography
                    sx={{
                      color: 'red',
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    style={{
                      marginTop: 0,
                    }}
                    variant="h2Medium"
                  >
                    <WarningIcon style={{ marginRight: '4px' }} />
                    Zoom in for better accuracy
                  </Typography>

                  {newZone.points.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        sx={{
                          width: '60px',
                          backgroundColor: 'lightgray',
                          borderRadius: '0',
                        }}
                        size="small"
                        onClick={() =>
                          setNewZone((prevNewZone: any) => {
                            return {
                              ...prevNewZone,
                              points: prevNewZone.points.slice(
                                0,
                                prevNewZone.points.length - 1,
                              ),
                            };
                          })
                        }
                      >
                        <UndoIcon />
                      </IconButton>
                      <span style={{ marginLeft: '10px' }}>Undo</span>
                    </div>
                  )}
                  {/* <FormControl label="Type" required>
                    <Stack>
                      <Select
                        error={validationResults.results['ZoneTypeId'].error}
                        fullWidth
                        value={ZoneTypeId}
                        onChange={(e) => {
                          onResetPolygonHandler();
                          onResetStartHandler();
                          setZoneTypeId(e.target.value);
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiSelect-select .notranslate::after': {
                            content: '"Select zone type"',
                            opacity: 0.42,
                          },
                        }}
                      >
                        {zoneTypesData?.data.map((zoneTypes: any) => (
                          <MenuItem key={zoneTypes.ID} value={zoneTypes.ID}>
                            {zoneTypes.ZoneTypeDesc}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={validationResults.results['ZoneTypeId'].error}
                      >
                        {validationResults?.results['ZoneTypeId']?.helperText}
                      </FormHelperText>
                    </Stack>
                  </FormControl> */}
                  {/*<FormControlLabel*/}
                  {/*  control={*/}
                  {/*    <Checkbox*/}
                  {/*      checked={state.DisableInactive}*/}
                  {/*      onChange={(e) => {*/}
                  {/*        dispatch({*/}
                  {/*          type: 'DisableInactive',*/}
                  {/*          payload: e.target.checked,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  }*/}
                  {/*  label="Disable inactive"*/}
                  {/*/>*/}
                  {ZoneTypeId !== '' &&
                    (ZoneTypeId === 2 ? (
                      <>
                        <div className="form-group row  mb-2">
                          <label
                            htmlFor="Start"
                            className="col-sm-4 col-form-label"
                          >
                            Central Point
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              id="Start"
                              value={
                                'X: ' +
                                convertXMapPointToXMeter(
                                  Start.x,
                                  relpxxAdd,
                                  xPointCraneRef,
                                  meter2Pixel,
                                ) +
                                '   Y: ' +
                                convertYMapPointToYMeter(
                                  Start.y,
                                  relpxxAdd,
                                  yPointCraneRef,
                                  meter2Pixel,
                                )
                              }
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group row  mb-2">
                          <label
                            htmlFor="Radius"
                            className="col-sm-4 col-form-label"
                          >
                            Radius
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              id="Radius"
                              value={convertRadiusMapToRadiusMeter(
                                Radius,
                                relpxxAdd,
                                meter2Pixel,
                              )}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          height: '200px',
                          width: '500px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        <h3 className="text-center">Points</h3>
                        <SvgPoints />
                      </div>
                    ))}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  flex={1}
                  spacing={2}
                >
                  {ZoneTypeId !== '' &&
                    (ZoneTypeId === 2 ? (
                      <Button onClick={onResetStartHandler} variant="outlined">
                        Reset Circle
                      </Button>
                    ) : (
                      <>
                        <Button
                          onClick={onResetPolygonHandler}
                          variant="outlined"
                        >
                          Reset Polygon
                        </Button>
                        <Button
                          onClick={onDeletePointHandler}
                          variant="outlined"
                        >
                          Delete Point
                        </Button>
                      </>
                    ))}

                  <Button onClick={onCancelAdd}>Cancel</Button>
                  <Button
                    variant="contained"
                    disabled={
                      !newZone.name ||
                      !newZone.height ||
                      newZone.points.length < 1
                    }
                    onClick={onSubmitAdd}
                  >
                    Create
                  </Button>
                </Stack>
              </Stack>
            )}
          </Paper>
        </LayoutStack>
      </ResponsiveStack>
    );
  }

  return (
    <ResponsiveStack alignItems="center" justifyContent="center">
      <LayoutStack>
        <GridToolbar>
          <Stack>
            <Button
              variant="contained"
              onClick={onClickAdd}
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          </Stack>
          <Stack>
            <Button
              sx={{ visibility: 'hidden' }}
              variant="outlined"
              // disabled={zoneIsFetching}
              onClick={async () => {
                // await refetchZones();
                await queryClient.invalidateQueries('GetProjectZonePoints');
              }}
            >
              {/* {zoneIsFetching ? */}
              {!zones ? <CircularProgress size={16} /> : <RefreshIcon />}
            </Button>
          </Stack>
        </GridToolbar>
        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            padding: 4,
            overflow: 'auto',
          }}
          elevation={1}
        >
          {/* {zoneIsFetching && !zonesError && <Loading />} */}
          {/* {!zoneIsFetching && zonesError && <ErrorState error={zonesError} />} */}
          {zones && (
            // && !zonesError && zoneIsSuccess &&
            <Grid
              {...{
                rows: zones || [],
                columnsDef: getColumnsDef(
                  ROW_HEIGHT,
                  onEdit,
                  onDelete,
                  onClickActive,
                ),
                onRowClicked,
                ROW_HEIGHT,
                maxVisibleRows,
              }}
            />
          )}
        </Paper>
      </LayoutStack>
      {!!deleteItemId && (
        <ConfirmDialog
          title="Delete Zone"
          text="Are you sure you want to delete this zone?"
          onOk={onDeleteConfirmed}
          onCancel={onDeleteClose}
          onDeleteSuccess={onDeleteSuccess}
          asyncApi={{
            loading: deleteZoneIsLoading,
            error: deleteZoneError,
            data: deleteZoneData,
          }}
        />
      )}

      {editItemId && !!zones?.length && (
        <EditZone
          siteId={siteId}
          item={zones.find(({ id }: any) => id === editItemId)}
          onClose={() => onEdit()}
          onSuccess={onEditSuccess}
        />
      )}
      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={error}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </ResponsiveStack>
  );
};

export default ZonesToolbar;
