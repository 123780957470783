import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CalcSumTask } from '../../store/calcsumtask';
import { useAppSelector } from '../../store/hooks';
interface WorkDayChartProps {
  calcSumTask: CalcSumTask[];
  Report: boolean;
}
const WorkDayChart = ({ calcSumTask, Report }: WorkDayChartProps) => {
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level
  );
  let centerX = mediaResLevel < 3 ? '50%' : '50%';
  let centerY = mediaResLevel < 3 ? '50%' : '50%';
  let fsNumber = mediaResLevel < 3 ? '12px' : '14px';
  let MRlayout = mediaResLevel < 3 ? 'horizontal' : 'vertical';
  let olWidth = mediaResLevel < 4 ? 220 : 330;
  let showLegend = mediaResLevel < 4 ? false : true;
  let objHeight = Report ? 200 : 140;
  let objWidth = Report ? 300 : olWidth;
  let objFontSize = Report ? '10px' : fsNumber;
  showLegend = Report ? true : showLegend;
  MRlayout = Report ? 'vertical' : MRlayout;
  var calcSumTask1 = calcSumTask.map((v) => ({ name: v.name, y: v.y }));
  const options: Highcharts.Options = {
    chart: {
      plotShadow: false,
      type: 'pie',
      height: objHeight,
      width: objWidth,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 65,
        center: [centerX, centerY],
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          style: {
            fontSize: objFontSize,
            fontFamily: 'Quicksand',
          },
          enabled: true,
          distance: 4,
          format: '{point.percentage:.1f} %',
        },
      },
    },
    legend: {
      // align: 'left',
      // verticalAlign: 'top',
      // //@ts-ignore
      // layout: MRlayout,
      // itemStyle: {
      //     fontSize: fsNumber,
      //     fontFamily: "Quicksand",
      // },
      // symbolHeight:8,
      // symbolWidth:8,
      // itemMarginBottom: 34,
      padding: 0,
      align: 'left',
      verticalAlign: 'top',
      //@ts-ignore
      layout: MRlayout,

      itemStyle: {
        fontSize: fsNumber,
        fontFamily: 'Quicksand',
      },
      symbolHeight: 8,
      symbolWidth: 8,
      itemMarginBottom: 12,
      x: 0,
      y: 0,
    },

    colors: ['#ff6f00', '#005573', '#00c1b6'],
    series: [
      {
        name: 'Daily Crane utilization',
        type: 'pie',
        colorByPoint: true,
        innerSize: '75%',
        showInLegend: showLegend,
        data: calcSumTask1,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WorkDayChart;
