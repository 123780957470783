import { ActionsRenderer } from '../../components/grid';
import { Stack, Typography } from '@mui/material';
import React from 'react';

export default (
  ROW_HEIGHT: string | number,
  onEdit?: any,
  onDelete?: any,
  isMobile?: boolean,
) => {
  return [
    {
      headerName: 'Date Created',
      field: 'DateCreated',
      sortable: true,
      cellRendererFramework: ({ data }: any) => {
        return (
          <Stack
            spacing={2}
            width="100%"
            height={ROW_HEIGHT}
            sx={{ paddingTop: 2, paddingBottom: 2 }}
          >
            <Typography variant="h2Medium">
              {data.Barcode} / {data.CargoTypeDesc}
            </Typography>
            <Typography variant="smallRegular" color="grey.500">
              Quantity: <b>{data.Quantity}</b>
            </Typography>
            <Typography variant="smallRegular" color="grey.500">
              DateCreated: <b>{data.DateCreated}</b>
            </Typography>
          </Stack>
        );
      },
      hide: !isMobile,
    },
    {
      headerName: 'Barcode',
      field: 'barcode',
      sortable: true,
      hide: isMobile,
      minWidth: 160,
      maxWidth: 170,
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      sortable: true,
      hide: isMobile,
      minWidth: 100,
      maxWidth: 120,
    },
    {
      headerName: 'Cargo Type',
      field: 'cargoType.name',
      sortable: true,
      hide: isMobile,
    },
    {
      headerName: 'Source Latitude',
      field: 'sourceLat',
      sortable: true,
      hide: isMobile,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      headerName: 'Source Longitude',
      field: 'sourceLong',
      sortable: true,
      hide: isMobile,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      headerName: 'Destination Latitude',
      field: 'destinationLat',
      sortable: true,
      hide: isMobile,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      headerName: 'Destination Longitude',
      field: 'destinationLong',
      sortable: true,
      hide: isMobile,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      headerName: '',
      sortable: false,
      field: 'id',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onEdit,
        onDelete,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
