import { Grid, Paper, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import {
  DailyMaterialsChart,
  DailyUtilizationChart,
  IdleSchedulingGantChart,
  LiftSchedulingGantChart,
  LiftsPerHourChart,
  MaterialsTypeChart,
  MetricsChart,
  WindSpeedChart,
} from '../../../components/dashboard/projectReport/charts';
import { Loading } from '../../../components/styled';
import { useAppSelector } from '../../../store/hooks';
import * as storageHelper from '../../../utils/storageHelper';
import { ULTRAWIS_SELECTED_CRANE } from '../../../variables/constants';
import { socket } from '../../../websocket-client/websocket-client';
dayjs.extend(utc);
dayjs.extend(timezone);

const Charts = ({
  loading,
  startDate,
  isLoadingTelemetries,
  setIsLoadingTelemetries,
  refreshTelemeries,
  setRefreshTelemeries,
}: any) => {
  const addMargin = useMediaQuery('(min-width: 1200px)');
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const timezone =
    useAppSelector((state: any) => state.projectCrane.Timezone) ||
    'Asia/Jerusalem';
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsStats = useAppSelector(
    (state: any) => state.projectCrane.liftsStats,
  );
  const selectedCrane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE);

  const [isSelectedDateToday, setIsSelectedDateToday] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState(0);
  const [telemetries, setTelemetries] = useState<any>([]);
  const [isDateToday, setIsDateToday] = useState(
    dayjs(startDate).isSame(dayjs(), 'day'),
  );
  const [isTelemetryRefreshedFromWindTab, setIsTelemetryRefreshedFromWindTab] =
    useState(false);

  useEffect(() => {
    setIsDateToday(dayjs(startDate).isSame(dayjs(), 'day'));
  }, [startDate]);

  useEffect(() => {
    // console.log('refreshTelemeries', refreshTelemeries);
    if (!refreshTelemeries) return;
    setIsLoadingTelemetries(true);
    requestTelemetries();
  }, [refreshTelemeries]);

  // useEffect to handle real-time data
  useEffect(() => {
    // console.log('selectedCrane', selectedCrane);
    // console.log('selectedDate', selectedDate);
    if (!selectedCrane) return;
    socket.connect();

    if (refreshTelemeries) setRefreshTelemeries(false);

    setIsLoadingTelemetries(true);
    // requestTelemetries();
    // setTelemetries([]);

    socket.on('telemetries-response', (data) => {
      // console.log('Received telemetries:', data);
      setIsLoadingTelemetries(false);

      if (!data || data.length === 0) {
        setTelemetries([]);
        return;
      }
      if (data.length === 1) {
        setTelemetries((prevState: any) => [
          ...prevState,
          ...data.map((telemetry: any) => {
            return {
              ...telemetry,
              timestamp: dayjs.utc(telemetry.timestamp).tz(timezone),
            };
          }),
        ]);
      } else {
        setTelemetries((prevState: any) => [
          ...data.map((telemetry: any) => {
            return {
              ...telemetry,
              timestamp: dayjs.utc(telemetry.timestamp).tz(timezone),
            };
          }),
        ]);
      }
    });

    // Cleanup listeners on component unmount
    return () => {
      // console.log('Cleaning up');
      socket.disconnect();
      socket.off('connect');
      socket.off('disconnect');
      socket.off('telemetries-response');
    };
  }, [selectedCrane, startDate, selectedTimeRange]);

  const requestTelemetries = async () => {
    // check if selectedDate is today
    const today = dayjs().format('YYYY-MM-DD');
    const selectedDateDayjs = dayjs(startDate).format('YYYY-MM-DD');
    const isSelectedDateTodayNew = today === selectedDateDayjs;
    setIsSelectedDateToday(isSelectedDateTodayNew);
    const { start, end } = getStartAndEnd(
      isSelectedDateTodayNew,
      selectedTimeRange,
    );

    const telemetryRequest = {
      craneId: selectedCrane,
      start: start,
      end: end,
    };
    // console.log('Sending telemetries request:', telemetryRequest);
    socket.emit('telemetries-request', telemetryRequest);
  };

  const getStartAndEnd = (
    isSelectedDateTodayNew: boolean,
    selectedTimeRange: number,
  ) => {
    if (!isSelectedDateTodayNew) {
      return {
        start: dayjs.utc(startDate).tz(timezone).startOf('day').toISOString(),
        end: dayjs.utc(startDate).tz(timezone).endOf('day').toISOString(),
      };
    }

    switch (selectedTimeRange) {
      case 0:
        return {
          start: dayjs
            .utc(startDate)
            .tz(timezone)
            .subtract(15, 'minute')
            .toISOString(),
          end: dayjs.utc(startDate).tz(timezone).toISOString(),
        };
      case 1:
        return {
          start: dayjs
            .utc(startDate)
            .tz(timezone)
            .subtract(1, 'hour')
            .toISOString(),
          end: dayjs.utc(startDate).tz(timezone).toISOString(),
        };

      case 2:
        return {
          start: dayjs
            .utc(startDate)
            .tz(timezone)
            .subtract(3, 'hour')
            .toISOString(),
          end: dayjs.utc(startDate).tz(timezone).toISOString(),
        };
      case 3:
        return {
          start: dayjs.utc(startDate).tz(timezone).startOf('day').toISOString(),
          end: dayjs.utc(startDate).tz(timezone).endOf('day').toISOString(),
        };
      default:
        return {
          start: dayjs.utc(startDate).tz(timezone).toISOString(),
          end: dayjs.utc(startDate).tz(timezone).toISOString(),
        };
    }
  };

  return (
    <>
      {isDateToday && (
        <Grid item xs={12} lg={6}>
          <Paper
            style={{ height: sm ? 'inherit' : medium ? 'inherit' : '400px' }}
            sx={{
              padding: 4,
              overflow: 'auto',
              marginRight: addMargin ? 4 : 0,
              minHeight: 400,
            }}
          >
            <MetricsChart
              data={lifts}
              height={220}
              startDate={startDate}
              isLoadingTelemetries={isLoadingTelemetries}
              telemetries={telemetries}
              isTelemetryRefreshedFromWindTab={isTelemetryRefreshedFromWindTab}
              onDashboard
            />
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? 0 : addMargin ? 4 : 0,
          }}
        >
          <WindSpeedChart
            data={lifts}
            height={220}
            onDashboard
            startDate={startDate}
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            isLoadingTelemetries={isLoadingTelemetries}
            telemetries={telemetries}
            isSelectedDateToday={isSelectedDateToday}
            setIsLoadingTelemetries={setIsLoadingTelemetries}
            setRefreshTelemeries={setRefreshTelemeries}
            isTelemetryRefreshedFromWindTab={isTelemetryRefreshedFromWindTab}
            setIsTelemetryRefreshedFromWindTab={
              setIsTelemetryRefreshedFromWindTab
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          // style={{ height: sm ? '400px' : 'inherit' }}
          style={{ height: sm ? 'inherit' : '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            // width: sm ? '100%' : 'inherit',
            marginRight: isDateToday ? (addMargin ? 4 : 0) : 0,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <DailyUtilizationChart height={220} onDashboard />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? 0 : addMargin ? 4 : 0,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <LiftsPerHourChart height={220} onDashboard />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? (addMargin ? 4 : 0) : 0,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <MaterialsTypeChart height={220} onDashboard />
            // <CraneLiftsByHourChart data={data} onDashboard />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? 0 : addMargin ? 4 : 0,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <LiftSchedulingGantChart
              height={220}
              startDate={startDate}
              onDashboard
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? (addMargin ? 4 : 0) : 0,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <DailyMaterialsChart height={220} onDashboard />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper
          style={{ height: '400px' }}
          sx={{
            padding: 4,
            overflow: 'auto',
            marginRight: isDateToday ? 0 : addMargin ? 4 : 0,
            marginBottom: 20,
          }}
        >
          {loading ? (
            <Loading sx={{ minHeight: 300 }} />
          ) : (
            <IdleSchedulingGantChart
              height={220}
              startDate={startDate}
              onDashboard
            />
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default Charts;
