import React from 'react';
import {
  Badge,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridToolbar } from '../../../components/styled';
import {
  NextPrevDate,
  ProjectCraneSelector,
} from '../../../components/selectors';
import { useLocation } from 'react-router-dom';

const Toolbar = ({
  setSelectedProject,
  setSelectedCrane,
  selectedCargoType,
  onCargoTypeChange,
  selectedDate,
  setSelectedDate,
  eventsLength,
  onRefresh,
  cargoTypes,
}: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const { pathname } = useLocation();

  return (
    <GridToolbar>
      <ProjectCraneSelector
        onProjectChange={setSelectedProject}
        onCraneChange={setSelectedCrane}
        sx={{ justifyContent: 'flex-start', width: '100%' }}
      >
        {pathname === 'cranescheduler' && (
          <Stack
            spacing={2}
            sx={{
              marginRight: sm ? 0 : 4,
              marginBottom: 4,
              width: sm ? '100%' : 'auto',
            }}
          >
            <Typography variant="smallRegular">Cargo type</Typography>
            <Select
              value={selectedCargoType}
              onChange={onCargoTypeChange}
              sx={{
                width: sm ? '100%' : 220,
                marginRight: sm ? 0 : 4,
                marginBottom: 4,
                '& .MuiSelect-select .notranslate::after': {
                  content: '"Select cargo type"',
                  opacity: 0.42,
                },
              }}
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {cargoTypes?.map((CargoType: any) => (
                <MenuItem key={CargoType.ID} value={CargoType.ID}>
                  {CargoType.CargoTypeDesc}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">&nbsp;</Typography>
          <Stack direction="row" alignItems="center" spacing={6}>
            <NextPrevDate
              defaultDate={selectedDate}
              onDateChange={setSelectedDate}
            />
            <Badge badgeContent={eventsLength || 0} color="success">
              <Button
                variant="outlined"
                onClick={onRefresh}
                sx={{ height: 39 }}
              >
                <RefreshIcon />
              </Button>
            </Badge>
          </Stack>
        </Stack>
      </ProjectCraneSelector>
    </GridToolbar>
  );
};

export default Toolbar;
