import { styled, Grid, Stack, CircularProgress } from '@mui/material';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import React from 'react';

export const StyledGrid = styled(Grid)`
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  background-color: #ffffff;
  height: 100%;
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 80%;

  .swiper-wrapper {
    margin: 12px 0 24px 0;
  }

  .swiper-horizontal > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 8px;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .swiper-scrollbar-drag {
    background-color: #ff6f00;
  }

  .swiper-pagination-fraction {
    bottom: 30px;
    color: #ff6f00;
    font-weight: bold;
    font-size: 18px;
    background-color: white;
    width: auto;
    transform: translateX(-50%);
    left: 50%;
    padding: 0 8px;
    border-radius: 8px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      font-size: 36px;
      color: #ff6f00;
    }
  }
`;

export const Loading = (props: any) => {
  return (
    <Stack
      width="100%"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <CircularProgress size={36} />
    </Stack>
  );
};
