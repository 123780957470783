import React, { useEffect, useState, useMemo } from 'react';
import { debounce } from '@mui/material/utils';

import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
  StyledAutocomplete,
} from '../../../../../components/styled';
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid } from '../../../../../components/grid';
import getColumnsDef from './columns';
import { useQueryClient } from 'react-query';
import {
  useGetProjectUsers,
  // usePostAvailableUsers,
  useDeleteProjectUser,
  usePostProjectUser,
  useGetSiteUsersSuggestions,
} from '../../../../../queries';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../../../../components/confirm';
import { SearchInput } from '../../../../../components/inputs';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorState } from '../../../../../components/alerts';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const ProjectUsers = ({ siteId, siteUsers, refetchSite }: any) => {
  const [filters, setFilters] = useState<any>({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState<any>(null);
  const [search, setSearch] = useState<any>('');

  //#region react-query
  const queryClient = useQueryClient();

  // const {
  //   data: projectUsersData,
  //   error: projectUsersError,
  //   refetch: projectUsersRefetch,
  //   isFetching: projectUsersIsFetching,
  // } = useGetProjectUsers({
  //   projectId,
  // });

  const {
    mutate: availableUsersMutate,
    isLoading: availableUsersIsLoading,
    error: availableUsersError,
    data: availableUsersData,
    reset: availableUsersReset,
  } = useGetSiteUsersSuggestions({
    onSettled: () => {
      queryClient.invalidateQueries('GetSiteUsersSuggestions');
    },
  });

  const {
    mutate: deleteProjectUserMutate,
    isLoading: deleteProjectUserIsLoading,
    error: deleteProjectUserError,
    data: deleteProjectUserData,
    reset: deleteProjectUserReset,
  } = useDeleteProjectUser({
    onSettled: () => {
      queryClient.invalidateQueries('DeleteProjectUser');
    },
  });

  const {
    mutate: createProjectUserMutate,
    isLoading: createProjectUserIsLoading,
    error: createProjectUserError,
  } = usePostProjectUser({
    onSuccess: () => {
      setUsersInputValue('');
      setUsersValue([]);
      availableUsersReset();
      setOpenSnackbar(true);
      setAlertSuccessMessage('User/s was successfully added to site!');
      setTimeout(async () => await refetchSite(), 100);
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostProjectUser');
    },
  });

  //#endregion

  //#region Delete
  const [deleteItemId, setDeleteItemId] = useState<number | null>();

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = () =>
    deleteProjectUserMutate({ siteId, userId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteProjectUserReset();
    onDelete();
    setTimeout(async () => await refetchSite(), 100);
  };

  const onDeleteClose = () => {
    deleteProjectUserReset();
    onDelete();
  };

  //#endregion

  //#region Autocomplete

  const [usersValue, setUsersValue] = React.useState<any>([]);
  const [usersInputValue, setUsersInputValue] = React.useState<any>('');

  useEffect(() => {
    if (usersInputValue !== '') {
      getAvailableUsers({
        siteId,
        search: usersInputValue.toLowerCase(),
      });
    } else {
      availableUsersReset();
    }
  }, [usersInputValue]);

  const getAvailableUsers = useMemo(
    () =>
      debounce(
        ({ siteId, search }) => availableUsersMutate({ siteId, search }),
        400,
      ),
    [],
  );

  useEffect(() => {
    if (!siteUsers) return;
    setFilters(siteUsers);
  }, [siteUsers]);

  //#endregion

  const onClickAddProjectUsers = () => {
    if (usersValue.length === 0) return;
    const usersIds = usersValue.map(({ id }: any) => id);
    createProjectUserMutate({ siteId, usersIds });
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setAlertSuccessMessage(null);
  };

  const error = ((createProjectUserError || availableUsersError) as Error)
    ?.message;

  return (
    <LayoutStack>
      <GridToolbar>
        <Stack direction="row" spacing={4} alignItems="flex-start">
          <StyledAutocomplete
            sx={{ width: 300 }}
            multiple
            autoComplete
            filterOptions={(x) => x}
            forcePopupIcon={false}
            clearOnBlur={false}
            loading={availableUsersIsLoading}
            isOptionEqualToValue={(option: any, value: any) =>
              option.ud === value.id
            }
            id="available-users"
            onChange={(event: any, newValue: any) => {
              console.log('newValue', newValue);
              setUsersValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setUsersInputValue(newInputValue);
            }}
            value={usersValue}
            inputValue={usersInputValue}
            options={
              availableUsersData?.data
                ? availableUsersData?.data.filter((user: any) => {
                    return !siteUsers.some(
                      (siteUser: any) =>
                        siteUser.user.id === user.id ||
                        usersValue.some((value: any) => value.id === user.id),
                    );
                  })
                : []
            }
            getOptionLabel={(option: any) =>
              `${option?.firstName} ${option?.lastName}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Start typing to search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {availableUsersIsLoading ? (
                        <CircularProgress size={16} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <Button
            variant="contained"
            disabled={usersValue.length === 0 || createProjectUserIsLoading}
            onClick={onClickAddProjectUsers}
            endIcon={<AddIcon />}
            sx={{ height: 39 }}
          >
            {createProjectUserIsLoading ? (
              <CircularProgress size={16} />
            ) : (
              `Add`
            )}
          </Button>
        </Stack>

        <Stack direction="row" spacing={4} alignItems="flex-start">
          <SearchInput
            placeholder="Search by user name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              const newFilters = siteUsers.filter(
                (siteUser: any) =>
                  siteUser.user.firstName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  siteUser.user.lastName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()),
              );
              setFilters(newFilters);
            }}
            onClear={() =>
              setFilters(siteUsers.map((siteUser: any) => siteUser.user))
            }
          />

          {/* <Button
            variant="outlined"
            disabled={projectUsersIsFetching}
            onClick={() => projectUsersRefetch()}
            sx={{ height: 39 }}
          >
            {projectUsersIsFetching ? (
              <CircularProgress size={16} />
            ) : (
              <RefreshIcon />
            )}
          </Button> */}
        </Stack>
      </GridToolbar>
      <ContentStack>
        {/* {projectUsersIsFetching && !projectUsersError && <Loading />}
        {!projectUsersIsFetching && projectUsersError && (
          <ErrorState error={projectUsersError} />
        )} */}
        {
          // !projectUsersIsFetching && !projectUsersError &&
          siteUsers && (
            <Grid
              {...{
                rows: filters,
                columnsDef: getColumnsDef(ROW_HEIGHT, onDelete),
                onDelete,
                ROW_HEIGHT,
                maxVisibleRows,
                filters,
              }}
            />
          )
        }

        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete Site User"
            text="Are you sure you want to remove this user from the site?"
            onOk={onDeleteConfirmed}
            onCancel={onDeleteClose}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={{
              loading: deleteProjectUserIsLoading,
              error: deleteProjectUserError,
              data: deleteProjectUserData,
            }}
          />
        )}

        <Snackbar
          open={openSnackbar && (!!alertSuccessMessage || !!error)}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...(!!error ? { message: error } : {})}
          onClose={closeSnackbar}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {alertSuccessMessage && (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={closeSnackbar}
              severity="success"
              sx={{ width: '100%' }}
            >
              {alertSuccessMessage}
            </MuiAlert>
          )}
        </Snackbar>
      </ContentStack>
    </LayoutStack>
  );
};

export default ProjectUsers;
