import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalcTask {
  averageTaskTime: string;
  shortestLiftTime: string;
  longestLiftTime: string;
  cnt: string;
}
export interface CalcTaskT {
  calctask: CalcTask;
}
const initialState: CalcTaskT = {
  calctask: {
    averageTaskTime: '',
    shortestLiftTime: '',
    longestLiftTime: '',
    cnt: '',
  },
};

const calcTaskSlice = createSlice({
  name: 'calctask',
  initialState: initialState,
  reducers: {
    setCalcTask(state, action: PayloadAction<CalcTask>) {
      state.calctask = action.payload;
      // console.log (state.calctask);
    },
    resetCalcTask: () => initialState,
  },
});

export const calcTaskActions = calcTaskSlice.actions;

export default calcTaskSlice.reducer;
