import React, { useEffect, useReducer, useState } from 'react';
import '../../components/ObjList.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ErrorAlert } from '../../components/alerts';
import { NumberInput } from '../../components/NumberInput';
import { Loading } from '../../components/styled';
import useAssets from '../../hooks/useAssets';
import * as storageHelper from '../../utils/storageHelper';
import {
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_CRANE_DATA,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import ErrorState from '../../components/alerts/ErrorState';
import { useGetCargoType, useGetProject } from '../../queries';
import { AssetSitemap } from './components';
import {
  GoogleMapPoint,
  SiteGoogleMapsMode,
} from '../../components/SiteGoogleMaps/SiteGoogleMaps';
import { convertXYToLatLong } from '../../utils/coordinatesHelper';
import AssetMap from './components/AssetMap';
import { Sign } from 'crypto';
import SignallerGoogleMaps from '../SignallerRequest/components/SignallerGoogleMaps/SignallerGoogleMaps';
import { craneTasksActions } from '../../store/craneTask';

const initialState: any = {
  barcode: '',
  sourceLat: '',
  sourceLong: '',
  destinationLat: '',
  destinationLong: '',
  cargoTypeId: '',
  quantity: 200,
  imagePath: null,
};

const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const CreateAsset = ({ onClose, onSuccess, item = initialState }: any) => {
  const { createAssetApi, createAssetApiReset } = useAssets();
  const { loading, error, data } = useAppSelector(
    (state) => state.asset.createApi,
  );
  const [siteCenter, setSiteCenter] = useState<GoogleMapPoint>({
    lat: 0,
    lng: 0,
  });

  const [addOnClickMap, setAddOnClickMap] = useState<
    'none' | 'source' | 'destination'
  >('source');

  const projectId = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);
  // const Project = useAppSelector((state: any) => state.projectCrane.Project);
  // const user = useAppSelector((state) => state.user.user);
  // const selectedCrane = useAppSelector(
  //   (state: any) => state.projectCrane.selectedCrane,
  // );
  const selectedCrane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE_DATA);
  const User = storageHelper.getItem(USER);
  const Project = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);

  const dispatchStore = useAppDispatch();
  const templates = useAppSelector((state: any) => state.craneTasks.templates);

  useEffect(() => {
    if (User && Project) {
      const site = User.sites.find((site: any) => site.id === Project);
      if (!site) return;
      setSiteCenter({
        lat: site.originLatitude,
        lng: site.originLongitude,
      });
    }
  }, [storageHelper]);

  const {
    data: projectData,
    isLoading: projectDataIsLoading,
    error: projectDataError,
  } = useGetProject(
    { projectId },
    {
      cacheTime: 0,
    },
  );

  const {
    xPointCraneRef,
    yPointCraneRef,
    Pixel2Meter: meter2Pixel,
    SiteMap: siteMap,
    LatCraneRef,
    LongCraneRef,
  } = projectData?.data?.projectData || {};

  useEffect(() => {
    if (LatCraneRef && LatCraneRef) {
      setSiteCenter({
        lat: LatCraneRef,
        lng: LongCraneRef,
      });
    }
  }, [projectData?.data?.projectData]);

  // const user = storageHelper.getItem(USER);
  const craneId = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE);

  const {
    isLoading: cargoTypesLoading,
    data: cargoTypesData,
    error: cargoTypesError,
  } = useGetCargoType();

  const [state, dispatch] = useReducer(reducer, item);

  const valid =
    // state.barcode.trim() !== '' &&
    state.sourceLat &&
    state.sourceLong &&
    state.destinationLat &&
    state.destinationLong &&
    state.cargoTypeId !== '' &&
    state.quantity !== '';

  const onSubmit = async () => {
    if (!valid) return;

    const asset = {
      barcode: state.barcode,
      quantity: Number(state.quantity),
      sourceLat: Number(state.sourceLat),
      sourceLong: Number(state.sourceLong),
      destinationLat: Number(state.destinationLat),
      destinationLong: Number(state.destinationLong),
      craneId: selectedCrane.id,
      cargoTypeId: state.cargoTypeId,
    };

    await createAssetApi(asset);

    dispatchStore(craneTasksActions.setTemplates([...templates, asset]));
    onSuccess();
    createAssetApiReset();
  };

  useEffect(() => {
    if (data) {
      onSuccess();
      createAssetApiReset();
    }
  }, [createAssetApiReset, data, onSuccess]);

  const isLoading = cargoTypesLoading || projectDataIsLoading;
  const pageError = cargoTypesError || projectDataError;

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Create Asset</DialogTitle>
      <DialogContent>
        {isLoading && !pageError && <Loading />}
        {!isLoading && pageError && <ErrorState error={pageError} />}
        {!isLoading && !pageError && (
          <Stack sx={{ padding: '32px 8px' }} spacing={4}>
            <Stack spacing={2}>
              <Typography variant="smallRegular">Barcode</Typography>
              <TextField
                fullWidth
                value={state.barcode}
                onChange={(e) =>
                  dispatch({ type: 'barcode', payload: e.target.value })
                }
              />
            </Stack>
            <Stack direction="row" spacing={4}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="smallRegular">Source latitude</Typography>
                <NumberInput
                  mobile
                  step={0.00001}
                  // precision={3}
                  value={state.sourceLat}
                  onChange={(value) =>
                    dispatch({
                      type: 'sourceLat',
                      payload: value,
                    })
                  }
                />
              </Stack>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="smallRegular">Source longitude</Typography>
                <NumberInput
                  mobile
                  step={0.00001}
                  // precision={3}
                  value={state.sourceLong}
                  onChange={(value) =>
                    dispatch({
                      type: 'sourceLong',
                      payload: value,
                    })
                  }
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="smallRegular">
                  Destination latitude
                </Typography>
                <NumberInput
                  mobile
                  step={0.00001}
                  // precision={3}
                  value={state.destinationLat}
                  onChange={(value) =>
                    dispatch({
                      type: 'destinationLat',
                      payload: value,
                    })
                  }
                />
              </Stack>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="smallRegular">
                  Destination longitude
                </Typography>
                <NumberInput
                  mobile
                  step={0.00001}
                  // precision={3}
                  value={state.destinationLong}
                  onChange={(value) =>
                    dispatch({
                      type: 'destinationLong',
                      payload: value,
                    })
                  }
                />
              </Stack>
            </Stack>
            <Stack spacing={2}>
              {/* <AssetSitemap
                {...{
                  siteMap,
                  state,
                  xPointCraneRef,
                  yPointCraneRef,
                  meter2Pixel,
                  addOnClickMap,
                  setAddOnClickMap,
                  dispatch,
                }}
              /> */}
              <AssetMap
                {...{
                  siteCenter,
                  mode: SiteGoogleMapsMode.create_asset,
                  dispatch,
                  sourceLat: state.sourceLat,
                  sourceLng: state.sourceLong,
                  destinationLat: state.destinationLat,
                  destinationLng: state.destinationLong,
                }}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="smallRegular">Cargo type</Typography>
              <Select
                value={state.cargoTypeId}
                onChange={(e) => {
                  dispatch({
                    type: 'cargoTypeId',
                    payload: e.target.value,
                  });
                }}
                sx={{
                  width: '100%',
                  '& .MuiSelect-select .notranslate::after': {
                    content: '"Select cargo type"',
                    opacity: 0.42,
                  },
                }}
              >
                {cargoTypesData?.data.map((cargoType: any) => (
                  <MenuItem key={cargoType.id} value={cargoType.id}>
                    {cargoType.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="smallRegular">Quantity</Typography>
              <TextField
                fullWidth
                type="number"
                value={state.quantity}
                onChange={(e) =>
                  dispatch({
                    type: 'quantity',
                    payload: e.target.value,
                  })
                }
              />
            </Stack>
          </Stack>
        )}
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {!cargoTypesError && (
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!valid || loading || cargoTypesLoading}
          >
            {loading || cargoTypesLoading ? (
              <CircularProgress size={16} />
            ) : (
              'Create'
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateAsset;
