import React, { useState, useCallback, useEffect } from 'react';
import { Box, IconButton, TextField, Tooltip, Typography, Stack } from '@mui/material';
import { Loading } from '../styled';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ clickable }: any) => ({
  width: '100%',
  cursor: clickable ? 'pointer' : 'auto',
  padding: clickable ? '8px 6px' : undefined,
  maxHeight: clickable ? '39px' : undefined,
  wordBreak: 'break-all',
  borderRadius: 4,
  border: 'solid 1px transparent',
  '&:hover': {
    backgroundColor: clickable ? '#E0E0E1' : undefined,
    transition: 'background-color .5s',
  },
}));

const InlineEdit = ({
  id,
  defaultValue,
  placeholder,
  readOnly,
  onUpdate,
  showEditingPencil,
  loading,
  noEmpty,
}: any) => {
  const [editing, setEditing] = useState(false);

  const [value, setValue] = useState(defaultValue);

  const onCancel = useCallback(() => {
    setEditing(false);
    setValue(defaultValue);
  }, [defaultValue]);

  const onSubmit = useCallback(
    (e) => {
      if (noEmpty && !value) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        setEditing(false);
        onUpdate(value);
      }
    },
    [onUpdate, value, noEmpty],
  );

  const onClickOutside = useCallback(
    ({ target }) => {
      let { parentNode } = target;
      const parentIds = [target.id];
      while (parentNode) {
        parentIds.unshift(parentNode.id);
        parentNode = parentNode.parentNode;
      }
      if (!parentIds.includes(id)) {
        setEditing(false);
        setValue(defaultValue);
      }
    },
    [id, defaultValue],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutside);
    return () => window.removeEventListener('click', onClickOutside);
  }, [onClickOutside]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Stack alignItems="center" direction="row" width="100%" spacing={2}>
      {editing ? (
        <TextField
          id={id}
          autoFocus
          fullWidth
          value={value}
          onChange={({ target }) => setValue(target.value)}
          placeholder={placeholder}
          disabled={readOnly || loading}
          InputProps={{
            readOnly: readOnly || loading,
          }}
          inputProps={{
            style: {
              padding: '0 7px'
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onSubmit(ev);
            }
          }}
        />
      ) : (
        <StyledTypography
          variant="mediumRegular"
          id={id}
          //@ts-ignore
          clickable={!showEditingPencil && !readOnly && !loading}
          onClick={() =>
            !showEditingPencil && !readOnly && !loading && setEditing(true)
          }
        >
          {value || placeholder}
        </StyledTypography>
      )}
      {editing && !loading && (
        <Box alignItems="center" sx={{ minWidth: 72 }}>
          <Tooltip title="Close">
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save">
            <IconButton disabled={noEmpty && !value} onClick={onSubmit}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {loading && <Loading />}
      {showEditingPencil && !editing && !readOnly && !loading && (
        <Tooltip title="Edit">
          <IconButton id={id} onClick={() => setEditing(true)} size="small">
            <EditIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
export default InlineEdit;
