import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdleTime, IdleTimes } from './idletimes';

const initialState: IdleTimes = {
  idletimes: [
    {
      num: 0,
      datesampling: '',
      starttimeday: '',
      startidle: '',
      stopidle: '',
      idleinminute: 0,
    },
  ],
};

const idleTimesRptSlice = createSlice({
  name: 'idletimesrpt',
  initialState,
  reducers: {
    setIdleTimeRpt(state, action: PayloadAction<IdleTime[]>) {
      state.idletimes = action.payload;
      //    console.log(state.idletimes);
    },
    resetIdleTimeRpt: () => initialState,
  },
});

export const idleTimesRptActions = idleTimesRptSlice.actions;

export default idleTimesRptSlice.reducer;
