import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Link,
  Box,
  Paper,
  TextField,
  Stack,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
} from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import * as storageHelper from '../utils/storageHelper';
import { USER } from '../variables/constants';
import './Login.scss';
import { useHttp } from '../hooks/useHttp/useHttp';
import { useAsyncFunction } from '../hooks/useAsyncFunction';
import { UserRole } from '../variables/enums';

const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

function Login() {
  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { loading, error, setError, call } = useAsyncFunction();
  const { login } = useHttp();
  const navigate = useNavigate();
  const user = storageHelper.getItem(USER);

  useEffect(() => {
    if (user?.id) {
      if (![UserRole.ADMIN, UserRole.COMPANY_EXECUTIVE].includes(user.role))
        navigate('/signallerrequest');
      else navigate('/dashboard');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [userEmail, password]);

  const handleLogin = async () => {
    call(() => login(userEmail, password));
  };

  if (user?.ID > 0) {
    return null;
  }

  // todo: client side validation

  return (
    <RootStyle className="login-page">
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ width: '100%' }}>
          <ContentStyle>
            <HeadingStyle>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Login to your account
              </Typography>
            </HeadingStyle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <TextField
                  id="email"
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email Address"
                  onChange={(e) => setUserEmail(e.target.value)}
                  value={userEmail}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="password"
                  fullWidth
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  value={password}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 4 }}
                >
                  <Link
                    component={RouterLink}
                    variant="mediumMedium"
                    to="/forgotpassword"
                    underline="hover"
                  >
                    Forgot password?
                  </Link>
                </Stack>
                <Button
                  id="submit"
                  disabled={loading}
                  fullWidth
                  size="large"
                  type="submit"
                  sx={{ my: 2, display: 'block', padding: 3 }}
                  variant="contained"
                  onClick={handleLogin}
                >
                  {loading ? <CircularProgress size={16} /> : 'Login'}
                </Button>
              </Box>
            </Box>
            <Collapse in={!!error}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setError(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {error?.message}
              </Alert>
            </Collapse>
          </ContentStyle>
        </Paper>
      </Container>
    </RootStyle>
  );
}

export default Login;
