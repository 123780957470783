import { createSlice } from '@reduxjs/toolkit';
export interface TimeFrame {
  startTimeFrame: string;
  windData: number[];
}

const initialState: TimeFrame = { startTimeFrame: '', windData: [] };

const timeFrameSlice = createSlice({
  name: 'timeframe',
  initialState,
  reducers: {
    setStartTimeFrame(state, action) {
      state.startTimeFrame = action.payload;
    },
    setWindData(state, action) {
      state.windData = action.payload;
    },
    resetTimeFrame: () => initialState,
  },
});

export const timeFrameActions = timeFrameSlice.actions;

export default timeFrameSlice.reducer;
