import { configureStore } from '@reduxjs/toolkit';

import calcTaskReducer from './calctask';
import calcSumTaskReducer from './calcsumtask';
import lastTasksReducer from './lasttasks';
import tasksReducer from './tasks';
import tasksRptReducer from './tasksrpt';
import idleTimesReducer from './idletimes';
import idleTimesRptReducer from './idletimesrpt';
import telemetriesReducer from './telemetries';
import telemetriesRptReducer from './telemetriesrpt';
import dailyVideoReducer from './dailyvideo';
import lasttelemetryReducer from './lasttelemetry';
import mediaresReducer from './mediares';
import userReducer from './user';
import projectCraneReducer from './projectcrane';
import timeFrameRptReducer from './timeframerpt';
import timeFrameReducer from './timeframe';

import cargoTypeReducer from './cargotype';
import tasksInHoursReducer from './tasksinhours';
import tasksInHoursRptReducer from './tasksinhoursrpt';
import calcSumTaskRptReducer from './calcsumtaskrpt';
import GanttTasksReducer from './gantttasks';
import GanttTasksRptReducer from './gantttasksrpt';
import tasksByCategoryReducer from './tasksbycategory';
import tasksByCategoryRptReducer from './tasksbycategoryrpt';
import searchAiReducer from './searchai';
import deviceTypeReducer from './devicetype';
import reportHeaderReducer from './reportheader';
import projectReportReducer from './projectReport';
import craneLiftImagesReducer from './craneLiftImages';
import assetReducer from './asset';
import craneTaskReducer from './craneTask';

export const store = configureStore({
  reducer: {
    lasttasks: lastTasksReducer,
    tasks: tasksReducer,
    calctask: calcTaskReducer,
    calcsumtask: calcSumTaskReducer,
    calcsumtaskrpt: calcSumTaskRptReducer,
    idletimes: idleTimesReducer,
    telemetries: telemetriesReducer,
    dailyVideo: dailyVideoReducer,
    ReportHeader: reportHeaderReducer,
    lasttelemetry: lasttelemetryReducer,
    mediares: mediaresReducer,
    user: userReducer,
    projectCrane: projectCraneReducer,
    tasksrpt: tasksRptReducer,
    telemetriesrpt: telemetriesRptReducer,
    idletimesrpt: idleTimesRptReducer,
    timeFrame: timeFrameReducer,
    timeframerpt: timeFrameRptReducer,
    cargoType: cargoTypeReducer,
    deviceType: deviceTypeReducer,
    tasksInHours: tasksInHoursReducer,
    tasksInHoursRpt: tasksInHoursRptReducer,
    GanttTasks: GanttTasksReducer,
    searchAi: searchAiReducer,
    tasksByCategory: tasksByCategoryReducer,
    tasksByCategoryRpt: tasksByCategoryRptReducer,
    GanttTasksRpt: GanttTasksRptReducer,
    ProjectReport: projectReportReducer,
    craneLiftImages: craneLiftImagesReducer,
    asset: assetReducer,
    craneTasks: craneTaskReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
