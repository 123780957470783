import { useDispatch } from 'react-redux';
import { tasksActions } from '../store/tasks';
import { tasksRptActions } from '../store/tasksrpt';
import { tasksInHoursActions } from '../store/tasksinhours';
import { tasksInHoursRptActions } from '../store/tasksinhoursrpt';
import { useHttp } from './useHttp/useHttp';
import { ganttTasksActions } from '../store/gantttasks';
import { tasksByCategoryActions } from '../store/tasksbycategory';
import { tasksByCategoryRptActions } from '../store/tasksbycategoryrpt';
import { ganttTasksRptActions } from '../store/gantttasksrpt';
import { ApiMethods } from '../variables/enums';
import {
  GANTT_TASKS_PATHS,
  TASK_BY_HOURS_PATHS,
  TASK_PATHS,
  TASKS_BY_CATEGORY_PATHS,
  TASKS_RPT_PATHS,
} from '../variables/endpoints';

const useTasks = () => {
  const dispatch = useDispatch();
  const { api } = useHttp();

  const setHandlerTasks = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_PATHS.getVTasks(dateProp, craneId.toString()),
      });
      dispatch(tasksActions.setTasks(data));
    } catch (err) {}
  };

  const setHandlerTasksRpt = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASKS_RPT_PATHS.getTasksRpt(dateProp, craneId.toString()),
      });
      dispatch(tasksRptActions.setTasksRpt(data));
    } catch (err) {}
  };

  const setHandlerGanttTasks = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: GANTT_TASKS_PATHS.getGanttTasks(dateProp, craneId.toString()),
      });
      dispatch(ganttTasksActions.setGanttTasks(data));
    } catch (err) {}
  };

  const setHandlerGanttTasksRpt = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: GANTT_TASKS_PATHS.getGanttTasks(dateProp, craneId.toString()),
      });
      dispatch(ganttTasksRptActions.setGanttTasksRpt(data));
    } catch (err) {}
  };

  const setHandlerTasksByCategory = async (
    dateProp: string,
    craneId: number
  ) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASKS_BY_CATEGORY_PATHS.getTasksByCategory(
          dateProp,
          craneId.toString()
        ),
      });
      dispatch(tasksByCategoryActions.setTasksByCategory(data));
    } catch (err) {}
  };

  const setHandlerTasksByCategoryRpt = async (
    dateProp: string,
    craneId: number
  ) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASKS_BY_CATEGORY_PATHS.getTasksByCategory(
          dateProp,
          craneId.toString()
        ),
      });
      dispatch(tasksByCategoryRptActions.setTasksByCategoryRpt(data));
    } catch (err) {}
  };

  const setHandlerTasksInHours = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_BY_HOURS_PATHS.getTasksByHours(dateProp, craneId.toString()),
      });
      dispatch(tasksInHoursActions.setTasksInHours(data));
    } catch (err) {}
  };

  const setHandlerTasksInHoursRpt = async (
    dateProp: string,
    craneId: number
  ) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_BY_HOURS_PATHS.getTasksByHours(dateProp, craneId.toString()),
      });
      dispatch(tasksInHoursRptActions.setTasksInHoursRpt(data));
    } catch (err) {}
  };
  return {
    setHandlerTasks,
    setHandlerTasksRpt,
    setHandlerTasksInHours,
    setHandlerTasksInHoursRpt,
    setHandlerGanttTasks,
    setHandlerTasksByCategory,
    setHandlerTasksByCategoryRpt,
    setHandlerGanttTasksRpt,
  };
};

export default useTasks;
