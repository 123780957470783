export enum ApiMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

export enum DateFormatTypes {
  MOMENT_DATE = 'DD/MM/yyyy',
  MOMENT_DATE_TIME = 'DD/MM/yyyy HH:mm:ss',
}

// Don't use underscore [_] in notification types!
export enum UserNotificationTypes {
  // WIND_SPEED = 'wind-speed',
  WIND_SPEED = 'WIND_SPEED',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  COMPANY_EXECUTIVE = 'COMPANY_EXECUTIVE',
  SITE_TEAM_MEMBER = 'SITE_TEAM_MEMBER',
  CRANE_GROUND_TEAM_MEMBER = 'CRANE_GROUND_TEAM_MEMBER',
}

export enum LiftStateType {
  TRANSITION_WITHOUT_LOAD = 'TRANSITION_WITHOUT_LOAD',
  LOAD = 'LOAD',
  TRANSITION_WITH_LOAD = 'TRANSITION_WITH_LOAD',
  UNLOAD = 'UNLOAD',
}

export enum CraneTaskType {
  COME_HERE = 'COME_HERE',
  GO_THERE = 'GO_THERE',
  SOURCE_TO_DESTINATION = 'SOURCE_TO_DESTINATION',
  TEMPLATE = 'TEMPLATE',
}

export enum CraneTaskDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}
