import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as storageHelper from '../../../utils/storageHelper';
import { ULTRAWIS_SELECTED_PROJECT, USER } from '../../../variables/constants';
import { userPagesMobile } from './menuItems';

const MobileMenu = ({ toggleDrawer, handleCloseNavMenu }: any) => {
  const { pathname } = useLocation();
  const [openSettings, setOpenSettings] = React.useState(false);
  const user = storageHelper.getItem(USER);
  const regularUser = user.id;

  const toggleSettings = () => {
    setOpenSettings(!openSettings);
  };

  const selectedProjectStorage =
    storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT) || '';
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedProjectStorage) return;
    console.log('selectedProjectStorage', selectedProjectStorage);
    const isNumber = !isNaN(Number(selectedProjectStorage));
    console.log('isNumber', isNumber);
    if (isNumber) {
      console.log('navigate to logout');
      localStorage.clear();
      sessionStorage.clear();
      navigate('/logout');
    }
  }, [storageHelper]);

  return (
    <Box
      sx={{ width: 250, paddingTop: 4 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {regularUser &&
          userPagesMobile.map(({ label, route, icon }) => (
            <ListItem
              key={label}
              disablePadding
              selected={pathname === route}
              onClick={() => handleCloseNavMenu(route)}
            >
              <ListItemButton>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
        {/*<Divider />*/}
        {/*<ListItemButton onClick={toggleSettings}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <SettingsIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Settings" />*/}
        {/*  {openSettings ? <ExpandLess /> : <ExpandMore />}*/}
        {/*</ListItemButton>*/}
        {/*<Collapse in={openSettings} timeout="auto" unmountOnExit>*/}
        {/*  <List component="div" disablePadding sx={{ paddingLeft: 14 }}>*/}
        {/*    {settingsPages.map(({ label, route }) => (*/}
        {/*      <ListItem*/}
        {/*        key={label}*/}
        {/*        disablePadding*/}
        {/*        selected={pathname === route}*/}
        {/*        onClick={() => handleCloseNavMenu(route)}*/}
        {/*      >*/}
        {/*        <ListItemButton>*/}
        {/*          <ListItemText primary={label} />*/}
        {/*        </ListItemButton>*/}
        {/*      </ListItem>*/}
        {/*    ))}*/}
        {/*  </List>*/}
        {/*</Collapse>*/}
      </List>
    </Box>
  );
};

export default MobileMenu;
