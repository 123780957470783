import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task, Tasks } from './tasks';

const initialState: Tasks = {
  tasks: [
    {
      ID: 0,
      num: 0,
      CraneId: 0,
      CranesId: 0,
      DateLoad: '',
      TimeLoadStart: '',
      PicNameLoad: '',
      TimeUnloadEnd: '',
      PicNameUnload: '',
      TotalTimeLength: '',
      CargoTypeDesc: '',
    },
  ],
};

const tasksRptSlice = createSlice({
  name: 'tasksrpt',
  initialState,
  reducers: {
    setTasksRpt(state, action: PayloadAction<Task[]>) {
      state.tasks = action.payload;
      // console.log (state.tasks);
    },
    resetTasksRpt: () => initialState,
  },
});

export const tasksRptActions = tasksRptSlice.actions;

export default tasksRptSlice.reducer;
