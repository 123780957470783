import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = () => apiGetAsync('craneType');

export const useGetCraneType = () => {
  const key = ['GetCraneType'];
  const fn = () => api();

  return useQuery(key, fn, { staleTime: Infinity });
};
