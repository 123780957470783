import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => {
  const { id, ...restProps } = props;
  return apiPutAsync(`crane/${id}`, restProps);
};

export const usePutCrane = (options: any) => {
  const key = ['PutCrane'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
