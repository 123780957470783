// @ts-nocheck
import { Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import SiteGoogleMaps, {
  GoogleMapPoint,
  SiteGoogleMapsMode,
} from '../../../components/SiteGoogleMaps/SiteGoogleMaps';
import {
  convertLatLongToXY,
  convertXYToLatLong,
} from '../../../utils/coordinatesHelper';

const AssetMap = ({
  siteCenter,
  mode,
  dispatch,
  sourceLat,
  sourceLng,
  destinationLat,
  destinationLng,
}: any) => {
  const [source, setSource] = useState<GoogleMapPoint | null>(null);
  const [destination, setDestination] = useState<GoogleMapPoint | null>(null);

  useEffect(() => {
    if (!sourceLat || !sourceLng) {
      if (source?.lat || source?.lng) setSource(null);
      return;
    } else {
      setSource({ lat: Number(sourceLat), lng: Number(sourceLng) });
    }

    if (!destinationLat || !destinationLng) {
      if (destination?.lat || destination?.lng) setDestination(null);
      return;
    } else {
      setDestination({
        lat: Number(destinationLat),
        lng: Number(destinationLng),
      });
    }
    // const { lat: sourceLat, lng: sourceLng } = convertXYToLatLong(
    //   siteCenter,
    //   sourceX,
    //   sourceY,
    // );
    // const { lat, lng } = convertXYToLatLong(
    //   siteCenter,
    //   destinationX,
    //   destinationY,
    // );
  }, [siteCenter, sourceLat, sourceLng, destinationLat, destinationLng]);

  const onMapClick = (e: any) => {
    if (mode === SiteGoogleMapsMode.create_asset) {
      const point: GoogleMapPoint = {
        lat: Number(e.latLng.lat()).toFixed(8) as any,
        lng: Number(e.latLng.lng()).toFixed(8) as any,
      };
      console.log('point', point);
      // const { x, y } = convertLatLongToXY(siteCenter, point.lat, point.lng);
      if (!source) {
        setSource(point);
        dispatch({
          type: 'sourceLat',
          payload: point.lat.toString(),
        });
        dispatch({
          type: 'sourceLong',
          payload: point.lng.toString(),
        });
      } else if (!destination) {
        setDestination(point);
        dispatch({
          type: 'destinationLat',
          payload: point.lat.toString(),
        });
        dispatch({
          type: 'destinationLong',
          payload: point.lng.toString(),
        });
      }
    }
  };

  return (
    <div style={{ height: '400px' }}>
      <SiteGoogleMaps
        siteCenter={siteCenter}
        onMapClick={onMapClick}
        mode={mode}
      >
        {source && (
          <Marker
            title="Source"
            position={source}
            icon={{
              url: require('../../../assets/icons/source.svg').default,
              //   scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )}
        {destination && (
          <Marker
            title="Destination"
            position={destination}
            icon={{
              url: require('../../../assets/icons/destination.svg').default,
              //   scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )}
      </SiteGoogleMaps>
    </div>
  );
};

export default AssetMap;
