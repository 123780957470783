// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartProperty } from '../content/styled';
import { Stack, Typography } from '@mui/material';
import { JobsiteDailyConcreteTable } from '../tables';
import { sumBy, map } from 'lodash';
import { secondsToMinutes } from './utils';

const JobsiteDailyConcreteChart = (props: any) => {
  const { data, crane, tableData } = props;

  if (data.length === 0) {
    return (
      <Stack
        sx={{
          height: 1064,
          alignItems: 'center',
          marginBottom: 4,
          borderWidth: 1,
          borderColor: 'grey.400',
          borderRadius: 4,
          borderStyle: 'solid',
          padding: 6,
        }}
      >
        <Stack
          sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
          spacing={4}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
            Jobsite daily concrete
          </Typography>
          <Stack
            sx={{
              padding: 2,
              backgroundColor: 'backgrounds.system',
              alignItems: 'center',
            }}
          >
            <Stack>
              <Stack direction="row" spacing={6}>
                <ChartProperty label="Name" value={crane.name} width={150} />
                <ChartProperty label="Daily quantity" value="-" width={150} />
                <ChartProperty
                  label="Daily Weight"
                  value={`- Tons`}
                  width={150}
                />
                <ChartProperty label="Daily Duration" value="-" width={150} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
          <Stack
            sx={{
              padding: 4,
              width: '100%',
              height: '40%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="smallRegular" color="#878a99">
              {'No data to display'}
            </Typography>
          </Stack>
          <JobsiteDailyConcreteTable data={[]} />
        </Stack>
      </Stack>
    );
  }

  const dailyQuantity = data.length;
  const dailyWeight = +(sumBy(data, (d) => d.concreteWeight) * 0.001).toFixed(
    2,
  );
  const durationMinutes = sumBy(data, (d) => d.duration);
  const categories = map(data, (d) => d.startTime);
  const seriesData = map(data, (d) =>
    d.concreteWeight ? +(d.concreteWeight * 0.001).toFixed(2) : '-',
  );

  const options = {
    chart: {
      type: 'line',
      height: 400,
    },
    title: {
      text: 'Concrete [Tons]',
    },
    exporting: { enabled: false },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: 'Tons',
      },
      labels: {
        format: '{value:.1f}',
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Concrete Weight [Tons]',
        data: seriesData,
      },
    ],
  };

  return (
    <Stack
      sx={{
        height: 1064,
        alignItems: 'center',
        marginBottom: 4,
        borderWidth: 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
        padding: 6,
      }}
    >
      <Stack
        sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
        spacing={4}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
          Jobsite daily concrete
        </Typography>
        <Stack
          sx={{
            padding: 2,
            backgroundColor: 'backgrounds.system',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Stack direction="row" spacing={6}>
              <ChartProperty label="Name" value={crane.name} width={150} />
              <ChartProperty
                label="Daily quantity"
                value={dailyQuantity}
                width={150}
              />
              <ChartProperty
                label="Daily Weight"
                value={`${dailyWeight} Tons`}
                width={150}
              />
              <ChartProperty
                label="Daily Duration"
                value={secondsToMinutes(durationMinutes * 60)}
                width={150}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <JobsiteDailyConcreteTable data={tableData} />
      </Stack>
    </Stack>
  );
};

export default JobsiteDailyConcreteChart;
