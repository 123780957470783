import React from 'react';
import { Stack, Paper } from '@mui/material';

const ColorRenderer = ({ value, rowHeight }: any) => {
  return (
    <Stack
      sx={{
        height: rowHeight,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <Paper
        sx={{ height: rowHeight, backgroundColor: value, width: '70px' }}
        elevation={0}
      ></Paper>
    </Stack>
  );
};

export default ColorRenderer;
