import React from 'react';
import getColumnsDef from './columns';
import Table from '../Table';

const JobsiteWeeklyStructureTable = (props: any) => {
  const { data } = props;

  return (
    <Table
      {...{
        data,
        getColumnsDef,
      }}
    />
  );
};

export default JobsiteWeeklyStructureTable;
