import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) =>
  apiPutAsync(`company/delete-logo/${props.companyId}`);

export const usePutDeleteCompanyLogo = (options?: any) => {
  const key = ['PutDeleteCompanyLogo'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
