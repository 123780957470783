import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Tabs, Stack } from '@mui/material';
import {
  useGetCompany,
  useGetCompanyProjects,
  useGetCompanyUsers,
} from '../../queries';
import { Loading } from '../../components/styled';
import ErrorState from '../../components/alerts/ErrorState';
import { Tab, TabPanel } from '../../components/Tabs';
import { Sites } from './Projects';
import { Users } from './Users';

const Company = () => {
  const { companyId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const {
    data: company,
    error: getCompanyError,
    isLoading: getCompanyIsLoading,
    refetch: refetchCompany,
    isRefetching: getCompanyProjectsIsRefetching,
  } = useGetCompany(
    { companyId },
    {
      cacheTime: 0,
    },
  );

  // const {
  //   error: getCompanyProjectsError,
  //   isLoading: getCompanyProjectsIsLoading,
  //   data: company,
  //   refetch: refetchCompanyProjects,
  //   isRefetching: getCompanyProjectsIsRefetching,
  // } = useGetCompanyProjects(
  //   { companyId },
  //   {
  //     enabled:
  //       !!companyId && getCompanyData && Object.keys(getCompanyData).length > 0,
  //   },
  // );

  // const {
  //   error: getCompanyUsersError,
  //   isLoading: getCompanyUsersIsLoading,
  //   data: getCompanyUsersData,
  // } = useGetCompanyUsers(
  //   { companyId },
  //   {
  //     enabled:
  //       !!companyId && getCompanyData && Object.keys(getCompanyData).length > 0,
  //   },
  // );

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const pageError = getCompanyError;
  // || getCompanyProjectsError;
  // || getCompanyUsersError;

  const pageIsLoading = getCompanyIsLoading;
  // || getCompanyProjectsIsLoading;
  // ||
  // getCompanyUsersIsLoading;

  if (pageIsLoading) {
    return <Loading />;
  }

  if (pageError || !companyId) {
    return (
      <Paper
        sx={{
          padding: 8,
          backgroundColor: 'common.white',
          width: '100%',
          overflowY: 'auto',
          display: 'flex',
          flex: 1,
        }}
        elevation={1}
      >
        <ErrorState error={pageError} />
      </Paper>
    );
  }

  return (
    <Stack height="100%" flex={1} spacing={4}>
      <Tabs value={selectedTab} onChange={onTabChange}>
        <Tab label="Sites" />
        <Tab label="Users" />
      </Tabs>
      <Grid container rowSpacing={4} flex={1}>
        <TabPanel value={selectedTab} index={0}>
          <Sites
            sites={company?.data.sites}
            isLoading={getCompanyIsLoading}
            isRefetching={getCompanyProjectsIsRefetching}
            handleRefetch={refetchCompany}
            companyId={companyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Users users={company?.data.users} isLoading={getCompanyIsLoading} />
        </TabPanel>
      </Grid>
    </Stack>
  );
};

export default Company;
