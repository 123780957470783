import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Stack,
  CircularProgress,
  useMediaQuery,
  Box,
  IconButton,
  Autocomplete,
} from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Collapse from '@mui/material/Collapse';
import ErrorState from './alerts/ErrorState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';

export const LayoutStack = ({ children, ...rest }: any) => {
  return (
    <Stack spacing={4} height="100%" width="100%" {...rest}>
      {children}
    </Stack>
  );
};

export const ContentStack = ({
  children,
  paperFlexDirection = 'row',
  ...rest
}: any) => {
  return (
    <Stack
      width="100%"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Paper
        sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: '100%',
          padding: 4,
          overflow: 'auto',
          flexDirection: paperFlexDirection,
        }}
        elevation={1}
      >
        {children}
      </Paper>
    </Stack>
  );
};

export const MainTitle = ({ children }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  return (
    <Typography
      variant={sm ? 'h2Medium' : 'h1SemiBold'}
      color="typography.secondary"
      sx={{ textTransform: 'uppercase' }}
    >
      {children}
    </Typography>
  );
};

export const GridToolbar = ({ children, sx }: any) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const sm = useMediaQuery('(max-width: 600px)');

  return (
    <Paper
      sx={{
        padding: 4,
        backgroundColor: 'common.white',
        width: '100%',
      }}
      elevation={1}
    >
      {sm ? (
        <Stack
          direction="row"
          justifyContent={expanded ? 'flex-end' : 'space-between'}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <KeyboardDoubleArrowUpIcon />
          ) : (
            <>
              <Typography>Filters</Typography>
              <KeyboardDoubleArrowDownIcon />
            </>
          )}
        </Stack>
      ) : null}
      <Collapse in={expanded}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={sx}
        >
          {children}
        </Stack>
      </Collapse>
    </Paper>
  );
};

export const Loading = (props: any) => {
  return (
    <Stack
      width="100%"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <CircularProgress size={props.size || 36} />
    </Stack>
  );
};

export const ImageWithFallback = (props: any) => {
  const { src, ...restProps } = props;
  const [isErrored, setIsErrored] = useState(false);
  const onError = () => setIsErrored(true);

  if (isErrored)
    return (
      <ErrorState
        error={{ code: 404, message: 'Not found' }}
        showReloadButton={false}
      />
    );

  return <Box component="img" src={src} onError={onError} {...restProps} />;
};

export const NavigateBack = (props: any) => {
  const { onClickBack } = props;
  return (
    <Stack sx={{ alignItems: 'flex-start', marginBottom: 2 }}>
      <IconButton size="large" onClick={onClickBack}>
        <ArrowBackIcon />
      </IconButton>
    </Stack>
  );
};

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '6px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '6px',
    },
  },
});
