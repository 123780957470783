import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useAppSelector } from '../../store/hooks';
import xrange from 'highcharts/modules/xrange';
import { GanttTask } from '../../store/gantttasks';

xrange(Highcharts);
interface TaskGanttChartProps {
  ganttTasks: GanttTask[];
  Report: boolean;
}
const TaskGanttChart = ({ Report, ganttTasks }: TaskGanttChartProps) => {
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level
  );
  // const ganttTasks = useAppSelector((state) => state.GanttTasks.gantttasks);

  // let centerX = (mediaResLevel < 3) ? '50%' : '50%'
  // let centerY = (mediaResLevel < 3) ? '50%' : '50%'
  // let fsNumber = (mediaResLevel < 3) ? '12px' :'14px';
  // let MRlayout = (mediaResLevel < 3) ? 'horizontal' :'vertical';
  let olWidth = mediaResLevel < 3 ? 440 : 700;
  let showLegend = mediaResLevel < 3 ? false : true;
  let objHeight = Report ? 270 : 140; //EfficiencyChart
  // let objHeight = Report ? 200:140;
  let objWidth = Report ? 500 : olWidth;
  // let objFontSize = Report ? '10px':fsNumber;
  // if(report){
  //     const calcSumTask = useAppSelector((state) => state.calcsumtaskrpt.calcsumtask);
  var ganttTasks1 = ganttTasks.map((v) => ({
    x: Date.UTC(v.syear, v.smonth, v.sday, v.shour, v.sminute),
    x2: Date.UTC(v.eyear, v.emonth, v.eday, v.ehour, v.eminute),
    y: v.catid,
    cat: v.category,
    color: v.color,
  }));
  // console.log("ganttTasks1");
  // console.log(ganttTasks1);
  const uniqueCategory = ganttTasks
    .map((item) => item.category)
    .filter((value, index, self) => self.indexOf(value) === index);

  let chartHeight = 25 * (uniqueCategory.length + 1);
  let chartHeight2 = objHeight > chartHeight ? objHeight : chartHeight;
  //             let resArr:string[] = [];
  //             ganttTasks.filter(function(item){
  //   var i = resArr.findIndex(x => (x === item.category ));
  //   if(i <= -1){
  //         resArr.push(item.category);
  //   }
  //   return null;
  // });
  // console.log("resArr");
  // console.log(resArr);
  // }
  // else{
  //     const calcSumTask = useAppSelector((state) => state.calcsumtask.calcsumtask);
  //     var calcSumTask1 = calcSumTask.map(v => ({ name: v.name, y: v.y }));
  // }

  const options: Highcharts.Options = {
    chart: {
      plotShadow: false,
      type: 'xrange',
      height: chartHeight2,
      // width:objWidth,
      marginTop: 0,
      // marginBottom:0
      // alignThresholds:true
    },
    //         caption:{
    // margin:0
    //         },

    tooltip: {
      pointFormat: '<b>{point.cat}</b>',
    },
    // accessibility: {
    //     point: {
    //         descriptionFormatter: function (point) {
    //             var ix = point.index + 1,
    //                 category = point.category,
    //                 from = new Date(point.x),
    //                  //@ts-ignore
    //                 to = new Date(point.x2);
    //             return ix + '. ' + category + ', ' + from.toDateString() +
    //                 ' to ' + to.toDateString() + '.';
    //         }

    //     }
    // },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: '',
      },
      categories: uniqueCategory,
      // reversed: true
    },

    series: [
      {
        type: 'xrange', //marker:{height:5},

        // colors: ["#ff6f00", "#005573", "#00c1b6"],

        showInLegend: false,
        borderRadius: 0,
        borderWidth: 0,
        // pointPadding: 0,
        // groupPadding: 0,
        borderColor: 'gray',
        // pointWidth: 20,
        dataLabels: {
          padding: 0,
        },
        //@ts-ignore
        data: ganttTasks1,
      },
    ],
    // dataLabels: {
    //     enabled: true
    // }
  }; // plotOptions: {
  //     pie: {
  //         size: 65,
  //         center: [centerX, centerY],
  //         allowPointSelect: true,
  //         cursor: 'pointer',
  //         showInLegend: true,
  //         dataLabels: {
  //             style: {
  //                 fontSize: objFontSize,
  //                 fontFamily: "Quicksand"
  //             },
  //             enabled: true,
  //             distance: 4,
  //             format: '{point.percentage:.1f} %'
  //         }
  //     }
  // },
  //     legend: {
  //         // align: 'left',
  //         // verticalAlign: 'top',
  //         // //@ts-ignore
  //         // layout: MRlayout,
  //         // itemStyle: {
  //         //     fontSize: fsNumber,
  //         //     fontFamily: "Quicksand",
  //         // },
  //         // symbolHeight:8,
  //         // symbolWidth:8,
  //         // itemMarginBottom: 34,
  //         padding:0,
  //         align: 'left',
  //         verticalAlign: 'top',
  //         //@ts-ignore
  //         layout: MRlayout,

  //         itemStyle: {
  //             fontSize: fsNumber,
  //             fontFamily: "Quicksand",
  //         },
  //         symbolHeight:8,
  //         symbolWidth:8,
  //         itemMarginBottom: 12,
  //         x: 0,
  //         y: 0,

  //     },

  //     colors: ["#ff6f00", "#005573", "#00c1b6"],
  //     series: [{
  //         // name: 'Work day',
  //         // type: "pie",
  //         // colorByPoint: true,
  //         // innerSize: "75%",
  //         // showInLegend:showLegend,
  //         data: ganttTasks1//piedata//Object.entries(chartData).map(([name, data]) => ({ name: name, y: data }))
  //     }]
  // }

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default TaskGanttChart;
