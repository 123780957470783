import React, { useState } from 'react';
import { Box, Button, Popover, Slider, Stack, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

const filters = ['brightness', 'contrast', 'saturate', 'sepia', 'grayscale'];
export const settingsInitialState = {
  brightness: 100,
  contrast: 100,
  saturate: 100,
  sepia: 0,
  grayscale: 0,
};

const SliderFilter = ({ settings, onSettingsChange, name }: any) => (
  <Slider
    size="small"
    track={false}
    value={settings[name]}
    marks={[{ value: 0 }, { value: 200 }]}
    step={1}
    min={0}
    max={200}
    onChange={(e, value) =>
      onSettingsChange({
        ...settings,
        [name]: value,
      })
    }
  />
);

export const Settings = ({ settings, onSettingsChange }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        size="small"
        sx={{ marginLeft: 2, marginRight: 2 }}
      >
        <TuneIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 250, padding: 8 }}>
          {filters.map((filter) => (
            <span key={filter}>
              <Typography gutterBottom>{filter}</Typography>
              <SliderFilter
                settings={settings}
                onSettingsChange={onSettingsChange}
                name={filter}
              />
            </span>
          ))}
          <Button
            onClick={() => onSettingsChange(settingsInitialState)}
            variant="outlined"
            size="small"
            sx={{ marginTop: 2 }}
            fullWidth
          >
            Reset
          </Button>
        </Box>
      </Popover>
    </>
  );
};
