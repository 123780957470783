import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Stack, useMediaQuery } from '@mui/material';
import { formatTotalSecondsToDateFormat } from '../../../../../utils';
import { ChartProperty } from '../../content/styled';
import { secondsToMinutes } from '../utils';

const ChartBar = (props: any) => {
  const { noLifts, operationSec, idleSec, onDashboard, startTime, endTime } =
    props;

  const sm = useMediaQuery('(max-width: 600px)');

  if (onDashboard) {
    return (
      <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
        <Stack>
          <Stack direction={sm ? 'column' : 'row'} spacing={sm ? 3 : 6}>
            <ChartProperty
              label="Operation"
              value={
                noLifts
                  ? '-'
                  : `${formatTotalSecondsToDateFormat(operationSec)} (hours)`
              }
              width={300}
              fontSize={sm ? 12 : 16}
              icon={<DoneAllIcon fontSize="small" />}
            />
            <ChartProperty
              label="Idle"
              value={
                noLifts
                  ? '-'
                  : `${formatTotalSecondsToDateFormat(idleSec)} (hours)`
              }
              width={300}
              fontSize={sm ? 12 : 16}
              icon={<NotInterestedIcon fontSize="small" />}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
      <Stack>
        <Stack direction="row" spacing={6}>
          <ChartProperty
            label="Start time"
            value={noLifts ? '-' : startTime}
            width={120}
          />
          <ChartProperty
            label="End time"
            value={noLifts ? '-' : endTime}
            width={120}
          />
        </Stack>
        <Stack direction="row" spacing={6}>
          <ChartProperty
            label="Idle"
            value={noLifts ? '-' : secondsToMinutes(idleSec)}
            width={120}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChartBar;
