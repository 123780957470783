import { ActionsRenderer, DateRenderer } from '../../components/grid';
import { DateFormatTypes } from '../../variables/enums';

export default (ROW_HEIGHT: string | number, onDelete?: any) => {
  return [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
      minWidth: 400,
      maxWidth: 400,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      sortable: true,
      cellRendererFramework: DateRenderer,
      cellRendererParams: {
        formatType: DateFormatTypes.MOMENT_DATE_TIME,
      },
      minWidth: 220,
      maxWidth: 220,
    },
    {
      headerName: 'Updated',
      field: 'updatedAt',
      sortable: true,
      cellRendererFramework: DateRenderer,
      cellRendererParams: {
        formatType: DateFormatTypes.MOMENT_DATE_TIME,
      },
      minWidth: 220,
      maxWidth: 220,
    },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'id',
      minWidth: 140,
      maxWidth: 140,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onDelete,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
