import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Stack } from '@mui/material';
import useCargoTypes from '../../hooks/useCargoTypes';
import { useAppSelector } from '../../store/hooks';
import { Grid } from '../../components/grid';
import { ConfirmDialog } from '../../components/confirm';
import CreateCargoType from './CreateCargoType';
import EditCargoType from './EditCargoType';
import ErrorState from '../../components/alerts/ErrorState';
import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import getColumnsDef from './columns';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const Cargotypes = () => {
  const { fetchCargoTypesApi, deleteCargoTypeApi, deleteCargoTypeApiReset } =
    useCargoTypes();
  const { loading, error, data } = useAppSelector((state) => state.cargoType);
  const deleteApi = useAppSelector((state) => state.cargoType.deleteApi);
  const [editItemId, setEditItemId] = useState<string | null>();
  const [deleteItemId, setDeleteItemId] = useState<string | null>();
  const [createOpen, setCreateOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchCargoTypesApi();
  }, []);

  const onEdit = (editItemId?: string) => {
    setEditItemId(editItemId);
  };

  const onEditSuccess = async () => {
    await fetchCargoTypesApi();
    onEdit();
  };

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    await fetchCargoTypesApi();
    setCreateOpen(false);
  };

  const onDelete = (deleteItemId?: string) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = async (deleteItemId?: string) => {
    await deleteCargoTypeApi(deleteItemId);
  };

  const onDeleteSuccess = async (deleteItemId?: string) => {
    await fetchCargoTypesApi();
    deleteCargoTypeApiReset();
    onDelete();
  };

  return (
    <LayoutStack>
      <MainTitle>Cargo Types List</MainTitle>
      <GridToolbar>
        <Stack>
          <Button
            variant="contained"
            onClick={() => onCreate(true)}
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <Button variant="outlined" onClick={fetchCargoTypesApi}>
            <RefreshIcon />
          </Button>
        </Stack>
      </GridToolbar>
      <ContentStack>
        {loading && !error && <Loading />}
        {!loading && error && <ErrorState error={error} />}
        {!loading && !error && data.length > 0 && (
          <Grid
            {...{
              rows: data,
              columnsDef: getColumnsDef(ROW_HEIGHT, onEdit, onDelete),
              ROW_HEIGHT,
              maxVisibleRows,
            }}
          />
        )}
        {!loading && !error && data.length === 0 && <p>No cargo types found</p>}
        {createOpen && (
          <CreateCargoType
            onClose={() => onCreate(false)}
            onSuccess={onCreateSuccess}
          />
        )}
        {editItemId && (
          <EditCargoType
            item={data.find(({ id }) => id === editItemId)}
            onClose={() => onEdit()}
            onSuccess={onEditSuccess}
          />
        )}
        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete Cargo Type"
            text="Are you sure you want to delete this cargo type?"
            onOk={() => onDeleteConfirmed(deleteItemId)}
            onCancel={() => onDelete()}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={deleteApi}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};
export default Cargotypes;
