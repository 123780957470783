import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { isMobile, isTablet, isAndroid } from 'react-device-detect';

export const userPages = [
  { label: 'Dashboard', route: '/dashboard', icon: <DashboardIcon /> },
  { label: 'Site', route: '/site', icon: <DashboardIcon /> },
  ...(isMobile || isTablet || isAndroid
    ? [
        // {
        //   label: 'Signaller',
        //   route: '/signallerrequest',
        //   icon: <SensorOccupiedIcon />,
        // },
        // {
        //   label: 'Logistics',
        //   route: '/logistics',
        //   icon: <LocalShippingIcon />,
        // },
      ]
    : []),
  // { label: 'Asset Manager', route: '/assetmanager', icon: <CategoryIcon /> },
  // {
  //   label: 'Tasks Scheduler',
  //   route: '/cranescheduler',
  //   icon: <CalendarMonthIcon />,
  // },
];

export const userPagesMobile = [
  { label: 'Dashboard', route: '/dashboard', icon: <DashboardIcon /> },
  {
    label: 'Signaller',
    route: '/signallerrequest',
    icon: <SensorOccupiedIcon />,
  },
  // { label: 'Asset Manager', route: '/assetmanager', icon: <CategoryIcon /> },
  // { label: 'Logistics', route: '/logistics', icon: <LocalShippingIcon /> },
  // {
  //   label: 'Tasks Scheduler',
  //   route: '/cranescheduler',
  //   icon: <CalendarMonthIcon />,
  // },
  // { label: 'Asset Manager', route: '/assetmanager', icon: <CategoryIcon /> },
  // {
  //   label: 'Crane Scheduler',
  //   route: '/cranescheduler',
  //   icon: <CalendarMonthIcon />,
  // },
];

export const sysAdminPages = [
  // { label: 'Executed Tasks', route: '/tasks' },
  // { label: 'Asset Planning', route: '/assetplaning' },
];

export const settingsPages = [
  { label: 'Users', route: '/users' },
  { label: 'Companies', route: '/companies' },
  { label: 'Cranes', route: '/cranes' },
  { label: 'Cargo Types', route: '/cargotypes' },
];

export const mediaResPages = [
  { label: 'Full Report', route: '/fullreport' },
  { label: 'Daily Video', route: '/dailyvideo' },
];
