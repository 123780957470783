import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Login.scss';

import { useHttp } from '../hooks/useHttp/useHttp';
import Info2 from '../assets/icons/icon-info2';
import Eye from '../assets/icons/eye';
import EyeSlash from '../assets/icons/eyeslash';
import { ApiMethods } from '../variables/enums';
import { CHANGE_PASSWORD_PATH } from '../variables/endpoints';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [UserEmail, setUserEmail] = useState<string>('');
  const { api } = useHttp();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  let pattern = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const HandleChangePassordRequest = async () => {
    if (newPassword.trim() === '' || oldPassword.trim() === '') {
      return;
    }
    if (newPassword !== confirmPassword) {
      alert('Password and Confirm is not identical');
      return;
    }
    if (!pattern.test(newPassword)) {
      alert('Password not strong enough');
      return;
    }
    try {
      await api({
        method: ApiMethods.PUT,
        url: CHANGE_PASSWORD_PATH,
        data: {
          email: UserEmail,
          oldPassword,
          newPassword,
        },
      });
      alert('Your Password has been changed successfuly');
      navigate('/');
    } catch (err) {}
  };

  return (
    <div className="login-page">
      <div>
        <Popup
          trigger={
            <button className="icon__btn">
              <Info2 style={{ color: '#f70606' }} />
            </button>
          }
          position="left center"
        >
          <ul>
            <li>at least 8 characters</li>
            <li>at least 1 uppercase</li>
            <li>at least 1 lowercase</li>
            <li>at least 1 number</li>
          </ul>
        </Popup>
      </div>
      <div>
        <input
          className="login-page__text-field"
          type="text"
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="User Email"
          value={UserEmail}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className="login-page__text-field_pass"
          type={passwordShown ? 'text' : 'password'}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Old Password"
          value={oldPassword}
        />
        <button className="icon__btn" onClick={() => togglePassword()}>
          {passwordShown ? <Eye /> : <EyeSlash />}
        </button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className="login-page__text-field_pass"
          type={passwordShown ? 'text' : 'password'}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          value={newPassword}
        />
        <button className="icon__btn" onClick={() => togglePassword()}>
          {passwordShown ? <Eye /> : <EyeSlash />}
        </button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className="login-page__text-field_pass"
          type={passwordShown ? 'text' : 'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          value={confirmPassword}
        />
        <button className="icon__btn" onClick={() => togglePassword()}>
          {passwordShown ? <Eye /> : <EyeSlash />}
        </button>
      </div>
      <button
        type="button"
        className="login-page__btn"
        onClick={HandleChangePassordRequest}
      >
        Change Passsword
      </button>
    </div>
  );
};

export default ChangePassword;
