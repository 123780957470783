import React, { useState, useEffect, useRef } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Select from '@mui/material/Select';
import { MenuItem, useMediaQuery } from '@mui/material';

// Add RTL support if you want to support RTL languages
mapboxgl.setRTLTextPlugin(
  'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  null,
  true, // Lazy load the plugin
);

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const streetMapBoxUrl = `https://api.mapbox.com/styles/v1/ultrawis/cly2qe99y005o01pf0e5wcl09/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxAccessToken}`;
const satelliteMapBoxUrl = `https://api.mapbox.com/styles/v1/ultrawis/cly2qf98d006e01qpdrrd4eym/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxAccessToken}`;
const blankMapBoxUrl = `https://api.mapbox.com/styles/v1/ultrawis/cly2qfhev005l01pid0yb01yt/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxAccessToken}`;

export const MapType = {
  street: 'street',
  satellite: 'satellite',
  blank: 'blank',
};

export interface MapCoordinate {
  lat: number;
  lng: number;
}

const Map = ReactMapboxGl({
  accessToken: mapboxAccessToken || '',
});

const MapBox = ({ siteOrigin, mapType, children }: any) => {
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState('');
  const [mapTypes, setMapTypes] = useState([
    { label: 'Satellite', value: MapType.satellite },
    { label: 'Street', value: MapType.street },
    // { label: 'Blank', value: MapType.blank },
  ]);
  const [selectedMapType, setSelectedMapType] = useState(mapType);
  const sm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    switch (selectedMapType) {
      case MapType.street:
        setMapStyle('mapbox://styles/ultrawis/cly2qe99y005o01pf0e5wcl09');
        break;
      case MapType.satellite:
        setMapStyle('mapbox://styles/ultrawis/cly2qf98d006e01qpdrrd4eym');
        break;
      case MapType.blank:
        setMapStyle('mapbox://styles/ultrawis/cly2qfhev005l01pid0yb01yt');
        break;
      default:
        //   mapStyle = 'mapbox://styles/mapbox/streets-v11';
        setMapStyle('mapbox://styles/ultrawis/cly2qf98d006e01qpdrrd4eym');
    }
  }, [selectedMapType]);

  const handleStyleLoad = (map: any) => {
    // Add fullscreen control to the map
    map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
    mapRef.current = map;
  };

  const handleMapTypeChange = (event: any) => {
    console.log('event.target.value', event.target.value);
    setSelectedMapType(event.target.value);
  };

  return (
    <>
      <Select
        value={selectedMapType}
        onChange={handleMapTypeChange}
        style={{ position: 'absolute', top: 150, left: 10, zIndex: 1 }}
        sx={{ width: sm ? '30%' : '150px' }}
      >
        {mapTypes &&
          mapTypes.length > 0 &&
          mapTypes.map((type: any) => (
            <MenuItem key={type.label} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
      </Select>
      <Map
        ref={mapRef}
        style={mapStyle}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        center={[siteOrigin.lng, siteOrigin.lat]}
        zoom={[18]}
        onStyleLoad={handleStyleLoad}
      >
        {children}
      </Map>
    </>
  );
};

export default MapBox;
