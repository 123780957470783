import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskByCategory, TasksByCategory } from './tasksbycategory';

const initialState: TasksByCategory = {
  tasksbycategory: [
    {
      hournum: 0,
      General: 0,
      Concrete: 0,
      IronRodesPack: 0,
      IronGridMesh: 0,
      WoodPack: 0,
      TrashContainer: 0,
      Bricks: 0,
      structureInstall: 0,
      structureDismantle: 0,
      NotIdentified: 0,
    },
  ],
};

const tasksByCategoryRptSlice = createSlice({
  name: 'tasksbycategoryrpt',
  initialState: initialState,
  reducers: {
    setTasksByCategoryRpt(state, action: PayloadAction<TaskByCategory[]>) {
      state.tasksbycategory = action.payload;
      //    console.log(state.idletimes);
    },
    resetTasksByCategoryRpt: () => initialState,
  },
});

export const tasksByCategoryRptActions = tasksByCategoryRptSlice.actions;

export default tasksByCategoryRptSlice.reducer;
