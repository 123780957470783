import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiDialogActions'] = {
    styleOverrides: {
      spacing: 8,
      root: {
        backgroundColor: theme.palette.backgrounds.system,
      },
    },
  };

  return styleOverrides;
};
