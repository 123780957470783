import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = () => apiGetAsync('craneModel');

export const useGetCraneModel = () => {
  const key = ['GetCraneModel'];
  const fn = () => api();

  return useQuery(key, fn, { staleTime: Infinity });
};
