// @ts-nocheck
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HashRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/layout';
import { store } from './store/index';
import { Provider as ThemeProvider } from './components/Provider';
import ProjectReport from './components/dashboard/projectReport/ProjectReport';
import { AssetManager } from './pages/AssetManager';
import { CargoTypes } from './pages/CargoTypes';
import ChangePassword from './pages/ChangePassword';
import { Companies } from './pages/Companies';
import { Company, CompanyPage, Crane, Site } from './pages/Company';
import CraneScheduler from './pages/CraneScheduler/CraneScheduler';
import CranebitTasks from './pages/CranebitTasks';
import Cranes from './pages/Cranes/Cranes';
import CreateReports from './pages/CreateReports';
import Dashboard from './pages/Dashboard';
import DeleteAccount from './pages/DeleteAccount';
import ForgotPassword from './pages/FogotPassword';
import Login from './pages/Login';
import { Logistics } from './pages/Logistics';
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import SignallerRequest from './pages/SignallerRequest';
import { UserList } from './pages/UserList';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import * as cordovaHelper from './utils/cordovaHelper';
cordovaHelper.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false,
      retryDelay: 0,
    },
  },
});

const renderReactDom = () => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools />*/}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider>
          <Provider store={store}>
            <HashRouter>
              <Routes>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword/">
                  <Route path=":id/:code" element={<ResetPassword />} />
                </Route>
                <Route
                  path="/project-report/:projectId/:startDate"
                  element={<ProjectReport />}
                />
                <Route path="/cranebit/">
                  <Route path=":id" element={<CranebitTasks />} />
                </Route>
                <Route
                  path=""
                  element={
                    <Layout>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route path="/changepassword" element={<ChangePassword />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/fullreport" element={<Dashboard />} />
                  <Route path="/dailyvideo" element={<Dashboard />} />
                  <Route path="/users" element={<UserList />} />
                  <Route path="/site" element={<Site />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/company" element={<CompanyPage />}>
                    <Route path=":companyId" element={<Company />} />
                    <Route path=":companyId/sites/:siteId" element={<Site />} />
                    <Route
                      path=":companyId/sites/:siteId/cranes/:craneId"
                      element={<Crane />}
                    />
                  </Route>
                  <Route path="/cranes" element={<Cranes />} />
                  <Route path="/assetmanager/" element={<AssetManager />} />
                  <Route path="/cargotypes" element={<CargoTypes />} />
                  <Route path="/createreports" element={<CreateReports />} />
                  <Route path="/cranescheduler" element={<CraneScheduler />} />
                  <Route
                    path="/signallerrequest"
                    element={<SignallerRequest />}
                  />
                  <Route path="/logistics" element={<Logistics />} />
                </Route>
              </Routes>
            </HashRouter>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>,
    document.getElementById('root'),
  );
};

// @ts-ignore
if (window.cordova) {
  document.addEventListener(
    'deviceready',
    () => {
      renderReactDom();
    },
    false,
  );
} else {
  renderReactDom();
}
