import React, { Fragment, useEffect, useState } from 'react';
import { Task } from '../../../store/tasks';

interface SectionProps {
  task: Task;
}
const Section = ({ task }: SectionProps) => {
  const [taskLoadPic, setTaskLoadPic] = useState<any>();
  const [taskUnloadPic, setTaskUnloadPic] = useState<any>();

  useEffect(() => {
    const getPics = async () => {
      setTaskLoadPic(
        'https://ultrawis-mm.s3.eu-south-1.amazonaws.com/' + task.PicNameLoad
      );
      setTaskUnloadPic(
        'https://ultrawis-mm.s3.eu-south-1.amazonaws.com/' + task.PicNameUnload
      );
    };
    getPics();
  }, [taskLoadPic, taskUnloadPic]);

  return (
    <>
      {task && (
        <Fragment>
          <div className="report__metadata">
            <span>Lift {task.num}</span>
            <span>Start Time: {task.TimeLoadStart}</span>
            <span>End Time: {task.TimeUnloadEnd}</span>
            <span>Cargo Type: {task.CargoTypeDesc}</span>
          </div>
          <div className="report__crane-outputs">
            <div>
              <img width={268} height={150} src={taskLoadPic} />
              <span>{'Load ' + task.num}</span>
            </div>
            <div>
              <img width={268} height={150} src={taskUnloadPic} />
              <span>{'Unload ' + task.num}</span>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Section;
