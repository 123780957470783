import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiMenuItem'] = {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        ...theme.typography.mediumRegular,
        transition:
          'color 0.1s linear, background-color 0.1s linear, border 0.1s linear, fill 0.1s linear',
        color: theme.palette.typography.primary,
        minHeight: 32,
        maxHeight: 32,
        height: 32,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: theme.palette.common.white,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.grey.A200,
          color: theme.palette.typography.primary,
          fontWeight: 'bold',
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.grey.A200,
          color: theme.palette.typography.primary,
          fontWeight: 'bold',
        },
      },
    },
  };
  return styleOverrides;
};
