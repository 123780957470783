// @ts-nocheck
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { getLiftsByHour, getWorkDayWindowHours } from '../../../../../utils';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
  CraneHeader,
} from '../../content/styled';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tooltipMaterialScheduler = (s, point, timezone) => {
  const start = dayjs.tz(point.x, timezone).format('HH:mm:ss');
  const end = dayjs.tz(point.x2, timezone).format('HH:mm:ss');
  return (
    s +
    `<span style="color:${point.color}">\u25CF</span> <b>${start} - ${end}</b><br/>`
  );
};

const IdleSchedulingGantChart = ({ height, startDate, onDashboard }) => {
  const selectedDate = startDate.format('YYYY-MM-DD');
  const lifts = useAppSelector((state) => state.projectCrane.lifts);
  const idleSegments = useAppSelector(
    (state) => state.projectCrane.idleSegments,
  );
  const operationSegments = useAppSelector(
    (state) => state.projectCrane.operationSegments,
  );
  const liftsByHourStore = useAppSelector(
    (state) => state.projectCrane.liftsByHour,
  );
  const hours = useAppSelector(
    (state) => state.projectCrane.workDayWindowHours,
  );
  const timezone =
    useAppSelector((state) => state.projectCrane.Timezone) || 'Asia/Jerusalem';
  const [liftsByHour, setLiftsByHour] = useState([]);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(0);
  const [colors, setColors] = useState([]);

  const title = 'Idle Scheduling Gant';

  useEffect(() => {
    if (!lifts || lifts.length === 0) return;

    setColors(lifts.map((item) => item.cargoType.color));
    setLiftsByHour(liftsByHourStore);
  }, [lifts, liftsByHourStore]);

  const seriesData = useMemo(() => {
    // add another hour to the end of the day
    const liftSchedulingGantHours = hours.concat(hours[hours.length - 1] + 1);
    const startOfDay = dayjs.tz(selectedDate, timezone).startOf('day');
    setMinHour(startOfDay.add(hours[0], 'hours').valueOf());
    setMaxHour(
      startOfDay
        .add(
          liftSchedulingGantHours[liftSchedulingGantHours.length - 1],
          'hours',
        )
        .valueOf(),
    );

    const xrangeData = [];

    for (const segment of idleSegments) {
      xrangeData.push({
        x: dayjs
          .tz(
            `${selectedDate} ${segment.startTime}`,
            'YYYY-MM-DD HH:mm:ss',
            timezone,
          )
          .valueOf(),
        x2: dayjs
          .tz(
            `${selectedDate} ${segment.endTime}`,
            'YYYY-MM-DD HH:mm:ss',
            timezone,
          )
          .valueOf(),
        y: 0,
        name: 'idle',
      });
    }
    return xrangeData;
  }, [idleSegments, hours, startDate, timezone]);

  const options = {
    time: {
      timezone,
    },
    chart: {
      plotShadow: false,
      type: 'xrange',
      height: height,
      marginTop: 0,
    },

    tooltip: {
      hideDelay: 50,
      formatter: function () {
        return this.points.reduce(
          (acc, point) => tooltipMaterialScheduler(acc, point, timezone),
          '',
        );
      },
      shared: true,
      style: {
        fontSize: '14px',
      },
    },
    exporting: { enabled: false },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      min: minHour,
      max: maxHour,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      categories: [],
    },
    series: [
      {
        type: 'xrange',
        showInLegend: false,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: 'gray',
        dataLabels: {
          padding: 0,
        },
        data: seriesData,
      },
    ],
    colors,
  };

  if (lifts.length === 0 && idleSegments.length === 0) {
    return (
      <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        </ChartStackHeader>
        <ChartStackContent
          sx={{
            padding: 4,
            width: '100%',
            height: height + 32,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          spacing={4}
        >
          <Typography variant="h2Medium" color="#878a99">
            {'No data to display'}
          </Typography>
        </ChartStackContent>
      </ChartStack>
    );
  }

  return (
    <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
      </ChartStackHeader>
      <ChartStackContent onDashboard={onDashboard}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </ChartStackContent>
    </ChartStack>
  );
};

export default IdleSchedulingGantChart;
