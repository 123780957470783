import { compact, map, orderBy } from 'lodash';
import moment from 'moment/moment';

const weekDays = Array.from({ length: 7 }, (v, i) => i);

const convertToFields = (data: any[]) => {
  if (data.length) {
    return compact(
      map(data, ({ TimeStart, TimeEnd, DayOfWeek }) => {
        if (TimeStart !== '00:00:00' && TimeEnd !== '00:00:00') {
          const startHour = Number(TimeStart?.split(':')[0]);
          const endHour = Number(TimeEnd?.split(':')[0]);

          return {
            period: DayOfWeek,
            start: moment().set({ hour: startHour, minutes: 0, seconds: 0 }),
            end: moment().set({ hour: endHour, minutes: 0, seconds: 0 }),
          };
        }
        return null;
      }),
    );
  }

  return [];
};

const getChartDate = () => {
  const chartDate = new Date();
  return {
    chartYear: chartDate.getFullYear(),
    chartMonth: chartDate.getMonth(),
    chartDay: chartDate.getDay(),
  };
};

const handleIntervalsData = (data: any[]) => {
  const handledIntervals: any[] = [];

  // handle mondayThursday period
  const mondayThursday = data.find(({ period }) => period === 'mondayThursday');

  const removeUtcOffset = (date: any) => {
    const offset = date.utcOffset();
    return date.add(offset, 'minutes');
  };

  if (mondayThursday) {
    data.forEach((interval) => {
      const start = removeUtcOffset(interval.start);
      const end = removeUtcOffset(interval.end);
      if (interval.period === 'mondayThursday') {
        handledIntervals.push(
          {
            DayOfWeek: 1,
            TimeStart: start,
            TimeEnd: end,
          },
          { DayOfWeek: 2, TimeStart: start, TimeEnd: end },
          { DayOfWeek: 3, TimeStart: start, TimeEnd: end },
          { DayOfWeek: 4, TimeStart: start, TimeEnd: end },
        );
      } else {
        handledIntervals.push({
          DayOfWeek: interval.period,
          TimeStart: start,
          TimeEnd: end,
        });
      }
    });
  } else {
    data.forEach((interval) => {
      const start = removeUtcOffset(interval.start);
      const end = removeUtcOffset(interval.end);
      handledIntervals.push({
        DayOfWeek: interval.period,
        TimeStart: start,
        TimeEnd: end,
      });
    });
  }

  return orderBy(handledIntervals, 'DayOfWeek');
};

export { weekDays, convertToFields, getChartDate, handleIntervalsData };
