// @ts-nocheck
import { Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
} from '../../content/styled';
import { tooltipContentFormatter } from '../utils';

const MaterialsTypeChart = ({ height, onDashboard }: any) => {
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsByHour = useAppSelector(
    (state: any) => state.projectCrane.liftsByHour,
  );
  const [cargoTypes, setCargoTypes] = useState([]);
  const title = 'Lift Material Type \\ Quantities Per Hour';

  useEffect(() => {
    if (!lifts || lifts.length === 0) return;

    const cargoTypesData = lifts
      .map((item) => item.cargoType)
      .filter((obj, index, self) => {
        return index === self.findIndex((t) => t.name === obj.name);
      });
    setCargoTypes(cargoTypesData);
  }, [lifts]);

  const series = useMemo(() => {
    const seriesData = [];
    cargoTypes.forEach((cargoType) => {
      const dataPerCargoType = liftsByHour.map((hour) => {
        const hourData = hour.cargoTypes.find(
          (cType) => cType.id === cargoType.id,
        );
        if (!hourData) return 0;
        return hourData.durationSec;
      });

      seriesData.push({
        name: cargoType.name,
        data: dataPerCargoType,
      });
    });

    return seriesData;
  }, [liftsByHour, cargoTypes]);

  if (lifts.length === 0) {
    return (
      <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
          {/* {!onDashboard && (
            <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
              <Stack>
                <CraneHeader {...crane} />
              </Stack>
            </Stack>
          )} */}
        </ChartStackHeader>
        <ChartStackContent
          sx={{
            padding: 4,
            width: '100%',
            height: height + 32,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          spacing={4}
        >
          <Typography variant="h2Medium" color="#878a99">
            {'No data to display'}
          </Typography>
        </ChartStackContent>
      </ChartStack>
    );
  }

  const options = {
    chart: {
      type: 'column',
      height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    exporting: { enabled: false },
    xAxis: {
      categories: liftsByHour.map(({ label }) => label),
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      min: 0,
      tickPositions: [0, 10, 20, 30, 40, 50, 60],
      title: {
        text: 'Minutes',
        style: {
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      stackLabels: {
        formatter: function () {
          return Math.round(this.total);
        },
        enabled: true,
        style: {
          fontWeight: 'bold',
          fontSize: '10px',
          color:
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
    },
    legend: {
      verticalAlign: 'top',
      layout: 'horizontal',
      itemStyle: {
        fontSize: '14px',
      },
    },
    tooltip: {
      hideDelay: 50,
      formatter: function () {
        return this.points.reduce(tooltipContentFormatter, '');
      },
      shared: true,
      style: {
        fontSize: '14px',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '10px',
          },
        },
      },
    },
    series,
    colors: cargoTypes.map(({ color }) => color),
  };

  return (
    <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        {/* {!onDashboard && (
          <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
            <Stack>
              <CraneHeader {...crane} />
            </Stack>
          </Stack>
        )} */}
      </ChartStackHeader>
      <ChartStackContent onDashboard={onDashboard}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </ChartStackContent>
    </ChartStack>
  );
};

export default MaterialsTypeChart;
