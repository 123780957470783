import React from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ActionsRenderer = ({
  value,
  onEdit,
  onDelete,
  onInvite,
  rowHeight,
}: any) => {
  return (
    <Stack sx={{ height: rowHeight }} direction="row" alignItems="center">
      {onEdit && (
        <Tooltip title="Edit">
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onEdit(value);
            }}
          >
            <EditIcon sx={{ fontSize: 20, cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Delete">
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onDelete(value);
            }}
          >
            <DeleteIcon sx={{ fontSize: 20, cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>
      )}
      {onInvite && (
        <Tooltip title="Invite">
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onInvite(value);
            }}
          >
            <PersonAddIcon sx={{ fontSize: 20, cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ActionsRenderer;
