import { useMutation } from 'react-query';
import { apiPatchAsync, apiPostAsync } from '../api/axios';

const api = (props: any) => {
  const { id, cargoTypeId } = props;
  return apiPatchAsync(`lift/${id}/cargo-type`, { cargoTypeId });
};

export const useUpdateLiftCargoType = (options?: any) => {
  const key = ['UpdateLiftCargoType'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
