// @ts-nocheck
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { JobsiteCranesIdleTimeTable } from '../tables';
import { sumBy } from 'lodash';
import { secondsToMinutes } from './utils';

const JobsiteCranesIdleTime = (props: any) => {
  const { records } = props;

  const data = records.map((craneRecords) => ({
    crane: craneRecords.crane.name,
    idleTime:
      secondsToMinutes(sumBy(craneRecords.records, (r) => r?.idle?.seconds)) ||
      0,
  }));

  return (
    <Stack
      sx={{
        height: 1064,
        alignItems: 'center',
        marginBottom: 4,
        borderWidth: 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
        padding: 6,
      }}
    >
      <Stack
        sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
        spacing={4}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
          Jobsite Cranes Idle Time
        </Typography>
        <Stack
          sx={{
            padding: 2,
            backgroundColor: 'backgrounds.system',
            alignItems: 'center',
          }}
        ></Stack>
      </Stack>
      <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
        <JobsiteCranesIdleTimeTable data={data} />
      </Stack>
    </Stack>
  );
};

export default JobsiteCranesIdleTime;
