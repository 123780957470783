import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = () => apiGetAsync(`user`);

export const useGetUsers = (props: any, options?: any) => {
  const key = ['GetUsers', Object.values(props)];
  const fn = () => api();

  return useQuery(key, fn, {
    enabled: !!props.userId,
    ...(options || {}),
  });
};
