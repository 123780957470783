import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LastTelemetry {
  windspeedkmh: number;
}

export interface LastTelemetryT {
  lasttelemetry: LastTelemetry;
}
const initialState: LastTelemetryT = {
  lasttelemetry: {
    windspeedkmh: 0,
  },
};

const lastTelemetrySlice = createSlice({
  name: 'lasttelemetry',
  initialState: initialState,
  reducers: {
    setLastTelemetry(state, action: PayloadAction<LastTelemetry>) {
      state.lasttelemetry = action.payload;
      //  console.log (state.lasttelemetry);
    },
    // resetLastTelemetry:() => initialState
    resetLastTelemetry(state) {
      state.lasttelemetry = initialState.lasttelemetry;
      // console.log ("state.lasttelemetry");
      // console.log (state.lasttelemetry);
    },
  },
});

export const lastTelemetryActions = lastTelemetrySlice.actions;

export default lastTelemetrySlice.reducer;
