import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const appBarStyleOverrides: Components['MuiAppBar'] = {
    styleOverrides: {
      root: {
        zIndex: 10,
        '.MuiButton-root': {
          marginRight: 6,
        },
        '.MuiButton-root:hover, .MuiButton-root-hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }
      },
    },
  };
  return appBarStyleOverrides;
};
