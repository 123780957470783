import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress, Stack } from '@mui/material';
import { ErrorState } from '../../components/alerts';
import { ConfirmDialog } from '../../components/confirm';
import { Grid } from '../../components/grid';
import { SearchInput } from '../../components/inputs';
import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import {
  useDeleteCrane,
  useGetCranes,
  usePutCraneActivationStatus,
  usePutCraneRecordringStatus,
} from '../../queries';
import getColumnsDef from './columns';
// import { CreateCompany } from './index';
// import { ConfirmDialog } from '../../components/confirm';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const Cranes = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<any>([]);

  // #region react-query
  const queryClient = useQueryClient();

  const { data: cranes, error, refetch, isFetching } = useGetCranes();

  useEffect(() => {
    if (!cranes) return;
    setFilters(cranes?.data);
  }, [cranes]);

  //#endregion

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>();

  const {
    mutateAsync: updateCraneActivationStatus,
    error: updateCraneActivationStatusError,
    data: updateCraneActivationStatusData,
    isLoading: updateCraneActivationStatusLoading,
  } = usePutCraneActivationStatus();

  const {
    mutateAsync: updateCraneRecordingStatus,
    error: updateCraneRecordingStatusError,
    data: updateCraneRecordingStatusData,
    isLoading: updateCraneRecordingStatusLoading,
  } = usePutCraneRecordringStatus({
    onSuccess: () => {
      refetch();
    },
  });

  const {
    mutate: deleteCraneMutate,
    isLoading: deleteCraneIsLoading,
    error: deleteCraneError,
    data: deleteCraneData,
    reset: deleteCraneReset,
  } = useDeleteCrane({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteCrane');
    },
  });

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    await refetch();
    setCreateOpen(false);
  };

  const onRowClicked = (e: any) => {
    console.log(e.data);
    navigate(
      `company/${e.data.site.company.id}/sites/${e.data.siteId}/cranes/${e.data.id}`,
    );
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = () => deleteCraneMutate({ craneId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteCraneReset();
    onDelete();
    setTimeout(async () => await refetch(), 100);
  };

  const onDeleteClose = () => {
    deleteCraneReset();
    onDelete();
  };

  const onActive = (e: any, item: any) => {
    updateCraneActivationStatus({
      id: item.id,
    });
    setFilters((prevCranes: any) => {
      const index = prevCranes.findIndex(({ id }: any) => id === item.id);
      prevCranes[index].isActive = !prevCranes[index].isActive;
      return [...prevCranes];
    });
  };

  const onRecording = async (craneId: string) => {
    updateCraneRecordingStatus({ id: craneId });
  };

  return (
    <LayoutStack>
      <MainTitle>Cranes List</MainTitle>
      <GridToolbar>
        <Stack></Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <SearchInput
            minWidth={380}
            placeholder="Search by id, name, company or project"
            value={filters?.UserName}
            onChange={(e) => {
              const newFilters = cranes?.data.filter(
                (item: any) =>
                  item.id.toString().includes(e.target.value) ||
                  item.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  item.site.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  item.site.company.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()),
              );
              setFilters(newFilters);
            }}
            onClear={() => setFilters(cranes?.data)}
          />
          <Button
            variant="outlined"
            disabled={isFetching}
            onClick={() => refetch()}
          >
            {isFetching ? <CircularProgress size={16} /> : <RefreshIcon />}
          </Button>
        </Stack>
      </GridToolbar>
      <ContentStack>
        {isFetching && !error && <Loading />}
        {!isFetching && error && <ErrorState error={error} />}
        {!isFetching && !error && cranes?.data.length && (
          <Grid
            {...{
              rows: filters,
              columnsDef: getColumnsDef(
                ROW_HEIGHT,
                onDelete,
                onActive,
                onRecording,
              ),
              // onRowClicked,
              onDelete,
              ROW_HEIGHT,
              maxVisibleRows,
              // options: {
              //   rowStyle: { cursor: 'pointer' },
              // },
            }}
          />
        )}

        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete Crane"
            text="Are you sure you want to delete this crane?"
            onOk={onDeleteConfirmed}
            onCancel={onDeleteClose}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={{
              loading: deleteCraneIsLoading,
              error: deleteCraneError,
              data: deleteCraneData,
            }}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};

export default Cranes;
