import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
} from '@mui/material';
import ReactPlayer from 'react-player';

const VideoRecordingPlayer = ({ recording, onClose }: any) => {
  const dateDayjs = recording.date.format('YYYY-MM-DD');
  const sm = useMediaQuery('(max-width: 600px)');

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          margin: sm ? '12px' : '24px',
          width: '100%',
        },
      }}
      open
      sx={{
        paddingTop: '50px',
      }}
    >
      <DialogTitle sx={{ fontSize: sm ? '16px' : '24px' }}>
        <b style={{ marginLeft: sm ? '0px' : '12px' }}>
          {dateDayjs} - {recording.cameraType}
        </b>{' '}
        {recording.time}
      </DialogTitle>
      <DialogContent
        style={{ padding: sm ? '2px' : '18px' }}
        sx={{
          height: sm ? '200px' : '520px',
        }}
      >
        <Stack sx={{ paddingTop: '0px', height: '100%' }} spacing={4}>
          {recording.signedUrl && (
            <ReactPlayer
              url={recording.signedUrl}
              controls
              width="100%"
              height="100%"
              playing
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoRecordingPlayer;
