import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportHeader {
  CraneId: number;
  CompanyName: string;
  LogoPath: string;
  ProjectName: string;
  CraneType: string;
  CraneName: string;
  ReportDate: string;
  TimeStart: string;
  TimeEnd: string;
}
export interface ReportHeaderT {
  reportheader: ReportHeader;
}
const initialState: ReportHeaderT = {
  reportheader: {
    CraneId: -1,
    CompanyName: '',
    LogoPath: '',
    ProjectName: '',
    CraneType: '',
    CraneName: '',
    ReportDate: '',
    TimeStart: '',
    TimeEnd: '',
  },
};

const reportHeaderSlice = createSlice({
  name: 'reportheader',
  initialState: initialState,
  reducers: {
    setReportHeader(state, action: PayloadAction<ReportHeader>) {
      state.reportheader = action.payload;
      //    console.log(state.lasttasks);
    },
    resetReportHeader: () => initialState,
  },
});

export const reportHeaderActions = reportHeaderSlice.actions;

export default reportHeaderSlice.reducer;
