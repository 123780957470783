import React, { useState } from 'react';
import {
  Container,
  Typography,
  Link,
  Box,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
} from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import * as storageHelper from '../utils/storageHelper';
import { USER } from '../variables/constants';
import './Login.scss';
import { useAsyncFunction } from '../hooks/useAsyncFunction';
import { ConfirmDialog } from '../components/confirm';
import { useDeleteUserRequest } from '../queries/useDeleteUserRequest';

const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

function DeleteAccount() {
  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [deleteUserRequest, setDeleteUserRequest] = useState<any>(false);
  const { loading, error, setError } = useAsyncFunction();
  const navigate = useNavigate();
  const user = storageHelper.getItem(USER);

  const {
    mutateAsync: deleteUser,
    error: deleteError,
    data: deleteData,
    isLoading: deleteLoading,
    reset: resetDelete,
  } = useDeleteUserRequest();

  const handleDeleteRequest = () => {
    if (!userEmail) {
      setError({ message: 'Email is required' } as any);
      return;
    }
    if (!password) {
      setError({ message: 'Password is required' } as any);
      return;
    }
    setDeleteUserRequest(true);
  };

  const onDeleteConfirmed = async () => {
    await deleteUser({ email: userEmail, password });
  };

  return (
    <RootStyle className="login-page">
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ width: '100%' }}>
          <ContentStyle>
            <HeadingStyle>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Delete your account
              </Typography>
            </HeadingStyle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <TextField
                  id="email"
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email Address"
                  onChange={(e) => setUserEmail(e.target.value)}
                  value={userEmail}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="password"
                  fullWidth
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  value={password}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box>
                <Button
                  id="submit"
                  disabled={loading}
                  fullWidth
                  size="large"
                  type="submit"
                  sx={{ my: 2, display: 'block', padding: 3 }}
                  variant="contained"
                  onClick={handleDeleteRequest}
                >
                  {loading ? <CircularProgress size={16} /> : 'Delete Account'}
                </Button>
                {!!deleteUserRequest && (
                  <ConfirmDialog
                    title="Delete Account"
                    text="Are you sure you want to delete your account? This action is irreversible."
                    okText="Delete"
                    onOk={onDeleteConfirmed}
                    onCancel={() => setDeleteUserRequest(false)}
                    onDeleteSuccess={() => navigate('/logout')}
                    asyncApi={{
                      loading: deleteLoading,
                      error: deleteError,
                      data: deleteData,
                    }}
                  />
                )}
              </Box>
            </Box>
            <Collapse in={!!error}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setError(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {error?.message}
              </Alert>
            </Collapse>
          </ContentStyle>
        </Paper>
      </Container>
    </RootStyle>
  );
}

export default DeleteAccount;
