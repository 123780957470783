// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { CircularProgress, Stack, styled, useMediaQuery } from '@mui/material';

const TableWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['gridHeight', 'disableVerticalScroll'].includes(prop as string),
})<{ gridHeight?: string; disableVerticalScroll: boolean }>`
  flex: 1;
  width: 100%;
  position: relative;

  .ag-body-viewport.ag-layout-normal {
    overflow-y: ${({ disableVerticalScroll }) =>
      disableVerticalScroll ? 'hidden' : 'auto'};
  }
`;

const Grid = ({
  rows,
  columnsDef,
  ROW_HEIGHT,
  maxVisibleRows,
  className,
  hideHeader,
  options,
  defaultColDef,
  onRowClicked,
  filters = {},
  isLiftsGrid,
}: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridReady, setGridReady] = useState(false);
  const sm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    setTimeout(() => setGridReady(true), 100);
  }, []);

  const gridHeight = useMemo(() => {
    if (maxVisibleRows === undefined) {
      return 0;
    }
    const max = maxVisibleRows;
    const rowsCount = rows.length
      ? Math.min(max, Math.max(0, rows.length)) + 1
      : max;
    return rowsCount * ROW_HEIGHT;
  }, [ROW_HEIGHT, maxVisibleRows, rows.length]);

  const sizeColumnsToFit = useCallback(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onGridReady = useCallback(
    ({ api, ...rest }) => {
      setGridApi(api);
      api.sizeColumnsToFit();
      setTimeout(() => {
        if (options?.onGridReady) {
          options?.onGridReady({ api, ...rest });
        }
      }, 250);
    },
    [options, sizeColumnsToFit],
  );

  const filterBy = useCallback(
    (field, value) => {
      if (field === 'name') return;
      const filterInstance = gridApi.getFilterInstance(field);
      if (filterInstance) {
        filterInstance.setModel({
          filter: value,
          type: 'contains',
        });
        filterInstance.applyModel();
        gridApi.onFilterChanged();
      }
      setTimeout(() => sizeColumnsToFit(), 1);
    },
    [gridApi, sizeColumnsToFit],
  );

  useEffect(() => {
    if (gridApi) {
      Object.keys(filters).map((key) => filterBy(key, filters[key]));
    }
  }, [gridApi, filters, filterBy, rows]);

  const getRowStyle = (params: any, onRowClicked: any) => {
    if (onRowClicked) return { cursor: 'pointer' };
  };

  useEffect(() => {
    if (gridApi && rows) {
      gridApi.setRowData(rows);
    }
  }, [rows, gridApi]);

  return (
    <Stack flex={1} width="100%" height={isLiftsGrid && sm ? '800px' : '100%'}>
      <>
        {!gridReady && <CircularProgress size={24} />}
        <TableWrapper
          className={`ag-theme-material ${className || ''}`}
          disableVerticalScroll={maxVisibleRows === undefined}
          gridHeight={gridHeight ? `${gridHeight}px` : '100%'}
          style={{ visibility: gridReady ? 'visible' : 'hidden' }}
        >
          <AutoSizer onResize={sizeColumnsToFit} disableHeight>
            {() => <span />}
          </AutoSizer>
          {gridReady && (
            <AgGridReact
              defaultColDef={defaultColDef}
              enableCellTextSelection={true}
              gridOptions={{
                multiSortKey: 'ctrl',
                rowSelection: 'single',
                suppressCellSelection: true,
                headerHeight: hideHeader ? 0 : 48,
                rowHeight: ROW_HEIGHT,
                onRowDataChanged: sizeColumnsToFit,
                onGridColumnsChanged: sizeColumnsToFit,
                animateRows: true,
                ...options,
                onGridReady,
              }}
              overlayNoRowsTemplate={
                '<span style="color: #878a99; font-size 12px;">No data to display</span>'
              }
              modules={AllCommunityModules}
              columnDefs={columnsDef}
              onRowClicked={onRowClicked}
              rowData={rows}
              getRowStyle={(params: any) => getRowStyle(params, onRowClicked)}
            />
          )}
        </TableWrapper>
      </>
    </Stack>
  );
};
export default Grid;
