import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { projectCraneActions } from '../../../store/projectcrane';
import { USER } from '../../../variables/constants';
import { UserRole } from '../../../variables/enums';
import * as storageHelper from '../../././../utils/storageHelper';
import AppSiteSelector from '../../common/AppSiteSelector';
import Logo from './Logo';
import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';
import { settingsPages, sysAdminPages, userPages } from './menuItems';

function ResponsiveAppBar() {
  const user = storageHelper.getItem(USER);
  const regularUser = user?.id;
  const sysAdmin = user.role === UserRole.ADMIN;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const sm = useMediaQuery('(max-width: 600px)');
  const dispatch = useAppDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSettingsClick = (
    event: React.MouseEvent<HTMLElement>,
    route?: string,
  ) => {
    if (route) {
      navigate(route);
    }
    handleClose();
  };

  const handleCloseNavMenu = (route?: string) => {
    if (route) {
      setDrawerOpened(false);
      navigate(route);
    }
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const [drawerOpened, setDrawerOpened] = React.useState<boolean>();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerOpened(open);
    };

  return (
    <AppBar
      position="sticky"
      id="navmain"
      // sx={{
      //   padding: '0 40px',
      // }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Logo />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpened}
              onClose={toggleDrawer(false)}
            >
              <MobileMenu
                toggleDrawer={toggleDrawer}
                handleCloseNavMenu={handleCloseNavMenu}
              />
            </Drawer>
          </Box>
          <Logo responsive />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            {regularUser &&
              userPages.map(({ label, route }) => (
                <Button
                  key={route}
                  onClick={() => handleCloseNavMenu(route)}
                  className={
                    pathname === route
                      ? 'app-bar-button MuiButton-root-hover'
                      : 'app-bar-button'
                  }
                  sx={{ fontSize: 16 }}
                >
                  {label}
                </Button>
              ))}
            {sysAdmin &&
              sysAdminPages.map(({ label, route }) => (
                <Button
                  key={route}
                  onClick={() => handleCloseNavMenu(route)}
                  className={
                    pathname === route
                      ? 'app-bar-button MuiButton-root-hover'
                      : 'app-bar-button'
                  }
                  sx={{ fontSize: 16 }}
                >
                  {label}
                </Button>
              ))}

            {sysAdmin && (
              <>
                <Button
                  onClick={handleClick}
                  className={
                    settingsPages.some(({ route }) => route === pathname)
                      ? 'app-bar-button MuiButton-root-hover'
                      : 'app-bar-button'
                  }
                  sx={{ fontSize: 16 }}
                >
                  Settings
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{ marginTop: 2, minWidth: 300 }}
                >
                  <Paper sx={{ width: 220 }}>
                    {settingsPages.map(({ label, route }) => (
                      <MenuItem
                        key={route}
                        onClick={(e) => onSettingsClick(e, route)}
                        selected={pathname === route}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Paper>
                </Menu>
              </>
            )}
          </Box>
          {!sm && <AppSiteSelector />}
          <Box sx={{ flexGrow: 0 }}>
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
