import React from 'react';
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CraneTaskDirection } from '../../../variables/enums';

const AssetInfoCard = (props: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const {
    hasJob,
    template,
    handleClickAssetArrow,
    upButtonLoading,
    downButtonLoading,
    isUpTaskActive,
    isDownTaskActive,
  } = props;
  return (
    <Paper
      sx={{
        width: sm ? '100%' : '240px',
        height: 80,
        padding: 2,
        // backgroundColor: 'backgrounds.system',
        boxShadow: '0px 0px 3px 0px #000000',
        margin: 1,
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography
            gutterBottom
            variant="h2Medium"
            component="div"
            noWrap
            sx={
              hasJob
                ? {
                    color: 'typography.disabled',
                  }
                : {}
            }
          >
            {template.barcode || '-'}
          </Typography>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={() =>
                  handleClickAssetArrow(template, CraneTaskDirection.UP)
                }
                disabled={
                  // upButtonLoading ||
                  // hasJob ||
                  isUpTaskActive
                }
                sx={{ color: '#16947D', borderColor: '#16947D' }}
              >
                {upButtonLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  handleClickAssetArrow(template, CraneTaskDirection.DOWN)
                }
                disabled={
                  // downButtonLoading ||
                  // hasJob ||
                  isDownTaskActive
                }
                sx={{ color: '#E73D3D', borderColor: '#E73D3D' }}
              >
                {downButtonLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <ArrowDownwardIcon />
                )}
              </Button>
            </Stack>
            <Stack
              direction="row"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="smallRegular">
                Quantity:&nbsp;
                {template.quantity}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography
            variant="h2Medium"
            sx={
              // hasJob
              //   ? {
              //       color: 'typography.disabled',
              //     }
              //   : {}
              { marginTop: -5 }
            }
          >
            {template.cargoType.name}&nbsp;
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AssetInfoCard;
