import React from 'react';
import { styled } from '@mui/material';
import NumericInput, { NumericInputProps } from 'react-numeric-input';
import { isMobile, isTablet, isAndroid } from 'react-device-detect';

export type NumberInputProps = NumericInputProps;

const StyledNumericInput = styled(NumericInput)`
  min-height: 39px;
  max-height: 39px;
  width: 100%;
  font-size: 14px !important;
  padding: 0 12px !important;
`;

export const NumberInput = (props: NumberInputProps) => {
  return <StyledNumericInput {...props} mobile={isMobile || isTablet || isAndroid } />;
};
