import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

interface TelemetryCardProps {
  title: string;
  units: string;
  value: string;
  icon: JSX.Element;
  titleStyle?: React.CSSProperties;
}

const TelemetryCard = ({
  title,
  units,
  value,
  icon,
  titleStyle,
}: TelemetryCardProps) => {
  const sm = useMediaQuery('(max-width: 600px)');

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={2.8}
      lg={2.8}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: sm ? 1 : 2,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          height: sm ? '200px' : '250px',
          width: sm ? '80%' : '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          padding: sm ? 1 : 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            zIndex: 100,
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: sm ? '26px' : '36px',
              color: '#ff6f00',
            }}
          >
            {value && value !== 'NaN' ? value : '0'}
          </Typography>
          <Typography
            variant="mediumMedium"
            sx={{
              color: 'darkgray',
              textAlign: 'center',
              height: '20px',
            }}
          >
            {units}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            flex: 1,
          }}
        >
          {icon}
          <Typography
            variant="mediumMedium"
            style={titleStyle}
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            {title}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default TelemetryCard;
