import React, { useEffect, useReducer } from 'react';
import '../../components/ObjList.scss';
import { MuiColorInput } from 'mui-color-input';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  TextField,
  CircularProgress,
} from '@mui/material';
import useCargoTypes from '../../hooks/useCargoTypes';
import { useAppSelector } from '../../store/hooks';
import { ErrorAlert } from '../../components/alerts';

const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const EditCargoType = ({ onClose, onSuccess, item }: any) => {
  const { updateCargoTypeApi, updateCargoTypeApiReset } = useCargoTypes();
  const { loading, error, data } = useAppSelector(
    (state) => state.cargoType.updateApi,
  );

  const [state, dispatch] = useReducer(reducer, item);
  const valid = !!state.name && !!state.color;

  const onSubmit = async () => {
    if (!valid) return;
    await updateCargoTypeApi(item.name, state);
  };

  useEffect(() => {
    if (data) {
      onSuccess();
      updateCargoTypeApiReset();
    }
  }, [updateCargoTypeApiReset, data, onSuccess]);

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Update Cargo Type</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Stack spacing={4}>
            <label>Description</label>
            <TextField
              fullWidth
              value={state.name}
              autoFocus
              onChange={(e) =>
                dispatch({ type: 'name', payload: e.target.value })
              }
            />
          </Stack>
          <Stack spacing={4}>
            <label>Color</label>
            <Stack direction="row">
              <MuiColorInput
                value={state.color}
                format="hex"
                onChange={(e) => dispatch({ type: 'color', payload: e })}
              />
            </Stack>
          </Stack>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!valid || loading}
        >
          {loading ? <CircularProgress size={16} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCargoType;
