import React from 'react';
import { Grid } from '../../../grid';
import { ContentStack } from '../../../styled';

const Table = ({ data, getColumnsDef }: any) => {
  return (
    <ContentStack>
      <Grid
        {...{
          rows: data,
          columnsDef: getColumnsDef(),
          className: 'charts',
          ROW_HEIGHT: 32,
        }}
      />
    </ContentStack>
  );
};

export default Table;
