import React from 'react';
import {
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useGetCargoType } from '../../../../../queries';
import { Loading } from '../../../../../components/styled';
import ErrorState from '../../../../../components/alerts/ErrorState';
import { ProjectCraneSelector } from '../../../../../components/selectors';

const LogisticsInventory = (props: any) => {
  const {
    cargoTypeId,
    setCargoTypeId,
    quantity,
    setQuantity,
    setSelectedProject,
    setSelectedCrane,
  } = props;

  const {
    isLoading: cargoTypesLoading,
    data: cargoTypesData,
    error: cargoTypesError,
  } = useGetCargoType();

  const sm = useMediaQuery('(max-width: 600px)');

  return (
    <Stack spacing={4} sx={{ height: '100%' }}>
      {cargoTypesLoading && !cargoTypesError && <Loading />}
      {!cargoTypesLoading && cargoTypesError && (
        <ErrorState error={cargoTypesError} />
      )}
      {!cargoTypesLoading && !cargoTypesError && (
        <ProjectCraneSelector
          onProjectChange={setSelectedProject}
          onCraneChange={setSelectedCrane}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        >
          <Stack
            spacing={2}
            sx={{
              marginRight: sm ? 0 : 4,
              marginBottom: 4,
              width: sm ? '100%' : 'auto',
            }}
          >
            <Typography variant="smallRegular">Cargo type</Typography>
            <Select
              value={cargoTypeId}
              onChange={({ target: { value } }) => setCargoTypeId(value)}
              sx={{
                width: sm ? '100%' : 220,
                '& .MuiSelect-select .notranslate::after': {
                  content: '"Select cargo type"',
                  opacity: 0.42,
                },
              }}
            >
              {cargoTypesData?.data.map((cargoType: any) => (
                <MenuItem key={cargoType.id} value={cargoType.id}>
                  {cargoType.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              marginRight: sm ? 0 : 4,
              marginBottom: 4,
              width: sm ? '100%' : 'auto',
            }}
          >
            <Typography variant="smallRegular">Quantity</Typography>
            <TextField
              sx={{
                width: sm ? '100%' : 220,
              }}
              type="number"
              value={quantity}
              onChange={({ target: { value } }) => setQuantity(value)}
            />
          </Stack>
        </ProjectCraneSelector>
      )}
    </Stack>
  );
};

export default LogisticsInventory;
