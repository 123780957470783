import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = () => apiGetAsync('crane');
export const useGetCranes = () => {
  const key = ['GetCranes'];
  const fn = () => api();

  return useQuery(key, fn, { cacheTime: 0 });
};
