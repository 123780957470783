import { Typography } from '@mui/material';
import {
  ActionsRenderer,
  CheckboxRenderer,
} from '../../../../../components/grid';

export default (
  ROW_HEIGHT: string | number,
  onDelete?: any,
  onActive?: any,
  onEdit?: any,
) => {
  return [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
      minWidth: 350,
      maxWidth: 350,
    },
    {
      headerName: 'Identity Name',
      field: 'identityName',
      sortable: true,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      headerName: 'Type',
      field: 'type',
      sort: 'asc',
      cellRendererFramework: ({ data }: any) => {
        const { type } = data;
        const typeStr =
          type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
        return <Typography>{typeStr}</Typography>;
      },
    },
    {
      headerName: 'IP Address',
      field: 'ipAddress',
      sort: 'asc',
    },
    {
      headerName: 'Active',
      field: 'isActive',
      sortable: true,
      cellRendererFramework: CheckboxRenderer,
      minWidth: 100,
      maxWidth: 100,
      cellRendererParams: {
        onClick: onActive,
      },
    },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'id',
      minWidth: 140,
      maxWidth: 140,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onEdit,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
