import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  ContentStack,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import { Button, Stack, SelectChangeEvent, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorState from '../../components/alerts/ErrorState';
import { Grid } from '../../components/grid';
import getColumnsDef from './columns';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useAssets from '../../hooks/useAssets';
import CreateAsset from './CreateAsset';
import { ConfirmDialog } from '../../components/confirm';
import EditAsset from './EditAsset';
import Toolbar from './Toolbar';
import {
  useGetAssetsInfo,
  useGetCargoType,
  useGetCraneTasks,
} from '../../queries';
import { craneTasksActions } from '../../store/craneTask';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const AssetManager = () => {
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
  const dispatch = useAppDispatch();
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.selectedCrane,
  );
  const templates = useAppSelector((state: any) => state.craneTasks.templates);
  const Project = useAppSelector((state: any) => state.projectCrane.Project);

  const { deleteAssetApi, deleteAssetApiReset } = useAssets();
  const deleteApi = useAppSelector((state) => state.asset.deleteApi);
  const [editItemId, setEditItemId] = useState<number | null>();
  const [deleteItemId, setDeleteItemId] = useState<string | null>();
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<any>('');
  const [selectedCargoType, setSelectedCargoType] = useState('');
  const [createdDate, setCreatedDate] = useState<moment.Moment | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const {
  //   error: assetInfoError,
  //   isLoading: assetInfoLoading,
  //   isSuccess: assetInfoSuccess,
  //   data: assetInfoData,
  //   refetch: getAssetInfo,
  // } = useGetAssetsInfo(
  //   {
  //     selectedDate: createdDate ? createdDate.format('yyyy-MM-DD') : '',
  //     CargoTypeId: selectedCargoType || '0',
  //     selectedCrane,
  //     selectedProject,
  //     scheduled: false,
  //   },
  //   {
  //     onSuccess: ({ data }: any) => {
  //       setIsLoading(false);
  //     },
  //   },
  // );

  const {
    error: assetInfoError,
    isLoading: assetInfoLoading,
    isSuccess: assetInfoSuccess,
    data: assetInfoData,
    refetch: getCraneTaskTemplates,
  } = useGetCraneTasks(
    {
      craneId: selectedCrane.id,
    },
    {
      onSuccess: (data: any) => {
        const { tasks, templates: newTemplates } = data.data;
        // dispatch(craneTasksActions.setTasks(tasks));
        dispatch(craneTasksActions.setTemplates(newTemplates));
        setIsLoading(false);
      },
    },
  );

  useEffect(() => {
    if (selectedCrane && selectedProject) {
      getCraneTaskTemplates();
      setIsLoading(true);
    }
  }, [selectedCrane, selectedProject, createdDate, selectedCargoType]);

  const { isLoading: cargoTypesLoading, data: cargoTypesData } =
    useGetCargoType();

  const onCargoTypeChange = (event: SelectChangeEvent) => {
    setSelectedCargoType(event.target.value);
  };

  const onRefreshClick = () => {
    getCraneTaskTemplates();
    setIsLoading(true);
  };

  const onEdit = (editItemId?: number) => {
    setEditItemId(editItemId);
  };

  const onEditSuccess = async () => {
    // getCraneTaskTemplates();
    // setIsLoading(true);
    onEdit();
  };

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    getCraneTaskTemplates();
    setIsLoading(true);
    setCreateOpen(false);
  };

  const onDelete = (deleteItemId?: string) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = async (deleteItemId?: string) => {
    console.log('deleteItemId', deleteItemId);
    await deleteAssetApi(deleteItemId);
    dispatch(
      craneTasksActions.setTemplates(
        templates.filter(({ id }: any) => id !== deleteItemId),
      ),
    );
    setDeleteItemId(null);
  };

  const onDeleteSuccess = async (deleteItemId?: number) => {
    getCraneTaskTemplates();
    setIsLoading(true);
    deleteAssetApiReset();
    onDelete();
  };

  return (
    <LayoutStack>
      <MainTitle>Asset Manager</MainTitle>
      <Toolbar
        {...{
          setSelectedProject,
          createdDate,
          setCreatedDate,
          selectedCargoType,
          onCargoTypeChange,
          onRefreshClick,
        }}
      />
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          {/*<Tabs value={0}>*/}
          {/*  <Tab label="Asset List" />*/}
          {/*  <Tab label="Project Image" />*/}
          {/*</Tabs>*/}
        </Stack>
        <Stack>
          <Button
            variant="contained"
            onClick={() => onCreate(true)}
            endIcon={<AddIcon />}
            disabled={Project === '' || selectedCrane?.id === ''}
          >
            Add
          </Button>
        </Stack>
      </Stack>
      <ContentStack>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {assetInfoError && <ErrorState error={assetInfoError} />}
            {assetInfoSuccess && !assetInfoError && (
              <Grid
                {...{
                  rows: templates,
                  columnsDef: getColumnsDef(
                    medium || sm ? 100 : ROW_HEIGHT,
                    onEdit,
                    onDelete,
                    medium || sm,
                  ),
                  onEdit,
                  onDelete,
                  ROW_HEIGHT: medium || sm ? 100 : ROW_HEIGHT,
                  maxVisibleRows,
                  hideHeader: sm,
                  isLiftsGrid: true,
                }}
              />
            )}
            {createOpen && (
              <CreateAsset
                onClose={() => onCreate(false)}
                onSuccess={onCreateSuccess}
              />
            )}
            {editItemId && !!templates.length && (
              <EditAsset
                item={templates.find(({ id }: any) => id === editItemId)}
                onClose={() => onEdit()}
                onSuccess={onEditSuccess}
              />
            )}
            {!!deleteItemId && (
              <ConfirmDialog
                title="Delete Asset"
                text="Are you sure you want to delete this asset?"
                onOk={() => onDeleteConfirmed(deleteItemId)}
                onCancel={() => onDelete()}
                onDeleteSuccess={onDeleteSuccess}
                asyncApi={deleteApi}
              />
            )}
          </>
        )}
      </ContentStack>
    </LayoutStack>
  );
};
export default AssetManager;
