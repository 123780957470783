const parseJSON = (value: string | null) => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}

export const getItem = (key: string) => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? parseJSON(item) : undefined;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error)
    return undefined
  }
}

export const setItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error)
  }
}

export const removeItem = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    console.warn(`Error removing localStorage key “${key}”:`, error)
  }
}
