import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => apiPutAsync(`user/${props.id}/reports`);

export const usePutUserReceiveReportsStatus = (options?: any) => {
  const key = ['PutUserReceiveReportsStatus'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
