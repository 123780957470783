import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import {
  Button,
  IconButton,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ContentStack,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import * as storageHelper from '../../utils/storageHelper';
import { ULTRAWIS_SELECTED_CRANE_DATA, USER } from '../../variables/constants';
import { convertDateToAppDate } from '../../utils/helpers';
import ErrorState from '../../components/alerts/ErrorState';
import {
  useGetCargoType,
  useDeleteAssetJob,
  usePostAssetJob,
  useGetAssetsJobs,
  useGetAssetsInfo,
  useGetCraneTasks,
  usePostCraneTask,
} from '../../queries';
import { EmptyState } from '../../components/alerts';
import {
  Toolbar,
  AssetInfoCard,
  AssetJobsList,
  TasksEmptyState,
} from './components';
import { usePostReorderAssetJobs } from '../../queries/usePostReorderAssetJobs';
import CranebitTasks from '../CranebitTasks';
import { margin } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { set } from 'lodash';
import { loadavg } from 'os';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CraneTaskTemplate, craneTasksActions } from '../../store/craneTask';
import { CraneTaskDirection, CraneTaskType } from '../../variables/enums';

const reorderAssetJobs = (
  assetsJobs: any,
  startIndex: number,
  endIndex: number,
  jobInterval: number,
) => {
  const assetsJobsArray = Array.from(assetsJobs);

  //@ts-ignore
  const { TimeSlice: firstTimeSlice, DateSchedule: jobsDate } =
    assetsJobsArray[0];

  const [removed] = assetsJobsArray.splice(startIndex, 1);
  assetsJobsArray.splice(endIndex, 0, removed);

  return assetsJobsArray.map((item: any, index: number) => {
    const calculatedTime = moment(`${jobsDate} ${firstTimeSlice}`).add(
      jobInterval * index,
      'minute',
    );

    return {
      AssetInfoId: item.AiId,
      DateSchedule: jobsDate,
      TimeSlice: calculatedTime.format('HH:mm:ss'),
    };
  });
};

const CraneScheduler = () => {
  const queryClient = useQueryClient();
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.selectedCrane,
  );
  const tasks = useAppSelector((state: any) => state.craneTasks.tasks);
  const templates = useAppSelector((state: any) => state.craneTasks.templates);
  const user = storageHelper.getItem(USER);

  const [assetsJobs, setAssetsJobs] = useState<any>([]);
  const [filteredAssetsInfos, setFilteredAssetsInfos] = useState<any>([]);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedCargoType, setSelectedCargoType] = useState('');
  const [selectedProject, setSelectedProject] = useState<any>('');
  // const [selectedCrane, setSelectedCrane] = useState<any>('');
  const [clickedAsset, setClickedAsset] = useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [createdTask, setCreatedTask] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetchTasks, setRefetchTasks] = useState(0);

  const dispatch = useAppDispatch();

  //#region react-query

  const {
    mutate: deleteAssetJobsMutate,
    isLoading: deleteAssetJobsIsLoading,
    error: deleteAssetJobsError,
  } = useDeleteAssetJob({
    onSuccess: () => {
      // assetsJobsRefetch();
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteAssetJob');
    },
  });

  const {
    mutate: createCraneTaskMutate,
    isLoading: createCraneTaskIsLoading,
    error: createCraneTaskError,
  } = usePostCraneTask({
    onSuccess: (data: any) => {
      setClickedAsset(null);
      const newTask = data.data;
      setCreatedTask(newTask);
      dispatch(craneTasksActions.setTasks([...tasks, newTask]));
      dispatch(
        craneTasksActions.setTemplates(
          templates
            .map((template: CraneTaskTemplate) => {
              if (template.id === newTask.templateId) {
                const newQuantity = template.quantity - 1;
                if (newQuantity === 0) {
                  return undefined;
                }
                return {
                  ...template,
                  quantity: newQuantity,
                };
              }
              return template;
            })
            .filter(Boolean),
        ),
      );
      // assetsInfoRefetch();
      setIsLoading(false);
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('PostAssetJob');
    },
  });

  const {
    mutate: reorderAssetJobsMutate,
    isLoading: reorderAssetJobsIsLoading,
    error: reorderAssetJobsError,
  } = usePostReorderAssetJobs({
    onSuccess: () => {
      // assetsJobsRefetch();
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('PostReorderAssetJobs');
    },
  });

  const {
    isLoading: cargoTypesLoading,
    data: cargoTypesData,
    error: cargoTypesError,
  } = useGetCargoType();

  // const {
  //   isLoading: assetsJobsIsLoading,
  //   error: assetsJobsError,
  //   refetch: assetsJobsRefetch,
  // } = useGetAssetsJobs(
  //   {
  //     dateLoad: convertDateToAppDate(selectedDate),
  //     craneId: selectedCrane.toString(),
  //   },
  //   {
  //     onSuccess: (data: any) => {
  //       console.log('data', data);
  //       setAssetsJobs(
  //         data.data?.filter(({ AiId, Done }: any) => !!AiId && !Done),
  //       );
  //     },
  //   },
  // );

  const {
    isLoading: craneTasksIsLoading,
    error: craneTasksError,
    refetch: craneTasksRefetch,
  } = useGetCraneTasks(
    {
      craneId: selectedCrane.id,
    },
    {
      onSuccess: (data: any) => {
        const { tasks, templates } = data.data;
        dispatch(craneTasksActions.setTasks(tasks));
        dispatch(craneTasksActions.setTemplates(templates));
        setIsLoading(false);
      },
    },
  );
  // const {
  //   isLoading: assetsInfoIsLoading,
  //   error: assetsInfoError,
  //   refetch: assetsInfoRefetch,
  // } = useGetAssetsInfo(
  //   {
  //     selectedDate: '',
  //     CargoTypeId: selectedCargoType || 0,
  //     selectedCrane,
  //     selectedProject,
  //     scheduled: false,
  //   },
  //   {
  //     onSuccess: (data: any) => {
  //       setFilteredAssetsInfos(
  //         data.data?.filter(
  //           (record: any) =>
  //             record.Quantity && record.Quantity !== 0 && record.Org,
  //         ) || [],
  //       );
  //       setIsLoading(false);
  //     },
  //   },
  // );

  //#endregion

  //#region Handlers
  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const onCargoTypeChange = (event: SelectChangeEvent) => {
    setSelectedCargoType(event.target.value);
  };

  const handleClickAssetArrow = async (
    template: any,
    direction: CraneTaskDirection,
  ) => {
    const {
      id,
      barcode,
      sourceLat,
      sourceLong,
      sourceAlt,
      destinationLat,
      destinationLong,
      destinationAlt,
      craneId,
      cargoTypeId,
    } = template;

    // const CargoTypeId = cargoTypesData?.data?.find(
    //   (cargoType: any) => cargoType?.CargoTypeDesc === CargoTypeDesc,
    // )?.ID;

    // const JobInterval = user.Projects.find(
    //   (rec: any) => rec.ID === selectedProject,
    // ).JobInterval;

    setClickedAsset({ id, direction });

    const type = CraneTaskType.TEMPLATE;

    createCraneTaskMutate({
      type,
      barcode,
      sourceLat,
      sourceLong,
      sourceAlt,
      destinationLat,
      destinationLong,
      destinationAlt,
      direction,
      craneId,
      cargoTypeId,
      issuerId: user.id,
      templateId: id,
    });
  };

  const openCranebitScreen = () => {
    const w = 650;
    const h = 850;
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 4 / systemZoom + dualScreenTop;

    const newWindow = window.open(
      `/#/cranebit/${selectedCrane}`,
      'cranebit',
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
    );
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // dropped in disabled area
    if (result.destination.index < lastEventDone?.ID) {
      return;
    }

    // dropped in same position
    if (result.destination.index === result.source.index) {
      return;
    }

    const jobInterval = user.Projects.find(
      (rec: any) => rec.ID === selectedProject,
    ).JobInterval;

    const assetJobsOrder = reorderAssetJobs(
      assetsJobs,
      result.source.index,
      result.destination.index,
      jobInterval,
    );

    reorderAssetJobsMutate({ assetJobsOrder, jobInterval });
  };

  const handleClickDeleteAssetJob = (aiId: any) => {
    deleteAssetJobsMutate({
      aiId,
    });
  };

  useEffect(() => {
    if (selectedCrane?.id) {
      setIsLoading(true);
      // assetsInfoRefetch();
      // assetsJobsRefetch();s
      craneTasksRefetch();
    } else {
      // setIsLoading(true);
      // setFilteredAssetsInfos([]);
    }
  }, [selectedCrane, ULTRAWIS_SELECTED_CRANE_DATA]);

  useEffect(() => {
    if (
      !!createCraneTaskError ||
      !!deleteAssetJobsError ||
      !!cargoTypesError ||
      !!reorderAssetJobsError
    ) {
      setOpenSnackbar(true);
    }
  }, [
    createCraneTaskError,
    deleteAssetJobsError,
    cargoTypesError,
    reorderAssetJobsError,
  ]);

  const lastEventDone = assetsJobs
    .slice()
    .reverse()
    .find((event: any) => event.Done);

  const error = (
    (createCraneTaskError ||
      deleteAssetJobsError ||
      cargoTypesError ||
      reorderAssetJobsError) as Error
  )?.message;

  const isAssetsInfoSettled = true;
  // !assetsInfoIsLoading && !assetsInfoError;
  const isAssetsJobsSettled =
    !createCraneTaskIsLoading &&
    !deleteAssetJobsIsLoading &&
    // !assetsJobsIsLoading &&
    !reorderAssetJobsIsLoading;
  // !assetsJobsError;
  //#endregion

  if (cargoTypesLoading) return <Loading />;

  return (
    <LayoutStack height="100%">
      <Stack direction="row" justifyContent="space-between">
        <MainTitle>Tasks Scheduler</MainTitle>
        {/* <Button
          variant="outlined"
          onClick={openCranebitScreen}
          sx={{ height: 39 }}
        >
          Crane Operator Tasks Screen
        </Button> */}
      </Stack>
      <Toolbar
        {...{
          setSelectedProject,
          setSelectedCrane: () => {},
          selectedDate,
          setSelectedDate,
          selectedCargoType,
          onCargoTypeChange,
          cargoTypes: cargoTypesData?.data,
          onRefresh: () => {
            // setIsLoading(true);
            // assetsInfoRefetch();
            setRefetchTasks((prev) => prev + 1);
            // assetsJobsRefetch();
            craneTasksRefetch();
          },
        }}
      />
      <Stack
        spacing={sm ? 5 : 0}
        direction={sm ? 'column-reverse' : 'row'}
        height={sm ? 'none' : '100%'}
        // height="100%"
      >
        <ContentStack height="calc(100% - 20px)" flex={medium || sm ? 1 : 3}>
          <Stack width="100%" spacing={2} height="100%">
            <Stack direction="column" height="100%">
              <Typography
                variant="h2Medium"
                sx={{ marginBottom: 5, textAlign: sm ? 'center' : 'inherit' }}
              >
                Task Templates <CalendarMonthIcon />
              </Typography>
              <Stack
                flex={2}
                height="100%"
                sx={{
                  flexWrap: 'wrap',
                  gap: 6,
                  overflow: 'auto',
                  paddingRight: 4,
                  alignContent: 'flex-start',
                  display: isLoading ? 'flex' : sm ? 'flex' : 'inherit',
                  aligItems: sm ? 'center' : 'inherit',
                  justifyContent: isLoading
                    ? 'center'
                    : sm
                    ? 'center'
                    : 'inherit',
                }}
                direction="row"
              >
                {/* {assetsInfoIsLoading && !assetsInfoError && <Loading />} */}
                {!craneTasksIsLoading && craneTasksError && (
                  <ErrorState height="100%" error={craneTasksError} />
                )}
                {isLoading && <Loading />}

                {!isLoading &&
                  isAssetsInfoSettled &&
                  (templates.length === 0 ? (
                    <EmptyState sx={{ height: '100%' }} />
                  ) : (
                    templates.map((template: any) => {
                      const upButtonLoading =
                        createCraneTaskIsLoading &&
                        clickedAsset?.id === template.id &&
                        clickedAsset?.direction === CraneTaskDirection.UP;

                      const downButtonLoading =
                        createCraneTaskIsLoading &&
                        clickedAsset?.id === template.id &&
                        clickedAsset?.direction === CraneTaskDirection.DOWN;

                      const hasJob = tasks.some(
                        ({ templateId }: any) => template.id === templateId,
                      );

                      return (
                        <AssetInfoCard
                          key={uuid()}
                          {...{
                            hasJob,
                            template,
                            handleClickAssetArrow,
                            upButtonLoading,
                            downButtonLoading,
                            isUpTaskActive: tasks.some(
                              (task: any) =>
                                task.templateId === template.id &&
                                task.direction === CraneTaskDirection.UP,
                            ),
                            isDownTaskActive: tasks.some(
                              (task: any) =>
                                task.templateId === template.id &&
                                task.direction === CraneTaskDirection.DOWN,
                            ),
                          }}
                        />
                      );
                    })
                  ))}
              </Stack>
            </Stack>
          </Stack>
        </ContentStack>
        <ContentStack
          height="calc(100% - 20px)"
          sx={{
            marginLeft: 5,
          }}
          flex={medium || sm ? 3 : 1}
        >
          {/* {(createCraneTaskIsLoading ||
              deleteAssetJobsIsLoading ||
              assetsJobsIsLoading ||
              reorderAssetJobsIsLoading) &&
              !assetsJobsError && <Loading />} */}

          <CranebitTasks
            refetchTasks={refetchTasks}
            craneId={selectedCrane}
            cranebitTasksError={craneTasksError}
            cranebitTasksIsSuccess={!craneTasksIsLoading}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            createdTask={createdTask}
            tasks={tasks}
          />

          {/* {!assetsJobsIsLoading && assetsJobsError && (
              <ErrorState error={assetsJobsError} />
            )} */}

          {/* {isAssetsJobsSettled &&
                (assetsJobs?.length === 0 ? (
                  <TasksEmptyState />
                ) : (
                  <AssetJobsList
                    {...{
                      onDragEnd,
                      assetsJobs,
                      lastEventDone,
                      handleClickDeleteAssetJob,
                    }}
                  />
                ))} */}
        </ContentStack>
      </Stack>

      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={error}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutStack>
  );
};

export default CraneScheduler;
