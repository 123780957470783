import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { Paper, Stepper, Step, StepLabel } from '@mui/material';

const LogisticStepperHeader = (props: any) => {
  const { activeStep, steps, isStepSkipped } = props;

  if (activeStep > steps.length) return null;

  return (
    <Paper
      sx={{
        padding: 4,
        backgroundColor: 'common.white',
        width: '100%',
      }}
      elevation={1}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label: string, index: number) => {
          const stepProps: { completed?: boolean } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={uuid()} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Paper>
  );
};

export default LogisticStepperHeader;
