import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => {
  const { craneId, ...restProps } = props;
  return apiPutAsync(`crane/${craneId}/video-recordings/request`, restProps);
};

export const usePutRequestCraneVideoRecordings = (options: any) => {
  const key = ['PutRequestCraneVideoRecordings'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
