import Typography from '@mui/material/Typography';
import React from 'react';
import { Alert as MuiAlert } from '@mui/material';

const LogisticsSubmittedContent = (props: any) => {
  return (
    <MuiAlert severity="success" sx={{ width: '100%', alignItems: 'center' }}>
      <Typography variant="smallRegular">
        Asset successfully created!
      </Typography>
    </MuiAlert>
  );
};

export default LogisticsSubmittedContent;
