import { createSlice } from '@reduxjs/toolkit';
import { AsyncState, defaultAsyncState, InitialAsyncState } from './types';

export * from './types';

export const fetchBuilder = (prefix: string) => (builder: any) => {
  builder.addCase(`${prefix}/fetch/pending`, (state: any, action: any) => {
    state.loading = true;
    state.error = undefined;
  });
  builder.addCase(`${prefix}/fetch/rejected`, (state: any, action: any) => {
    state.loading = false;
    state.error = action.error;
  });
  builder.addCase(`${prefix}/fetch/fulfilled`, (state: any, action: any) => {
    state.loading = false;
    state.data = action.payload;
  });
};

export const builder = (prefix: string) => (builder: any) => {
  fetchBuilder(prefix)(builder);
  builder.addCase(`${prefix}/create/pending`, (state: any, action: any) => {
    state.createApi.loading = true;
    state.createApi.error = undefined;
  });
  builder.addCase(`${prefix}/create/rejected`, (state: any, action: any) => {
    state.createApi.loading = false;
    state.createApi.error = action.error;
  });
  builder.addCase(`${prefix}/create/fulfilled`, (state: any, action: any) => {
    state.createApi.loading = false;
    state.createApi.data = action.payload;
  });
  builder.addCase(`${prefix}/create/reset`, (state: any, action: any) => {
    state.createApi.loading = false;
    state.createApi.error = undefined;
    state.createApi.data = undefined;
  });
  builder.addCase(`${prefix}/delete/pending`, (state: any, action: any) => {
    state.deleteApi.loading = true;
    state.deleteApi.error = undefined;
  });
  builder.addCase(`${prefix}/delete/rejected`, (state: any, action: any) => {
    state.deleteApi.loading = false;
    state.deleteApi.error = action.error;
  });
  builder.addCase(`${prefix}/delete/fulfilled`, (state: any, action: any) => {
    state.deleteApi.loading = false;
    state.deleteApi.data = action.payload;
  });
  builder.addCase(`${prefix}/delete/reset`, (state: any, action: any) => {
    state.deleteApi.loading = false;
    state.deleteApi.error = undefined;
    state.deleteApi.data = undefined;
  });
  builder.addCase(`${prefix}/update/pending`, (state: any, action: any) => {
    state.updateApi.loading = true;
    state.updateApi.error = undefined;
  });
  builder.addCase(`${prefix}/update/rejected`, (state: any, action: any) => {
    state.updateApi.loading = false;
    state.updateApi.error = action.error;
  });
  builder.addCase(`${prefix}/update/fulfilled`, (state: any, action: any) => {
    state.updateApi.loading = false;
    state.updateApi.data = action.payload;
  });
  builder.addCase(`${prefix}/update/reset`, (state: any, action: any) => {
    state.updateApi.loading = false;
    state.updateApi.error = undefined;
    state.updateApi.data = undefined;
  });
};

export function createCrudSlice<T>(name: string) {
  const initialState: InitialAsyncState<T[]> = {
    data: [],
    loading: false,
    error: undefined,
    deleteApi: defaultAsyncState,
    createApi: defaultAsyncState,
    updateApi: defaultAsyncState,
  };

  return createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers: builder(name),
  });
}

export function createFetchSlice(name: string, initialState: any) {
  return createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers: fetchBuilder(name),
  });
}
