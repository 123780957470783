import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { set, sortBy } from 'lodash';
import { projectCraneActions } from '../../store/projectcrane';
import * as storageHelper from '../../utils/storageHelper';
import {
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_CRANE_DATA,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import {
  Stack,
  Select,
  MenuItem,
  PaperProps,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useAppSelector } from '../../store/hooks';

const ProjectCraneSelector = ({ sx, children }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const dispatch = useDispatch();
  const [projects, setProjects] = useState<any>([]);
  // const [cranes, setCranes] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>(
    storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT) || '',
  );
  const [selectedCrane, setSelectedCrane] = useState<any>(
    storageHelper.getItem(ULTRAWIS_SELECTED_CRANE) || '',
  );

  const selectedProjectStore = useAppSelector(
    (state: any) => state.projectCrane.Project,
  );

  const user = storageHelper.getItem(USER);

  const userStore = useAppSelector((state: any) => state.user);

  useEffect(() => {
    setProjects(user.sites);
    // // dispatch(projectCraneActions.setCurrentProject(user.sites[0].id));
    // // dispatch(projectCraneActions.setCurrentCrane(user.sites[0].cranes[0].id));
    // // setCranes(user.Cranes);
    // const project = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);
    // if (project) {
    //   dispatch(projectCraneActions.setCurrentProject(project));
    //   setSelectedProject(project);
    // } else if (user?.sites[0]?.id) {
    //   const projectId = user.sites[0].id;
    //   dispatch(projectCraneActions.setCurrentProject(projectId));
    //   setSelectedProject(projectId);
    //   storageHelper.setItem(ULTRAWIS_SELECTED_PROJECT, projectId);
    // }

    const crane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE_DATA);
    if (crane) {
      dispatch(projectCraneActions.setSelectedCrane(crane));
      setSelectedCrane(crane.id);
    } else if (user?.sites[0]?.cranes[0]?.id) {
      const crane = user.sites[0].cranes[0];
      dispatch(projectCraneActions.setCurrentCrane(crane.id));
      setSelectedCrane(crane.id);
      storageHelper.setItem(ULTRAWIS_SELECTED_CRANE, crane.id);
      storageHelper.setItem(ULTRAWIS_SELECTED_CRANE_DATA, crane);
    }
  }, [storageHelper, userStore]);

  useEffect(() => {
    if (selectedProjectStore) {
      setSelectedProject(selectedProjectStore);
      const selectedCraneStorage = storageHelper.getItem(
        ULTRAWIS_SELECTED_CRANE_DATA,
      );
      const selectedCraneStorageId = selectedCraneStorage?.id;
      const isStorageCraneInProject = user.sites.some(
        (site: any) =>
          site.id === selectedProjectStore &&
          site.cranes.some((crane: any) => crane.id === selectedCraneStorageId),
      );
      if (isStorageCraneInProject) return;

      const newSelectedCrane = user.sites.filter(
        (site: any) => site.id === selectedProjectStore,
      )[0].cranes[0]?.id;
      if (newSelectedCrane) updateCrane(newSelectedCrane);
    }
    // if (selectedCrane && cranes.length) {
    //   dispatch(projectCraneActions.setCurrentCrane(selectedCrane));
    //   onCraneChange(selectedCrane);
    // }
  }, [projects, selectedProjectStore]);

  const handleProjectChange = (event: any) => {
    const { value } = event.target;
    dispatch(projectCraneActions.setCurrentProject(value));
    dispatch(projectCraneActions.setCurrentCrane(''));
    setSelectedProject(value);
    setSelectedCrane('');
    storageHelper.setItem(ULTRAWIS_SELECTED_PROJECT, value);
    storageHelper.setItem(ULTRAWIS_SELECTED_CRANE, '');
  };

  const handleCraneChange = (event: any) => {
    const { value } = event.target;
    updateCrane(value);
  };

  const updateCrane = (craneId: string) => {
    dispatch(projectCraneActions.setCurrentCrane(craneId));
    setSelectedCrane(craneId);
    storageHelper.setItem(ULTRAWIS_SELECTED_CRANE, craneId);

    const crane = user.sites
      .filter((site: any) => site.id === selectedProjectStore)[0]
      .cranes.filter((crane: any) => crane.id === craneId)[0];
    dispatch(projectCraneActions.setSelectedCrane(crane));
    storageHelper.setItem(ULTRAWIS_SELECTED_CRANE_DATA, crane);
  };

  return (
    <Stack direction="row" flexWrap="wrap" sx={sx}>
      {sm && (
        <Stack
          spacing={2}
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">Site</Typography>
          <Select
            value={selectedProject}
            onChange={handleProjectChange}
            sx={{ width: sm ? '100%' : 220 }}
          >
            {projects &&
              projects.length > 0 &&
              sortBy(projects, 'name').map((site: any) => (
                <MenuItem key={uuid()} value={site.id}>
                  {site.name}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      )}
      <Stack
        spacing={2}
        sx={{
          marginRight: sm ? 0 : 4,
          marginBottom: 4,
          width: sm ? '100%' : 'auto',
        }}
      >
        <Typography variant="smallRegular">Crane</Typography>
        <Select
          value={selectedCrane}
          onChange={handleCraneChange}
          sx={{ width: sm ? '100%' : 220 }}
        >
          {
            projects &&
              projects.length > 0 &&
              projects
                .filter((site: any) => site.id === selectedProject)
                .map((site: any) => {
                  return site.cranes.map((crane: any) => {
                    return (
                      <MenuItem key={crane.id} value={crane.id}>
                        {crane.name}
                      </MenuItem>
                    );
                  });
                })
            // sortBy(cranes, 'CraneName')
            //   .filter((crane: any) => crane.ProjectId === selectedProject)
            //   .map((Crane: any) => (
            //     <MenuItem key={uuid()} value={Crane.ID}>
            //       {Crane.CraneName}
            //     </MenuItem>
            //   ))}
          }
        </Select>
      </Stack>
      {children}
    </Stack>
  );
};

export default ProjectCraneSelector;
