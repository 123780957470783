import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => {
  const { siteId, ...restProps } = props;
  return apiDeleteAsync(`site/${siteId}`, restProps);
};

export const useDeleteProject = (options: any) => {
  const key = ['DeleteProject'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
