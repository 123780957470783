// @ts-nocheck
import { Typography, useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { formatTotalSecondsToDateFormat } from '../../../../../utils';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
} from '../../content/styled';
import { secondsToMinutes } from '../utils';
import LiftsPerHourModal from './LiftsPerHourModal';

const LiftsPerHourChart = ({ height, onDashboard }: any) => {
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsByHour = useAppSelector(
    (state: any) => state.projectCrane.liftsByHour,
  );
  const [openedItem, setOpenedItem] = useState();
  const sm = useMediaQuery('(min-width: 768px)');
  const title = 'Lifts Per Hour';
  const noRecords = lifts.length === 0;

  if (noRecords) {
    return (
      <ChartStack height={onDashboard ? 300 : 345} onDashboard={onDashboard}>
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        </ChartStackHeader>
        <ChartStackContent
          sx={{
            width: '100%',
            height,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2Medium" color="#878a99">
            {'No data to display'}
          </Typography>
        </ChartStackContent>
      </ChartStack>
    );
  }

  const series = [
    {
      name: 'Idle',
      data: liftsByHour.map((hour: any) => hour.idleSec / 60),
    },
    {
      name: 'Operation',
      data: liftsByHour.map((hour: any) => hour.operationSec / 60),
    },
  ];

  const options = {
    chart: {
      type: 'column',
      height,
      fn: {
        secondsToMinutes,
      },
    },
    exporting: { enabled: false },
    series,
    title: {
      text: '',
    },
    xAxis: {
      // categories,
      categories: liftsByHour.map((hour: any) => hour.label),
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      min: 0,
      tickPositions: [0, 10, 20, 30, 40, 50, 60, 70],
      title: {
        text: 'Minutes',
        style: {
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      stackLabels: {
        align: 'center',
        enabled: true,
        // formatter: function () {
        //   // return records[this.x].tasksCount;
        // },
        formatter: function () {
          return parseFloat(Number(liftsByHour[this.x].total).toFixed(1));
        },
      },
    },
    tooltip: {
      hideDelay: 50,
      style: {
        fontSize: '14px',
      },
      pointFormatter: function () {
        const { y, series } = this;
        // const value = this.series.chart.options.chart.fn.secondsToMinutes(
        //   y * 60,
        // );
        // const value = this.series.chart.options.chart.fn.secondsToMinutes(y);
        const value = formatTotalSecondsToDateFormat(y * 60, false);
        return `<span style="color:${series.color}">
          ${series.name}</span>: 
          <b>${value} (minutes)</b> <br />
        `;
      },
      shared: true,
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      itemStyle: {
        fontSize: '14px',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        cursor: sm ? 'pointer' : 'default',
        point: {
          events: {
            click: function ({ point }) {
              if (sm) {
                const item = liftsByHour.find(
                  ({ label }) => label === point.category,
                );
                setOpenedItem(item);
              }
            },
          },
        },
      },
    },
    colors: ['#00c1b6', '#ff6f00', '#EAECEE'],
  };

  return (
    <ChartStack height={onDashboard ? 300 : 345} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        {/* <ChartBar {...{ operationTime, idleTime, onDashboard }} /> */}
      </ChartStackHeader>
      <ChartStackContent onDashboard={onDashboard}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </ChartStackContent>
      {openedItem && (
        <LiftsPerHourModal
          item={openedItem}
          onClose={() => setOpenedItem(null)}
        />
      )}
    </ChartStack>
  );
};

export default LiftsPerHourChart;
