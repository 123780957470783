// @ts-nocheck
import { Stack, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { sum } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { getLiftsByHour } from '../../../../../utils';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
  CraneHeader,
} from '../../content/styled';

const DailyMaterialsChart = ({ height, onDashboard }: any) => {
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsByHour = useAppSelector(
    (state: any) => state.projectCrane.liftsByHour,
  );
  const [cargoTypes, setCargoTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const title = 'Daily Materials';

  useEffect(() => {
    if (!lifts || lifts.length === 0) return;

    const cargoTypesData = lifts
      .map((item) => item.cargoType)
      .filter((obj, index, self) => {
        return index === self.findIndex((t) => t.name === obj.name);
      });
    setCargoTypes(cargoTypesData);
    setColors(cargoTypesData.map((cargoType) => cargoType.color));
  }, [lifts]);

  const seriesData = useMemo(() => {
    const seriesData = [];

    cargoTypes.forEach((cargoType) => {
      const dataPerCargoType = liftsByHour.map((hour) => {
        const hourData = hour.cargoTypes.find(
          (cType) => cType.id === cargoType.id,
        );
        if (!hourData) return 0;

        return hourData.durationSec;
      });

      seriesData.push({
        name: cargoType.name,
        y: sum(dataPerCargoType),
      });
    });

    return seriesData;
  }, [liftsByHour, cargoTypes]);

  const options = {
    chart: {
      height,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    exporting: { enabled: false },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      hideDelay: 50,
      style: {
        fontSize: '14px',
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '14px',
          },
        },
        showInLegend: true,
      },
    },
    legend: {
      align: 'left',
      layout: 'vertical',
      verticalAlign: 'top',
      x: 10,
      y: 0,
      itemStyle: {
        fontSize: '14px',
      },
    },
    series: [
      {
        name: 'Time',
        colorByPoint: true,
        minPointSize: 10,
        innerSize: '50%',
        data: seriesData,
      },
    ],
    colors,
  };

  if (lifts.length === 0) {
    return (
      <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
        </ChartStackHeader>
        <ChartStackContent
          sx={{
            padding: 4,
            width: '100%',
            height: height + 32,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          spacing={4}
        >
          <Typography variant="h2Medium" color="#878a99">
            {'No data to display'}
          </Typography>
        </ChartStackContent>
      </ChartStack>
    );
  }

  return (
    <ChartStack height={onDashboard ? 300 : 340} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography sx={{ fontSize: '24px' }}>{title}</Typography>
      </ChartStackHeader>
      <ChartStackContent onDashboard={onDashboard}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </ChartStackContent>
    </ChartStack>
  );
};

export default DailyMaterialsChart;
