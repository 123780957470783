const convertXMapPointToXMeter = (
  xMapPoint: number,
  relpxx: number,
  xPointCraneRef: number,
  meter2Pixel: number,
) => {
  if (!xMapPoint) return 0;
  return (xMapPoint * relpxx - xPointCraneRef) / meter2Pixel;
};

const convertLatLongToXY = (siteCenter: any, lat: number, lng: number) => {
  const earthRadiusKm = 6371.01; // Earth's radius in kilometers
  const latDiff = lat - siteCenter.lat;
  const lngDiff = lng - siteCenter.lng;

  const latDiffInRad = (latDiff * Math.PI) / 180;
  const lngDiffInRad = (lngDiff * Math.PI) / 180;

  const y = latDiffInRad * earthRadiusKm * 1000; // Convert to meters
  const x =
    lngDiffInRad *
    earthRadiusKm *
    1000 *
    Math.cos((siteCenter.lat * Math.PI) / 180); // Convert to meters

  return { x, y };
};

const convertXYToLatLong = (
  siteCenter: any,
  distanceX: number,
  distanceY: number,
) => {
  const earthRadiusKm = 6371.01; // Earth's radius in kilometers
  const dy = distanceY / 1000; // Convert distance to kilometers
  const dx = distanceX / 1000; // Convert distance to kilometers
  const newLat = siteCenter.lat + (dy / earthRadiusKm) * (180 / Math.PI);
  const newLng =
    siteCenter.lng +
    ((dx / earthRadiusKm) * (180 / Math.PI)) /
      Math.cos((siteCenter.lat * Math.PI) / 180);
  return { lat: newLat, lng: newLng };
};

const convertYMapPointToYMeter = (
  yMapPoint: number,
  relpxx: number,
  yPointCraneRef: number,
  meter2Pixel: number,
) => {
  if (!yMapPoint) return 0;
  return -(yMapPoint * relpxx - yPointCraneRef) / meter2Pixel;
};

const convertXMeterToXMapPoint = (
  xMeter: number,
  meter2Pixel: number,
  xPointCraneRef: number,
  relpxx: number,
) => (xMeter * meter2Pixel + xPointCraneRef) / relpxx;

const convertYMeterToYMapPoint = (
  yMeter: number,
  meter2Pixel: number,
  yPointCraneRef: number,
  relpxx: number,
) => (yPointCraneRef - yMeter * meter2Pixel) / relpxx;

const convertRadiusMapToRadiusMeter = (
  radius: number,
  relpxx: number,
  meter2Pixel: number,
) => (radius * relpxx) / meter2Pixel;

const convertRadiusMeterToRadiusMapValue = (
  radiusMeter: number,
  relpxx: number,
  meter2Pixel: number,
) => (radiusMeter * meter2Pixel) / relpxx;

function isLocationReachable({ xPoint, yPoint, xMeter, yMeter, radius }: any) {
  const x = xPoint - xMeter;
  const y = yPoint - yMeter;
  const distance = Math.sqrt(x * x + y * y);
  return distance < radius;
}

export {
  convertXMapPointToXMeter,
  convertYMapPointToYMeter,
  convertRadiusMapToRadiusMeter,
  convertXMeterToXMapPoint,
  convertYMeterToYMapPoint,
  convertRadiusMeterToRadiusMapValue,
  isLocationReachable,
  convertLatLongToXY,
  convertXYToLatLong,
};
