import { useMutation } from 'react-query';
import { apiGetAsync, apiPostAsync } from '../api/axios';

const api = (props: any) => {
  const { siteId, search } = props;
  return apiGetAsync(`site/${siteId}/users/suggestions?search=${search}`);
};

export const useGetSiteUsersSuggestions = (options?: any) => {
  const key = ['GetSiteUsersSuggestions'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
