import React, { useState } from 'react';
import { IconButton, Paper, Snackbar, Stack, styled } from '@mui/material';
import { useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import { Loading } from '../../../../components/styled';
import {
  useGetCompany,
  useGetCrane,
  useGetProject,
  usePutCompany,
  usePutCompanyName,
  usePutCrane,
  usePutDeleteCompanyLogo,
  usePutProject,
  usePutProjectName,
} from '../../../../queries';
import Details from './Details';
import CompanyLogo from './CompanyLogo';

const ResponsivePaper = styled(Paper)`
  width: 300px;

  @media screen and (min-width: 1366px) {
    width: 400px;
  }
  @media screen and (min-width: 1600px) {
    width: 400px;
  }
  @media screen and (min-width: 1920px) {
    width: 500px;
  }
`;

const CompanyHeader = ({ companyId, siteId, craneId }: any) => {
  const queryClient = useQueryClient();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const {
    error: getCompanyError,
    isLoading: getCompanyIsLoading,
    data: getCompanyData,
    refetch: refetchCompany,
  } = useGetCompany(
    { companyId },
    {
      cacheTime: 0,
    },
  );

  const {
    mutate: putCompanyMutate,
    isLoading: putCompanyIsLoading,
    error: putCompanyError,
  } = usePutCompany({
    onSuccess: () => {
      refetchCompany();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutCompany');
    },
  });

  const {
    mutate: putCompanyNameMutate,
    isLoading: putCompanyNameIsLoading,
    error: putCompanyNameError,
  } = usePutCompanyName({
    onSuccess: () => {
      refetchCompany();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutCompany');
    },
  });

  const {
    isLoading: putDeleteCompanyLogoIsLoading,
    error: putDeleteCompanyLogoError,
  } = usePutDeleteCompanyLogo();

  const onChangeCompanyName = (value: string) =>
    putCompanyNameMutate({ companyId, name: value });

  const { data: site, refetch: refetchProject } = useGetProject(
    { siteId },
    {
      cacheTime: 0,
    },
  );

  const {
    mutate: putProjectMutate,
    isLoading: putProjectIsLoading,
    error: putProjectError,
  } = usePutProject({
    onSuccess: () => {
      refetchProject();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutProject');
    },
  });

  const {
    mutate: putProjectNameMutate,
    isLoading: putProjectNameIsLoading,
    error: putProjectNameError,
  } = usePutProjectName({
    onSuccess: () => {
      refetchProject();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutProjectName');
    },
  });

  const onChangeProjectName = (value: string) =>
    putProjectNameMutate({ siteId, name: value });

  const { data: getCraneData, refetch: refetchCrane } = useGetCrane(
    { craneId },
    {
      cacheTime: 0,
    },
  );

  const {
    mutate: putCraneMutate,
    isLoading: putCraneIsLoading,
    error: putCraneError,
  } = usePutCrane({
    onSuccess: () => {
      refetchCrane();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutCrane');
    },
  });

  const onChangeCraneName = (value: string) =>
    putCraneMutate({ craneId, name: value });

  const isLoading =
    getCompanyIsLoading ||
    putCompanyNameIsLoading ||
    putDeleteCompanyLogoIsLoading;
  const error =
    getCompanyError ||
    putCompanyNameError ||
    putDeleteCompanyLogoError ||
    putProjectError ||
    putCraneError;

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <ResponsivePaper
      elevation={1}
      sx={{
        padding: 4,
        backgroundColor: 'common.white',
        overflowY: 'auto',
        display: 'flex',
        gap: 4,
        mt: '0 !important',
        alignItems: 'flex-start',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Stack alignItems="flex-start" width="100%" spacing={4}>
          <CompanyLogo
            LogoPath={getCompanyData?.data?.logoPath}
            companyId={companyId}
            putCompanyMutate={putCompanyMutate}
            refetchCompany={refetchCompany}
          />
          <Details
            title="Company"
            name={getCompanyData?.data.name}
            onUpdate={onChangeCompanyName}
            loading={putCompanyNameIsLoading}
          />
          {siteId && (
            <Details
              title="Site"
              name={site?.data?.name}
              onUpdate={onChangeProjectName}
              loading={putProjectIsLoading}
            />
          )}
          {craneId && (
            <Details
              title="Crane"
              name={getCraneData?.data?.name}
              onUpdate={onChangeCraneName}
              loading={putCraneIsLoading}
            />
          )}
        </Stack>
      )}
      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={(error as any)?.message}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </ResponsivePaper>
  );
};

export default CompanyHeader;
