import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiPostAsync } from '../api/axios';
import { fetchBuilder, AsyncState } from './utils';

export const fetchTaskByHoursByProject = createAsyncThunk(
  'taskbyhoursbyproject/fetch',
  async ({ ProjectId, DateLoad, CraneIds, FirstDayOfWeek }: any) => {
    const { data } = await apiPostAsync('taskbyhoursbyproject', {
      ProjectId,
      DateLoad,
      CraneIds,
      FirstDayOfWeek
    });
    return data;
  },
);

export interface CranesDailyReport {
  project: any;
  date: any;
  records: any[];
  safety: any;
}

const initialState: AsyncState<CranesDailyReport> = {
  data: {
    project: undefined,
    date: undefined,
    records: [],
    safety: undefined,
  },
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: 'taskbyhoursbyproject',
  initialState,
  reducers: { resetTaskByHoursByProject: () => initialState },
  extraReducers: fetchBuilder('taskbyhoursbyproject'),
});

export const actions = slice.actions;

export default slice.reducer;
