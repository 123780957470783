import React from 'react';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import StartIcon from '@mui/icons-material/Start';

export const SliderNavigation = ({
  slideTo,
  activeIndex,
  data,
  continuationToken,
  onPreviousPage,
  onNextPage,
  nextLongPressEvents,
  backLongPressEvents,
  label,
}: any) => {
  const sm = useMediaQuery('(max-width: 768px)');

  return (
    <Stack
      direction="row"
      spacing={2}
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ paddingTop: 5, paddingBottom: 5 }}
    >
      <Button
        onClick={onPreviousPage}
        variant="outlined"
        startIcon={
          sm ? null : <StartIcon style={{ transform: 'rotate(180deg)' }} />
        }
        disabled={continuationToken === null}
      >
        {sm ? (
          <StartIcon style={{ transform: 'rotate(180deg)' }} />
        ) : (
          'Prev page'
        )}
      </Button>
      <Button
        onClick={() => slideTo(0)}
        variant="outlined"
        disabled={activeIndex === 0}
      >
        <FirstPageIcon />
      </Button>
      <Button
        {...backLongPressEvents}
        variant="outlined"
        disabled={activeIndex === 0}
      >
        <ChevronLeftIcon />
      </Button>
      <Typography variant="mediumRegular">{label}</Typography>
      <Button
        {...nextLongPressEvents}
        variant="outlined"
        disabled={activeIndex === data?.data?.Contents.length - 1}
      >
        <ChevronRightIcon />{' '}
      </Button>
      <Button
        onClick={() => slideTo(data?.data?.Contents.length - 1)}
        variant="outlined"
        disabled={activeIndex === data?.data?.Contents.length - 1}
      >
        <LastPageIcon />
      </Button>
      <Button
        onClick={() => onNextPage(data?.data?.NextContinuationToken)}
        variant="outlined"
        endIcon={sm ? null : <StartIcon />}
        disabled={!data?.data?.NextContinuationToken}
      >
        {sm ? <StartIcon /> : 'Next page'}
      </Button>
    </Stack>
  );
};
