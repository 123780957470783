import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppSelector } from '../../store/hooks';
import { TaskInHours } from '../../store/tasksinhours';
interface EfficiencyChartProps {
  tasksInHours: TaskInHours[];
  Report: boolean;
}

const EfficiencyChart = ({ tasksInHours, Report }: EfficiencyChartProps) => {
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level
  );

  let fsNumber = mediaResLevel < 3 ? '7px' : '10px';
  // let LiftTitle = (mediaResLevel < 3) ? '<b>L:' :'<b> Lifts:';
  let showLegend = Report ? true : false; //false;//(mediaResLevel < 3) ? false :true;
  let objHeight = Report ? 300 : 140;
  let ticHeight = Report ? 80 : 70;
  var hours = tasksInHours.map(function (el) {
    return el.hournum + ':00';
  });
  var minutestran = tasksInHours.map(function (el) {
    return el.minutestran;
  });
  //   console.log("minutestran");
  //   console.log(minutestran);
  var minutesload = tasksInHours.map(function (el) {
    return el.minutesload;
  });
  var minutesidle = tasksInHours.map(function (el) {
    return el.minutesidle;
  });
  var lifts = tasksInHours.map(function (el) {
    return el.taskscnt;
  });

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: objHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: hours,
    },
    yAxis: {
      min: 0,

      tickPositions: [0, ticHeight],
      title: {
        text: 'Minutes',
      },
      stackLabels: {
        enabled: true, //false,//true,
        formatter: function () {
          return lifts[this.x] + '</b>';
        },
        style: {
          fontWeight: 'bold',
          fontSize: fsNumber,

          color:
            // theme
            //@ts-ignore
            (Highcharts.defaultOptions.title.style &&
              //@ts-ignore
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
    },
    legend: {
      align: 'right',
      x: -30,
      verticalAlign: 'top',
      // y: 0,
      floating: true,
      backgroundColor:
        //@ts-ignore
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      //@ts-ignore
      // headerFormat: '<b>{point.x}</b><br/>Lifts:lifts[{point.point.index}]} <br/>',
      // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
      formatter: function () {
        //@ts-ignore
        return this.points.reduce(
          function (s, point) {
            //@ts-ignore
            return (
              s +
              '<span style="color:' +
              //@ts-ignore
              point.series.color +
              '">' +
              point.series.name +
              '</span>: <b>' +
              point.y +
              '</b> <br/>'
            );
          },
          //@ts-ignore
          '<b>' +
            this.x +
            '</b><br/>Lifts:' +
            tasksInHours.filter(
              //@ts-ignore
              (rec) => rec.hournum == this.x.substring(0, this.x.length - 3)
            )[0].taskscnt +
            '<br/>'
        );
      },
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',

        dataLabels: {
          enabled: false, //showLegend//true,
          style: {
            fontSize: fsNumber,
          },
        },
      },
    },

    series: [
      {
        name: 'Idle',
        color: '#00c1b6',
        //@ts-ignore
        data: minutesidle,
        showInLegend: showLegend,
      },
      {
        name: 'Transition',
        color: '#ff6f00',
        //@ts-ignore
        data: minutestran,
        showInLegend: showLegend,
      },
      {
        name: 'Load/Unload',
        color: '#005573',
        //@ts-ignore
        data: minutesload,
        showInLegend: showLegend,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default EfficiencyChart;
