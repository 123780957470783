import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { ErrorAlert } from '../alerts';

export default function ConfirmDialog({
  title,
  text,
  onOk,
  onCancel,
  onDeleteSuccess,
  asyncApi,
  okText = 'OK',
}: any) {
  const [deleted, setDeleted] = useState<boolean>();
  const { loading, error, data } = asyncApi;

  useEffect(() => {
    if ((data?.status === 200 || data?.status === 201) && !deleted) {
      setDeleted(true);
      onDeleteSuccess();
    }
  }, [data, deleted, onDeleteSuccess]);

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Typography variant="mediumRegular">{text}</Typography>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOk} autoFocus variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={16} /> : okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
