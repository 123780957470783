import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { mediaResActions } from '../../store/mediares';
import { useLayoutEffect } from '../../hooks/useLayoutEffect';
import { AppBar } from './AppBar';
import * as storageHelper from '../../utils/storageHelper';
import {
  FCM_TOKEN,
  TOKEN,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import {
  useGetUserProfile,
  usePostSubscribeDeviceToTopics,
} from '../../queries';
import { useEffect } from 'react';
import { userActions } from '../../store/user';

const Layout = (props) => {
  const dispatch = useAppDispatch();
  const token = storageHelper.getItem(TOKEN);
  const user = storageHelper.getItem(USER);
  const userId = user?.id;
  const navigate = useNavigate();

  const {
    data: userProfileData,
    error: userProfileError,
    refetch: userProfileRefetch,
    isFetching: userProfileIsFetching,
  } = useGetUserProfile(
    {},
    {
      enabled: true,
    },
  );

  useEffect(() => {
    // Check if an old project from the previous database structure is saved in the local storage
    const selectedProjectStorage =
      storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT) || '';
    if (!selectedProjectStorage) {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/logout');
    }
    const isNumber = !isNaN(Number(selectedProjectStorage));
    if (isNumber) {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/logout');
    }
  }, []);

  useEffect(() => {
    if (userProfileData?.data) {
      const userData = userProfileData.data;
      storageHelper.setItem(USER, userData);
      dispatch(userActions.setUser(userData));
    }
  }, [userProfileData]);

  const fcmToken = storageHelper.getItem(FCM_TOKEN);

  const { mutate: subscribeDeviceToTopicsMutate } =
    usePostSubscribeDeviceToTopics({
      onError: () => {},
    });

  const handleResize = () => {
    let orientationLandscape = false;
    let medialevel = 0;
    if (window.matchMedia('(orientation: Landscape)').matches) {
      orientationLandscape = true;
    }
    if (
      window.matchMedia('(min-width: 700px)').matches &&
      orientationLandscape
    ) {
      medialevel = 2;
    } //smartphone Rotated
    if (
      window.matchMedia('(min-width: 800px)').matches &&
      !orientationLandscape
    ) {
      medialevel = 3;
    } //tablet
    if (
      window.matchMedia('(min-width: 1280px)').matches &&
      orientationLandscape
    ) {
      medialevel = 4;
    } //screen
    if (medialevel === 0) {
      medialevel = 1;
    }

    dispatch(mediaResActions.setMediaRes({ level: medialevel }));
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level,
  );

  const location = useLocation();

  // useEffect(() => {
  //   if (userId && token && fcmToken) {
  //     subscribeDeviceToTopicsMutate({ userId, fcmToken });
  //   }
  // }, [token, fcmToken, userId]);

  if (!token) {
    return <Navigate replace to="/login" state={{ from: location }} />;
  }

  return (
    <>
      <AppBar />
      <Stack
        sx={{
          padding: mediaResLevel >= 4 ? '32px 48px 32px' : '24px 12px',
          backgroundColor: 'backgrounds.system',
        }}
        height={`calc(100% - ${mediaResLevel >= 4 ? 66 : 64}px)`}
      >
        {props.children}
      </Stack>
    </>
  );
};

export default Layout;
