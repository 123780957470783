import * as storageHelper from '../utils/storageHelper';
import { FCM_TOKEN } from '../variables/constants';

declare var cordova: any;
declare var window: any;

export const init = () => {
  function initCordova() {
    console.log('[Cordova] initCordova');

    if (!window?.FirebasePlugin) {
      return;
    }

    try {
      window.FirebasePlugin.grantPermission();

      window.FirebasePlugin.getToken(
        async function (token: string) {
          console.log('[Cordova] FirebasePlugin.getToken', token);

          if (token) storageHelper.setItem(FCM_TOKEN, token);
        },
        function (error: any) {
          console.error('[Cordova] FirebasePlugin.getToken', error);
        },
      );
      window.FirebasePlugin.onMessageReceived(
        function (notification: any) {
          if (notification.alert_message) {
            alert(notification.alert_message);
          }
        },
        function (error: any) {
          console.error('[Cordova] FirebasePlugin.onNotificationOpen', error);
        },
      );
    } catch (e) {
      console.error('[Cordova] FirebasePlugin.error', e);
    }
  }

  document.addEventListener('deviceready', initCordova, false);
};

export const setFirebaseUserId = (userId: string) => {
  if (!window?.FirebasePlugin) {
    return;
  }

  window.FirebasePlugin.setUserId(userId);
};

export const setFirebaseUserProperties = (
  properties: { name: string; value: string | boolean }[],
) => {
  if (!window?.FirebasePlugin) {
    return;
  }

  properties.forEach((prop) => {
    window.FirebasePlugin.setUserProperty(prop.name, prop.value);
  });
};

export const subscribeFirebaseTopic = (topicId: string) => {
  if (!window?.FirebasePlugin) {
    return;
  }

  window.FirebasePlugin.subscribe(
    topicId,
    function () {
      console.log('[Cordova] Subscribed to topic:', topicId);
    },
    function (error: any) {
      console.error('[Cordova] Error subscribing to topic: ' + error);
    },
  );
};

export const unsubscribeFirebaseTopic = (topicId: string) => {
  if (!window?.FirebasePlugin) {
    return;
  }

  window.FirebasePlugin.unsubscribe(
    topicId,
    function () {
      console.log('[Cordova] Unsubscribed from topic:', topicId);
    },
    function (error: any) {
      console.error('[Cordova] Error unsubscribing from topic: ' + error);
    },
  );
};

export const getFileContentAsBase64 = (imageUri: string, callback: any) => {
  window.resolveLocalFileSystemURL(imageUri, gotFile, fail);

  function fail(e: any) {
    alert('Cannot found requested file');
  }

  function gotFile(fileEntry: any) {
    fileEntry.file(function (file: any) {
      let reader = new FileReader();
      reader.onloadend = function (e) {
        let content = this.result;
        callback(content);
      };
      reader.readAsDataURL(file);
    });
  }
};

export const getPicture = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    navigator.camera.getPicture(resolve, reject, {
      quality: 50,
      destinationType: Camera.DestinationType.FILE_URI,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
    });
  });
};

export const scanBarcode = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    cordova.plugins.barcodeScanner.scan(resolve, reject, {
      // preferFrontCamera: true, // iOS and Android
      // showFlipCameraButton: true, // iOS and Android
      showTorchButton: true, // iOS and Android
      torchOn: true, // Android, launch with the torch switched on (if available)
      saveHistory: true, // Android, save scan history (default false)
      prompt: 'Place a barcode inside the scan area', // Android
      resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      // formats: 'QR_CODE,PDF_417', // default: all but PDF_417 and RSS_EXPANDED
      // orientation: 'landscape', // Android only (portrait|landscape), default unset so it rotates with the device
      disableAnimations: true, // iOS
      disableSuccessBeep: false, // iOS and Android
    });
  });
};

export const watchGpsPosition = (
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback | null,
): number => {
  return navigator.geolocation.watchPosition(successCallback, errorCallback, {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  });
};

export const clearGpsWatch = (watchId: number) => {
  navigator.geolocation.clearWatch(watchId);
};
