import { Avatar, FormControlLabel, Switch, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { isEmpty } from 'lodash';
import imageBackground from '../../../../assets/images/industrial-cranes.png';
import * as storageHelper from '../../../../utils/storageHelper';
import { USER } from '../../../../variables/constants';
import { ErrorState } from '../../../alerts';
import { FormControl } from '../../../inputs';
import { Loading } from '../../../styled';
import { ProjectNotification } from '../index';

const MobileNotifications = (props: any) => {
  const {
    receiveMobileNotifications,
    onChangeReceiveMobileNotifications,
    isLoading,
    error,
    // userProjectsData,
    userNotifications,
    dispatchUserNotifications,
    setNotificationsData,
    notificationsData,
  } = props;

  const userProjectsData = {
    data: [],
  };
  const user = storageHelper.getItem(USER);

  return (
    <Stack sx={{ width: '100%' }}>
      <FormControl label="Receive Mobile Notifications">
        <FormControlLabel
          control={
            <Switch
              checked={receiveMobileNotifications}
              onChange={onChangeReceiveMobileNotifications}
              name="receiveMobileNotification"
            />
          }
          label="Active"
        />
      </FormControl>

      {receiveMobileNotifications && (
        <Stack>
          {isLoading && !error && <Loading />}
          {!isLoading && error && <ErrorState error={error} />}
          {!isLoading && !error && userProjectsData?.data && (
            <Stack flex={1} width="100%">
              <Stack direction="column" spacing={4} width="100%">
                {isEmpty(user.sites) ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                    flex={1}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: 'backgrounds.system',
                        width: 140,
                        height: 140,
                      }}
                    >
                      <img
                        src={imageBackground}
                        alt="No results found"
                        width={150}
                      />
                    </Avatar>
                    <Typography variant="h1SemiBold">
                      No results found
                    </Typography>
                    <Typography
                      variant="mediumRegular"
                      color="typography.negativeDisabled"
                    >
                      These is no project assigned to you.
                    </Typography>
                  </Stack>
                ) : (
                  user?.sites &&
                  user.sites.map((site: any) => (
                    <ProjectNotification
                      {...{
                        key: site.id,
                        site: site,
                        cranes: site.cranes,
                        company: user.company,
                        userNotifications,
                        dispatchUserNotifications,
                        setNotificationsData,
                        notificationsData,
                      }}
                    />
                  ))
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default MobileNotifications;
