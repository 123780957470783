import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) =>
  apiPutAsync(`project/delete-sitemap/${props.projectId}`);

export const usePutDeleteProjectSitemap = (options: any) => {
  const key = ['PutDeleteProjectSitemap'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
