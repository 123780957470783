// @ts-ignore
import WizLogo from '../../../assets/icons/wiz-logo';
import pdfData from '../../../assets/data/pdf-data.json';
import { useAppSelector } from '../../../store/hooks';
import EfficiencyChart from '../EfficiencyChart';
import { WindSpeedChart } from '../WindSpeedChart';

import './ReportDialog.scss';

import React from 'react';
import { S3MMSITE_PATH } from '../../../variables/endpoints';

const maxWindSpeed = Number(50);

export const PageOne = () => {
  const dateTlmStart = useAppSelector(
    (state) => state.timeframerpt.startTimeFrame
  );
  const windData = useAppSelector((state) => state.timeframerpt.windData);
  const tasksInHours = useAppSelector(
    (state) => state.tasksInHoursRpt.tasksinhours
  );
  const ReportHeader = useAppSelector(
    (state) => state.ReportHeader.reportheader
  );

  return (
    <>
      <div style={{ height: '27px' }} />
      <div className="report__page-border">
        <div className="report__page-title">
          <WizLogo width={24} height={24} />
          <span>Report Widesite®</span>
          <span>
            {new Intl.DateTimeFormat('he-IL').format(
              Date.UTC(
                Number(dateTlmStart.substring(0, 4)),
                Number(dateTlmStart.substring(4, 6)) - 1,
                Number(dateTlmStart.substring(6, 8))
              )
            )}
          </span>{' '}
        </div>
        {ReportHeader.LogoPath && ReportHeader.LogoPath.length > 0 && (
          <div className="report__page-title2">
            <img
              src={`${S3MMSITE_PATH}/${ReportHeader.LogoPath}`}
              style={{ width: '50px' }}
            />
          </div>
        )}
        <div className="page-one__header">
          <div className="report__metadata">
            <span>Company name: {ReportHeader.CompanyName}</span>
            <span>Site name: {ReportHeader.ProjectName}</span>
            <span>Crane name: {ReportHeader.CraneName}</span>
            <span>Crane Type: {ReportHeader.CraneType}</span>
            <span>Start time: {ReportHeader.TimeStart}</span>
            <span>End time: {ReportHeader.TimeEnd}</span>
            <span>Operator: </span>
          </div>
        </div>
        <div className="page-one__content">
          <div className="report__metadata"></div>
          <br />
          <h2 className="report__section-title">
            Wind speed and direction throughout the day
          </h2>
          <div className="report__metadata">
            <span>Top speed: {maxWindSpeed} km/h</span>
          </div>
          <div className="report__divider" />
          <h6>Wind speed (Km/h)</h6>
          <WindSpeedChart
            dateTlmStart={dateTlmStart}
            windData={windData}
            Report={true}
          />

          <div className="report__divider" />

          <h6>Lifts per hour</h6>
          {tasksInHours.length > 0 && (
            <EfficiencyChart tasksInHours={tasksInHours} Report={true} />
          )}
        </div>

        <div className="report__page--footer">
          <span />
          <span>
            This report reffers exclusively to the building company. All rights
            reserved to ©Ultrawis LTD.
          </span>
          <span>{1}</span>
        </div>
      </div>
      <div style={{ height: '27px' }} />
    </>
  );
};
