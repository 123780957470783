import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface WindSpeedChartProps {
  windData: number[];
  Report: boolean;
  dateTlmStart: string;
}
export const WindSpeedChart = ({
  dateTlmStart,
  windData,
  Report,
}: WindSpeedChartProps) => {
  const rndNumber = Math.floor(Math.random() * 744) + 700;
  const maxWindSpeed = Number(45);
  const objHeight = Report ? 270 : 140;
  const objWidth = Report ? 500 : 280;
  var yAxisLabels = [0, 25, 50];
  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0,
      type: 'datetime',
      // labels: {
      //     overflow: 'justify'
      // },
      // min : Date.UTC(Number(dateTlmStart.substring(0,4)),Number(dateTlmStart.substring(4,6))-1,Number(dateTlmStart.substring(6,8)),
      // Number(dateTlmStart.substring(8,10)),Number(dateTlmStart.substring(10,12)), 0),
      // max: Date.UTC(Number(dateTlmStop.substring(0,4)),Number(dateTlmStop.substring(4,6))-1,Number(dateTlmStop.substring(6,8)),
      // Number(dateTlmStop.substring(8,10)),Number(dateTlmStop.substring(10,12)), 0),
    },

    yAxis: {
      title: {
        text: '',
      },
      max: 50,
      tickPositioner: function () {
        return yAxisLabels;
      },
      plotLines: [
        {
          value: maxWindSpeed,
          width: 2,
          color: '#ff0000',
        },
      ],
    },
    chart: {
      spacing: [4, 0, 0, 3],
      backgroundColor: '#ffffff', //"#f6f6f6"
      animation: false,
      type: 'spline',
      height: objHeight,
      scrollablePlotArea: {
        minWidth: objWidth,
        scrollPositionX: 1,
      },
    },

    plotOptions: {
      series: {
        pointStart: Date.UTC(
          Number(dateTlmStart.substring(0, 4)),
          Number(dateTlmStart.substring(4, 6)) - 1,
          Number(dateTlmStart.substring(6, 8)),
          Number(dateTlmStart.substring(8, 10)),
          Number(dateTlmStart.substring(10, 12)),
          0
        ),
        pointInterval: (60000 * rndNumber) / (rndNumber - 1), //dateDiffMinutes * 60000 / (dateDiffMinutes-1)// one day
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        color: '#000000',
        type: 'spline',
        name: '',
        data: windData,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
