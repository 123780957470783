import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) => apiGetAsync(`site/${props.siteId}`);

export const useGetProject = (props: any, options?: any) => {
  const key = ['GetProject', Object.values(props)];
  const fn = () => api(props);

  return useQuery(key, fn, {
    enabled: !!props.siteId,
    ...(options || {}),
  });
};
