import { Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { EmptyState } from '../../../../alerts';
import { ImageDialog } from '../../../../../pages/Dashboard/ImageTimeline/components';
import { SlideContent } from '../../../../../pages/Dashboard/ImageTimeline/components/SlideContent';
import {
  Loading,
  StyledGrid,
  StyledSwiper,
} from '../../../../../pages/Dashboard/ImageTimeline/styled';

const ImagesTimelineTab = ({ lift, images, isLoading }: any) => {
  const { id } = lift;

  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState('');

  const sm = useMediaQuery('(max-width: 600px)');

  const onSlideChange = ({ activeIndex }: any) => {
    setActiveIndex(activeIndex);
  };

  const handleClickOpen = (imageSrc: string) => {
    setImageSrc(imageSrc);
  };

  const handleClose = () => {
    setImageSrc('');
  };

  return (
    <StyledGrid
      key={id}
      container
      flex={1}
      alignContent="flex-start"
      sx={{ marginTop: 0, overflowY: 'hidden', height: sm ? '100%' : '90%' }}
    >
      <ImageDialog
        {...{ imageSrc, handleClose, getKeyDetails: null, imageStyle: {} }}
      />
      {isLoading && <Loading />}

      {!isLoading && (
        <>
          {!images || (Array.isArray(images) && images.length === 0) ? (
            <Stack height="100%" width="100%">
              <EmptyState showDescriprion={false} />
            </Stack>
          ) : (
            <>
              <StyledSwiper
                onSwiper={setSwiperRef}
                sx={{
                  paddingBottom: 10,
                  height: sm ? '95%' : '100%',
                }}
                // @ts-ignore
                slidesPerView={sm ? 1 : 2}
                spaceBetween={30}
                keyboard={{
                  enabled: true,
                }}
                scrollbar={{
                  hide: false,
                  draggable: true,
                }}
                onSlideChange={onSlideChange}
                modules={[
                  Virtual,
                  Navigation,
                  Pagination,
                  Keyboard,
                  Mousewheel,
                  Scrollbar,
                ]}
                mousewheel
                freeMode
                centeredSlides
                navigation
                virtual
              >
                {images.map((image: any, index: number) => (
                  <SwiperSlide
                    key={image.src}
                    virtualIndex={index}
                    style={{ height: '100%' }}
                  >
                    <SlideContent
                      {...{
                        imageSrc: image.src,
                        handleClickOpen,
                        time: image.time,
                      }}
                    />
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </>
          )}
        </>
      )}
    </StyledGrid>
  );
};

export default ImagesTimelineTab;
