import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import './ReportDialog.scss';
import { PageOne } from './PageOne';
import { PageTwo } from './PageTwo';
import { useAppSelector } from '../../../store/hooks';
import { timeFrameRptActions } from '../../../store/timeframerpt';
import { Modal } from 'bootstrap';
import { PageOneTwo } from './pageOneTwo';
let windData: number[] = [];
let dateStartSelected: Date;
let pageNumber: number = 2;

const ReportDialogToPdf = () => {
  const dispatch = useDispatch();
  const tasks = useAppSelector((state) => state.tasksrpt.tasks);
  const telemetries = useAppSelector(
    (state) => state.telemetriesrpt.telemetries
  );
  const [tasksLength, setTasksLength] = useState<number>(0);
  const modalReportRef = useRef<HTMLDivElement>(null);

  function fixDigit(val: String) {
    return val.toString().length === 1 ? '0' + val : val;
  }
  function getDateSelected(val: Date) {
    //@ts-ignore
    let sMonth = fixDigit((val.getMonth() + 1).toString());
    //@ts-ignore
    let sDay = fixDigit(val.getDate());
    //@ts-ignore
    return (
      //@ts-ignore
      val.getFullYear() +
      sMonth +
      sDay +
      //@ts-ignore
      fixDigit(val.getHours()) +
      //@ts-ignore
      fixDigit(val.getMinutes())
    );
  }

  let TaskPages = () => {
    pageNumber = 3;
    let rows = [];
    // if ((tasksLength%3===0))
    // {
    // //    pageNumber=2;
    // for (let j = 2,i=0; j < tasksLength-1; j += 3,i++) {
    //         rows.push(<PageTwo pageNumber={pageNumber} tasks={tasks.slice(j, j+3)} key={j}/>)
    //         pageNumber++;
    //         }
    // }
    // else
    // {
    //  pageNumber=2;
    for (let j = 0, i = 0; j < tasksLength; j += 4, i++) {
      rows.push(
        <PageTwo
          pageNumber={pageNumber}
          tasks={tasks.slice(j, j + 4)}
          key={j}
        />
      );
      pageNumber++;
    }
    // }
    return <Fragment>{rows}</Fragment>;
  };

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      setTasksLength(tasks.length);
      console.log(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    if (telemetries && telemetries.length > 0) {
      if (telemetries[0].ID !== 0) {
        windData = [];
        for (let i = 0; i < telemetries.length; i += 1) {
          windData.push(Number(telemetries[i].WindSpeedKmH));
        }
        dateStartSelected = new Date(
          telemetries[0].DateSampling + ' ' + telemetries[0].TimeSampling
        );
        dispatch(timeFrameRptActions.setWindData(windData));
        dispatch(
          timeFrameRptActions.setStartTimeFrame(
            getDateSelected(dateStartSelected)
          )
        );
      }
    }
  }, [telemetries]);
  return (
    <div>
      {(tasksLength >= 1 || windData.length > 0) && <PageOne />}
      {tasksLength >= 1 && <PageOneTwo />}
      {tasks && tasks.length >= 1 && <TaskPages />}
      {/* {(tasksLength>1)&&((tasksLength%3)===0)&&<PageThree pageNumber={pageNumberThree} task={tasks.slice(tasksLength-1,tasksLength)} idleTimes={idleTimes}/>}
            {(tasksLength>1)&&((tasksLength%3)!==0)&&<PageThree pageNumber={pageNumberThree} task={null } idleTimes={idleTimes}/>} */}
    </div>
  );
};

export default ReportDialogToPdf;
