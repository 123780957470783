import React from 'react';
// @ts-ignore
import WizLogo from '../../../assets/icons/wiz-logo';
import Section from './Section';
import { Task } from '../../../store/tasks';
import { useAppSelector } from '../../../store/hooks';
import { S3MMSITE_PATH } from '../../../variables/endpoints';

interface PageTwoProps {
  tasks: Task[];
  pageNumber: number;
}

export const PageTwo = ({ tasks, pageNumber }: PageTwoProps) => {
  const dateTlmStart = useAppSelector(
    (state) => state.timeframerpt.startTimeFrame
  );
  const ReportHeader = useAppSelector(
    (state) => state.ReportHeader.reportheader
  );

  return (
    <>
      <div style={{ height: '25px' }} className="page-break-line" />
      <div className="report__page-border">
        <div className="report__page-title">
          <WizLogo width={24} height={24} />
          <span>Report Widesite®</span>
          <span>
            {new Intl.DateTimeFormat('he-IL').format(
              Date.UTC(
                Number(dateTlmStart.substring(0, 4)),
                Number(dateTlmStart.substring(4, 6)) - 1,
                Number(dateTlmStart.substring(6, 8))
              )
            )}
          </span>{' '}
        </div>
        {ReportHeader.LogoPath && ReportHeader.LogoPath.length > 0 && (
          <div className="report__page-title2">
            <img
              src={`${S3MMSITE_PATH}/${ReportHeader.LogoPath}`}
              style={{ width: '50px' }}
            />
          </div>
        )}
        {tasks[0] && <Section task={tasks[0]} />}
        {tasks[1] && <Section task={tasks[1]} />}
        {tasks[2] && <Section task={tasks[2]} />}
        {tasks[2] && <Section task={tasks[3]} />}
        <div className="report__page--footer">
          <span />
          <span>
            This report reffers exclusively to the building company. All rights
            reserved to ©Ultrawis LTD.
          </span>
          <span>{pageNumber}</span>
        </div>
      </div>
      <div style={{ height: '25px' }} />
    </>
  );
};
