import React from 'react';
import { useNavigate } from 'react-router-dom';
import getColumnsDef from './columns';
import { Grid } from '../../../../../components/grid';
import { ContentStack, Loading } from '../../../../../components/styled';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const SitesGrid = (props: any) => {
  const { sites, isLoading, onDelete, companyId } = props;
  const navigate = useNavigate();

  const onRowClicked = (e: any) =>
    navigate(`/company/${companyId}/sites/${e.data.id}`);

  return (
    <ContentStack>
      {isLoading && <Loading />}
      {!isLoading && (
        <Grid
          {...{
            rows: sites,
            columnsDef: getColumnsDef(ROW_HEIGHT, onDelete),
            onRowClicked,
            onDelete,
            ROW_HEIGHT,
            maxVisibleRows,
          }}
        />
      )}
    </ContentStack>
  );
};

export default SitesGrid;
