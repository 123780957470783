import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => apiDeleteAsync(`assetjob/${props.aiId}`);

export const useDeleteAssetJob = (options: any) => {
  const key = ['DeleteAssetJob'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
