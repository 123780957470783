import axios from 'axios';
import { API_PATH } from '../variables/endpoints';
import * as storageHelper from '../utils/storageHelper';
import { TOKEN } from '../variables/constants';
import { merge } from 'lodash';
import ApiError from './ApiError';

const instance = axios.create({
  headers: {
    'Device-used': 'WEB',
  },
});

const apiAsync = async (
  method: string,
  path: string,
  data?: any,
  headers?: any,
) => {
  const url = `${API_PATH}/${path}`;
  const token = storageHelper.getItem(TOKEN);

  const config = token
    ? merge(headers, {
        headers: {
          authorization: token,
          ...headers,
        },
      })
    : headers;

  try {
    return await instance.request({
      method,
      url,
      data,
      ...config,
    });
  } catch (e: any) {
    if (e?.response?.status === 401 || e?.response?.status === 404) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        window.location.href = '/#/logout';
      }, 1000);
    }

    throw e?.response?.data?.message && e.response.status
      ? new ApiError(e.response.data.message, e.response.status)
      : e;
  }
};

const apiGetAsync = async (path: string) => {
  return apiAsync('get', path);
};

const apiPostAsync = async (path: string, data?: any) => {
  return apiAsync('post', path, data);
};

const apiPutAsync = async (path: string, data?: any) => {
  return apiAsync('put', path, data);
};

const apiDeleteAsync = async (path: string, data?: any) => {
  return apiAsync('delete', path, data);
};

const apiPatchAsync = async (path: string, data?: any) => {
  return apiAsync('patch', path, data);
};

export {
  instance,
  apiAsync,
  apiGetAsync,
  apiPostAsync,
  apiPutAsync,
  apiDeleteAsync,
  apiPatchAsync,
};
