import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalcSumTask {
  name: string;
  y: number;
}

export interface CalcSumTasks {
  calcsumtask: CalcSumTask[];
}
const initialState: CalcSumTasks = {
  calcsumtask: [
    {
      name: '',
      y: 0,
    },
  ],
};

const calcSumTaskSlice = createSlice({
  name: 'calcsumtask',
  initialState: initialState,
  reducers: {
    setCalcSumTask(state, action: PayloadAction<CalcSumTask[]>) {
      state.calcsumtask = action.payload;
      // console.log (state.calctask);
    },
    resetCalcSumTask: () => initialState,
  },
});

export const calcSumTaskActions = calcSumTaskSlice.actions;

export default calcSumTaskSlice.reducer;
