import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Stack } from '@mui/material';
import { ImageUploader } from '../../../../components/ImageUploader';
import { S3MMSITE_PATH } from '../../../../variables/endpoints';
import { ConfirmDialog } from '../../../../components/confirm';
import { toBase64 } from '../../../../utils/helpers';
import { usePutDeleteCompanyLogo } from '../../../../queries';

const CompanyLogo = ({
  LogoPath,
  companyId,
  putCompanyMutate,
  refetchCompany,
}: any) => {
  const [deleteImageOpen, setDeleteImageOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate: putDeleteCompanyLogoMutate,
    isLoading: putDeleteCompanyLogoIsLoading,
    data: putDeleteCompanyLogoData,
    error: putDeleteCompanyLogoError,
    reset: putDeleteLogoCompanyReset,
  } = usePutDeleteCompanyLogo({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('PutDeleteCompanyLogo');
    },
  });

  const onChangeImageData = async (file: File) => {
    const imageBase64: any = await toBase64(file);

    if (imageBase64) {
      const imageEncoded = JSON.stringify(imageBase64);
      putCompanyMutate({ companyId, imageEncoded });
    }
  };

  const onDeleteImageConfirmed = () =>
    putDeleteCompanyLogoMutate({ companyId });

  const onDialogClose = () => {
    setDeleteImageOpen(false);
  };

  const onDialogSuccess = async () => {
    onDialogClose();
    putDeleteLogoCompanyReset();
    setTimeout(async () => await refetchCompany(), 100);
  };

  return (
    <>
      <Stack width="100%" alignItems="center">
        <ImageUploader
          isCreateModal={false}
          uploaderId={'update-company-uploader'}
          imageSrc={LogoPath ? `${S3MMSITE_PATH}/${LogoPath}` : null}
          onChangeImageData={onChangeImageData}
          onClickDeleteImage={() => setDeleteImageOpen(true)}
        />
      </Stack>
      {deleteImageOpen && (
        <ConfirmDialog
          title="Delete Company logo"
          text="Are you sure you want to delete this company logo?"
          onOk={onDeleteImageConfirmed}
          onCancel={onDialogClose}
          onDeleteSuccess={onDialogSuccess}
          asyncApi={{
            loading: putDeleteCompanyLogoIsLoading,
            error: putDeleteCompanyLogoError,
            data: putDeleteCompanyLogoData,
          }}
        />
      )}
    </>
  );
};

export default CompanyLogo;
