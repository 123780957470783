import { useAppDispatch } from '../store/hooks';
import { reportHeaderActions } from '../store/reportheader';
import { useHttp } from './useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { REPORT_HEADER_PATHS } from '../variables/endpoints';

const useReportHeader = () => {
  const dispatch = useAppDispatch();
  const { api } = useHttp();
  const setHandlerReportHeader = async (dateRef: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: REPORT_HEADER_PATHS.getReportHeader(craneId.toString(), dateRef),
      });
      dispatch(reportHeaderActions.setReportHeader(data[0]));
    } catch (err) {}
  };
  return { setHandlerReportHeader };
};

export default useReportHeader;
