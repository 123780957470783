// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { sumBy, times } from 'lodash';
import { secondsToMinutes, WEEKDAY } from './utils';
import { ChartStack } from '../content/styled';

const CranesDailyOperationalDuration = (props: any) => {
  const { records, project } = props;

  const daysCount = records.length;
  const cranesCount = Number(project.CranesCount);

  const series = times(cranesCount, (crane) => {
    const data = times(daysCount, (day) => {
      const craneDayRecords = records[day]?.records[crane]?.records || [];

      if (craneDayRecords.length === 0) return 0;

      const load = sumBy(craneDayRecords, (r) => r.load.seconds);
      const unload = sumBy(craneDayRecords, (r) => r.unload.seconds);
      const transitionWithoutLoad = sumBy(
        craneDayRecords,
        (r) => r.transitionWithoutLoad.seconds,
      );

      const transitionWithLoad = sumBy(
        craneDayRecords,
        (r) => r.transitionWithLoad.seconds,
      );

      const transition = transitionWithLoad + transitionWithoutLoad;

      return Number(((load + unload + transition) / 3600).toFixed(2));
    });

    return {
      name: records[0].records[crane].crane.name,
      data,
    };
  });

  const days = times(daysCount, (day) => WEEKDAY[day]);

  const options = {
    chart: {
      type: 'column',
      height: 300,
    },
    exporting: { enabled: false },
    colors: ['#4572C4', '#EC7D31', '#A5A5A5', '#FFC100'],
    title: {
      text: 'Cranes Daily Operational Duration [Hours]',
    },
    xAxis: {
      categories: days,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: 12,
      title: { text: undefined },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter: function () {
        const { y, series } = this;
        return (
          `<tr><td style="color:${series.color};padding:0">${series.name}: </td>` +
          `<td style="padding:0"><b>${secondsToMinutes(y * 3600)}</b></td></tr>`
        );
      },
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series,
  };

  return (
    <ChartStack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartStack>
  );
};

export default CranesDailyOperationalDuration;
