import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import { Tab, TabPanel } from '../../components/Tabs';
import AppHeader from '../../components/common/appHeader';
import DailyVideoDialog from '../../components/dashboard/DailyVideoDialog';
import CraneLiftImagesDialog from '../../components/dashboard/craneLiftImages/CraneLiftItamesDialog';
import ProjectReportDialog from '../../components/dashboard/projectReport/ProjectReportDialog';
import { LayoutStack, MainTitle } from '../../components/styled';
import useDailyVideo from '../../hooks/usedailyvideo';
import { useGetCraneVideoRecordings } from '../../queries';
import { useGetLifts } from '../../queries/useGetLifts';
import { dailyVideoActions } from '../../store/dailyvideo';
import { useAppSelector } from '../../store/hooks';
import { projectCraneActions } from '../../store/projectcrane';
import { convertDateToAppDate } from '../../utils/helpers';
import * as storageHelper from '../../utils/storageHelper';
import {
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_CRANE_DATA,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import Charts from './Charts/Charts';
import { ImageTimeline } from './ImageTimeline';
import Media from './Media/Media';
import { SiteClock } from './SiteClock';
import LiftList from './LiftList/LiftList';
import { ActionsToolbar } from './components';
dayjs.extend(utc);
dayjs.extend(timezone);

const initialDailyRecordsState = {
  crane: {},
  records: [],
  startEndTime: {},
  windSpeed: [],
};

const Dashboard = () => {
  let DateSample = '';

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setHandlerDailyVideo } = useDailyVideo();
  const addMargin = useMediaQuery('(min-width: 1200px)');
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
  const [projectReportOpen, setProjectReportOpen] = useState<boolean>(false);
  const [craneReportOpen, setCraneReportOpen] = useState<boolean>(false);
  const [craneLiftImagesOpen, setCraneLiftImagesOpen] = useState(false);
  const [dailyVideoOpen, setDailyVideoOpen] = useState<boolean>(false);
  const [dailyRecords, setDailyRecords] = useState(initialDailyRecordsState);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isGetLiftsLoading, setIsGetLiftsLoading] = useState(false);
  const [isLoadingTelemetries, setIsLoadingTelemetries] =
    useState<boolean>(false);
  const [refreshTelemeries, setRefreshTelemeries] = useState<boolean>(false);

  const user = storageHelper.getItem(USER);
  const sm = useMediaQuery('(max-width: 600px)');
  const timezone =
    useAppSelector((state: any) => state.projectCrane.Timezone) ||
    'Asia/Jerusalem';

  const selectedProject = useAppSelector(
    (state: any) => state.projectCrane.Project,
  );
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setRefreshTelemeries(true);
  };

  // const {
  //   mutate: taskByHoursByProjectMutate,
  //   isLoading: taskByHoursByProjectIsLoading,
  //   error: taskByHoursByProjectError,
  // } = usePostTaskByHoursByProject({
  //   onSuccess: (reportData: any) => {
  //     const { records } = reportData.data;
  //     const dailyRecords =
  //       records.find(
  //         (innerRecords: any) =>
  //           innerRecords.date === convertDateToAppDate(startDate),
  //       )?.records[0] || initialDailyRecordsState;

  //     setDailyRecords(dailyRecords);
  //   },
  //   onError: () => {
  //     setOpenSnackbar(true);
  //   },
  //   onSettled: () => {
  //     queryClient.invalidateQueries('PostTaskByHoursByProject');
  //   },
  // });

  const {
    data: liftsData,
    refetch: refetchGetLifts,
    isError: isGetLiftsError,
    // isLoading: isGetLiftsLoading,
  } = useGetLifts(
    {
      craneId: selectedCrane,
      date: convertDateToAppDate(startDate),
    },
    {
      onSuccess: () => {
        setIsGetLiftsLoading(false);
        setRefreshTelemeries(false);
      },
    },
  );

  const {
    isFetching: videoRecordingsIsLoading,
    error: videoRecordingsError,
    refetch: videoRecordingsRefetch,
    data: videRecordingsData,
  } = useGetCraneVideoRecordings(
    {
      craneId: selectedCrane,
    },
    {
      enabled: selectedTab === 3,
    },
  );

  useEffect(() => {
    if (liftsData && liftsData.data) {
      const {
        lifts,
        operationSegments,
        idleSegments,
        liftsByHour,
        operationTime,
        liftsStats,
        workDayWindowHours,
      } = liftsData.data;
      dispatch(projectCraneActions.setLifts(lifts));
      dispatch(projectCraneActions.setOperationSegments(operationSegments));
      dispatch(projectCraneActions.setIdleSegments(idleSegments));
      dispatch(projectCraneActions.setLiftsByHour(liftsByHour));
      dispatch(projectCraneActions.setLiftsStats(liftsStats));
      dispatch(projectCraneActions.setWorkDayWindowHours(workDayWindowHours));
    }
  }, [liftsData]);

  const refreshPage = () => {
    switch (selectedTab) {
      case 0:
        setIsGetLiftsLoading(true);
        setRefreshTelemeries(true);
        refetchGetLifts();
        break;
      case 1:
        setIsGetLiftsLoading(true);
        refetchGetLifts();
        break;
      case 2:
        break;
      case 3:
        videoRecordingsRefetch();
        break;
      default:
        break;
    }
  };

  // const loadData = () => {
  //   taskByHoursByProjectMutate({
  //     ProjectId: selectedProject,
  //     DateLoad: convertDateToAppDate(startDate),
  //     CraneIds: [selectedCrane],
  //   });
  // };

  useEffect(() => {
    const selectedProject =
      storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT) || '';
    if (selectedProject) {
      dispatch(projectCraneActions.setCurrentProject(selectedProject));
    }
    const selectedCraneNew =
      storageHelper.getItem(ULTRAWIS_SELECTED_CRANE) || '';
    if (selectedCraneNew) {
      dispatch(projectCraneActions.setCurrentCrane(selectedCraneNew));
    }

    const selectedCraneDataNew =
      storageHelper.getItem(ULTRAWIS_SELECTED_CRANE_DATA) || {};
    if (selectedCraneDataNew) {
      dispatch(projectCraneActions.setSelectedCrane(selectedCraneDataNew));
    }
  }, []);

  useEffect(() => {
    if (user.sites.length > 0 && selectedProject) {
      const selectedProjectTimezone = user.sites.find(
        (p: any) => p.id === selectedProject,
      ).timezone;

      if (selectedProjectTimezone)
        dispatch(
          projectCraneActions.setCurrentTimezone(selectedProjectTimezone),
        );
    }
  }, [user.sites]);

  useEffect(() => {
    if (selectedProject && startDate && selectedCrane) {
      // loadData();
      refreshPage();
    }
  }, [startDate, selectedProject, selectedCrane]);

  useEffect(() => {
    if (selectedCrane && selectedCrane > 16) {
      dispatch(dailyVideoActions.resetDailyVideo());
      try {
        DateSample = convertDateToAppDate(startDate);
        setHandlerDailyVideo(DateSample, selectedCrane);
      } catch (err) {
        // console.error(err);
      }
    }
  }, [startDate]);

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const error = isGetLiftsError; //(taskByHoursByProjectError as Error)?.message;

  return (
    <LayoutStack>
      <Stack direction="row" justifyContent="space-between">
        <MainTitle>Dashboard</MainTitle>
        <ActionsToolbar
          {...{
            startDate,
            setCraneLiftImagesOpen,
            setCraneReportOpen,
            setProjectReportOpen,
            setDailyVideoOpen,
          }}
        />
      </Stack>

      <AppHeader
        startDate={startDate}
        setStartDate={setStartDate}
        onRefresh={refreshPage}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Tabs value={selectedTab} onChange={onTabChange}>
          <Tab label="Charts" />
          {/* <Tab
          label="Live Streams"
          disabled={
            !(startDate && selectedProject !== '' && selectedCrane !== '')
          }
        /> */}
          <Tab
            label="Lifts"
            disabled={
              !(startDate && selectedProject !== '' && selectedCrane !== '')
            }
          />
          <Tab
            label="Image Timeline"
            disabled={
              !(startDate && selectedProject !== '' && selectedCrane !== '')
            }
          />
          <Tab
            label="Media"
            disabled={
              !(startDate && selectedProject !== '' && selectedCrane !== '')
            }
          />
        </Tabs>
        {!sm && (
          <>
            <SiteClock />
          </>
        )}
      </div>

      {sm && (
        <div style={{ marginBottom: selectedTab === 0 ? '-15px' : 'inherit' }}>
          <SiteClock />
        </div>
      )}

      <Grid container rowSpacing={4} flex={1}>
        <TabPanel value={selectedTab} index={0}>
          <Charts
            loading={isGetLiftsLoading}
            isLoadingTelemetries={isLoadingTelemetries}
            setIsLoadingTelemetries={setIsLoadingTelemetries}
            startDate={startDate}
            refreshTelemeries={refreshTelemeries}
            setRefreshTelemeries={setRefreshTelemeries}
          />
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={1}>
          <VideoStreams selectedCrane={selectedCrane} />
        </TabPanel> */}
        <TabPanel value={selectedTab} index={1}>
          <LiftList
            dailyRecords={dailyRecords}
            recordsIsLoading={isGetLiftsLoading}
            liftsData={liftsData}
            refetchGetLifts={refetchGetLifts}
            startDate={startDate}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <ImageTimeline
            startDate={startDate}
            selectedSite={selectedProject}
            selectedCrane={selectedCrane}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <Media
            videRecordingsData={videRecordingsData?.data}
            videoRecordingsIsLoading={videoRecordingsIsLoading}
            videoRecordingsError={videoRecordingsError}
            videoRecordingsRefetch={videoRecordingsRefetch}
          />
        </TabPanel>
      </Grid>
      <DailyVideoDialog
        dailyVideoOpen={dailyVideoOpen}
        onClose={() => setDailyVideoOpen(false)}
      />

      {selectedProject && projectReportOpen && (
        <ProjectReportDialog
          onClose={() => setProjectReportOpen(false)}
          startDate={convertDateToAppDate(startDate)}
          projectId={selectedProject}
        />
      )}
      {selectedProject && selectedCrane && craneReportOpen && (
        <ProjectReportDialog
          onClose={() => setCraneReportOpen(false)}
          startDate={convertDateToAppDate(startDate)}
          projectId={selectedProject}
          craneIds={[selectedCrane]}
        />
      )}

      {selectedProject && selectedCrane && craneLiftImagesOpen && (
        <CraneLiftImagesDialog
          onClose={() => setCraneLiftImagesOpen(false)}
          startDate={convertDateToAppDate(startDate)}
        />
      )}

      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={error}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutStack>
  );
};

export default Dashboard;
