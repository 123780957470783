import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiButton'] = {
    defaultProps: {
      disableRipple: true,
      variant: 'primary',
    },
    styleOverrides: {
      root: {
        '&.app-bar-button': {
          marginTop: 4,
          padding: theme.spacing(0.5, 4),
          borderRadius: '49px',
          transition:
            'color 0.1s linear, background-color 0.1s linear, border 0.1s linear, fill 0.1s linear',
          borderWidth: '1px',
          color: theme.palette.common.white,
          backgroundColor: 'transparent',
          lineHeight: 1,
          textTransform: 'inherit',
          '&.Mui-disabled': {
            color: theme.palette.typography.disabled,
            pointerEvents: 'all',
            cursor: 'not-allowed',
          },
          '&.MuiButton-root-hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      },
      contained: {
        color: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.common.black,
        },
      },
      sizeMedium: {
        height: '32px',
        minWidth: '32px',
      },
    },
  };

  return styleOverrides;
};
