import React, { useReducer } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorAlert } from '../../../../components/alerts';
import { useQueryClient } from 'react-query';
import { usePostCompanyProject } from '../../../../queries';

const initialState = {
  name: '',
};
const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const CreateSiteDialog = ({ onClose, onSuccess, companyId }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = usePostCompanyProject({
    onSuccess: () => {
      onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostCompanyProject');
    },
  });

  const valid = state.name.trim() !== '';

  const handleSubmit = async () => {
    const { name } = state;

    mutate({ name, companyId });
  };

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Create Site</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Stack spacing={2}>
            <Typography variant="smallRegular">Site name</Typography>
            <TextField
              fullWidth
              value={state.name}
              autoFocus
              onChange={(e) =>
                dispatch({ type: 'name', payload: e.target.value })
              }
            />
          </Stack>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!valid || isLoading}
        >
          {isLoading ? <CircularProgress size={16} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSiteDialog;
