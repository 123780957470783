import { useDispatch } from 'react-redux';
import { idleTimesActions } from '../store/idletimes';
import { idleTimesRptActions } from '../store/idletimesrpt';
import { useHttp } from './useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { TASK_PATHS } from '../variables/endpoints';

const useIdleTime = () => {
  const dispatch = useDispatch();
  const { api } = useHttp();
  const setHandlerIdleTime = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_PATHS.getIdletime(dateProp, craneId.toString()),
      });
      dispatch(idleTimesActions.setIdleTime(data));
    } catch (err) {}
  };
  const setHandlerIdleTimeRpt = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_PATHS.getIdletime(dateProp, craneId.toString()),
      });
      dispatch(idleTimesRptActions.setIdleTimeRpt(data));
    } catch (err) {}
  };

  return { setHandlerIdleTime, setHandlerIdleTimeRpt };
};

export default useIdleTime;
