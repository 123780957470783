import { useDispatch } from 'react-redux';
import { fetchTaskByHoursByProject } from '../store/projectReport';

const useProjectReport = () => {
  const dispatch = useDispatch();

  const fetchTaskByHoursByProjectApi = async (args: any) => {
    return dispatch(fetchTaskByHoursByProject(args));
  };

  return {
    fetchTaskByHoursByProjectApi,
  };
};

export default useProjectReport;
