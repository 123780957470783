import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) =>
  apiGetAsync(`lift/crane/${props.craneId}?date=${props.date}`);

export const useGetLifts = (props: any, options?: any) => {
  const key = ['GetLifts'];
  const fn = () => api(props);

  return useQuery(key, fn, {
    enabled: !!props.craneId && !!props.date,
    ...(options || {}),
  });
};
