import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiPostAsync } from '../api/axios';
import { fetchBuilder, AsyncState } from './utils';

export const fetchCraneLiftImages = createAsyncThunk(
  'craneliftimages/fetch',
  async ({ DateLoad, CraneId }: any) => {
    const { data } = await apiPostAsync('craneliftimages', {
      DateLoad,
      CraneId,
    });
    return data;
  }
);

export interface CraneLiftImages {
  crane: any;
  tasks: any[];
}

const initialState: AsyncState<CraneLiftImages> = {
  data: {
    crane: undefined,
    tasks: [],
  },
  loading: false,
  error: undefined,
};

//@ts-ignore
const slice = createSlice({
  name: 'craneliftimages',
  initialState,
  reducers: {},
  extraReducers: fetchBuilder('craneliftimages'),
});

export const actions = slice.actions;

export default slice.reducer;
