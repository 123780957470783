import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

const LogisticsStepperNavigation = (props: any) => {
  const {
    activeStep,
    stepsLength,
    submitStep,
    disabledNextButton,
    loadingNextButton,
    handleReset,
    handleBack,
    isStepOptional,
    handleSkip,
    handleNext,
  } = props;

  const isSubmitStep = activeStep === submitStep;
  const buttonLabel = isSubmitStep ? 'Create Asset' : 'Next';

  if (activeStep > stepsLength) return null;

  if (activeStep === stepsLength)
    return (
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="contained" onClick={handleReset}>
          Create another asset
        </Button>
      </Stack>
    );

  return (
    <Stack
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Button
        variant="contained"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      {isStepOptional(activeStep) && (
        <Button variant="outlined" onClick={handleSkip} sx={{ mr: 1 }}>
          Skip
        </Button>
      )}
      <Button
        disabled={disabledNextButton}
        variant="contained"
        onClick={handleNext}
      >
        {loadingNextButton ? (
          <Stack direction="row" sx={{ gap: 2 }}>
            <CircularProgress size={16} />
            {!isSubmitStep && (
              <Typography variant="smallRegular">Next</Typography>
            )}
          </Stack>
        ) : (
          buttonLabel
        )}
      </Button>
    </Stack>
  );
};

export default LogisticsStepperNavigation;
