import { CheckboxRenderer } from '../../../components/grid';
import { displayUserRole } from '../../../utils';

export default () => {
  return [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
      minWidth: 400,
      maxWidth: 400,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
    },
    {
      headerName: 'Role',
      field: 'role',
      sortable: true,
      cellRendererFramework: (params: any) => {
        return displayUserRole(params.value);
      },
      minWidth: 300,
    },
    // {
    //   headerName: 'Executive member',
    //   field: 'CompanyExecutiveMember',
    //   cellRendererFramework: CheckboxRenderer,
    //   minWidth: 120,
    // },
    // {
    //   headerName: 'Status',
    //   field: 'isActive',
    //   cellRendererFramework: CheckboxRenderer,
    //   minWidth: 120,
    // },
  ];
};
