// @ts-ignore
import WizLogo from '../../../assets/icons/wiz-logo';
import WorkDayChart from '../WorkDayChart';
import { useAppSelector } from '../../../store/hooks';
import TaskByCategoryChart from '../TaskByCategoryChart';
import TaskGanttChart from '../TaskGanttChart';
import React from 'react';
import { S3MMSITE_PATH } from '../../../variables/endpoints';

export const PageOneTwo = () => {
  const tasks = useAppSelector((state) => state.tasksrpt.tasks);
  const calcSumTask = useAppSelector(
    (state) => state.calcsumtaskrpt.calcsumtask
  );
  const tasksByCategory = useAppSelector(
    (state) => state.tasksByCategoryRpt.tasksbycategory
  );
  const ganttTasks = useAppSelector((state) => state.GanttTasksRpt.gantttasks);
  const dateTlmStart = useAppSelector(
    (state) => state.timeframerpt.startTimeFrame
  );
  const ReportHeader = useAppSelector(
    (state) => state.ReportHeader.reportheader
  );
  return (
    <>
      <div style={{ height: '27px' }} />
      <div className="report__page-border">
        <div className="report__page-title">
          <WizLogo width={24} height={24} />
          <span>Report Widesite®</span>
          <span>
            {new Intl.DateTimeFormat('he-IL').format(
              Date.UTC(
                Number(dateTlmStart.substring(0, 4)),
                Number(dateTlmStart.substring(4, 6)) - 1,
                Number(dateTlmStart.substring(6, 8))
              )
            )}
          </span>{' '}
        </div>
        {ReportHeader.LogoPath && ReportHeader.LogoPath.length > 0 && (
          <div className="report__page-title2">
            <img
              src={`${S3MMSITE_PATH}/${ReportHeader.LogoPath}`}
              style={{ width: '50px' }}
            />
          </div>
        )}
        <div className="work-day__Report2">
          <div>
            <h6>Daily Crane utilization</h6>
          </div>
          <div className="work-day__Report">
            {tasks && <WorkDayChart calcSumTask={calcSumTask} Report={true} />}
            {tasks && (
              <div className="work-day__btn__div">
                <button type="button" className="rounded-circle work-day__btn">
                  {tasks.length}
                </button>
                <div>Lifts</div>
              </div>
            )}
          </div>
        </div>
        <div className="report__divider" />
        <div>
          <h6>Materials by time</h6>
          <TaskByCategoryChart
            tasksByCategory={tasksByCategory}
            Report={true}
          />
        </div>
        <div className="report__divider" />
        <div>
          <h6>Materials gant</h6>
          <TaskGanttChart ganttTasks={ganttTasks} Report={true} />
        </div>
        <div className="report__page--footer">
          <span />
          <span>
            This report reffers exclusively to the building company. All rights
            reserved to ©Ultrawis LTD.
          </span>
          <span>{2}</span>
        </div>
      </div>
    </>
  );
};
