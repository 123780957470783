import { createSlice } from '@reduxjs/toolkit';
export interface SearchAi {
  CargoTypeId: number;
  SelectedProject: number;
  SelectedCrane: number;
  scheduled: boolean;
  selectedDate: string;
  isSearchNeeded: string;
}

const initialState: SearchAi = {
  CargoTypeId: 0,
  SelectedProject: -1,
  SelectedCrane: -1,
  scheduled: false,
  selectedDate: '',
  isSearchNeeded: '',
};

const searchAiSlice = createSlice({
  name: 'searchai',
  initialState,
  reducers: {
    setCargoType(state, action) {
      state.CargoTypeId = action.payload;
    },
    setProject(state, action) {
      state.SelectedProject = action.payload;
    },
    setCrane(state, action) {
      state.SelectedCrane = action.payload;
    },
    setScheduled(state, action) {
      state.scheduled = action.payload;
    },
    setDate(state, action) {
      state.selectedDate = action.payload;
    },
    setSearchNeeded(state, action) {
      state.isSearchNeeded = action.payload;
    },
    resetSearchAi: () => initialState,
  },
});

export const searchAiActions = searchAiSlice.actions;

export default searchAiSlice.reducer;
