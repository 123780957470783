import { useEffect, useState } from 'react';
import { Marker } from 'react-mapbox-gl';
import { ReactComponent as DestinationIcon } from '../../../../../assets/icons/destination.svg';
import { ReactComponent as SourceIcon } from '../../../../../assets/icons/source.svg';
import MapBox, { MapCoordinate, MapType } from '../../../../MapBox/MapBox';

const MapTab = ({ data: lift }: any) => {
  const {
    sourceLatitude,
    sourceLongitude,
    destinationLatitude,
    destinationLongitude,
  } = lift;

  const [source, setSource] = useState<MapCoordinate | null>(null);
  const [destination, setDestination] = useState<MapCoordinate | null>(null);

  useEffect(() => {
    if (!sourceLatitude || !sourceLongitude) {
      if (source?.lat || source?.lng) setSource(null);
      return;
    } else {
      setSource({ lat: Number(sourceLatitude), lng: Number(sourceLongitude) });
    }

    if (!destinationLatitude || !destinationLongitude) {
      if (destination?.lat || destination?.lng) setDestination(null);
      return;
    } else {
      setDestination({
        lat: Number(destinationLatitude),
        lng: Number(destinationLongitude),
      });
    }
  }, [lift]);

  return (
    <div style={{ height: '100%' }}>
      {source && destination && (
        <MapBox siteOrigin={source} mapType={MapType.street}>
          <>
            <Marker
              key={source?.lat}
              coordinates={[source.lng, source.lat]}
              anchor="bottom"
            >
              <SourceIcon
                color="#ff6f00"
                style={{ width: '50px', height: '50px' }}
              />
            </Marker>
            <Marker
              key={destination?.lat}
              coordinates={[destination.lng, destination.lat]}
              anchor="bottom"
            >
              <DestinationIcon
                color="#ff6f00"
                style={{ width: '50px', height: '50px' }}
              />
            </Marker>
          </>
        </MapBox>
      )}
    </div>
  );
};

export default MapTab;
