import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface Telemetry {
  ID: number;
  CraneId: number;
  DateSampling: string;
  TimeSampling: string;
  WindSpeedKmH: number;
  WindDirDeg: number;
}
export interface Telemetries {
  telemetries: Telemetry[];
}

const initialState: Telemetries = {
  telemetries: [
    {
      ID: 0,
      CraneId: 0,
      DateSampling: '',
      TimeSampling: '',
      WindSpeedKmH: 0,
      WindDirDeg: 0,
    },
  ],
};

const telemetriesSlice = createSlice({
  name: 'telemetries',
  initialState: initialState,
  reducers: {
    setTelemetries(state, action: PayloadAction<Telemetry[]>) {
      state.telemetries = action.payload;
      //console.log (state.telemetries);
    },
    resetTelemetries: () => initialState,
  },
});

export const telemetriesActions = telemetriesSlice.actions;

export default telemetriesSlice.reducer;
