import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiSelect'] = {
    styleOverrides: {
      outlined: {
        boxSizing: 'border-box',
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 16px',
        color: 'black',
        gap: '8px',
        background: theme.palette.backgrounds.white,
        borderRadius: theme.spacing(1),
      },
      select: {
        ...theme.typography.mediumRegular,
        minHeight: 39,
        maxHeight: 39,
      },
      iconOpen: {},
    },
  };
  return styleOverrides;
};
