import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Stack, Breadcrumbs, Link, Typography } from '@mui/material';
import { LayoutStack } from '../../components/styled';
import { CompanyHeader } from './components';
import { useGetCompany, useGetProject, useGetCrane } from '../../queries';

const Company = () => {
  const { companyId, siteId, craneId } = useParams();

  const { data: getCompanyData } = useGetCompany(
    { companyId },
    {
      cacheTime: 0,
      siteId,
    },
  );

  const { data: getProjectData } = useGetProject(
    { siteId },
    {
      cacheTime: 0,
    },
  );

  const { data: getCraneData } = useGetCrane(
    { craneId },
    {
      cacheTime: 0,
    },
  );

  return (
    <LayoutStack>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/#/companies">
          Companies
        </Link>
        {getCompanyData?.data?.name && (
          <Link
            underline="hover"
            color="inherit"
            href={`/#/company/${getCompanyData?.data?.id}`}
          >
            {getCompanyData?.data?.name}
          </Link>
        )}
        {getProjectData?.data?.projectData?.name && (
          <Link
            underline="hover"
            color="inherit"
            href={`/#/company/${getCompanyData?.data?.ID}/site/${getProjectData?.data?.projectData?.id}`}
          >
            {getProjectData?.data?.projectData?.name}
          </Link>
        )}
        {getCraneData?.data?.name && (
          <Typography color="text.primary">
            {getCraneData?.data?.name}
          </Typography>
        )}
      </Breadcrumbs>
      <Stack direction="row" width="100%" height="100%" spacing={4}>
        <CompanyHeader
          companyId={companyId}
          siteId={siteId}
          craneId={craneId}
        />
        <Outlet />
      </Stack>
    </LayoutStack>
  );
};

export default Company;
