import React, { useState } from 'react';
import { Button, Dialog, Paper, Stack, useMediaQuery } from '@mui/material';
import { Settings } from './Settings';
import { settingsInitialState } from './Settings';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/icon-download.svg';

export const ImageDialog = ({
  imageSrc,
  handleClose,
  getKeyDetails,
  imageStyle,
}: any) => {
  const [settings, setSettings] = useState<any>(settingsInitialState);
  const sm = useMediaQuery('(max-width: 768px)');

  const onSettingsChange = (newSettings: any) => {
    setSettings(newSettings);
  };

  const filter = Object.keys(settings)
    .map((key) => `${key}(${settings[key]}%)`)
    .join(' ');

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = imageSrc;
    link.click();
  };

  return (
    <Dialog
      open={!!imageSrc}
      onClose={handleClose}
      fullScreen
      style={{ height: '100%' }}
    >
      <Stack sx={{ padding: sm ? 1 : 6 }} style={{ height: '100%' }}>
        <Paper
          sx={{
            position: 'absolute',
            top: 36,
            left: 36,
            padding: 3,
            backgroundColor: 'antiquewhite',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          {getKeyDetails && getKeyDetails(imageSrc)}&nbsp;&nbsp;
          <Button variant="contained" size="small" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleDownload}
            style={{ marginLeft: '10px' }}
          >
            Download
          </Button>
          <Settings {...{ settings, onSettingsChange }} />
        </Paper>
        <Paper
          sx={{
            backgroundImage: `url('${imageSrc}')`,
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: 'solid 1px #ff6f00',
            borderRadius: 4,
            filter,
            ...imageStyle,
          }}
        />
      </Stack>
    </Dialog>
  );
};
