import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiPaper'] = {
    styleOverrides: {
      root: {
        '&.MuiAccordion-root:before': {
          backgroundColor: 'transparent',
        },
        '.MuiMenu-list': {
          border: 'none',
        },
        '&.MuiMenu-paper': {
          minWidth: 130,
          '.MuiPaper-root' : {
            boxShadow: 'none',
          }
        },
      },
    },
  };
  return styleOverrides;
};
