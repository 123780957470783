import React from 'react';
import { Stack, Typography } from '@mui/material';

const InformationStack = (props: any) => {
  const { title, children, ...rest } = props;
  return (
    <Stack spacing={2} {...rest}>
      <Stack
        alignItems="flex-start"
        sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}
      >
        <Typography variant="h2Medium">{title}</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        width="100%"
        sx={{
          flexWrap: 'wrap',
          gap: 2,
          paddingBottom: 2,
          overflow: 'visible',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default InformationStack;
