function getImageProps(
  isCreateForm: boolean,
  readOnly: boolean,
  imageSrc: string,
  preview: string | null,
) {
  if (readOnly) {
    if (imageSrc)
      return {
        src: imageSrc,
      };
    // Add here a placeholder image for uploader
    // return { src: '' };
  }

  if (isCreateForm) {
    if (preview)
      return {
        src: preview,
      };
  }

  if (preview)
    return {
      src: preview,
    };

  if (imageSrc)
    return {
      src: imageSrc,
    };

  // Add here a placeholder image for uploader
  // return { src: '' };
}

export { getImageProps };
