import { Box, Stack, useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import timeline from 'highcharts/modules/timeline';
import { Property } from '../../../../dashboard/projectReport/content/styled';
import { S3_IMAGES_PATH } from '../../../../../variables/endpoints';
import InformationStack from './InformationStack';
import { width } from '@mui/system';

HighchartsExporting(Highcharts);
timeline(Highcharts);

const date = new Date();
let s3Path = S3_IMAGES_PATH;

const getTime = (time: any) => {
  if (!time) return Date.now();
  const segments = time.split(':');
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDay(),
    Number(segments[0]),
    Number(segments[1]),
    Number(segments[2]),
  );
};

const Timelinechart = ({ data, images }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');

  const X_AXIS_OFFSET = sm ? 20 * 60 * 1000 : 5 * 60 * 1000; // 2 minutes for small screens and 5 minutes for large screens

  const chartData = [
    {
      x: getTime(data.startTime),
      name: 'startTime',
      label: 'Start Time',
      description:
        'The time when the crane started loading the cargo at the source location',
      dataLabels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    {
      x: getTime(data.endTime),
      name: 'endTime',
      label: 'End Time',
      description:
        'The time when the crane finished unloading the cargo at the destination location',
      dataLabels: {
        style: {
          fontSize: '14px',
        },
      },
    },
  ];

  const colors = [
    '#2caffe',
    '#544fc5',
    '#00e272',
    '#fe6a35',
    '#6b8abc',
    '#d568fb',
    '#2ee0ca',
    '#fa4b42',
    '#feb56a',
    '#91e8e12',
  ];

  // Calculate min and max times for xAxis with offset
  const minTime = Math.min(...chartData.map((d) => d.x)) - X_AXIS_OFFSET;
  const maxTime = Math.max(...chartData.map((d) => d.x)) + X_AXIS_OFFSET;

  const options = {
    colors,
    chart: {
      zoomType: 'x',
      type: 'timeline',
      height: sm ? 200 : 360,
      // width: sm ? 320 : 1170,
      colors,
      style: {
        overflow: 'visible',
      },
    },
    exporting: { enabled: false },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      min: minTime,
      max: maxTime,
      labels: {
        style: {
          fontSize: '16px',
        },
      },
    },
    yAxis: {
      gridLineWidth: 1,
      title: null,
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      useHTML: true,
      // @ts-ignore
      formatter: function () {
        // @ts-ignore
        const { point, key } = this;
        let imageUrl = '';
        if (point.name === 'startTime' && Array.isArray(images) && images[0]) {
          imageUrl = images[0].src;
        } else if (
          point.name === 'endTime' &&
          Array.isArray(images) &&
          images[images.length - 1]
        ) {
          imageUrl = images[images.length - 1].src;
        }
        if (!sm && imageUrl) {
          return `<div style="text-align: center; overflow: visible;">
                    <img src="${imageUrl}" alt="Image" style="width: 550px; height: 400px;" />
                    <div>${point.label} <b>${data[key]}</b></div>
                  </div>`;
        }
        // else {
        //   return point.description;
        // }
      },
      style: {
        width: 550,
        fontSize: '14px',
      },
      // @ts-ignore
      positioner: function (labelWidth, labelHeight, point): any {
        // @ts-ignore
        const { chart } = this;
        var tooltipX, tooltipY;
        tooltipX = point.plotX + chart.plotLeft - labelWidth / 2;
        tooltipY = point.plotY + chart.plotTop - labelHeight + 10;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
    },
    series: [
      {
        type: 'line',
        data: [
          { x: getTime(data.startTime), y: 1 },
          { x: getTime(data.endTime), y: 1 },
        ], // connecting line between start and end time
        lineWidth: 2,
        color: '#2caffe', // line color
        showInLegend: false,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false, // disable hover effects
      },
      ...chartData.map((d, index) => {
        const m = index + 1;
        const distance = m % 2 === 0 ? 50 : -50;
        return {
          colors: [colors[index]],
          dataLabels: {
            allowOverlap: true,
            distance,
            // @ts-ignore
            formatter: function () {
              // @ts-ignore
              const { point, key } = this;
              return `
              <span style="color:${point.color}">● </span>
              <span style="font-weight: bold;font-size: 13px;">${point.label}</span><br/>
              ${data[key]}
            `;
            },
          },
          marker: {
            symbol: 'circle',
          },
          data: [d],
        };
      }),
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{
        style: { overflow: 'visible !important', width: '100%' },
      }}
    />
  );
};

const InformationTab = ({ data, images }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
  return (
    <Stack
      sx={{ paddingTop: 10, padding: sm ? 2 : 4, height: '100%' }}
      spacing={6}
      justifyContent={'space-evenly'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={sm ? '100%' : medium ? '50%' : '30%'}
      >
        <Property
          label="Duration"
          value={`${data.duration} minutes`}
          sx={{
            flex: 'inherit',
            whiteSpace: 'nowrap',
          }}
          minWidth={sm ? '80px' : '100px'}
          textFontSize={14}
          valueFontSize={16}
        />
        <Property
          label="Distance"
          value={`${data.distance} meters`}
          sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
          minWidth={sm ? '80px' : '100px'}
          textFontSize={14}
          valueFontSize={16}
        />
        <Property
          label="Weight"
          value={`${Number(data.weight * 0.001).toFixed(2)} tons`}
          sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
          minWidth={sm ? '80px' : '100px'}
          textFontSize={14}
          valueFontSize={16}
        />
      </Box>
      <InformationStack title="Timeline" height={sm ? '70%' : '100%'}>
        <Timelinechart data={data} images={images} />
      </InformationStack>
    </Stack>
  );
};

export default InformationTab;
