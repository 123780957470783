import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => {
  const { craneId, ...restProps } = props;
  return apiDeleteAsync(`crane/${craneId}`, restProps);
};

export const useDeleteCrane = (options: any) => {
  const key = ['DeleteCrane'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
