import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorAlert = ({ error, onClose }: any) => {
  const [state, setState] = useState(error);
  return (
    <Collapse in={!!state}>
      <Alert
        severity="error"
        action={
          <IconButton size="small" onClick={() => setState(null)}>
            <CloseIcon />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        <AlertTitle>Error</AlertTitle>
        <Stack spacing={2}>
          {state?.message && (
            <Typography variant="smallRegular">{state?.message}</Typography>
          )}
        </Stack>
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;
