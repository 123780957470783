import { ApiMethods } from '../../variables/enums';
import * as storageHelper from '../../utils/storageHelper';
import { merge } from 'lodash';
import { instance } from '../../api/axios';
import { LOGIN_PATH } from '../../variables/endpoints';
import { AxiosResponse } from 'axios';
import { userActions } from '../../store/user';
import { useAppDispatch } from '../../store/hooks';
import { ApiGetParam, ApiParam, ApiGeneralParam } from './types';
import {
  TOKEN,
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_CRANE_DATA,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';

export const useHttp = () => {
  const dispatch = useAppDispatch();

  const catchError = (e: any) => {
    if (e?.response?.status === 401 || e?.response?.status === 404) {
      window.location.href = '/login';
    }
    throw e;
  };

  const apiGet = async (param: ApiGetParam) => {
    const { url, config } = param;
    return instance.get(url, config).catch(catchError);
  };

  const apiPost = async (param: ApiGeneralParam) => {
    const { url, data, config } = param;
    return instance.post(url, data, config).catch(catchError);
  };

  const apiPut = async (param: ApiGeneralParam) => {
    const { url, data, config } = param;
    return instance.put(url, data, config).catch(catchError);
  };

  const apiDelete = async (param: ApiGetParam) => {
    const { url, config } = param;
    return instance.delete(url, config).catch(catchError);
  };

  const apiPatch = async (param: ApiGeneralParam) => {
    const { url, data, config } = param;
    return instance.patch(url, data, config).catch(catchError);
  };

  const api = async (param: ApiParam) => {
    const { method, url, data, config } = param;
    const token = storageHelper.getItem(TOKEN);

    const mergedConfig = token
      ? merge(config, {
          headers: {
            authorization: token,
          },
        })
      : config;

    switch (method) {
      case ApiMethods.GET:
        return apiGet({ url, config: mergedConfig });
      case ApiMethods.POST:
        return apiPost({ url, data, config: mergedConfig });
      case ApiMethods.PUT:
        return apiPut({ url, data, config: mergedConfig });
      case ApiMethods.DELETE:
        return apiDelete({ url, config: mergedConfig });
      case ApiMethods.PATCH:
        return apiPatch({ url, data, config: mergedConfig });
      default:
        return apiGet({ url, config: mergedConfig });
    }
  };

  const login = async (userEmail: string, password: string): Promise<any> => {
    return api({
      method: ApiMethods.POST,
      url: LOGIN_PATH,
      data: { email: userEmail, password },
    }).then((response: AxiosResponse) => {
      const { data } = response;
      storageHelper.setItem(TOKEN, data.token);
      storageHelper.setItem(USER, data.userData);
      dispatch(userActions.setUser(data.userData));
      return;
    });
  };

  const logout = () => {
    storageHelper.removeItem(TOKEN);
    storageHelper.removeItem(USER);
    storageHelper.removeItem(ULTRAWIS_SELECTED_PROJECT);
    storageHelper.removeItem(ULTRAWIS_SELECTED_CRANE);
    storageHelper.removeItem(ULTRAWIS_SELECTED_CRANE_DATA);
  };

  return {
    login,
    logout,
    api,
  };
};
