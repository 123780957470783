import React from 'react';
import { MenuItem, Select } from '@mui/material';

const SelectRowRenderer = ({
  value,
  data,
  onUpdateCargoType,
  selectOptions,
}: any) => {
  return (
    <Select
      value={value}
      onChange={(e) => onUpdateCargoType(data, e.target.value)}
    >
      {selectOptions.map(({ id, name }: any) => (
        <MenuItem value={name} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectRowRenderer;
