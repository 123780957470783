import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiAppBar from './MuiAppBar';
import MuiMenuItem from './MuiMenuItem';
import MuiPaper from './MuiPaper';
import MuiDialogTitle from './MuiDialogTitle';
import MuiSelect from './MuiSelect';
import MuiInputBase from './MuiInputBase';
import MuiDialogActions from './MuiDialogActions';
import MuiFormHelperText from './MuiFormHelperText';
import MuiTooltip from './MuiTooltip';
import MuiFormLabel from './MuiFormLabel';
import MuiTableCell from './MuiTableCell';

export default (theme: any) => {
  return {
    MuiButton: MuiButton(theme),
    MuiButtonBase: MuiButtonBase(theme),
    MuiAppBar: MuiAppBar(theme),
    MuiMenuItem: MuiMenuItem(theme),
    MuiPaper: MuiPaper(theme),
    MuiDialogTitle: MuiDialogTitle(theme),
    MuiSelect: MuiSelect(theme),
    MuiInputBase: MuiInputBase(theme),
    MuiDialogActions: MuiDialogActions(theme),
    MuiFormHelperText: MuiFormHelperText(theme),
    MuiTooltip: MuiTooltip(theme),
    MuiFormLabel: MuiFormLabel(theme),
    MuiTableCell: MuiTableCell(theme),
  };
};
