import React from 'react';
import {
  Typography,
  Paper,
  Alert as MuiAlert,
  Box,
  Stack,
  Button,
} from '@mui/material';

const SignallerSubmitted = (props: any) => {
  const { handleReset } = props;

  return (
    <Paper
      sx={{
        padding: 4,
        backgroundColor: 'common.white',
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      elevation={1}
    >
      <MuiAlert severity="success" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="smallRegular">
          Task successfully created!
        </Typography>
      </MuiAlert>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="contained" onClick={handleReset}>
          Create another task
        </Button>
      </Stack>
    </Paper>
  );
};

export default SignallerSubmitted;
