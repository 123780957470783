import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LastTask {
  num: number;
  category: number;
  DateLoad: string;
  timeLoadStart: string;
  taskLocations: string;
  totalTimeLength: string;
  picNameLoad: string;
  picNameUnload: string;
}
export interface LastTasks {
  lasttasks: LastTask[];
}

const initialState: LastTasks = {
  lasttasks: [
    {
      num: 0,
      category: 0,
      DateLoad: '',
      timeLoadStart: '',
      taskLocations: '',
      totalTimeLength: '',
      picNameLoad: '',
      picNameUnload: '',
    },
  ],
};

const lastTasksSlice = createSlice({
  name: 'lasttasks',
  initialState: initialState,
  reducers: {
    setLastTasks(state, action: PayloadAction<LastTask[]>) {
      state.lasttasks = action.payload;
      //    console.log(state.lasttasks);
    },
    resetLastTasks: () => initialState,
  },
});

export const lastTasksActions = lastTasksSlice.actions;

export default lastTasksSlice.reducer;
