import React, { useState } from 'react';
import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
} from '../../../../../components/styled';
import { Stack } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from 'react-query';
import {
  useDeleteCrane,
  useGetProjectCranes,
  usePutCraneActivationStatus,
} from '../../../../../queries';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorState } from '../../../../../components/alerts';
import { Grid } from '../../../../../components/grid';
import getColumnsDef from './columns';
import CreateCraneDialog from './CreateCraneDialog';
import { ConfirmDialog } from '../../../../../components/confirm';
import * as storageHelper from '../../../../../utils/storageHelper';
import { USER } from '../../../../../variables/constants';
import { UserRole } from '../../../../../variables/enums';
import EditCraneDialog from './EditCraneDialog';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const SiteCranes = ({
  siteId,
  siteCranes,
  refetchSite,
  getSiteIsFetching,
  getSiteError,
}: any) => {
  const navigate = useNavigate();
  const { companyId, siteIdParam } = useParams();
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState({
    open: false,
    crane: null,
  });
  const [deleteItemId, setDeleteItemId] = useState<number | null>();
  const user = storageHelper.getItem(USER);

  const queryClient = useQueryClient();

  const {
    mutate: deleteCraneMutate,
    isLoading: deleteCraneIsLoading,
    error: deleteCraneError,
    data: deleteCraneData,
    reset: deleteCraneReset,
  } = useDeleteCrane({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteCrane');
    },
  });

  const {
    mutateAsync: updateCraneActivationStatus,
    error: updateCraneActivationStatusError,
    data: updateCraneActivationStatusData,
    isLoading: updateCraneActivationStatusLoading,
  } = usePutCraneActivationStatus();

  //#region Handlers
  const onRowClicked = (e: any) =>
    navigate(`/company/${companyId}/sites/${siteId}/cranes/${e.data.id}`);

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onEdit = (craneId: string) => {
    const crane = siteCranes.find((crane: any) => crane.id === craneId);
    if (crane) {
      setEdit({ open: true, crane });
    }
  };

  const onEditSuccess = async () => {
    await refetchSite();
    setEdit({ open: false, crane: null });
  };

  const onCreateSuccess = async () => {
    await refetchSite();
    setCreateOpen(false);
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onActive = (e: any, item: any) => {
    updateCraneActivationStatus({
      id: item.id,
    });
    setTimeout(async () => await refetchSite(), 100);
  };

  const onDeleteConfirmed = () => deleteCraneMutate({ craneId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteCraneReset();
    onDelete();
    setTimeout(async () => await refetchSite(), 100);
  };

  const onDeleteClose = () => {
    deleteCraneReset();
    onDelete();
  };
  //#endregion

  return (
    <LayoutStack>
      <GridToolbar>
        <Stack>
          {user.role === UserRole.ADMIN && (
            <Button
              variant="contained"
              onClick={() => onCreate(true)}
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          )}
        </Stack>
        <Stack>
          <Button
            variant="outlined"
            disabled={getSiteIsFetching}
            onClick={() => refetchSite()}
          >
            {getSiteIsFetching ? (
              <CircularProgress size={16} />
            ) : (
              <RefreshIcon />
            )}
          </Button>
        </Stack>
      </GridToolbar>
      <ContentStack>
        {getSiteIsFetching && !getSiteError && <Loading />}
        {!getSiteIsFetching && getSiteError && (
          <ErrorState error={getSiteError} />
        )}
        {Array.isArray(siteCranes) && siteCranes && (
          <Grid
            {...{
              rows: siteCranes,
              columnsDef: getColumnsDef(ROW_HEIGHT, onDelete, onActive, onEdit),
              // onRowClicked,
              onDelete,
              ROW_HEIGHT,
              maxVisibleRows,
            }}
          />
        )}

        {createOpen && (
          <CreateCraneDialog
            onClose={() => onCreate(false)}
            onSuccess={onCreateSuccess}
            siteId={siteId}
          />
        )}

        {edit.open && (
          <EditCraneDialog
            crane={edit.crane}
            onClose={() => setEdit({ open: false, crane: null })}
            onSuccess={onEditSuccess}
          />
        )}

        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete crane"
            text="Are you sure you want to delete this crane?"
            onOk={onDeleteConfirmed}
            onCancel={onDeleteClose}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={{
              loading: deleteCraneIsLoading,
              error: deleteCraneError,
              data: deleteCraneData,
            }}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};

export default SiteCranes;
