import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ContentStack, Loading } from '../../../components/styled';
import AddVideoRecordingsModal from './components/AddVideoRecordingsModal';
import Recordings from './components/Recordings';

const Media = ({
  videRecordingsData,
  videoRecordingsIsLoading,
  videoRecordingsError,
  videoRecordingsRefetch,
}: any) => {
  const [openAddCraneMedia, setOpenAddCraneMedia] = useState(false);
  const [isCraneConnected, setIsCraneConnected] = useState(false);
  const [requestedRecordings, setRequestedRecordings] = useState<any>([]);
  const sm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (videoRecordingsError) {
      setIsCraneConnected(false);
      setRequestedRecordings({});
      return;
    }

    if (!videRecordingsData || !videRecordingsData.recordings) {
      return;
    }

    const newVideoRecordings = videRecordingsData.recordings
      .map((item: any) => {
        const cameraType = extractCameraType(item.path);
        const { date, time } = extractVideoLength(item.path);
        return {
          ...item,
          cameraType,
          date,
          time,
        };
      })
      .sort((a: any, b: any) => b.date - a.date);

    const groupedRecordings = newVideoRecordings.reduce(
      (acc: any, item: any) => {
        const dateStr = item.date.format('YYYY-MM-DD');
        if (!acc[dateStr]) {
          acc[dateStr] = [];
        }
        acc[dateStr].push(item);
        return acc;
      },
      {},
    );

    const requestedGroupedRecordings = Object.keys(groupedRecordings).reduce(
      (acc: any, date: string) => {
        const recordings = groupedRecordings[date].filter(
          (item: any) => item.isRequested,
        );
        if (recordings.length > 0) {
          return [
            ...acc,
            {
              date,
              recordings,
            },
          ];
        }
        return acc;
      },
      [],
    );

    // Sort requested recordings by date, and inner recordings by time
    requestedGroupedRecordings.forEach((group: any) => {
      const sortedRecordings = group.recordings.sort((a: any, b: any) => {
        const dateString = dayjs().format('YYYY-MM-DD');
        const aDateTimeString = `${dateString} ${a.time}`;
        const aDayjs = dayjs(aDateTimeString, 'YYYY-MM-DD HH:mm:ss');
        const bDateTimeString = `${dateString} ${b.time}`;
        const bDayjs = dayjs(bDateTimeString, 'HH:mm:ss');
        return aDayjs.isBefore(bDayjs) ? -1 : 1;
      });
      return {
        ...group,
        recordings: sortedRecordings,
      };
    });

    // const availableGroupedRecordings = Object.keys(groupedRecordings).reduce(
    //   (acc: any, date: string) => {
    //     const recordings = groupedRecordings[date].filter(
    //       (item: any) => !item.isRequested,
    //     );
    //     if (recordings.length > 0) {
    //       return [
    //         ...acc,
    //         {
    //           date,
    //           recordings,
    //         },
    //       ];
    //     }
    //     return acc;
    //   },
    //   [],
    // );

    // // Sort requested recordings by date, and inner recordings by time
    // availableGroupedRecordings.forEach((group: any) => {
    //   const sortedRecordings = group.recordings.sort((a: any, b: any) => {
    //     const dateString = dayjs().format('YYYY-MM-DD');
    //     const aDateTimeString = `${dateString} ${a.time}`;
    //     const aDayjs = dayjs(aDateTimeString, 'YYYY-MM-DD HH:mm:ss');
    //     const bDateTimeString = `${dateString} ${b.time}`;
    //     const bDayjs = dayjs(bDateTimeString, 'HH:mm:ss');
    //     return aDayjs.isBefore(bDayjs) ? -1 : 1;
    //   });
    //   return {
    //     ...group,
    //     recordings: sortedRecordings,
    //   };
    // });

    setRequestedRecordings(requestedGroupedRecordings);
    setIsCraneConnected(videRecordingsData.crane.isConnected);
  }, [videRecordingsData, videoRecordingsError]);

  const onVideoRecordingRequestSuccess = () => {
    setOpenAddCraneMedia(false);
    videoRecordingsRefetch();
  };

  const extractCameraType = (path: string) => {
    // const parts = path.split('\\');
    // const type = parts[parts.length - 2];
    // return type.charAt(0).toUpperCase() + type.slice(1);
    return 'Trolley';
  };

  const extractVideoLength = (path: string) => {
    const dateStr = path.match(/(\d{4}-\d{2}-\d{2}-\d{2}h\d{2}m\d{2}s)/)?.[0];
    if (dateStr) {
      const time = dateStr
        .split('-')
        .slice(3)
        .join('-')
        .replace(/h|m/g, ':')
        .replace('s', '');
      const date = dateStr.split('-').slice(0, 3).join('-');
      const dateDayjs = dayjs(date);

      return { date: dateDayjs, time };
    }
    return { date: '', time: '' };
  };

  return (
    <Grid container flex={1}>
      <ContentStack
        paperFlexDirection="column"
        style={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {openAddCraneMedia && (
          <AddVideoRecordingsModal
            onClose={() => setOpenAddCraneMedia(false)}
            onSuccess={onVideoRecordingRequestSuccess}
            extractCameraType={extractCameraType}
            extractVideoLength={extractVideoLength}
          />
        )}

        {videoRecordingsIsLoading ? (
          <Loading />
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => setOpenAddCraneMedia(true)}
              endIcon={<AddIcon />}
              sx={{
                width: sm ? '100%' : '200px',
                paddingTop: '18px',
                paddingBottom: '18px',
              }}
            >
              Add recordings
            </Button>

            {requestedRecordings.length === 0 ? (
              <Typography
                variant="h2Medium"
                color="#878a99"
                height={'100%'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {'No data to display'}
              </Typography>
            ) : (
              <Box sx={{ paddingTop: '20px' }}>
                <Recordings recordings={requestedRecordings} />
              </Box>
            )}
          </>
        )}
      </ContentStack>
    </Grid>
  );
};

export default Media;
