import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskInHours, TasksInHours } from './tasksinhours';
const initialState: TasksInHours = {
  tasksinhours: [
    {
      minutesload: 0,
      hournum: '',
      taskscnt: 0,
      minutestran: 0,
      minutesidle: 0,
    },
  ],
};

const tasksInHoursRptSlice = createSlice({
  name: 'tasksinhoursrpt',
  initialState,
  reducers: {
    setTasksInHoursRpt(state, action: PayloadAction<TaskInHours[]>) {
      state.tasksinhours = action.payload;
      // console.log (state.tasks);
    },
    resetTasksInHoursRpt: () => initialState,
  },
});

export const tasksInHoursRptActions = tasksInHoursRptSlice.actions;

export default tasksInHoursRptSlice.reducer;
