import React, { useEffect, useState } from 'react';
import * as cordovaHelper from '../../../../utils/cordovaHelper';
import { Paper } from '@mui/material';
import {
  LogisticsPictureAndBarcode,
  LogisticsInventory,
  LogisticsLocations,
  LogisticsSubmittedContent,
} from './components';
import { ErrorState } from '../../../../components/alerts';

const LogisticsForm = (props: any) => {
  const {
    submitStep,
    activeStep,
    siteCenter,
    setSiteCenter,
    source,
    setSource,
    destination,
    setDestination,
    setActiveStep,
    stepsLength,
    setSelectedProject,
    setSelectedCrane,
    imageData,
    setImageData,
    barcode,
    setBarcode,
    cargoTypeId,
    setCargoTypeId,
    quantity,
    setQuantity,
    selectedProject,
    selectedCrane,
    positionCoords,
    setPositionCoords,
    fetchLocationIsLoading,
    setFetchLocationIsLoading,
    setXyUserLocation,
    setXyDestinationLocation,
  } = props;

  const [actualAccuracy, setActualAccuracy] = useState<any>(null);

  // initialize and destroy watchPosition
  useEffect(() => {
    const watchId = cordovaHelper.watchGpsPosition(
      (position) => {
        const { accuracy, latitude, longitude, altitude = 0 } = position.coords;
        setActualAccuracy(accuracy);
        setPositionCoords({
          accuracy,
          latitude,
          longitude,
          altitude,
        });
        setFetchLocationIsLoading(false);
      },
      (error) => {
        console.log(error);
      },
    );
    return () => {
      cordovaHelper.clearGpsWatch(watchId);
    };
  }, []);

  const getFormContent = (activeStep: any) => {
    switch (activeStep) {
      case 0: {
        return (
          <LogisticsInventory
            {...{
              cargoTypeId,
              setCargoTypeId,
              quantity,
              setQuantity,
              setSelectedProject,
              setSelectedCrane,
            }}
          />
        );
      }
      case 1: {
        return (
          <LogisticsPictureAndBarcode
            {...{
              imageData,
              setImageData,
              barcode,
              setBarcode,
            }}
          />
        );
      }
      case 2:
      case 3: {
        return (
          <LogisticsLocations
            {...{
              submitStep,
              activeStep,
              siteCenter,
              setSiteCenter,
              source,
              setSource,
              destination,
              setDestination,
              setActiveStep,
              selectedProject,
              selectedCrane,
              positionCoords,
              actualAccuracy,
              setXyUserLocation,
              setXyDestinationLocation,
              fetchLocationIsLoading,
            }}
          />
        );
      }

      case stepsLength: {
        return <LogisticsSubmittedContent />;
      }
      default: {
        return <ErrorState error={{ code: 404, message: 'Not found' }} />;
      }
    }
  };

  const FormContent = getFormContent(activeStep);

  return (
    <Paper
      sx={{
        padding: 4,
        backgroundColor: 'common.white',
        width: '100%',
        flex: 1,
        overflowY: 'auto',
      }}
      elevation={1}
    >
      {FormContent}
    </Paper>
  );
};

export default LogisticsForm;
