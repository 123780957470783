import React from 'react';
import { Stack, StackProps, Typography } from "@mui/material";

export const FormControl = ({
  label,
  required,
  instructions,
  children,
}: any) => {
  return (
    <Stack spacing={2} width="100%">
      <Stack>
        <Typography variant="smallRegular">
          {required ? `${label}*` : label}
        </Typography>
      </Stack>
      <Stack spacing={2} flex={1}>
        {children}
        {instructions && (
          <Typography variant="smallRegular" sx={{ color: 'grey.500' }}>
            {instructions}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const FormGroup = ({ title, children, ...rest }: StackProps) => {
  return (
    <Stack spacing={8} width="100%" {...rest}>
      <Typography sx={{ color: 'secondary.main', padding: '4px 0', borderBottom: 'solid 1px ' }} variant="h2Medium">{title}</Typography>
      {children}
    </Stack>
  );
};
