import { useDispatch } from 'react-redux';
import { telemetriesActions } from '../store/telemetries';
import { telemetriesRptActions } from '../store/telemetriesrpt';
import { useHttp } from './useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { TELEMETRIES_PATHS } from '../variables/endpoints';

const useTelemetries = () => {
  const dispatch = useDispatch();
  const { api } = useHttp();
  const setHandlerTelemetries = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TELEMETRIES_PATHS.getTelemetries(dateProp, craneId.toString()),
      });
      dispatch(telemetriesActions.setTelemetries(data));
    } catch (err) {}
  };
  const setHandlerTelemetriesRpt = async (
    dateProp: string,
    craneId: number
  ) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TELEMETRIES_PATHS.getTelemetries(dateProp, craneId.toString()),
      });
      dispatch(telemetriesRptActions.setTelemetriesRpt(data));
    } catch (err) {}
  };
  return { setHandlerTelemetries, setHandlerTelemetriesRpt };
};

export default useTelemetries;
