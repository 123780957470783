import React, { useEffect, useMemo, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  TextField,
  CircularProgress,
} from '@mui/material';
import { ErrorAlert } from '../../../../../../../../components/alerts';
import { FormControl } from '../../../../../../../../components/inputs';
import { REGEX_VALIDATION } from '../../../../../../../../variables/constants';
import validate, { validators } from '../../../../../../../../utils/validate';
import { usePutZone } from '../../../../../../../../queries';
import { useQueryClient } from 'react-query';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { projectCraneActions } from '../../../../../../../../store/projectcrane';

const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const EditZone = ({ siteId, onClose, onSuccess, item }: any) => {
  const [state, dispatch] = useReducer(reducer, item || {});
  const dispatchStore = useAppDispatch();
  const zones = useAppSelector((state: any) => state.projectCrane.Zones);

  const validationResults = useMemo(() => {
    const definitions = {
      name: [() => validators.isRequired(state.name.toString())],
      height: [() => validators.isRequired(state.height.toString())],
    };
    return validate(state, definitions);
  }, [state]);

  const valid = validationResults.valid;

  const queryClient = useQueryClient();

  const {
    mutateAsync: updateZone,
    data: usePutZoneData,
    error: usePutZoneError,
    isLoading: usePutZoneIsLoading,
  } = usePutZone({
    onSettled: () => {
      queryClient.invalidateQueries('PutZone');
    },
  });

  const onSubmit = async () => {
    if (!valid) return;
    const { id: zoneId, name, height } = state;
    const parsedHeight = Number(height);
    await updateZone({ siteId, zoneId, name, height: parsedHeight });
  };

  useEffect(() => {
    if (usePutZoneData) {
      const updatedZones = zones.map((zone: any) => {
        if (zone.id === state.id) {
          return {
            ...zone,
            name: state.name,
            height: state.height,
          };
        }
        return zone;
      });
      dispatchStore(projectCraneActions.setZones(updatedZones));
      onSuccess();
    }
  }, [usePutZoneData, onSuccess]);

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Update Zone</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Stack spacing={4}>
            <FormControl label="Name" required>
              <TextField
                fullWidth
                value={state.name}
                {...validationResults.results['name']}
                onChange={(e) => {
                  dispatch({
                    type: 'name',
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl label="height" required>
              <TextField
                required
                fullWidth
                value={state.height}
                {...validationResults.results['height']}
                onChange={(e) => {
                  if (REGEX_VALIDATION.decimalPattern.test(e.target.value)) {
                    dispatch({
                      type: 'height',
                      payload: e.target.value,
                    });
                  }
                }}
              />
            </FormControl>
          </Stack>
        </Stack>
        {usePutZoneError && <ErrorAlert error={usePutZoneError} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!valid || usePutZoneIsLoading}
        >
          {usePutZoneIsLoading ? <CircularProgress size={16} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditZone;
