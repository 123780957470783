import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { flow, isEqual, reduce } from 'lodash';
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteUser,
  usePostSubscribeDeviceToTopics,
  usePutUser,
  usePutUserNotifications,
} from '../../queries';
import { convertToTopicNotification } from '../../utils/helpers';
import * as storageHelper from '../../utils/storageHelper';
import { FCM_TOKEN, USER } from '../../variables/constants';
import { MobileNotifications } from './components/MobileNotifications';

const reducer = (state: any, action: any) => {
  if (action.type === 'set_initial_state') return action.payload;
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const NotificationSettings = (props: any) => {
  const { open, onClose } = props;
  const user = storageHelper.getItem(USER);
  // const user = useAppSelector((state) => state.user.user);
  const [notificationsData, setNotificationsData] = useState<any>([]);

  useEffect(() => {
    if (!user) {
      onClose();
      // navigate('/login');
    }

    const notifications = user.notifications.map((notification: any) => {
      return {
        name: convertToTopicNotification(
          notification.companyId,
          notification.siteId,
          notification.craneId,
          notification.type,
        ),
        value: notification.isActive,
      };
    });
    reduce(
      notifications,
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {},
    );

    setNotificationsData(user.notifications);
    setInitialUserNotification(notifications);
    setReceiveMobileNotifications(user.canReceiveMobileNotifications);
    dispatchUserNotifications({
      type: 'set_initial_state',
      payload: notifications,
    });
  }, []);

  const { id: userId } = user;

  const fcmToken = storageHelper.getItem(FCM_TOKEN);

  const [userNotifications, dispatchUserNotifications] = useReducer(
    reducer,
    {},
  );
  const [initialUserNotification, setInitialUserNotification] = useState({});

  const [receiveMobileNotifications, setReceiveMobileNotifications] =
    useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState<any>(false);
  const [deleteUserRequest, setDeleteUserRequest] = useState<any>(false);
  const navigate = useNavigate();

  const {
    mutateAsync: deleteUser,
    error: deleteError,
    data: deleteData,
    isLoading: deleteLoading,
    reset: resetDelete,
  } = useDeleteUser();

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setAlertSuccessMessage(null);
  };

  const onSubmit = async () => {
    const body = {
      receiveMobileNotifications: receiveMobileNotifications,
      notifications: receiveMobileNotifications ? notificationsData : [],
    };

    await putUserNotificationsMutate({
      id: user.id,
      ...body,
    });

    // await putUserNotificationsMutate({ userId, userNotifications });

    storageHelper.setItem(USER, {
      ...user,
      notifications: notificationsData,
      canReceiveMobileNotifications: receiveMobileNotifications,
    });

    await onClose();
  };

  const onChangeReceiveMobileNotifications = () => {
    setReceiveMobileNotifications(!receiveMobileNotifications);
  };

  useLayoutEffect(() => {
    if (open) {
      // getUserRefetch();
      // userNotificationsRefetch();
      // userProjectsRefetch();
    }
  }, [open]);

  const onDeleteConfirmed = async () => {
    await deleteUser({ ID: userId });
  };

  //#region react-query
  const queryClient = useQueryClient();

  // const {
  //   data: getUserData,
  //   isFetching: getUserIsFetching,
  //   refetch: getUserRefetch,
  //   error: getUserError,
  // } = useGetUser(
  //   { userId },
  //   {
  //     enabled: open,
  //     cacheTime: 0,
  //     onSuccess: (data: any) => {
  //       setReceiveMobileNotifications(!!data?.data?.ReceiveMobileNotifications);
  //     },
  //   },
  // );

  // const {
  //   error: userNotificationsError,
  //   refetch: userNotificationsRefetch,
  //   isFetching: userNotificationsIsFetching,
  // } = useGetUserNotifications(
  //   {
  //     userId,
  //   },
  //   {
  //     enabled: open,
  //     onSuccess: (data: any) => {
  //       const notifications = !Array.isArray(data?.data)
  //         ? []
  //         : reduce(
  //             data.data.map((notification: any) => {
  //               return {
  //                 name: convertToTopicNotification(
  //                   notification.company_id,
  //                   notification.project_id,
  //                   notification.crane_id,
  //                   notification.notification_type,
  //                 ),
  //                 value: notification.active,
  //               };
  //             }),
  //             (acc, { name, value }) => ({ ...acc, [name]: value }),
  //             {},
  //           );
  //       setInitialUserNotification(notifications);

  //       dispatchUserNotifications({
  //         type: 'set_initial_state',
  //         payload: notifications,
  //       });
  //     },
  //   },
  // );

  // const {
  //   data: userProjectsData,
  //   error: userProjectsError,
  //   refetch: userProjectsRefetch,
  //   isFetching: userProjectsIsFetching,
  // } = useGetUserProjects(
  //   {
  //     userId,
  //   },
  //   {
  //     enabled: open,
  //   },
  // );

  const {
    mutateAsync: updateUser,
    error: updateUserError,
    isLoading: updateUserIsLoading,
  } = usePutUser({
    onSettled: () => {
      queryClient.invalidateQueries('PutUser');
    },
  });

  const {
    mutateAsync: subscribeDeviceToTopicsMutateAsync,
    error: subscribeDeviceToTopicsError,
    isLoading: subscribeDeviceToTopicsIsLoading,
  } = usePostSubscribeDeviceToTopics({
    onError: () => {},
  });

  const {
    mutateAsync: putUserNotificationsMutate,
    error: putUserNotificationsError,
    isLoading: putUserNotificationsIsLoading,
  } = usePutUserNotifications({
    onSuccess: (data: any) => {
      setOpenSnackbar(true);
      setAlertSuccessMessage(data.data.message);
      setTimeout(async () => {
        await subscribeDeviceToTopicsMutateAsync({ userId, fcmToken });
      }, 100);
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutUserNotifications');
    },
  });

  //#endregion

  const onlyActiveNotifications = flow([
    Object.entries,
    (arr) => arr.filter(([_key, value]: any) => value === true),
    Object.fromEntries,
  ])(userNotifications);

  const initialReceiveMobileNotifications = user.canReceiveMobileNotifications;
  // !!getUserData?.data?.ReceiveMobileNotifications;

  const isNotificationTabOnInitialState =
    isEqual(onlyActiveNotifications, initialUserNotification) &&
    receiveMobileNotifications === initialReceiveMobileNotifications;

  const mobileNotificationsIsLoading = false;
  //  userNotificationsIsFetching;
  // || userProjectsIsFetching;

  const dialogContentIsLoading = false; //getUserIsFetching;

  const isSubmitLoading =
    mobileNotificationsIsLoading ||
    dialogContentIsLoading ||
    updateUserIsLoading ||
    subscribeDeviceToTopicsIsLoading ||
    putUserNotificationsIsLoading;

  const mobileNotificationsError = false;
  // userNotificationsError;
  // || userProjectsError;

  const snackbarError = (
    (putUserNotificationsError ||
      subscribeDeviceToTopicsError ||
      updateUserError) as Error
  )?.message;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          sx: {
            minHeight: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <DialogTitle>Notification Settings</DialogTitle>
        <DialogContent sx={{ pt: '20px !important' }}>
          {/* {dialogContentIsLoading && !getUserError && <Loading />} */}
          {/* {!dialogContentIsLoading && getUserError && (
            <ErrorState error={getUserError} />
          )} */}
          {!dialogContentIsLoading && (
            // !getUserError && getUserData?.data &&
            <>
              <MobileNotifications
                {...{
                  receiveMobileNotifications,
                  onChangeReceiveMobileNotifications,
                  isLoading: mobileNotificationsIsLoading,
                  error: mobileNotificationsError,
                  // userProjectsData,
                  userNotifications,
                  dispatchUserNotifications,
                  setNotificationsData,
                  notificationsData,
                }}
              />
              {/* <br />
              <br />
              <FormControl label="Request Account Deletion">
                <FormControlLabel
                  control={
                    <Button
                      variant="contained"
                      onClick={() => setDeleteUserRequest(true)}
                    >
                      {isSubmitLoading ? (
                        <CircularProgress size={16} />
                      ) : (
                        'Delete'
                      )}
                    </Button>
                  }
                  label=""
                />
              </FormControl> */}
            </>
          )}
        </DialogContent>
        {/* {!!deleteUserRequest && (
          <ConfirmDialog
            title="Delete Account"
            text="Are you sure you want to delete your account? This action is irreversible."
            okText="Delete"
            onOk={onDeleteConfirmed}
            onCancel={() => setDeleteUserRequest(false)}
            onDeleteSuccess={() => navigate('/logout')}
            asyncApi={{
              loading: deleteLoading,
              error: deleteError,
              data: deleteData,
            }}
          />
        )} */}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={isNotificationTabOnInitialState || isSubmitLoading}
            onClick={onSubmit}
          >
            {isSubmitLoading ? <CircularProgress size={16} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar && (!!alertSuccessMessage || !!snackbarError)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...(!!snackbarError ? { message: snackbarError } : {})}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {alertSuccessMessage && (
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={closeSnackbar}
            severity="success"
            sx={{ width: '100%' }}
          >
            {alertSuccessMessage}
          </MuiAlert>
        )}
      </Snackbar>
    </>
  );
};

export default NotificationSettings;
