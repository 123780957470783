import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => {
  return apiDeleteAsync(`task/${props.id}`);
};

export const useDeleteCranebitTask = (options: any) => {
  const key = ['DeleteCraneTask'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
