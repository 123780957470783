import { useQuery } from 'react-query';
import { apiGetAsync, apiPostAsync } from '../api/axios';

const api = (props: any) => {
  const { selectedCrane: craneId, ...restProps } = props;
  return apiGetAsync(
    `crane/${craneId}/images-timeline?${new URLSearchParams(
      restProps,
    ).toString()}`,
  );
};

export const useGetTimelineImages = (
  props: any,
  options: { enabled: boolean },
) => {
  const key = [
    'GetTimelineImages',
    props?.startDate,
    props?.selectedSite,
    props?.selectedCrane,
    props?.sensor,
    props?.continuationToken,
  ];
  const fn = () => api(props);

  return useQuery(key, fn, {
    ...options,
    staleTime: Infinity,
  });
};
