import { TypographyOptions } from '@mui/material/styles/createTypography';

export const fontFamily = `'Noto Sans','Noto Sans Hebrew'`;

const typography: TypographyOptions = {
  fontFamily,
  fontSize: 14,
  h1Medium: {
    fontFamily,
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '130%',
  },
  h1SemiBold: {
    fontFamily,
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '130%',
  },
  h2Regular: {
    fontFamily,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
  },
  h2Medium: {
    fontFamily,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '150%',
  },
  mediumRegular: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '150%',
  },
  mediumMedium: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '150%',
  },
  mediumLight: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '150%',
  },
  smallRegular: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '130%',
  },
  smallMedium: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '130%',
  },
  paragraphRegular: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '190%',
  },
  paragraphMedium: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '190%',
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  button: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '400',
  },
  caption: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '400',
  },
  overline: undefined,
};

export default typography;
