import React, { useState } from 'react';
import { CreateSiteDialog, SitesGrid } from './components';
import { Stack } from '@mui/system';
import { GridToolbar, LayoutStack } from '../../../components/styled';
import { Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ConfirmDialog } from '../../../components/confirm';
import { useQueryClient } from 'react-query';
import { useDeleteProject } from '../../../queries';

const Sites = (props: any) => {
  const { sites, isLoading, isRefetching, handleRefetch, companyId } = props;

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>();

  const queryClient = useQueryClient();
  const {
    mutate: deleteProjectMutate,
    isLoading: deleteProjectIsLoading,
    error: deleteProjectError,
    data: deleteProjectData,
    reset: deleteProjectReset,
  } = useDeleteProject({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteProject');
    },
  });

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    await handleRefetch();
    setCreateOpen(false);
  };

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = () => deleteProjectMutate({ siteId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteProjectReset();
    onDelete();
    setTimeout(async () => await handleRefetch(), 100);
  };

  const onDeleteClose = () => {
    deleteProjectReset();
    onDelete();
  };

  return (
    <LayoutStack>
      <GridToolbar>
        <Stack>
          <Button
            variant="contained"
            onClick={() => onCreate(true)}
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <Button
            variant="outlined"
            disabled={isRefetching}
            onClick={() => handleRefetch()}
          >
            {isRefetching ? <CircularProgress size={16} /> : <RefreshIcon />}
          </Button>
        </Stack>
      </GridToolbar>
      <SitesGrid
        {...{
          sites,
          isLoading: isLoading || isRefetching,
          onDelete,
          companyId,
        }}
      />

      {createOpen && (
        <CreateSiteDialog
          onClose={() => onCreate(false)}
          onSuccess={onCreateSuccess}
          companyId={companyId}
        />
      )}

      {!!deleteItemId && (
        <ConfirmDialog
          title="Delete Site"
          text="Are you sure you want to delete this site?"
          onOk={onDeleteConfirmed}
          onCancel={onDeleteClose}
          onDeleteSuccess={onDeleteSuccess}
          asyncApi={{
            loading: deleteProjectIsLoading,
            error: deleteProjectError,
            data: deleteProjectData,
          }}
        />
      )}
    </LayoutStack>
  );
};

export default Sites;
