// @ts-nocheck
import { Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { max, times } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { Loading } from '../../../../styled';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
  CraneHeader,
} from '../../content/styled';
import { getDateSelected } from '../utils';
dayjs.extend(utc);
dayjs.extend(timezone);

const WindSpeedChart = ({
  data: { crane, windSpeedLimit = 45 },
  height = 180,
  width = 726,
  onDashboard,
  selectedTimeRange,
  setSelectedTimeRange,
  isLoadingTelemetries,
  telemetries,
  isSelectedDateToday,
  setIsLoadingTelemetries,
  setRefreshTelemeries,
  isTelemetryRefreshedFromWindTab,
  setIsTelemetryRefreshedFromWindTab,
}: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const [title, setTitle] = useState(
    onDashboard ? 'Safety - Wind Data' : `${crane.name} - Safety - Wind Data`,
  );
  const [windSpeedData, setWindSpeedData] = useState([]);
  const [options, setOptions] = useState({} as Highcharts.Options);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [currentCrane, setCurrentCrane] = useState('');

  const windData = useMemo(() => {
    return windSpeedData.map(({ WindSpeedKmH }) => WindSpeedKmH);
  }, [windSpeedData]);

  const maxWindData = useMemo(() => {
    return max(windData);
  }, [windData]);

  const maxValue = useMemo(() => {
    return (maxWindData < 30 ? 30 : maxWindData) + 20;
  }, [maxWindData]);

  const yAxisLabels = useMemo(() => {
    return [
      ...times(3).map((index) =>
        Number(Number((maxValue / 3) * index).toFixed(0)),
      ),
      Number(Number(maxValue).toFixed(0)),
    ];
  }, [maxValue]);

  const timezone =
    useAppSelector((state) => state.projectCrane.Timezone) || 'Asia/Jerusalem';

  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );

  useEffect(() => {
    if (
      isLoadingTelemetries ||
      (selectedCrane && selectedCrane !== currentCrane)
    ) {
      setIsLoadingSpinner(true);
      setCurrentCrane(selectedCrane);
    }
  }, [selectedCrane, isLoadingTelemetries]);

  useEffect(() => {
    if (isTelemetryRefreshedFromWindTab)
      setIsTelemetryRefreshedFromWindTab(false);
    // console.log('telemetries', telemetries);
    if (telemetries.length === 0) {
      if (!currentCrane) return;
      setWindSpeedData([]);
      setIsLoadingSpinner(false);
      return;
    }
    setWindSpeedData(
      telemetries.map((item) => {
        const milliseconds =
          item.meta.timestamp.seconds * 1000 +
          item.meta.timestamp.nanos / 1000000;
        const sampleTime = dayjs(milliseconds);
        // console.log('sampleTime', sampleTime.format('HH:mm:ss'));
        // console.log('weight', item.control.cargo_weight_kg);

        const windSpeed = item.control.wind_speed_kmh;
        return {
          WindSpeedKmH: windSpeed,
          DateSampling: sampleTime.format('YYYY-MM-DD'),
          TimeSampling: sampleTime.format('HH:mm:ss'),
          HighchartsData: [
            sampleTime.toDate().valueOf(), // Convert ISO 8601 timestamp to milliseconds
            windSpeed, // Wind speed value
          ],
        };
      }),
    );
    setIsLoadingSpinner(false);
  }, [telemetries]);

  useEffect(() => {
    if (windSpeedData.length === 0) return;

    const objWidth = 280;
    const rndNumber = Math.floor(Math.random() * 744) + 700;
    const dateTlmStart =
      windSpeedData.length > 0
        ? getDateSelected(
            new Date(
              windSpeedData[0].DateSampling +
                ' ' +
                windSpeedData[0].TimeSampling,
            ),
          )
        : null;

    // Add padding (e.g., 1 hour in milliseconds)
    const newOptions: Highcharts.Options = {
      title: {
        text: '',
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0.01,
        type: 'datetime',
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Wind speed (Km/h)',
          style: {
            fontSize: '14px',
          },
        },
        max: maxValue,
        tickPositioner: function () {
          return yAxisLabels;
        },
        plotLines: [
          {
            value: windSpeedLimit,
            width: 2,
            color: '#ff0000',
            label: {
              text: `Alert Limit: ${windSpeedLimit} Km/h`,
              align: 'right',
              x: -5,
              style: {
                color: '#ff0000',
                fontWeight: 'bold',
                fontSize: '14px',
              },
            },
          },
        ],
      },
      tooltip: {
        formatter: function () {
          const localTime = dayjs(this.x)
            .tz(timezone)
            .format('dddd, MMMM D, HH:mm:ss');
          return `<b>${this.y.toFixed(1)} Km/h</b><br/>${localTime}`;
        },
        style: {
          fontSize: '14px',
        },
        valueDecimals: 1,
      },
      exporting: { enabled: false },
      chart: {
        spacing: [4, 0, 0, 3],
        backgroundColor: '#ffffff',
        animation: false,
        type: 'spline',
        height,
        width: onDashboard ? undefined : width,
        scrollablePlotArea: {
          minWidth: objWidth,
          scrollPositionX: 1,
        },
      },
      time: {
        timezone: timezone,
      },
      plotOptions: {
        series: {
          pointStart: Date.UTC(
            Number(dateTlmStart.substring(0, 4)),
            Number(dateTlmStart.substring(4, 6)) - 1,
            Number(dateTlmStart.substring(6, 8)),
            Number(dateTlmStart.substring(8, 10)),
            Number(dateTlmStart.substring(10, 12)),
            0,
          ),
          pointInterval: (60000 * rndNumber) / (rndNumber - 1),
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          color: '#000000',
          type: 'spline',
          name: '',
          data: windSpeedData.map((item) => item.HighchartsData),
        },
      ],
    };

    setOptions(newOptions);
  }, [windSpeedData, yAxisLabels, maxValue, windSpeedLimit]);

  return (
    <ChartStack height={onDashboard ? 300 : 395} onDashboard={onDashboard}>
      <ChartStackHeader onDashboard={onDashboard}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography v sx={{ fontSize: '24px' }}>
            {title}
          </Typography>
          {/* <Stack
              sx={{
                padding: 2,
                backgroundColor: 'backgrounds.system',
                visibility:
                  windSpeedData.length > 0 && isSelectedDateToday
                    ? 'visible'
                    : 'hidden',
              }}
            >
              {windSpeedData.length > 0 && isSelectedDateToday ? (
                <Typography
                  variant="smallMedium"
                  sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}
                  style={{ fontSize: sm ? 14 : 24 }}
                >
                  {`${windSpeedData[
                    windSpeedData.length - 1
                  ].WindSpeedKmH.toFixed(1)} (Km/h)`}
                </Typography>
              ) : (
                <Typography
                  variant="smallMedium"
                  sx={{
                    padding: 2,
                    backgroundColor: 'backgrounds.system',
                    visibility: 'hidden',
                  }}
                  style={{ fontSize: 24 }}
                >
                  Placeholder
                </Typography>
              )}
            </Stack> */}
        </div>

        {isSelectedDateToday && (
          <Tabs
            value={selectedTimeRange}
            onChange={(e, index) => {
              setSelectedTimeRange(index);
              setWindSpeedData([]);
              setIsLoadingTelemetries(true);
              setRefreshTelemeries(true);
              setIsTelemetryRefreshedFromWindTab(true);
            }}
            sx={{
              // This targets all Tab components inside Tabs
              '& .MuiTab-root': {
                minWidth: sm ? '70px' : '100px',
                width: sm ? '70px' : '100px',
              },
            }}
          >
            <Tab label="15min" />
            <Tab label="1h" />
            <Tab label="3h" />
            <Tab label="1d" />
          </Tabs>
        )}
        {!isLoadingSpinner && (
          <>
            {!onDashboard && (
              <Stack sx={{ padding: 2, backgroundColor: 'backgrounds.system' }}>
                <CraneHeader {...crane} />
              </Stack>
            )}
          </>
        )}
      </ChartStackHeader>
      {isLoadingSpinner ? (
        <Loading />
      ) : (
        <>
          {telemetries.length === 0 || windSpeedData.length === 0 ? (
            <ChartStackContent
              sx={{
                padding: 4,
                width: '100%',
                height: height + 32,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              spacing={4}
            >
              <Typography variant="h2Medium" color="#878a99">
                {'No data to display'}
              </Typography>
            </ChartStackContent>
          ) : (
            <ChartStackContent onDashboard={onDashboard} sx={{ paddingTop: 4 }}>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </ChartStackContent>
          )}
        </>
      )}
    </ChartStack>
  );
};

export default WindSpeedChart;
