import React from 'react';
import { styled, Tab as MuiTab } from '@mui/material';

export const TabPanel = ({ children, value, index }: any) =>
  value === index ? children : <span />;

export const Tab = styled(MuiTab)`
  &.MuiButtonBase-root {
    font-weight: bold;
    @media screen and (max-width: 600px) {
      font-size: 12px;
      min-width: 70px;
      padding: 8px 6px;
    }
  }
`;
