import moment from 'moment/moment';
import { formatTotalSecondsToDateFormat } from '../../../../utils';

export const secondsToMinutes = (
  seconds: any,
  returnRaw = false,
  format = 'HH:mm:ss',
) => {
  if (returnRaw) return moment.utc(seconds * 1000).format('HH:mm:ss');
  return seconds ? moment.utc(seconds * 1000).format(format) : 0;
};

// Sum durations and convert to minutes
export const handleDurations = (cargoType: any, records: any) => {
  return (
    records.map(
      (record: any) =>
        record.durations?.reduce(
          (acc: number, curr: any) =>
            curr.cargoType === cargoType ? acc + curr.duration : acc,
          0,
        ) / 60,
    ) || 0
  );
};

export const tooltipContentFormatter = (s: any, point: any) => {
  if (point.y > 0) {
    return (
      s +
      '<span style="color:' +
      point.series.color +
      '">' +
      point.series.name +
      '</span>: <b>' +
      formatTotalSecondsToDateFormat(point.y * 60, false) +
      ' (minutes)</b> <br/>'
    );
  } else {
    return s;
  }
};

const fixDigit = (val: String) => {
  return val.toString().length === 1 ? '0' + val : val;
};

export const getDateSelected = (val: Date) => {
  //@ts-ignore
  let sMonth = fixDigit((val.getMonth() + 1).toString());
  //@ts-ignore
  let sDay = fixDigit(val.getDate());
  //@ts-ignore
  return (
    //@ts-ignore
    val.getFullYear() +
    sMonth +
    sDay +
    //@ts-ignore
    fixDigit(val.getHours()) +
    //@ts-ignore
    fixDigit(val.getMinutes())
  );
};

export const WEEKDAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
