import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import './fonts.scss';

const baseTheme: ThemeOptions = createTheme({
  typography,
  spacing: 4,
  palette,
});

const theme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    ...overrides(baseTheme),
  },
});

export default theme;
