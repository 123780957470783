import { chunk } from 'lodash';
import moment from 'moment';
import { UserNotificationTypes } from '../variables/enums';

const splitDataInChunks = (
  data: any[],
  threshold: number = 4,
  firstSliceSize: number,
  chunkSize: number,
) => {
  const clonedData = [...data];

  if (clonedData.length < threshold) {
    return [clonedData];
  }

  const firstSlice = clonedData.slice(0, firstSliceSize);
  const rest = chunk(clonedData.slice(firstSliceSize), chunkSize);
  return [firstSlice, ...rest];
};

const initials = (text: string, charsLimit = 3) => {
  if (!text) {
    return '';
  }

  if (text.split(' ').length === 1) {
    return text.substr(0, charsLimit);
  }

  return text
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .substr(0, charsLimit);
};

const convertDateToAppDate = (date: any) => {
  return moment(date).format('yyyy-MM-DD');
};

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const convertToTopicNotification = (
  companyId: string | number,
  siteId: string | number,
  craneId: string | number,
  type: UserNotificationTypes,
) => `${companyId}_${siteId}_${craneId}_${type}`;

export {
  splitDataInChunks,
  initials,
  convertDateToAppDate,
  toBase64,
  convertToTopicNotification,
};
