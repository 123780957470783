import {
  ActionsRenderer,
  CheckboxRenderer,
} from '../../../../../../../../components/grid';

export default (
  ROW_HEIGHT: string | number,
  onEdit?: any,
  onDelete?: any,
  onClickActive?: any,
) => {
  return [
    // {
    //   headerName: 'ID',
    //   field: 'id',
    //   sortable: true,
    //   sort: 'asc',
    //   minWidth: 300,
    //   maxWidth: 300,
    // },
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: 'Type',
      field: 'type',
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: 'Height',
      field: 'height',
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    // {
    //   headerName: 'Active',
    //   field: 'isActive',
    //   sortable: true,
    //   cellRendererFramework: CheckboxRenderer,
    //   cellRendererParams: {
    //     onClick: onClickActive,
    //   },
    //   minWidth: 80,
    //   maxWidth: 80,
    // },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'id',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onEdit,
        onDelete,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
