import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalcSumTask, CalcSumTasks } from './calcsumtask';

const initialState: CalcSumTasks = {
  calcsumtask: [
    {
      name: '',
      y: 0,
    },
  ],
};

const calcSumTaskRptSlice = createSlice({
  name: 'calcsumtaskrpt',
  initialState: initialState,
  reducers: {
    setCalcSumTaskRpt(state, action: PayloadAction<CalcSumTask[]>) {
      state.calcsumtask = action.payload;
      // console.log (state.calctask);
    },
    resetCalcSumTaskRpt: () => initialState,
  },
});

export const calcSumTaskRptActions = calcSumTaskRptSlice.actions;

export default calcSumTaskRptSlice.reducer;
