import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const appBarStyleOverrides: Components['MuiTableCell'] = {
    styleOverrides: {
      root: {
        ...theme.typography.paragraphRegular,
        backgroundColor: theme.palette.backgrounds.white,
      },
    },
  };
  return appBarStyleOverrides;
};
