import React from 'react';
import validator from 'validator';

export type FieldState = {
  error: boolean;
  helperText?: React.ReactNode;
};

export type ValidationResults = {
  valid: boolean;
  results: Record<string, FieldState>;
};

export const initialFieldState: FieldState = { helperText: '', error: false };

const isRequired = (value: any, helperText?: string): FieldState => {
  const error = validator.isEmpty(value || '');
  return {
    error,
    helperText: error ? helperText || 'Value is required' : '',
  };
};

const isEmail = (value: any, helperText?: string): FieldState => {
  const error = !validator.isEmail(value || '');
  return {
    error,
    helperText: error ? helperText || 'Email is invalid' : '',
  };
};

const isEqual = (value1: any, value2: any, helperText?: string): FieldState => {
  const error = !validator.equals(value1 || '', value2 || '');
  return {
    error,
    helperText: error ? helperText || 'Values are not equal' : '',
  };
};

const isInt = (value: any, helperText?: string): FieldState => {
  const error = !validator.isInt(value);
  return {
    error,
    helperText: error ? helperText || 'Value is invalid' : '',
  };
};

const hasLength = (value: number, helperText?: string): FieldState => {
  const error = value <= 0;
  return {
    error,
    helperText: error ? helperText || 'Value must be grater than 0' : '',
  };
};

const hasStrength = (
  value: number,
  threshold: number,
  helperText?: string,
): FieldState => {
  const error = value < threshold;
  return {
    error,
    helperText: error
      ? helperText ||
        `Password strength score must be grater than ${threshold} `
      : '',
  };
};

export const validators = {
  isRequired,
  isEmail,
  isEqual,
  isInt,
  hasLength,
  hasStrength,
};

const validate = (item: any, definitions: any): ValidationResults => {
  const results = Object.keys(definitions).reduce((acc: any, key: string) => {
    const errors = definitions[key]
      .map((validator: any) => validator())
      .filter(({ error }: FieldState) => error);
    acc[key] = errors.length ? errors[0] : initialFieldState;
    return acc;
  }, {});
  return {
    results,
    valid: !Object.keys(results).some((key: string) => results[key].error),
  };
};

export default validate;
