export default () => {
  return [
    {
      headerName: 'Lifts',
      field: 'lifts',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: 'Concrete Weight [Tons]',
      field: 'concreteWeight',
      minWidth: 200,
      maxWidth: 200,
      valueGetter: ({ data: { concreteWeight } }: any) => {
        return concreteWeight ? +(concreteWeight * 0.001).toFixed(2) : '-';
      },
    },
    {
      headerName: 'Duration [Minutes]',
      field: 'duration',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Start time',
      field: 'startTime',
      minWidth: 120,
      maxWidth: 120,
    },
  ];
};
