import { ActionsRenderer } from '../../../../../components/grid';
import { displayUserRole } from '../../../../../utils';

export default (ROW_HEIGHT: string | number, onDelete?: any) => {
  return [
    {
      headerName: 'ID',
      field: 'userId',
      sortable: true,
      minWidth: 500,
      maxWidth: 500,
      sort: 'desc',
    },
    {
      headerName: 'First Name',
      field: 'user.firstName',
      sortable: true,
      minWidth: 300,
    },
    {
      headerName: 'Last Name',
      field: 'user.lastName',
      sortable: true,
      minWidth: 300,
    },
    {
      headerName: 'Email',
      field: 'user.email',
      sortable: true,
      minWidth: 400,
    },
    {
      headerName: 'Role',
      field: 'user.role',
      sortable: true,
      cellRendererFramework: (params: any) => {
        return displayUserRole(params.value);
      },
      minWidth: 300,
    },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'userId',
      minWidth: 140,
      maxWidth: 140,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onDelete,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
