import React, { useMemo, useReducer } from 'react';
import {
  Button,
  Stack,
  TextField,
  Box,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  ContentStack,
  LayoutStack,
  Loading,
} from '../../../../../../components/styled';
import {
  useGetCraneBrand,
  useGetCraneModel,
  useGetCraneType,
} from '../../../../../../queries';
import { ErrorState } from '../../../../../../components/alerts';
import { FormControl, FormGroup } from '../../../../../../components/inputs';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';
import validate, { validators } from '../../../../../../utils/validate';
import { REGEX_VALIDATION } from '../../../../../../variables/constants';

const reducer = (state: any, action: any) => {
  if (action.type === 'set_initial_state') return action.payload;
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const CraneParameters = (props: any) => {
  const { craneData, putCraneMutate } = props;

  const {
    ID: craneId,
    BrandId,
    TypeId,
    ModelId,
    latitude,
    longitude,
    xMeter,
    yMeter,
    craneJibLength,
    craneJibWidth,
    craneJibHeight,
    craneJibMinAngle,
    craneJibMaxAngle,
    hookChainLength,
    craneTailLength,
    craneHeight,
    baseHeight,
    minOffsetFromCabin,
    minOffsetFromTrolley,
    minOffsetJibTip,
    Active,
  } = craneData;

  const initialState = {
    BrandId: BrandId?.toString() ?? '',
    TypeId: TypeId?.toString() ?? '',
    ModelId: ModelId?.toString() ?? '',
    latitude: latitude?.toString() ?? '',
    longitude: longitude?.toString() ?? '',
    xMeter: xMeter?.toString() ?? '',
    yMeter: yMeter?.toString() ?? '',
    craneJibLength: craneJibLength?.toString() ?? '',
    craneJibWidth: craneJibWidth?.toString() ?? '',
    craneJibHeight: craneJibHeight?.toString() ?? '',
    craneJibMinAngle: craneJibMinAngle?.toString() ?? '',
    craneJibMaxAngle: craneJibMaxAngle?.toString() ?? '',
    hookChainLength: hookChainLength?.toString() ?? '',
    craneTailLength: craneTailLength?.toString() ?? '',
    craneHeight: craneHeight?.toString() ?? '',
    baseHeight: baseHeight?.toString() ?? '',
    minOffsetFromCabin: minOffsetFromCabin?.toString() ?? '',
    minOffsetFromTrolley: minOffsetFromTrolley?.toString() ?? '',
    minOffsetJibTip: minOffsetJibTip?.toString() ?? '',
    Active,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onClose = () => {
    dispatch({
      type: 'set_initial_state',
      payload: initialState,
    });
  };

  const onSubmit = async () => {
    if (!valid) return;
    const { craneJibMinAngle, craneJibMaxAngle, ...restState } = state;
    await putCraneMutate({
      craneId,
      ...(isLufferType ? { craneJibMinAngle } : { craneJibMinAngle: null }),
      ...(isLufferType ? { craneJibMaxAngle } : { craneJibMaxAngle: null }),
      ...restState,
    });
  };

  const {
    isLoading: craneBrandIsLoading,
    data: craneBrandData,
    isSuccess: craneBrandIsSuccess,
    error: craneBrandError,
  } = useGetCraneBrand();

  const {
    isLoading: craneTypeIsLoading,
    data: craneTypeData,
    isSuccess: craneTypeIsSuccess,
    error: craneTypeError,
  } = useGetCraneType();

  const {
    isLoading: craneModelIsLoading,
    data: craneModelData,
    isSuccess: craneModelIsSuccess,
    error: craneModelError,
  } = useGetCraneModel();

  const lufferType = craneTypeData?.data.find(
    (type: any) => type.Type === 'LUFFER',
  );

  const isLufferType = state?.TypeId?.toString() === lufferType?.ID?.toString();

  const isInitialState = isEqual(state, initialState);

  const validationResults = useMemo(() => {
    const definitions = {
      BrandId: [() => validators.isRequired(state.BrandId.toString())],
      TypeId: [() => validators.isRequired(state.TypeId.toString())],
      ModelId: [() => validators.isRequired(state.ModelId.toString())],
      xMeter: [() => validators.isRequired(state.xMeter.toString())],
      latitude: [() => validators.isRequired(state.latitude.toString())],
      longitude: [() => validators.isRequired(state.longitude.toString())],
      yMeter: [() => validators.isRequired(state.yMeter.toString())],
      craneJibLength: [
        () => validators.isRequired(state.craneJibLength.toString()),
      ],
      craneJibWidth: [
        () => validators.isRequired(state.craneJibWidth.toString()),
      ],
      craneJibHeight: [
        () => validators.isRequired(state.craneJibHeight.toString()),
      ],
      craneJibMinAngle: [
        () =>
          isLufferType &&
          validators.isRequired(state.craneJibMinAngle.toString()),
      ],
      craneJibMaxAngle: [
        () =>
          isLufferType &&
          validators.isRequired(state.craneJibMaxAngle.toString()),
      ],
      hookChainLength: [
        () => validators.isRequired(state.hookChainLength.toString()),
      ],
      craneTailLength: [
        () => validators.isRequired(state.craneTailLength.toString()),
      ],
      craneHeight: [() => validators.isRequired(state.craneHeight.toString())],
      baseHeight: [() => validators.isRequired(state.baseHeight.toString())],
      minOffsetFromCabin: [
        () => validators.isRequired(state.minOffsetFromCabin.toString()),
      ],
      minOffsetFromTrolley: [
        () => validators.isRequired(state.minOffsetFromTrolley.toString()),
      ],
      minOffsetJibTip: [
        () => validators.isRequired(state.minOffsetJibTip.toString()),
      ],
    };
    return validate(state, definitions);
  }, [state]);

  const valid = validationResults.valid;

  const pageIsLoading =
    craneBrandIsLoading || craneTypeIsLoading || craneModelIsLoading;
  const pageError = craneBrandError || craneTypeError || craneModelError;
  const pageFetchesIsSuccess =
    craneBrandIsSuccess && craneTypeIsSuccess && craneModelIsSuccess;

  return (
    <Stack width="100%" height="calc(100vh - 228px)">
      <LayoutStack>
        <ContentStack>
          {pageIsLoading && !pageError && <Loading />}
          {!pageIsLoading && pageError && <ErrorState error={pageError} />}
          {!pageIsLoading && !pageError && pageFetchesIsSuccess && (
            <Stack flex={1} width="100%" sx={{ padding: 4 }}>
              <Stack flex={1} spacing={8} direction="row" width="100%">
                <Stack spacing={12} width="100%">
                  <FormGroup title="Crane">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Brand" required>
                        <Stack>
                          <Select
                            error={validationResults.results['BrandId'].error}
                            fullWidth
                            sx={{
                              '& .MuiSelect-select .notranslate::after': {
                                content: '"Select brand"',
                                opacity: 0.42,
                              },
                            }}
                            value={state.BrandId || ''}
                            onChange={(e) => {
                              dispatch({
                                type: 'BrandId',
                                payload: e.target.value,
                              });
                            }}
                          >
                            <MenuItem key={uuid()} value={''} disabled>
                              <em>Select an option</em>
                            </MenuItem>
                            {craneBrandData?.data?.map((item: any) => (
                              <MenuItem key={uuid()} value={item.ID.toString()}>
                                {item.brandName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={validationResults.results['BrandId'].error}
                          >
                            {validationResults?.results['BrandId']?.helperText}
                          </FormHelperText>
                        </Stack>
                      </FormControl>
                      <FormControl label="Type" required>
                        <Stack>
                          <Select
                            error={validationResults.results['TypeId'].error}
                            fullWidth
                            sx={{
                              '& .MuiSelect-select .notranslate::after': {
                                content: '"Select type"',
                                opacity: 0.42,
                              },
                            }}
                            value={state.TypeId || ''}
                            onChange={(e) => {
                              dispatch({
                                type: 'TypeId',
                                payload: e.target.value,
                              });
                            }}
                          >
                            <MenuItem key={uuid()} value={''} disabled>
                              <em>Select an option</em>
                            </MenuItem>
                            {craneTypeData?.data?.map((item: any) => (
                              <MenuItem key={uuid()} value={item.ID.toString()}>
                                {item.Type}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={validationResults.results['TypeId'].error}
                          >
                            {validationResults?.results['TypeId']?.helperText}
                          </FormHelperText>
                        </Stack>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Model" required>
                        <Stack>
                          <Select
                            error={validationResults.results['ModelId'].error}
                            fullWidth
                            sx={{
                              '& .MuiSelect-select .notranslate::after': {
                                content: '"Select model"',
                                opacity: 0.42,
                              },
                            }}
                            value={state.ModelId || ''}
                            onChange={(e) => {
                              dispatch({
                                type: 'ModelId',
                                payload: e.target.value,
                              });
                            }}
                          >
                            <MenuItem key={uuid()} value={''} disabled>
                              <em>Select an option</em>
                            </MenuItem>
                            {craneModelData?.data?.map((item: any) => (
                              <MenuItem key={uuid()} value={item.ID.toString()}>
                                {item.modelName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={validationResults.results['ModelId'].error}
                          >
                            {validationResults?.results['ModelId']?.helperText}
                          </FormHelperText>
                        </Stack>
                      </FormControl>
                      <FormControl label="Activation">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.Active}
                              onChange={(e) => {
                                dispatch({
                                  type: 'Active',
                                  payload: !state.Active,
                                });
                              }}
                              name="Active"
                              color="success"
                            />
                          }
                          label="Active"
                        />
                      </FormControl>
                    </Stack>
                  </FormGroup>
                  <FormGroup title="Position">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Latitude" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.latitude || ''}
                          {...validationResults.results['latitude']}
                          onChange={(e) =>
                            dispatch({
                              type: 'latitude',
                              payload: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                      <FormControl label="Longitude" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.longitude || ''}
                          {...validationResults.results['longitude']}
                          onChange={(e) =>
                            dispatch({
                              type: 'longitude',
                              payload: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="X Coordinate (Meters)" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.xMeter || ''}
                          {...validationResults.results['xMeter']}
                          onChange={(e) =>
                            dispatch({
                              type: 'xMeter',
                              payload: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                      <FormControl label="Y Coordinate (Meters)" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.yMeter || ''}
                          {...validationResults.results['yMeter']}
                          onChange={(e) =>
                            dispatch({
                              type: 'yMeter',
                              payload: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Stack>
                  </FormGroup>
                  <FormGroup title="Jib">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Length" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.craneJibLength || ''}
                          {...validationResults.results['craneJibLength']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'craneJibLength',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <FormControl label="Width" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.craneJibWidth || ''}
                          {...validationResults.results['craneJibWidth']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'craneJibWidth',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Height" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.craneJibHeight || ''}
                          {...validationResults.results['craneJibHeight']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'craneJibHeight',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      {isLufferType ? (
                        <FormControl label="Min Angle" required>
                          <TextField
                            fullWidth
                            type="number"
                            value={state.craneJibMinAngle || ''}
                            {...validationResults.results['craneJibMinAngle']}
                            onChange={(e) => {
                              if (
                                REGEX_VALIDATION.decimalPattern.test(
                                  e.target.value,
                                )
                              ) {
                                dispatch({
                                  type: 'craneJibMinAngle',
                                  payload: e.target.value,
                                });
                              }
                            }}
                          />
                        </FormControl>
                      ) : (
                        <Box width="100%" />
                      )}
                    </Stack>
                    {isLufferType && (
                      <Stack direction="row" spacing={4} width="100%">
                        <FormControl label="Max Angle" required>
                          <TextField
                            fullWidth
                            type="number"
                            value={state.craneJibMaxAngle || ''}
                            {...validationResults.results['craneJibMaxAngle']}
                            onChange={(e) => {
                              if (
                                REGEX_VALIDATION.decimalPattern.test(
                                  e.target.value,
                                )
                              ) {
                                dispatch({
                                  type: 'craneJibMaxAngle',
                                  payload: e.target.value,
                                });
                              }
                            }}
                          />
                        </FormControl>
                        <Box width="100%" />
                      </Stack>
                    )}
                  </FormGroup>
                  <FormGroup title="Length">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Hook chain" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.hookChainLength || ''}
                          {...validationResults.results['hookChainLength']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'hookChainLength',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <FormControl label="Tail" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.craneTailLength || ''}
                          {...validationResults.results['craneTailLength']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'craneTailLength',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </FormGroup>
                  <FormGroup title="Height">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Tower" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.craneHeight || ''}
                          {...validationResults.results['craneHeight']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'craneHeight',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <FormControl label="Base" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.baseHeight || ''}
                          {...validationResults.results['baseHeight']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'baseHeight',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </FormGroup>
                  <FormGroup title="Minimum offset">
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="From cabin" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.minOffsetFromCabin || ''}
                          {...validationResults.results['minOffsetFromCabin']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'minOffsetFromCabin',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <FormControl label="From trolley" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.minOffsetFromTrolley || ''}
                          {...validationResults.results['minOffsetFromTrolley']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'minOffsetFromTrolley',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4} width="100%">
                      <FormControl label="Jib tip" required>
                        <TextField
                          fullWidth
                          type="number"
                          value={state.minOffsetJibTip || ''}
                          {...validationResults.results['minOffsetJibTip']}
                          onChange={(e) => {
                            if (
                              REGEX_VALIDATION.decimalPattern.test(
                                e.target.value,
                              )
                            ) {
                              dispatch({
                                type: 'minOffsetJibTip',
                                payload: e.target.value,
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <Box width="100%" />
                    </Stack>
                  </FormGroup>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
                sx={{ pb: 4 }}
              >
                <Button onClick={onClose} disabled={isInitialState}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!valid || isInitialState}
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          )}
        </ContentStack>
      </LayoutStack>
    </Stack>
  );
};

export default CraneParameters;
