import React from 'react';
import getColumnsDef from './columns';
import { Grid } from '../../../components/grid';
import { ContentStack, Loading } from '../../../components/styled';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const Users = (props: any) => {
  const { users, isLoading } = props;

  return (
    <ContentStack>
      {isLoading && <Loading />}
      {!isLoading && (
        <Grid
          {...{
            rows: users,
            columnsDef: getColumnsDef(),
            ROW_HEIGHT,
            maxVisibleRows,
          }}
        />
      )}
    </ContentStack>
  );
};
export default Users;
