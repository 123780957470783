import { Marker } from '@react-google-maps/api';
import SiteGoogleMaps, {
  GoogleMapPoint,
  SiteGoogleMapsMode,
} from '../../../../components/SiteGoogleMaps/SiteGoogleMaps';
import { useCallback, useEffect, useState } from 'react';

const SignallerGoogleMaps = ({
  siteCenter,
  mode,
  newComeHerePoint,
  newGoTherePoint,
  setNewGoTherePoint,
}: any) => {
  const [newMarkerPoint, setNewMarkerPoint] = useState<GoogleMapPoint | null>(
    null,
  );

  useEffect(() => {
    if (mode === SiteGoogleMapsMode.come_here && newComeHerePoint) {
      // show a new marker on the clicked location
      setNewMarkerPoint(newComeHerePoint);
      return;
    }
  }, [newComeHerePoint]);

  const onMapClick = useCallback((e) => {
    if (mode === SiteGoogleMapsMode.go_there) {
      // show a new marker on the clicked location
      const newPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      setNewGoTherePoint(newPoint);
      setNewMarkerPoint(newPoint);
      return;
    }
  }, []);

  return (
    <>
      <SiteGoogleMaps
        siteCenter={siteCenter}
        onMapClick={onMapClick}
        mode={mode}
        newGoTherePoint={newGoTherePoint}
        setNewGoTherePoint={setNewGoTherePoint}
        height="90%"
      >
        {newMarkerPoint && <Marker position={newMarkerPoint} />}
      </SiteGoogleMaps>
    </>
  );
};

export default SignallerGoogleMaps;
