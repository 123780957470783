import { createSlice } from '@reduxjs/toolkit';
import { CraneTaskDirection, CraneTaskType } from '../variables/enums';

export interface CraneTask {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: CraneTaskType;
  barcode?: string;
  sourceLat?: number;
  sourceLong?: number;
  sourceAlt?: number;
  destinationLat?: number;
  destinationLong?: number;
  destinationAlt?: number;
  imagePath?: string;
  isDone: boolean;
  isActive: boolean;
  completionTime?: string;
  direction: CraneTaskDirection;
  order: number;
  issuer?: any;
  issuerId: string;
  crane?: any;
  craneId: string;
  cargoType?: any;
  cargoTypeId: string;
  template?: any;
  templateId?: string;
}

export interface CraneTaskTemplate {
  id: string;
  createdAt: string;
  updatedAt: string;
  barcode?: string;
  quantity: number;
  sourceLat?: number;
  sourceLong?: number;
  sourceAlt?: number;
  destinationLat?: number;
  destinationLong?: number;
  destinationAlt?: number;
  imagePath?: string;
  isActive: boolean;
  crane?: any;
  craneId: string;
  cargoType?: any;
  cargoTypeId: string;
}

export interface CraneTasks {
  tasks: CraneTask[];
  templates: CraneTaskTemplate[];
}

const initialState: CraneTasks = {
  tasks: [],
  templates: [],
};

const craneTasksSlice = createSlice({
  name: 'projectCrane',
  initialState: initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload;
    },
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    resetCraneTasks: () => initialState,
  },
});

export const craneTasksActions = craneTasksSlice.actions;

export default craneTasksSlice.reducer;
