import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { Loading } from '../../styled';
import { EmptyState, ErrorState } from '../../alerts';
import { useAppSelector } from '../../../store/hooks';
import { CraneLiftImagesContent } from './content/CraneLiftImagesContent';
import useCraneLiftImages from '../../../hooks/useCraneLiftImages';

interface CraneLiftImagesProps {
  startDate: string;
}

const CraneLiftImages = (props: CraneLiftImagesProps) => {
  const { startDate } = props;
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);

  // const { fetchCraneLiftImagesApi } = useCraneLiftImages();
  // const {
  //   error,
  //   loading,
  //   data: craneLiftImagesData,
  // } = useAppSelector((state) => state.craneLiftImages);

  // const selectedProject = useAppSelector(
  //   (state: any) => state.projectCrane.Project,
  // );
  // const selectedCrane = useAppSelector(
  //   (state: any) => state.projectCrane.Crane,
  // );

  // useEffect(() => {
  //   if (selectedCrane && startDate) {
  //     fetchCraneLiftImagesApi({
  //       DateLoad: startDate,
  //       CraneId: selectedCrane,
  //     });
  //   }
  // }, []);

  const noRecords = lifts.length === 0;

  return (
    <>
      {/* {loading && !error && <Loading sx={{ minHeight: 320 }} />}
      {!loading && error && (
        <ErrorState sx={{ minHeight: 320 }} error={error} />
      )}
      {!loading && !error && noRecords && (
        <EmptyState sx={{ minHeight: 320 }} error={error} />
      )}
      {!loading && !error && lifts.length > 0 && (
        <CraneLiftImagesContent startDate={startDate} />
      )} */}
      {lifts.length === 0 ? (
        <EmptyState sx={{ minHeight: 320 }} />
      ) : (
        <CraneLiftImagesContent startDate={startDate} />
      )}
    </>
  );
};

export default CraneLiftImages;
