import React, { useState, useEffect, useRef } from 'react';
import './DailyVideoDialog.scss';
import { useAppSelector } from '../../store/hooks';
import { Modal } from 'bootstrap';
import { S3MMSITE_PATH } from '../../variables/endpoints';

interface DailyVideoDialog {
  onClose: () => void;
  dailyVideoOpen: boolean;
}

const DailyVideoDialog = ({ onClose, dailyVideoOpen }: DailyVideoDialog) => {
  const [VideoStream, setVideoStream] = useState<string>('');
  const dailyVideo = useAppSelector((state) => state.dailyVideo.dailyvideo);
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level
  );
  const modalDailyVideoRef = useRef<HTMLDivElement>(null);
  let VideoWidthSize: number = 700;
  switch (mediaResLevel) {
    case 4:
      VideoWidthSize = 700;

      break;
    case 3:
      VideoWidthSize = 480;

      break;
    case 2:
      VideoWidthSize = 480;

      break;
    default:
      VideoWidthSize = 300;
  }
  useEffect(() => {
    if (dailyVideo) {
      setVideoStream(`${S3MMSITE_PATH}/${dailyVideo.VideoPath}`);
    }
  }, [dailyVideo]);
  useEffect(() => {
    if (dailyVideoOpen) {
      const modalEle = modalDailyVideoRef.current;
      //@ts-ignore
      const bsModal = new Modal(modalEle, {
        backdrop: 'static',
        keyboard: false,
      });
      bsModal.show();
    }
  }, [dailyVideoOpen]);
  const handleClose = () => {
    onClose();
    const modalEle = modalDailyVideoRef.current;
    //@ts-ignore
    const bsModal = Modal.getInstance(modalEle);
    //@ts-ignore
    bsModal.hide();
  };
  return (
    <div className="modal fade" ref={modalDailyVideoRef} tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Daily video
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => handleClose()}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <video
              width={VideoWidthSize}
              src={VideoStream}
              autoPlay
              controls
              muted
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handleClose()}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyVideoDialog;
