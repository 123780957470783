// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Stack, Typography } from '@mui/material';
import { JobsiteWeeklyConcreteTable } from '../tables';
import { flatten, map, sumBy, times } from 'lodash';
import { ChartProperty } from '../content/styled';
import { secondsToMinutes, WEEKDAY } from './utils';

const JobsiteWeeklyConcreteChart = (props: any) => {
  const { records, craneIndex } = props;

  const craneName = records[0].records[craneIndex].crane.name;
  const daysCount = records.length;

  const data = times(daysCount, (day) => {
    const craneDayRecords = records[day]?.records[craneIndex]?.records || [];

    const craneDayConcreteRecordsDurations = flatten(
      craneDayRecords.map(({ durations }) => durations),
    ).filter(({ cargoType }) => cargoType === 'Concrete');

    return {
      days: WEEKDAY[day],
      quantity: craneDayConcreteRecordsDurations.length,
      weight: +(
        sumBy(
          craneDayConcreteRecordsDurations,
          (item) => item.task.LiftWeight,
        ) * 0.001
      ).toFixed(2),
      duration: +(
        sumBy(craneDayConcreteRecordsDurations, 'duration') / 60
      ).toFixed(2),
    };
  });

  const days = map(data, (d) => d.days);

  const seriesDataWeightChart = map(data, (d) => d.weight);
  const seriesDataDurationChart = map(data, (d) => d.duration);

  const optionsWeightChart = {
    chart: {
      type: 'column',
      height: 250,
    },
    exporting: { enabled: false },
    colors: ['#4572C4'],
    title: {
      text: undefined,
    },
    xAxis: {
      categories: days,
      crosshair: true,
    },
    yAxis: {
      title: { text: undefined },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [{ name: 'Concrete weight [Tons]', data: seriesDataWeightChart }],
  };

  const optionsDurationChart = {
    chart: {
      type: 'column',
      height: 250,
    },
    exporting: { enabled: false },
    colors: ['#4572C4'],
    title: {
      text: undefined,
    },
    xAxis: {
      categories: days,
      crosshair: true,
    },
    yAxis: {
      title: { text: undefined },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [{ name: 'Duration [Minutes]', data: seriesDataDurationChart }],
  };

  const weeklyQuantity = sumBy(data, 'quantity');
  const dailyDuration = secondsToMinutes(sumBy(data, 'duration') * 60);
  const weeklyWeight = sumBy(data, 'weight').toFixed(2) + ' Tons';

  return (
    <Stack
      sx={{
        height: 1064,
        alignItems: 'center',
        marginBottom: 4,
        borderWidth: 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
        padding: 6,
      }}
    >
      <Stack
        sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
        spacing={4}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
          Jobsite Weekly Concrete
        </Typography>
        <Stack
          sx={{
            padding: 2,
            backgroundColor: 'backgrounds.system',
          }}
        >
          <Stack direction="row" spacing={6}>
            <ChartProperty label="Name" value={craneName} />
          </Stack>
          <Stack direction="row" spacing={6}>
            <ChartProperty label="Weekly Quantity" value={weeklyQuantity} />
            <ChartProperty label="Weekly Weight [Tons]" value={weeklyWeight} />
            <ChartProperty label="Daily Duration" value={dailyDuration} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
        <JobsiteWeeklyConcreteTable data={data} />
        <HighchartsReact highcharts={Highcharts} options={optionsWeightChart} />
        <HighchartsReact
          highcharts={Highcharts}
          options={optionsDurationChart}
        />
      </Stack>
    </Stack>
  );
};

export default JobsiteWeeklyConcreteChart;
