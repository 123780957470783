import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MediaRes {
  level: number;
}

export interface MediaResT {
  mediares: MediaRes;
}

const initialMediaRes: MediaResT = {
  mediares: {
    level: 1,
  },
};

const MediaResSlice = createSlice({
  name: 'mediares',
  initialState: initialMediaRes,
  reducers: {
    setMediaRes(state, action: PayloadAction<MediaRes>) {
      state.mediares = action.payload;
      //   console.log('1.changed in repository='+action.payload.level );
      //   console.log(action.payload );
      //   console.log('2.changed in repository='+state.mediares.level );
      //   console.log(state.mediares );
      //  // console.log('changed in repository' )
      //     console.log (action.payload);
      //  console.log (state.mediares.level);
    },
  },
});

export const mediaResActions = MediaResSlice.actions;

export default MediaResSlice.reducer;
