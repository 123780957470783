import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TaskInHours {
  minutesload: number;
  hournum: string;
  taskscnt: number;
  minutestran: number;
  minutesidle: number;
}
export interface TasksInHours {
  tasksinhours: TaskInHours[];
}

const initialState: TasksInHours = {
  tasksinhours: [
    {
      minutesload: 0,
      hournum: '',
      taskscnt: 0,
      minutestran: 0,
      minutesidle: 0,
    },
  ],
};

const tasksInHoursSlice = createSlice({
  name: 'tasksinhours',
  initialState,
  reducers: {
    setTasksInHours(state, action: PayloadAction<TaskInHours[]>) {
      state.tasksinhours = action.payload;
      // console.log (state.tasks);
    },
    resetTasksInHours: () => initialState,
  },
});

export const tasksInHoursActions = tasksInHoursSlice.actions;

export default tasksInHoursSlice.reducer;
