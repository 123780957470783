export const TOKEN = 'ultrawis-token';
export const USER = 'ultrawis-user';
export const FCM_TOKEN = 'firebase-cloud-messaging-token';
export const ULTRAWIS_SELECTED_CRANE = 'ultrawis-selected-crane';
export const ULTRAWIS_SELECTED_CRANE_DATA = 'ultrawis-selected-crane-data';
export const ULTRAWIS_SELECTED_PROJECT = 'ultrawis-selected-project';
export const POSITION_ACCURACY_THRESHOLD = 10; // meters

export const REGEX_VALIDATION = {
  threeDigits: /^[0-9]{0,3}$/,
  decimalPattern: /^[-]?[0-9]{0,9}[.]?[0-9]{0,4}$/,
  ipPattern: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
  portPattern: /^[0-9]{0,5}$/,
  distancePattern: /^[0-9]{0,3}[.]?[0-9]{0,2}$/,
  kgPattern: /^[0-9]{0,6}[.]?[0-9]{0,2}$/,
};
