import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Telemetry, Telemetries } from './telemetries';

const initialState: Telemetries = {
  telemetries: [
    {
      ID: 0,
      CraneId: 0,
      DateSampling: '',
      TimeSampling: '',
      WindSpeedKmH: 0,
      WindDirDeg: 0,
    },
  ],
};

const telemetriesRptSlice = createSlice({
  name: 'telemetriesrpt',
  initialState,
  reducers: {
    setTelemetriesRpt(state, action: PayloadAction<Telemetry[]>) {
      state.telemetries = action.payload;
      //console.log (state.telemetries);
    },
    resetTelemetriesRpt: () => initialState,
  },
});

export const telemetriesRptActions = telemetriesRptSlice.actions;

export default telemetriesRptSlice.reducer;
