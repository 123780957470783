import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import PestControlTwoToneIcon from '@mui/icons-material/PestControlTwoTone';

const ErrorState = ({ error, showReloadButton = true, ...rest }: any) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      flex={1}
      {...rest}
    >
      <PestControlTwoToneIcon sx={{ fontSize: 126 }} />
      <Typography variant="h1SemiBold">Internal Server Error</Typography>
      <Typography variant="mediumRegular" color="typography.negativeDisabled">
        {error?.code} - {error?.message}
      </Typography>
      {showReloadButton && (
        <Button onClick={() => window.location.reload()} variant="contained">
          Reload
        </Button>
      )}
    </Stack>
  );
};

export default ErrorState;
