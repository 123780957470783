import React, { useState } from 'react';
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const PasswordInput = (props: TextFieldProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <TextField
      fullWidth
      autoComplete="off"
      type={passwordShown ? 'text' : 'password'}
      label="Password"
      InputLabelProps={{ shrink: true }}
      inputProps={{
        autocomplete: 'new-password',
        form: {
          autocomplete: 'off',
        },
      }}
      InputProps={{
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onMouseDown={togglePassword}>
              {passwordShown ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
