export interface AsyncState<T> {
  data: T;
  loading: boolean;
  error: any | undefined;
}

export interface CrudAsyncState<T> extends AsyncState<T> {
  deleteApi?: AsyncState<T>;
  createApi?: AsyncState<T>;
  updateApi?: AsyncState<T>;
}

export interface InitialAsyncState<T> extends AsyncState<T> {
  deleteApi: AsyncState<any>;
  createApi: AsyncState<any>;
  updateApi: AsyncState<any>;
}

export const defaultAsyncState = {
  data: undefined,
  loading: false,
  error: undefined,
};
