import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { projectCraneActions } from '../store/projectcrane';
import { calcTaskActions } from '../store/calctask';
import { calcSumTaskActions } from '../store/calcsumtask';
import { telemetriesActions } from '../store/telemetries';
import { lastTelemetryActions } from '../store/lasttelemetry';
import { tasksInHoursActions } from '../store/tasksinhours';
import { timeFrameActions } from '../store/timeframe';
import { tasksByCategoryActions } from '../store/tasksbycategory';
import { ganttTasksActions } from '../store/gantttasks';
import { userActions } from '../store/user';
import { useHttp } from '../hooks/useHttp/useHttp';
import './Login.scss';

function Logout() {
  const { logout } = useHttp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    logout();
    dispatch(projectCraneActions.resetProjectCrane());
    dispatch(calcTaskActions.resetCalcTask());
    dispatch(calcSumTaskActions.resetCalcSumTask());
    dispatch(telemetriesActions.resetTelemetries());
    dispatch(lastTelemetryActions.resetLastTelemetry());
    dispatch(tasksInHoursActions.resetTasksInHours());
    dispatch(timeFrameActions.resetTimeFrame());
    dispatch(tasksByCategoryActions.resetTasksByCategory());
    dispatch(ganttTasksActions.resetGanttTasks());
    dispatch(userActions.resetUser());
    sessionStorage.removeItem('cid');
    sessionStorage.removeItem('pid');
    navigate('/login');
  }, [dispatch, logout, navigate]);

  return <></>;
}
export default Logout;
