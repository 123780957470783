import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) => apiGetAsync(`lift/${props.id}/images-timeline`);

export const useGetLiftImages = (props: any, options?: any) => {
  const key = ['GetLiftImages'];
  const fn = () => api(props);

  return useQuery(key, fn, {
    enabled: !!props.id,
    ...(options || {}),
  });
};
