import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { Typography, Stack, Grid, Paper, useMediaQuery } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Loading } from '../../../components/styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const SiteClock = () => {
  const [time, setTime] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const timezone = useAppSelector((state) => state.projectCrane.Timezone);
  const sm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    setIsLoading(true);

    if (!timezone) return;
    // Update the time once immediately before starting the interval
    setTime(dayjs().tz(timezone).format('HH:mm'));
    setIsLoading(false);

    const interval = setInterval(() => {
      setTime(dayjs().tz(timezone).format('HH:mm'));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <Grid
      sx={{
        width: sm ? 140 : 240,
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '10px',
        }}
      >
        <Typography
          variant="h1Medium"
          sx={{
            fontSize: sm ? 14 : 22,
            minWidth: sm ? '50px' : '100px',
          }}
        >
          Site Time:
        </Typography>
        {isLoading ? (
          <Loading size={sm ? 15 : 20} />
        ) : (
          <Typography variant="h1SemiBold" sx={{ fontSize: sm ? 14 : 22 }}>
            {time}
          </Typography>
        )}
        {!isLoading && (
          <AccessTimeIcon sx={{ marginTop: 1, fontSize: sm ? 14 : 22 }} />
        )}
      </Paper>
    </Grid>
  );
};

export default SiteClock;
