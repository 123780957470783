import { useMutation } from 'react-query';
import { apiPostAsync } from '../api/axios';

const api = (props: any) => {
  const { siteId, ...restProps } = props;
  return apiPostAsync(`site/${siteId}/users`, restProps);
};

export const usePostProjectUser = (options: any) => {
  const key = ['PostProjectUser'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
