import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface DailyVideo {
  ID: number;
  CraneId: number;
  DateSampling: string;
  VideoPath: string;
}
export interface DailyVideoT {
  dailyvideo: DailyVideo;
}
const initialState: DailyVideoT = {
  dailyvideo: { ID: 0, CraneId: 0, DateSampling: '', VideoPath: '' },
};

const dailyVideoSlice = createSlice({
  name: 'dailyvideo',
  initialState,
  reducers: {
    setDailyVideo(state, action: PayloadAction<DailyVideo>) {
      state.dailyvideo = action.payload;
      // console.log ("state.dailyvideo");
      // console.log (state.dailyvideo);
    },
    resetDailyVideo: () => initialState,
  },
});

export const dailyVideoActions = dailyVideoSlice.actions;

export default dailyVideoSlice.reducer;
