import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { mediaResActions } from '../../store/mediares';
import * as storageHelper from '../../utils/storageHelper';
import * as cordovaHelper from '../../utils/cordovaHelper';
import {
  ULTRAWIS_SELECTED_CRANE_DATA,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { S3MMSITE_PATH } from '../../variables/endpoints';
import { GridToolbar, Loading } from '../../components/styled';
import { ProjectCraneSelector } from '../../components/selectors';
import {
  useGetCrane,
  useGetProject,
  usePostCraneTask,
  usePostGtctm,
} from '../../queries';
import { useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import { Tab, TabPanel } from '../../components/Tabs';
import {
  SitemapFetchLocation,
  SitemapChooseLocation,
} from '../../components/Sitemap';
import { SignallerSubmitted } from './components';
import {
  convertLatLongToXY,
  convertXMapPointToXMeter,
  convertXMeterToXMapPoint,
  convertYMapPointToYMeter,
  convertYMeterToYMapPoint,
  isLocationReachable,
} from '../../utils/coordinatesHelper';
import {
  GoogleMapPoint,
  SiteGoogleMapsMode,
} from '../../components/SiteGoogleMaps/SiteGoogleMaps';
import SignallerGoogleMaps from './components/SignallerGoogleMaps/SignallerGoogleMaps';
import { useNavigate } from 'react-router-dom';
import { craneTasksActions } from '../../store/craneTask';
import { CraneTaskType, UserRole } from '../../variables/enums';

const SignallerRequest = () => {
  const user = storageHelper.getItem(USER);
  const Project = useAppSelector((state: any) => state.projectCrane.Project);
  const tasks = useAppSelector((state: any) => state.craneTasks.tasks);
  // const selectedCrane = useAppSelector(
  //   (state: any) => state.projectCrane.selectedCrane,
  // );
  const selectedCrane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE_DATA);
  const selectedProject = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);
  //#region States
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [comeHereWarning, setComeHereWarning] = useState<any>(null);
  const [goThereWarning, setGoThereWarning] = useState<any>(null);
  const [siteCenter, setSiteCenter] = useState<GoogleMapPoint>({
    lat: 0,
    lng: 0,
  });
  const [newGoTherePoint, setNewGoTherePoint] = useState<GoogleMapPoint | null>(
    null,
  );
  const [newComeHerePoint, setNewComeHerePoint] =
    useState<GoogleMapPoint | null>(null);

  const [relpxx, setRelpxx] = useState<number>(0);

  const [projectParams, setProjectParams] = useState<any>({});
  const navigate = useNavigate();

  const [xyMark, setXyMark] = useState<{ x: number; y: number } | null>(null);
  const [xyMarkUserLocation, setXyMarkUserLocation] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [imgWidth, setImgWidth] = useState<number>(0);
  const [xyDestinationGoThere, setXyDestinationGoThere] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [xyDestinationComeHere, setXyDestinationComeHere] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [imgWidthSize, setImgWidthSize] = useState(990);
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level,
  );

  // fetching location states
  const [fetchLocationIsLoading, setFetchLocationIsLoading] = useState(true);
  const [positionCoords, setPositionCoords] = useState<any>(null);
  const [actualAccuracy, setActualAccuracy] = useState<any>(null);
  //#endregion

  //#region Hooks
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user && selectedProject) {
      const site = user.sites.find((site: any) => site.id === selectedProject);
      if (!site) return;
      setSiteCenter({
        lat: site.originLatitude,
        lng: site.originLongitude,
      });
    }
  }, [storageHelper]);

  const {
    mutate: createCraneTaskMutate,
    isLoading: createCraneTaskIsLoading,
    error: createCraneTaskError,
  } = usePostCraneTask({
    onSuccess: (data: any) => {
      const newTask = data.data;
      const newTasks = [...tasks, newTask].sort((a, b) => a.order - b.order);
      dispatch(craneTasksActions.setTasks(newTasks));
      // if (user.role == UserRole.CRANE_GROUND_TEAM_MEMBER) {
      //   alert('Task created successfully');
      //   navigate('../signallerrequest');
      // } else {
      //   navigate('../cranescheduler');
      // }
      // alert('Task created successfully');
      setOpenSnackbar(true);
      setSnackbarMessage('Task created successfully');
      navigate('../signallerrequest');
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('PostAssetJob');
    },
  });

  // const {
  //   mutate: createGtctmMutate,
  //   isLoading: createGtctmIsLoading,
  //   error: createGtctmError,
  // } = usePostGtctm({
  //   onSuccess: () => {
  //     resetLocationStates();
  //     navigate('../cranescheduler');
  //     // setIsSubmitted(true);
  //   },
  //   onError: () => {
  //     setOpenSnackbar(true);
  //   },
  //   onSettled: () => {
  //     queryClient.invalidateQueries('PostGtctm');
  //   },
  // });

  // const { data: getCraneData } = useGetCrane(
  //   { craneId: selectedCrane.id },
  //   {
  //     cacheTime: 0,
  //   },
  // );

  //#endregion

  const [isSubmitted, setIsSubmitted] = useState(false);

  //#region Effects

  useEffect(() => {
    const SetMediaRes = () => {
      let orientationLandscape = false;
      let medialevel = 0;
      if (window.matchMedia('(orientation: Landscape)').matches) {
        orientationLandscape = true;
      }
      if (
        window.matchMedia('(min-width: 700px)').matches &&
        orientationLandscape
      ) {
        medialevel = 2;
      } //smartphone Rotated
      if (
        window.matchMedia('(min-width: 800px)').matches &&
        !orientationLandscape
      ) {
        medialevel = 3;
      } //tablet
      if (
        window.matchMedia('(min-width: 1280px)').matches &&
        orientationLandscape
      ) {
        medialevel = 4;
      } //screen
      if (medialevel === 0) {
        medialevel = 1;
      }

      dispatch(mediaResActions.setMediaRes({ level: medialevel }));

      resetLocationStates();
    };
    window.addEventListener('resize', SetMediaRes);
    SetMediaRes();
    return () => {
      window.removeEventListener('resize', SetMediaRes);
    };
  }, []);

  useEffect(() => {
    if (projectParams.SiteMap && projectParams.SiteMap.length > 0) {
      let img = new Image();
      img.src = `${S3MMSITE_PATH}/${projectParams.SiteMap}`;
      img.onload = () => {
        setImgWidth(img.width);
        setRelpxx(img.width / imgWidthSize);
      };
      resetLocationStates();
    }
  }, [projectParams]);

  useEffect(() => {
    switch (mediaResLevel) {
      case 4:
        setImgWidthSize(1000);
        setRelpxx(imgWidth / 1000);
        break;
      case 3:
        setImgWidthSize(700);
        setRelpxx(imgWidth / 700);
        break;
      case 2:
        setImgWidthSize(700);
        setRelpxx(imgWidth / 700);
        break;
      default:
        setImgWidthSize(340);
        setRelpxx(imgWidth / 340);
    }
  }, [mediaResLevel]);

  //#region Set project and crane params

  const { isLoading: projectDataIsLoading } = useGetProject(
    { projectId: selectedProject },
    {
      cacheTime: 0,
      onSuccess: (data: any) => {
        const {
          xPointCraneRef,
          yPointCraneRef,
          xMeterStart,
          yMeterStart,
          LatCraneRef,
          LongCraneRef,
          xMeterCraneRef,
          yMeterCraneRef,
          SiteMap,
          Pixel2Meter: meter2Pixel,
          EPSILON_FRONT_BACK_SLOW,
        } = data?.data?.projectData || {};
        setSiteCenter({ lat: LatCraneRef, lng: LongCraneRef });
        setProjectParams({
          xMeterStart,
          yMeterStart,
          LatCraneRef,
          LongCraneRef,
          xMeterCraneRef,
          yMeterCraneRef,
          SiteMap,
          xPointCraneRef,
          yPointCraneRef,
          meter2Pixel,
          EPSILON_FRONT_BACK_SLOW,
        });
      },
    },
  );

  //#endregion

  // initialize and destroy watchPosition
  useEffect(() => {
    const watchId = cordovaHelper.watchGpsPosition(
      (position) => {
        if (selectedTab === 0) {
          const {
            accuracy,
            latitude,
            longitude,
            altitude = 0,
          } = position.coords;
          setActualAccuracy(accuracy);
          // setPositionCoords({
          //   accuracy,
          //   latitude,
          //   longitude,
          //   altitude,
          // });
          setNewComeHerePoint({ lat: latitude, lng: longitude });
          setFetchLocationIsLoading(false);
        }
      },
      (error) => {
        console.log(error);
      },
    );
    return () => {
      cordovaHelper.clearGpsWatch(watchId);
    };
  }, [selectedTab]);

  useEffect(() => {
    if (positionCoords && projectParams) {
      const { latitude, longitude } = positionCoords;

      const {
        LatCraneRef,
        LongCraneRef,
        meter2Pixel,
        xPointCraneRef,
        yPointCraneRef,
      } = projectParams;

      if (LatCraneRef && LongCraneRef) {
        const latMid = (LatCraneRef + latitude) / 2;
        const m_per_deg_lat =
          111132.954 -
          559.822 * Math.cos(2.0 * latMid) +
          1.175 * Math.cos(4.0 * latMid);
        const m_per_deg_long = (Math.PI / 180) * 6367449 * Math.cos(latMid);
        const deltaLat = latitude - LatCraneRef;
        const deltaLong = longitude - LongCraneRef;

        const xMeter = deltaLong * m_per_deg_long;
        const yMeter = deltaLat * m_per_deg_lat;

        if (meter2Pixel) {
          if (isFinite(xMeter) && isFinite(yMeter)) {
            setXyDestinationComeHere({ x: xMeter, y: yMeter });
            const xPoint = convertXMeterToXMapPoint(
              xMeter,
              meter2Pixel,
              xPointCraneRef,
              relpxx,
            );

            const yPoint = convertYMeterToYMapPoint(
              yMeter,
              meter2Pixel,
              yPointCraneRef,
              relpxx,
            );

            setXyMarkUserLocation({ x: xPoint, y: yPoint });
          }
        }

        const args = {
          // xPoint: xMeter,
          // yPoint: yMeter,
          // xMeter: getCraneData?.data?.xMeter,
          // yMeter: getCraneData?.data?.yMeter,
          // radius: getCraneData?.data?.craneJibLength,
        };

        if (!isLocationReachable(args)) {
          setGoThereWarning('Unreachable destination!');
        } else {
          setGoThereWarning(null);
        }
      }
    }
  }, [
    positionCoords,
    projectParams,
    relpxx,
    // getCraneData
  ]);

  //#endregion

  //#region Handlers
  const handleClickGoThereImage = (e: React.MouseEvent) => {
    e.stopPropagation();

    const { xPointCraneRef, yPointCraneRef, meter2Pixel } = projectParams;

    setPositionCoords(null);
    setXyMarkUserLocation(null);

    setXyMark({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });

    const xMeter = convertXMapPointToXMeter(
      e.nativeEvent.offsetX,
      relpxx,
      xPointCraneRef,
      meter2Pixel,
    );

    const yMeter = convertYMapPointToYMeter(
      e.nativeEvent.offsetY,
      relpxx,
      yPointCraneRef,
      meter2Pixel,
    );

    setXyDestinationGoThere({ x: xMeter, y: yMeter });

    const args = {
      xPoint: xMeter,
      yPoint: yMeter,
      // xMeter: getCraneData?.data?.xMeter,
      // yMeter: getCraneData?.data?.yMeter,
      // radius: getCraneData?.data?.craneJibLength,
    };

    if (!isLocationReachable(args)) {
      setGoThereWarning('Unreachable destination!');
    } else {
      setGoThereWarning(null);
    }
  };

  const handleClickGoThere = async () => {
    // if (xyDestinationGoThere) {
    //   createGtctmMutate({
    //     CraneId: SelectedCrane,
    //     WorkerId: user.ID,
    //     XTouch: xyDestinationGoThere.x,
    //     YTouch: xyDestinationGoThere.y,
    //   });
    // }
    if (!newGoTherePoint) return;
    const type = CraneTaskType.GO_THERE;
    createCraneTaskMutate({
      type,
      // destinationLat: newGoTherePoint.lat,
      // destinationLong: newGoTherePoint.lng,
      sourceLat: newGoTherePoint.lat,
      sourceLong: newGoTherePoint.lng,
      // destinationAlt,
      craneId: selectedCrane.id,
      issuerId: user.id,
    });
    // const { x, y } = convertLatLongToXY(
    //   siteCenter,
    //   newGoTherePoint.lat,
    //   newGoTherePoint.lng,
    // );
    // console.log('x,y', x, y);
    // createGtctmMutate({
    //   CraneId: SelectedCrane,
    //   WorkerId: user.ID,
    //   XTouch: x,
    //   YTouch: y,
    // });
  };

  const handleClickComeHere = async () => {
    // if (positionCoords && xyDestinationComeHere) {
    //   createGtctmMutate({
    //     CraneId: SelectedCrane,
    //     WorkerId: user.ID,
    //     XTouch: Math.round(xyDestinationComeHere.x * 10000) / 10000,
    //     YTouch: Math.round(xyDestinationComeHere.y * 10000) / 10000,
    //     Latitude: positionCoords.latitude,
    //     Longitude: positionCoords.longitude,
    //     Altitude: positionCoords.altitude,
    //   });
    // }
    if (!newComeHerePoint) return;
    const type = CraneTaskType.COME_HERE;
    createCraneTaskMutate({
      type,
      // destinationLat: newComeHerePoint.lat,
      // destinationLong: newComeHerePoint.lng,
      sourceLat: newComeHerePoint.lat,
      sourceLong: newComeHerePoint.lng,
      // destinationAlt,
      craneId: selectedCrane.id,
      issuerId: user.id,
    });
    // const { x, y } = convertLatLongToXY(
    //   siteCenter,
    //   newComeHerePoint.lat,
    //   newComeHerePoint.lng,
    //   // 32.07050923, //newComeHerePoint.lat,
    //   // 34.79587723, //newComeHerePoint.lng,
    // );
    // createGtctmMutate({
    //   CraneId: SelectedCrane,
    //   WorkerId: user.ID,
    //   XTouch: x,
    //   YTouch: y,
    //   Latitude: newComeHerePoint.lat,
    //   Longitude: newComeHerePoint.lng,
    //   Altitude: 0, //positionCoords.altitude,
    // });
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    resetLocationStates();
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  //#endregion

  //#region Helpers

  const resetLocationStates = () => {
    setComeHereWarning(null);
    setGoThereWarning(null);
    setPositionCoords(null);
    setXyMarkUserLocation(null);
    setXyMark(null);
    setXyDestinationGoThere(null);
    setXyDestinationComeHere(null);
  };
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const handleReset = () => setIsSubmitted(false);
  //#endregion

  //#region Constants

  const error = ''; // (createGtctmError as Error)?.message;

  //#endregion

  if (projectDataIsLoading) return <Loading />;

  if (isSubmitted) return <SignallerSubmitted {...{ handleReset }} />;

  return (
    <Stack sx={{ height: '100%' }}>
      <GridToolbar>
        <ProjectCraneSelector
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        />
      </GridToolbar>
      <Tabs value={selectedTab} onChange={onTabChange}>
        <Tab label="Come here" />
        <Tab label="Go there" />
      </Tabs>

      <Grid container rowSpacing={4} flex={1} sx={{ marginTop: 0 }}>
        <TabPanel value={selectedTab} index={0}>
          <Paper
            sx={{
              padding: 4,
              backgroundColor: 'common.white',
              width: '100%',
              height: '100%',
            }}
            elevation={1}
          >
            {/* <SitemapFetchLocation
              {...{
                handleClickActionButton: handleClickComeHere,
                fetchLocationIsLoading:
                  fetchLocationIsLoading || createGtctmIsLoading,
                selectedCrane: SelectedCrane,
                fetchLocationWarning: comeHereWarning,
                xyMarkUserLocation,
                imgWidthSize,
                projectParams,
                actualAccuracy: fetchLocationIsLoading
                  ? actualAccuracy
                  : positionCoords?.accuracy,
                actionButtonLabel: 'Come here',
              }}
            /> */}
            <SignallerGoogleMaps
              siteCenter={newComeHerePoint}
              mode={SiteGoogleMapsMode.come_here}
              newComeHerePoint={newComeHerePoint}
              setNewGoTherePoint={setNewGoTherePoint}
            />
            <Fab
              sx={{
                minHeight: 64,
                width: medium || sm ? '100%' : 320,
                marginTop: 0,
              }}
              color="primary"
              variant="extended"
              disabled={!newComeHerePoint}
              onClick={handleClickComeHere}
            >
              Come Here
            </Fab>
          </Paper>
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <Paper
            sx={{
              padding: 4,
              backgroundColor: 'common.white',
              width: '100%',
              height: '100%',
            }}
            elevation={1}
          >
            {/* <SitemapChooseLocation
              {...{
                selectedCrane: SelectedCrane,
                handleClickActionButton: handleClickGoThere,
                handleClickOnImage: handleClickGoThereImage,
                selectLocationWarning: goThereWarning,
                imgWidthSize,
                projectParams,
                xyMark,
                actionButtonDisabled: !xyDestinationGoThere,
                actionButtonIsLoading: createGtctmIsLoading,
                actionButtonLabel: 'Go there',
              }}
            /> */}
            {/* <SiteGoogleMaps
              zones={[]}
              newZone={null}
              setNewZone={null}
              siteCenter={siteCenter}
              mode={SiteGoogleMapsMode.go_there}
              newGoTherePoint={newGoTherePoint}
              setNewGoTherePoint={setNewGoTherePoint}
            >
              {newGoTherePoint && <Marker position={newMarkerPoint} />}
            </SiteGoogleMaps> */}
            <SignallerGoogleMaps
              siteCenter={siteCenter}
              mode={SiteGoogleMapsMode.go_there}
              newGoTherePoint={newGoTherePoint}
              setNewGoTherePoint={setNewGoTherePoint}
            />
            <Fab
              sx={{
                minHeight: 64,
                // width: sm ? '100%' : 320,
                // ...(selectLocationWarning
                //   ? { backgroundColor: 'error.main' }
                //   : {}),
                width: medium || sm ? '100%' : 320,
                marginTop: 0,
              }}
              color="primary"
              variant="extended"
              disabled={!newGoTherePoint}
              onClick={handleClickGoThere}
            >
              Go There
            </Fab>
          </Paper>
        </TabPanel>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarMessage}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};

export default SignallerRequest;
