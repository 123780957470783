const WizLogo = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.31 31.67a8.192 8.192 0 0 1-.911-1.3c-.886-1.602-1.232-3.443-1.323-5.327-.178-3.699-.123-7.41-.168-11.116-.008-.662.002-1.325-.028-1.985-.003-.052-.013-.097-.016-.148l-.001-.612.016-.016v-.026c-.003-.05-.012-.095-.016-.144 0-2.319 0-4.638-.009-6.957v-.004l-.001-.797c0-.101-.005-.2-.01-.298V.035h-5.257v2.91h.01l-.019 2.3c0 3.207.011 6.414.018 9.62l-.001.13.001.672c-.011.808-.017 1.615-.02 2.423-.003 1.117.002 2.235.029 3.352.031 1.287.086 2.573.186 3.858.256 3.306.991 6.484 2.934 9.18.077-.005.156-.008.228-.017l.053-.004.002-.005c2.001-.269 3.429-1.075 4.5-2.494l.022-.03-.22-.26M59.741 12c0-.052-.002-.104-.005-.156V9.086h-2.348a2.591 2.591 0 0 0-.233 0H54.48v5.095c-.04 3.622.008 7.248-.165 10.862-.129 2.67-.744 5.265-2.722 7.199-2.58 2.52-5.644 2.427-8.67 1.356-2.972-1.052-4.067-3.817-4.518-6.79-.896-5.918-.255-11.895-.434-17.842l-.002-.09v-3.73h-5.458v5.608h.03c-.02 5.06.214 10.124-.206 15.172-.374 4.502-3.006 8.006-6.724 8.226a6.629 6.629 0 0 1-1.158-.047c-1.283 2.084-3.013 3.671-5.088 4.666.275.117.448.178.448.178 1.355.38 2.699.629 4.022.706l.1.003c2.857.148 5.619-.507 8.19-2.368 3.23-2.34 3.17-2.432 6.308-.048 5.493 4.174 14.076 2.93 18.183-2.685 1.984-2.713 2.726-5.92 2.985-9.257.343-4.427.197-8.867.14-13.3"
          fill="#FF6F00"
        />
        <path
          d="M27.043 10.27c.005-.405.005-.81.014-1.215.027-1.193.043-2.389.029-3.583V.035h-5.324v6.992h.008l-.002 2.758c-.003 4.855-.001 9.71-.04 14.564-.011 1.278-.13 2.534-.427 3.745h.001s-.05.22-.162.573a10.89 10.89 0 0 1-1.035 2.401c-.067.12-.136.235-.206.348-.01.015-.017.03-.028.045a3.392 3.392 0 0 1-.154.22c-.06.089-.125.168-.188.25l-.022.029c-1.071 1.419-2.499 2.225-4.5 2.494l-.002.005-.053.004c-.072.009-.151.012-.228.017-.096.006-.19.015-.284.022-.205.016-.403.04-.617.047l-.078.017c-.07 0-.134-.006-.203-.007-.069.001-.133.007-.203.007l-.078-.017c-.392-.015-.772-.04-1.129-.086l-.053-.004-.002-.005c-2.132-.286-3.618-1.174-4.71-2.772a3.66 3.66 0 0 1-.155-.22c-.01-.016-.017-.031-.027-.046a8.644 8.644 0 0 1-.206-.348c-.45-.77-.79-1.573-1.035-2.4a8.58 8.58 0 0 1-.162-.574h.001c-.296-1.21-.416-2.467-.426-3.745-.033-4.07-.038-8.14-.04-12.21h.001V5.145H2.89a2.524 2.524 0 0 0-.505 0H.028v6.992h.02C.045 14.625-.005 17.113 0 19.6c.011 1.382.037 2.763.091 4.143.012.256.024.513.038.769l.039.765c.05.763.115 1.526.198 2.289.021.204.057.402.09.602.027.166.054.331.086.494.36 1.765 1.077 3.386 1.8 5.022 1.476 2.605 3.636 4.483 6.271 5.483.158.044.308.092.474.132 1.503.43 2.953.655 4.334.69.04 0 .08.002.121.001h.12c1.381-.036 2.832-.261 4.335-.691.166-.04.316-.088.473-.132.306-.116.603-.246.895-.385 2.075-.988 3.805-2.563 5.088-4.631.096-.156.198-.306.29-.467.722-1.636 1.44-3.257 1.8-5.022.03-.163.058-.328.086-.494.032-.2.067-.398.09-.602.082-.763.146-1.526.197-2.289.015-.255.027-.51.039-.765.014-.256.027-.513.038-.769.054-1.38.08-2.761.09-4.143.008-3.11-.073-6.22-.04-9.33"
          fill="#005573"
        />
      </g>
    </svg>
  );
};

export default WizLogo;
