import { createSlice } from '@reduxjs/toolkit';
import { Task } from './tasks';
export interface TimeFrameRpt {
  startTimeFrame: string;
  windData: number[];
}

const initialState: TimeFrameRpt = { startTimeFrame: '', windData: [] };

const timeFrameRptSlice = createSlice({
  name: 'timeframerpt',
  initialState,
  reducers: {
    setStartTimeFrame(state, action) {
      state.startTimeFrame = action.payload;
    },
    setWindData(state, action) {
      state.windData = action.payload;
    },
    resetTimeFrame: () => initialState,
  },
});

export const timeFrameRptActions = timeFrameRptSlice.actions;

export default timeFrameRptSlice.reducer;
