// @ts-nocheck
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { useEffect, useState } from 'react';
import { Loading } from '../../../../styled';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
} from '../../content/styled';
import TelemetryCard from './TelemetryCard/TelemetryCard';
import { ReactComponent as KgIcon } from '../../../../../assets/icons/kg-icon.svg';
import { ReactComponent as UpDownArrowIcon } from '../../../../../assets/icons/up-down-arrow.svg';
import { useAppSelector } from '../../../../../store/hooks';
import dayjs from 'dayjs';
import moment from 'moment';

// This is necessary to enable gauge and solid gauge chart types
highchartsMore(Highcharts);
solidGauge(Highcharts);

const MetricsChart = ({
  onDashboard,
  startDate,
  isLoadingTelemetries,
  telemetries,
  isTelemetryRefreshedFromWindTab,
  height = 180,
}: any) => {
  const [windSpeedOptions, setWindSpeedOptions] = useState({});
  const [windSpeed, setWindSpeed] = useState('0');
  const [weight, setWeight] = useState('0');
  const [slewingAngleDeg, setSlewingAngleDeg] = useState('0');
  const [hookDistanceFromGround, setHookDistanceFromGround] = useState('0');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [currentCrane, setCurrentCrane] = useState('');
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(true);

  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );

  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  useEffect(() => {
    if (selectedCrane && selectedCrane !== currentCrane) {
      setCurrentCrane(selectedCrane);
    }
  }, [selectedCrane, isLoadingTelemetries, selectedDate]);

  useEffect(() => {
    // console.log('telemetries', telemetries);
    if (!telemetries || telemetries.length === 0) {
      if (!isLoadingTelemetries) setWindSpeed('0');

      return;
    }

    const timestamp = telemetries[telemetries.length - 1].meta.timestamp;
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanos / 1000000;
    const sampleTime = dayjs(milliseconds);

    const isSampleTimeMoreThan15Minutes =
      dayjs().diff(sampleTime, 'minute') > 15;
    // console.log('sampleTime', sampleTime.format('YYYY-MM-DD HH:mm:ss'));
    if (isSampleTimeMoreThan15Minutes) {
      return;
    }

    const wind_speed = Number(
      telemetries[telemetries.length - 1].control.wind_speed_kmh,
    ).toFixed(1);

    setWindSpeed(wind_speed < 0 ? 0 : wind_speed);
    const cargo_weight_kg = Number(
      telemetries[telemetries.length - 1].control.cargo_weight_kg,
    ).toFixed(1);
    setWeight(
      cargo_weight_kg < 0 || cargo_weight_kg > 50 * 1000 ? 0 : cargo_weight_kg,
    );

    const slewing_angle_degrees = Number(
      telemetries[telemetries.length - 1].control.slewing_angle_degrees,
    );
    const calculatedSlewingAngle = (90 - slewing_angle_degrees) % 360;
    const finalSlewingAngle =
      calculatedSlewingAngle < 0
        ? 360 + calculatedSlewingAngle
        : calculatedSlewingAngle;
    const finalRoundedSlewingAngle =
      finalSlewingAngle > 360 ? 360 : finalSlewingAngle;

    setSlewingAngleDeg(
      slewing_angle_degrees === 0
        ? '0'
        : Number(finalRoundedSlewingAngle).toFixed(1),
    );

    const hook_distance_from_ground = Number(
      telemetries[telemetries.length - 1].calculated.hook_position.z,
    ).toFixed(1);
    setHookDistanceFromGround(
      hook_distance_from_ground < 0 ? 0 : hook_distance_from_ground,
    );

    setWindSpeedOptions({
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '75px',
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          scale: sm ? 1.1 : 1,
        },
      },
      title: {
        text: windSpeed ? windSpeed : '',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          display: 'none',
        },
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '95%'], // Center vertically lower
        size: sm ? '110%' : '150%',
      },
      yAxis: {
        min: 0,
        max: 60,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        labels: {
          distance: 10, // Bring labels closer to the gauge
          style: {
            fontSize: '10px', // Smaller font size to fit labels within the gauge
          },
        },
        plotBands: [
          {
            from: 0,
            to: 45,
            color: '#00c1b6',
            thickness: '50%',
          },
          {
            from: 45,
            to: 60,
            color: '#DF5353', // red
            thickness: '50%',
          },
        ],
      },
      series: [
        {
          name: 'Speed',
          data: [Number(wind_speed)],
          tooltip: {
            valueSuffix: ' km/h',
          },
          dataLabels: {
            format: '{y} km/h',
            borderWidth: 0,
            color:
              (Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              '#ff6f00',
            style: {
              fontWeight: 'bold',
              fontSize: '36px',
              color: '#ff6f00',
              display: 'none',
            },
          },
        },
      ],
      exporting: {
        enabled: false, // Remove the hamburger menu from the chart
      },
    });
  }, [telemetries]);

  return (
    <ChartStack
      height={'100%'}
      onDashboard={onDashboard}
      sx={
        {
          // minHeight: 220,z
        }
      }
    >
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography
          sx={
            sm && onDashboard
              ? { paddingTop: 0, fontSize: '24px', paddingBottom: 2 }
              : { fontSize: '24px' }
          }
        >
          Metrics
        </Typography>
        {!isLoadingTelemetries && telemetries.length === 0 && (
          <ChartStackContent
            sx={{
              padding: 4,
              width: '100%',
              height: height + 32,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            spacing={4}
          >
            <Typography variant="h2Medium" color="#878a99">
              {'No data to display'}
            </Typography>
          </ChartStackContent>
        )}

        {!(!isLoadingTelemetries && telemetries.length === 0) &&
          !(isLoadingTelemetries && !isTelemetryRefreshedFromWindTab) &&
          !(isLoadingTelemetries && !isTelemetryRefreshedFromWindTab) &&
          !(
            (!isLoadingTelemetries || isTelemetryRefreshedFromWindTab) &&
            telemetries.length > 0 &&
            windSpeed !== '0'
          ) && (
            <ChartStackContent
              sx={{
                padding: 4,
                width: '100%',
                height: height + 32,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              spacing={4}
            >
              <Typography variant="h2Medium" color="#878a99">
                {'No data to display'}
              </Typography>
            </ChartStackContent>
          )}
      </ChartStackHeader>

      {isLoadingTelemetries && !isTelemetryRefreshedFromWindTab && (
        <Loading style={{ marginTop: medium || sm ? 150 : 'initial' }} />
      )}

      {(!isLoadingTelemetries || isTelemetryRefreshedFromWindTab) &&
        telemetries.length > 0 &&
        windSpeed !== '0' && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-around"
            height={'100%'}
          >
            <TelemetryCard
              title={'Wind Speed'}
              units={'Km/h'}
              value={windSpeed}
              titleStyle={{
                marginTop: sm ? '-5px' : '-13px',
                zIndex: 10,
              }}
              icon={
                <HighchartsReact
                  highcharts={Highcharts}
                  options={windSpeedOptions}
                  containerProps={{
                    style: {
                      width: '120px',
                    },
                  }}
                />
              }
            />
            <TelemetryCard
              title={'Weight'}
              units={'Kg'}
              value={weight}
              icon={<KgIcon style={{ marginBottom: 4 }} />}
            />
            <TelemetryCard
              title={'Slewing'}
              units={'Degrees'}
              value={slewingAngleDeg}
              icon={<RotateRightIcon sx={{ fontSize: 55 }} />}
            />
            <TelemetryCard
              title={'Hook Distance'}
              units={'Meters from ground'}
              value={hookDistanceFromGround}
              icon={
                <UpDownArrowIcon style={{ height: '45px', marginBottom: 4 }} />
              }
            />
          </Grid>
        )}
    </ChartStack>
  );
};

export default MetricsChart;
