import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Typography, useMediaQuery } from '@mui/material';
import WizLogo from '../../../assets/icons/wiz-logo';

const Logo = ({ responsive }: { responsive?: boolean }) => {
  const navigate = useNavigate();
  const sm = useMediaQuery('(max-width: 600px)');

  const handleLogin = () => {
    navigate('/');
  };

  const sx = responsive
    ? {
        // mr: 2,
        display: { xs: 'flex', md: 'none' },
        flexGrow: 1,
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        justifyContent: sm ? 'inherit' : 'center',
      }
    : {
        mr: 6,
        display: { xs: 'none', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'pointer',
      };

  return (
    <Typography
      variant="h1Medium"
      noWrap
      component="a"
      onClick={handleLogin}
      sx={sx}
      minWidth={'50px'}
    >
      <Avatar sx={{ backgroundColor: 'common.white' }}>
        <WizLogo width="30" height="30" />
      </Avatar>
    </Typography>
  );
};

export default Logo;
