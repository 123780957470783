import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import { Checkbox, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { CheckboxRenderer } from '../../components/grid';

export default (
  ROW_HEIGHT: string | number,
  onDelete?: any,
  onActive?: any,
  onRecording?: any,
) => {
  return [
    {
      headerName: 'ID',
      field: 'id',
      minWidth: 320,
      maxWidth: 320,
    },
    // {
    //   headerName: 'Identity Name',
    //   field: 'identityName',
    //   sortable: true,
    //   sort: 'asc',
    //   minWidth: 300,
    //   maxWidth: 300,
    // },
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
      minWidth: 150,
    },
    {
      headerName: 'Site',
      field: 'site.name',
      sortable: true,
      sort: 'asc',
      minWidth: 150,
    },
    {
      headerName: 'Site ID',
      field: 'siteId',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    // {
    //   headerName: 'Company',
    //   field: 'site.company.name',
    //   sortable: true,
    //   minWidth: 150,
    // },
    {
      headerName: 'Latitude',
      field: 'latitude',
      sortable: false,
      minWidth: 100,
    },
    {
      headerName: 'Longitude',
      field: 'longitude',
      sortable: false,
      minWidth: 120,
    },
    {
      headerName: 'Active',
      field: 'isActive',
      sortable: true,
      cellRendererFramework: CheckboxRenderer,
      minWidth: 120,
      cellRendererParams: {
        onClick: onActive,
      },
    },
    {
      headerName: 'Status',
      field: 'isConnected',
      sortable: true,
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: (data: any) => {
        const status = data.data.isConnected;
        return (
          <>
            {status ? (
              <Tooltip title="Active">
                <SignalWifiStatusbar4BarIcon sx={{ color: 'green' }} />
              </Tooltip>
            ) : (
              <Tooltip title="Inactive">
                <NotInterestedIcon sx={{ color: 'red' }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      headerName: 'Recording',
      field: 'isRecording',
      sortable: true,
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: (data: any) => {
        const isRecording = data.data.isRecording;
        const isCraneConnected = data.data.isConnected;
        return (
          <Checkbox
            checked={isRecording}
            onChange={(e) => onRecording(data.data.id)}
            disabled={!isCraneConnected}
          />
        );
      },
    },
    {
      headerName: 'Last Connection',
      field: 'lastConnectionTime',
      sortable: true,
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: (data: any) => {
        const lastStart = data.data.lastConnectionTime;
        return (
          <>{lastStart ? dayjs(lastStart).format('HH:mm:ss DD/MM/YYYY') : ''}</>
        );
      },
    },
    {
      headerName: 'Version',
      field: 'version',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
    },
    // {
    //   headerName: 'ACTIONS',
    //   sortable: false,
    //   field: 'ID',
    //   minWidth: 140,
    //   maxWidth: 140,
    //   cellRendererFramework: ActionsRenderer,
    //   cellRendererParams: {
    //     onDelete,
    //     rowHeight: ROW_HEIGHT,
    //   },
    // },
  ];
};
