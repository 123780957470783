// @ts-nocheck
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useCallback, useState } from 'react';
import { LayoutStack } from '../../../../../../../components/styled';
import { Point } from '../../../../../../CreateZone';
import { ZonesToolbar } from './components';
import ZonesMap, { Zone, ZonesMapMode } from './components/ZonesMap';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/hooks';
import { projectCraneActions } from '../../../../../../../store/projectcrane';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ZonesDialog = (props: any) => {
  const { siteId, handleClose, projectName, open } = props;

  const [dialogState, setDialogState] = useState('view');
  const [focusedZone, setFocusedZone] = useState(-1);
  const dispatch = useAppDispatch();
  const zones = useAppSelector((state: any) => state.projectCrane.Zones);

  //#region Add zone logic
  const [Start, setStart] = useState<any>({
    lat: 32.07040923,
    lng: 34.79577723,
  });
  const [Radius, setRadius] = useState(0);
  const [PolyPoints, setPolyPoints] = useState<Point[]>([]);
  const [relpxxAdd, setRelpxxAdd] = useState(0);
  const [ZoneTypeId, setZoneTypeId] = useState('');
  const [siteCenter, setSiteCenter] = useState({ lat: 0, lng: 0 });
  const [newZone, setNewZone] = useState<Zone>({
    name: '',
    height: 0,
    isActive: true,
    points: [],
  });

  const handleAddZone = useCallback((newZone) => {
    const newZones = [
      ...zones,
      {
        name: newZone.name,
        height: newZone.height,
        active: newZone.active,
        points: newZone.points,
      },
    ];
    dispatch(projectCraneActions.setZones(newZones));
  }, []);

  const startNewZone = () => {
    setNewZone({
      name: '',
      height: 0,
      isActive: true,
      points: [],
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              color: 'common.white',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: 'common.white' }}
            variant="h2Medium"
            component="div"
          >
            {projectName} - Anti-collision zones
          </Typography>
        </Toolbar>
      </AppBar>
      <LayoutStack
        sx={{ backgroundColor: 'backgrounds.system', padding: 4 }}
        direction="row"
      >
        <ZonesToolbar
          {...{
            dialogState,
            setDialogState,
            siteId,
            focusedZone,
            setFocusedZone,
            Start,
            setStart,
            Radius,
            setRadius,
            PolyPoints,
            setPolyPoints,
            ZoneTypeId,
            setZoneTypeId,
            relpxxAdd,
            handleAddZone,
            startNewZone,
            newZone,
            setNewZone,
            setSiteCenter,
            siteCenter,
          }}
        />

        <ZonesMap
          {...{
            siteCenter,
            newZone,
            setNewZone,
            isCreateModeActive: dialogState === 'add',
            zonesMapMode: ZonesMapMode.edit,
          }}
        />

        {/* <ZonesContent
          {...{
            dialogState,
            projectId,
            focusedZone,
            relpxxAdd,
            setRelpxxAdd,
            Start,
            setStart,
            Radius,
            setRadius,
            PolyPoints,
            setPolyPoints,
            ZoneTypeId,
          }}
        /> */}
      </LayoutStack>
    </Dialog>
  );
};

export default ZonesDialog;
