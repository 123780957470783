import { useMutation } from 'react-query';
import { apiPutAsync } from '../api/axios';

const api = (props: any) => apiPutAsync(`crane/${props.id}/active`);

export const usePutCraneActivationStatus = (options?: any) => {
  const key = ['PutCraneActivationStatus'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
