import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ErrorAlert } from '../../../../components/alerts';
import {
  useGetCraneVideoRecordings,
  usePutRequestCraneVideoRecordings,
} from '../../../../queries';
import { useAppSelector } from '../../../../store/hooks';
import Recordings from './Recordings';
import { Loading } from '../../../../components/styled';
import dayjs from 'dayjs';

const AddVideoRecordingsModal = ({
  onClose,
  onSuccess,
  extractCameraType,
  extractVideoLength,
}: any) => {
  const [selectedRecordings, setSelectedRecordings] = useState<any[]>([]);
  const [isCraneConnected, setIsCraneConnected] = useState(false);
  const [availableRecordings, setAvailableRecordings] = useState([]);
  const queryClient = useQueryClient();
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );
  const sm = useMediaQuery('(max-width: 600px)');

  const {
    isFetching: videoRecordingsIsLoading,
    error: videoRecordingsError,
    refetch: videoRecordingsRefetch,
    data: videRecordingsData,
  } = useGetCraneVideoRecordings(
    {
      craneId: selectedCrane,
    },
    {
      enabled: false,
      onSuccess: (res: any) => {
        const { crane, recordings } = res.data;
        setIsCraneConnected(crane.isConnected);
        const newVideoRecordings = recordings
          .map((item: any) => {
            const cameraType = extractCameraType(item.path);
            const { date, time } = extractVideoLength(item.path);
            return {
              ...item,
              cameraType,
              date,
              time,
            };
          })
          .sort((a: any, b: any) => b.date - a.date);

        const groupedRecordings = newVideoRecordings.reduce(
          (acc: any, item: any) => {
            const dateStr = item.date.format('YYYY-MM-DD');
            if (!acc[dateStr]) {
              acc[dateStr] = [];
            }
            acc[dateStr].push(item);
            return acc;
          },
          {},
        );

        const availableGroupedRecordings: any = Object.keys(
          groupedRecordings,
        ).reduce((acc: any, date: string) => {
          const recordings = groupedRecordings[date].filter(
            (item: any) => !item.isRequested,
          );
          if (recordings.length > 0) {
            return [
              ...acc,
              {
                date,
                recordings,
              },
            ];
          }
          return acc;
        }, []);

        // // Sort requested recordings by date, and inner recordings by time
        // availableGroupedRecordings.forEach((group: any) => {
        //   const sortedRecordings = group.recordings.sort((a: any, b: any) => {
        //     console.log(a, b);
        //     const dateString = dayjs().format('YYYY-MM-DD');
        //     const aDateTimeString = `${dateString} ${a.time}`;
        //     const aDayjs = dayjs(aDateTimeString, 'YYYY-MM-DD HH:mm:ss');
        //     const bDateTimeString = `${dateString} ${b.time}`;
        //     const bDayjs = dayjs(bDateTimeString, 'HH:mm:ss');
        //     return aDayjs.isBefore(bDayjs) ? -1 : 1;
        //   });
        //   return {
        //     ...group,
        //     recordings: sortedRecordings,
        //   };
        // });

        // Sort recordings by time within each date group
        availableGroupedRecordings.forEach((group: any) => {
          group.recordings.sort((a: any, b: any) => {
            const aDayjs = dayjs(
              `${dayjs().format('YYYY-MM-DD')} ${a.time}`,
              'YYYY-MM-DD HH:mm:ss',
            );
            const bDayjs = dayjs(
              `${dayjs().format('YYYY-MM-DD')} ${b.time}`,
              'YYYY-MM-DD HH:mm:ss',
            );
            return aDayjs.isBefore(bDayjs) ? -1 : 1;
          });
        });

        setAvailableRecordings(availableGroupedRecordings);
      },
    },
  );

  useEffect(() => {
    videoRecordingsRefetch();
  }, []);

  const { mutate, isLoading, error } = usePutRequestCraneVideoRecordings({
    onSuccess: () => {
      onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostProjectCrane');
    },
  });

  const handleSubmit = async () => {
    const recordings = availableRecordings.reduce(
      (acc: any, item: any) => acc.concat(item.recordings),
      [],
    );
    mutate({
      craneId: selectedCrane,
      paths: recordings
        .filter((item: any) => selectedRecordings.includes(item.id))
        .map((item: any) => item.path),
    });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      PaperProps={{
        style: {
          margin: sm ? '12px' : '24px',
          width: '100%',
        },
      }}
    >
      <DialogTitle>
        Available Recordings
        <RadioButtonCheckedIcon
          sx={{ fontSize: '24px', marginLeft: '5px', color: 'red' }}
        />
      </DialogTitle>
      <DialogContent sx={{ padding: sm ? '0px 8px' : '0px 20px' }}>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          {videoRecordingsIsLoading ? (
            <Loading />
          ) : (
            <>
              {isCraneConnected ? (
                <>
                  {availableRecordings.length > 0 ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: sm ? '14px' : '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        Select the recordings you would like to request
                      </Typography>
                      <Recordings
                        recordings={availableRecordings}
                        selectedRecordings={selectedRecordings}
                        setSelectedRecordings={setSelectedRecordings}
                      />
                    </>
                  ) : (
                    <Typography sx={{ fontSize: sm ? '14px' : '16px' }}>
                      No recordings available
                    </Typography>
                  )}
                </>
              ) : (
                <Typography sx={{ fontSize: sm ? '14px' : '16px' }}>
                  Crane is not connected
                </Typography>
              )}
            </>
          )}
        </Stack>
        {(error || videoRecordingsError) && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || selectedRecordings.length === 0}
        >
          {isLoading ? <CircularProgress size={16} /> : 'Request'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVideoRecordingsModal;
