import { useMutation } from 'react-query';
import { apiPostAsync } from '../api/axios';

const api = (props: any) => {
  const { userId, fcmToken, ...restData } = props;
  return apiPostAsync(
    `user/${userId}/notifications/subscribe/${fcmToken}`,
    restData,
  );
};

export const usePostSubscribeDeviceToTopics = (options?: any) => {
  const key = ['PostSubscribeDeviceToTopics'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
