import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface GanttTask {
  id: number;
  catid: number;
  category: string;
  color: string;
  syear: number;
  smonth: number;
  sday: number;
  shour: number;
  sminute: number;
  eyear: number;
  emonth: number;
  eday: number;
  ehour: number;
  eminute: number;
}
export interface GanttTasks {
  gantttasks: GanttTask[];
}

const initialState: GanttTasks = {
  gantttasks: [
    {
      id: 0,
      catid: 0,
      category: '',
      color: '',
      syear: 0,
      smonth: 0,
      sday: 0,
      shour: 0,
      sminute: 0,
      eyear: 0,
      emonth: 0,
      eday: 0,
      ehour: 0,
      eminute: 0,
    },
  ],
};

const ganttTasksSlice = createSlice({
  name: 'gantttasks',
  initialState: initialState,
  reducers: {
    setGanttTasks(state, action: PayloadAction<GanttTask[]>) {
      state.gantttasks = action.payload;
      //    console.log(state.idletimes);
    },
    resetGanttTasks: () => initialState,
  },
});

export const ganttTasksActions = ganttTasksSlice.actions;

export default ganttTasksSlice.reducer;
