import React from 'react';
import { Button, ButtonGroup, Stack, useMediaQuery } from '@mui/material';
import { Settings } from './Settings';

export const Sensors = ({
  setSensor,
  sensor,
  settings,
  onSettingsChange,
}: any) => {
  const sm = useMediaQuery('(min-width: 600px)');

  return (
    <Stack
      spacing={8}
      direction="row"
      width="100%"
      justifyContent="center"
      sx={{ position: 'relative' }}
    >
      <ButtonGroup>
        {[
          // <Button
          //   key="Sen_0"
          //   onClick={() => setSensor('Sen_0')}
          //   variant={sensor === 'Sen_0' ? 'contained' : 'outlined'}
          // >
          //   Panoramic
          // </Button>,
          // <Button
          //   key="Tail"
          //   onClick={() => setSensor('Tail')}
          //   variant={sensor === 'Tail' ? 'contained' : 'outlined'}
          // >
          //   Tail
          // </Button>,
          <Button
            key="Trolley"
            onClick={() => setSensor('Trolley')}
            variant={sensor === 'Trolley' ? 'contained' : 'outlined'}
          >
            Trolley
          </Button>,
          // <Button
          //   key="3D"
          //   onClick={() => setSensor('3D')}
          //   variant={sensor === '3D' ? 'contained' : 'outlined'}
          // >
          //   3D
          // </Button>,
        ]}
      </ButtonGroup>
      {sm && (
        <Stack sx={{ position: 'absolute', right: 0 }}>
          <Settings {...{ settings, onSettingsChange }} />
        </Stack>
      )}
    </Stack>
  );
};
