export default () => {
  return [
    {
      headerName: '',
      field: 'crane',
      minWidth: 250,
      maxWidth: 250,
    },
    {
      headerName: 'Total idle time',
      field: 'idleTime',
      minWidth: 420,
      maxWidth: 420,
    },
  ];
};
