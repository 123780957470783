// @ts-nocheck
import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CraneLiftImages from './CraneLiftImages';

interface ProjectReportPrintProps {
  onClose: () => void;
  startDate: string;
}

const CraneLiftImagesDialog = ({
  onClose,
  startDate,
}: ProjectReportPrintProps) => {
  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        Crane Lift Images
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: '20px 12px', padding: 0 }}>
        <CraneLiftImages startDate={startDate} />
      </DialogContent>
    </Dialog>
  );
};

export default CraneLiftImagesDialog;
