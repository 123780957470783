import React from 'react';
import moment from 'moment/moment';
import { Stack, Typography } from '@mui/material';
import { DateFormatTypes } from '../../../variables/enums';

const DateRenderer = ({
  value,
  formatType = DateFormatTypes.MOMENT_DATE,
}: any) => {
  if (!value) return null;

  return (
    <Stack
      sx={{
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <Typography variant="mediumRegular">
        {moment(value).format(formatType)}
      </Typography>
    </Stack>
  );
};

export default DateRenderer;
