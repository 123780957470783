export default () => {
  return [
    {
      headerName: 'Lifts',
      field: 'lifts',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Duration [Minutes]',
      field: 'duration',
      minWidth: 260,
      maxWidth: 260,
    },
    {
      headerName: 'Start time',
      field: 'startTime',
      minWidth: 200,
      maxWidth: 200,
    },
  ];
};
