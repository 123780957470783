import React from 'react';
import { Stack, Typography, Avatar } from '@mui/material';
import imageBackground from '../../assets/images/industrial-cranes.png';

const EmptyState = ({ showDescriprion = true }: any, ...rest: any) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      flex={1}
      {...rest}
    >
      <Avatar
        sx={{ backgroundColor: 'backgrounds.system', width: 140, height: 140 }}
      >
        <img src={imageBackground} alt="No results found" width={150} />
      </Avatar>
      <Typography variant="h1SemiBold">No results found</Typography>
      {showDescriprion && (
        <Typography
          variant="mediumRegular"
          color="typography.negativeDisabled"
          sx={{ textAlign: 'center' }}
        >
          Try adjusting your filter to find what your are looking for
        </Typography>
      )}
    </Stack>
  );
};

export default EmptyState;
