import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Badge, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { GridToolbar } from '../styled';
import { NextPrevDate, ProjectCraneSelector } from '../selectors';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';

const AppHeader = ({ startDate, setStartDate, onRefresh }: any) => {
  const sm = useMediaQuery('(max-width: 600px)');

  return (
    <GridToolbar>
      <ProjectCraneSelector
        sx={{ justifyContent: 'flex-start', width: '100%' }}
      >
        <Stack
          spacing={2}
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">Date</Typography>
          <DatePicker
            sx={{
              width: sm ? '100%' : 220,
            }}
            format="DD/MM/YYYY"
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            value={startDate}
            onChange={setStartDate}
            disableFuture={true}
          />
        </Stack>
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">&nbsp;</Typography>
          <Stack direction="row" alignItems="center" spacing={6}>
            <NextPrevDate
              defaultDate={startDate}
              onDateChange={(date) => setStartDate(moment(date))}
            />
            <Badge color="success">
              <Button
                variant="outlined"
                sx={{ height: 39 }}
                onClick={onRefresh}
              >
                <RefreshIcon />
              </Button>
            </Badge>
          </Stack>
        </Stack>
      </ProjectCraneSelector>
    </GridToolbar>
  );
};

export default AppHeader;
