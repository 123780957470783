import { ActionsRenderer } from '../../../../../../components/grid';

export default (ROW_HEIGHT: string | number, onDelete?: any) => {
  return [
    {
      headerName: 'ID',
      field: 'ID',
      sortable: true,
      sort: 'asc',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Distance',
      field: 'Distance',
    },
    {
      headerName: 'Kg',
      field: 'Kg',
    },
    {
      headerName: 'ACTIONS',
      sortable: false,
      field: 'ID',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: ActionsRenderer,
      cellRendererParams: {
        onDelete,
        rowHeight: ROW_HEIGHT,
      },
    },
  ];
};
