import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  StandardTextFieldProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';

export interface SearchInputProps extends StandardTextFieldProps {
  minWidth?: number;
  onClear: () => void;
}

export const SearchInput = ({
  value,
  minWidth = 320,
  onClear,
  ...rest
}: SearchInputProps) => {
  return (
    <TextField
      {...rest}
      fullWidth
      autoComplete="off"
      type="text"
      inputProps={{
        autoComplete: 'new-search',
        form: {
          autoComplete: 'off',
        },
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        sx: { minWidth },
        autoComplete: 'off',
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: !!value && (
          <InputAdornment position="end">
            <IconButton onClick={onClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
    />
  );
};
