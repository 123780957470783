import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) => apiGetAsync(`company/${props.companyId}`);

export const useGetCompany = (props: any, options?: any) => {
  const key = ['GetCompany', Object.values(props)];
  const fn = () => api(props);

  return useQuery(key, fn, {
    enabled: !!props.companyId,
    ...(options || {}),
  });
};
