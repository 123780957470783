import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProjectReport from './ProjectReport';

interface ProjectReportPrintProps {
  onClose?: () => void;
  startDate: string;
  projectId: number;
  craneIds?: number[];
}

const ProjectReportDialog = ({
  onClose,
  startDate,
  projectId,
  craneIds,
}: ProjectReportPrintProps) => {
  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        Daily {craneIds === undefined ? 'Project' : 'Crane'} Report
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: '20px 12px', padding: 0 }}>
        <ProjectReport
          startDate={startDate}
          projectId={projectId}
          craneIds={craneIds}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProjectReportDialog;
