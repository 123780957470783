import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  ContentStack,
  GridToolbar,
  LayoutStack,
  Loading,
  MainTitle,
} from '../../components/styled';
import { Button, CircularProgress, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDeleteCompany, useGetCompanies } from '../../queries';
import { ErrorState } from '../../components/alerts';
import { Grid } from '../../components/grid';
import getColumnsDef from './columns';
import { CreateCompany } from './index';
import { ConfirmDialog } from '../../components/confirm';

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const Companies = () => {
  const navigate = useNavigate();

  // #region react-query
  const queryClient = useQueryClient();

  const { data, error, refetch, isFetching } = useGetCompanies();

  const {
    mutate: deleteCompanyMutate,
    isLoading: deleteCompanyIsLoading,
    error: deleteCompanyError,
    data: deleteCompanyData,
    reset: deleteCompanyReset,
  } = useDeleteCompany({
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries('DeleteCompany');
    },
  });

  //#endregion

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>();

  const onCreate = (open: boolean) => {
    setCreateOpen(open);
  };

  const onCreateSuccess = async () => {
    await refetch();
    setCreateOpen(false);
  };

  const onRowClicked = (e: any) => navigate(`/company/${e.data.id}`);

  const onDelete = (deleteItemId?: number) => {
    setDeleteItemId(deleteItemId);
  };

  const onDeleteConfirmed = () =>
    deleteCompanyMutate({ companyId: deleteItemId });

  const onDeleteSuccess = async () => {
    deleteCompanyReset();
    onDelete();
    setTimeout(async () => await refetch(), 100);
  };

  const onDeleteClose = () => {
    deleteCompanyReset();
    onDelete();
  };

  return (
    <LayoutStack>
      <MainTitle>Companies List</MainTitle>
      <GridToolbar>
        <Stack>
          <Button
            variant="contained"
            onClick={() => onCreate(true)}
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <Button
            variant="outlined"
            disabled={isFetching}
            onClick={() => refetch()}
          >
            {isFetching ? <CircularProgress size={16} /> : <RefreshIcon />}
          </Button>
        </Stack>
      </GridToolbar>
      <ContentStack>
        {isFetching && !error && <Loading />}
        {!isFetching && error && <ErrorState error={error} />}
        {!isFetching && !error && data?.data.length && (
          <Grid
            {...{
              rows: data.data,
              columnsDef: getColumnsDef(ROW_HEIGHT, onDelete),
              onRowClicked,
              onDelete,
              ROW_HEIGHT,
              maxVisibleRows,
              options: {
                rowStyle: { cursor: 'pointer' },
              },
            }}
          />
        )}
        {createOpen && (
          <CreateCompany
            onClose={() => onCreate(false)}
            onSuccess={onCreateSuccess}
          />
        )}

        {!!deleteItemId && (
          <ConfirmDialog
            title="Delete Company"
            text="Are you sure you want to delete this company?"
            onOk={onDeleteConfirmed}
            onCancel={onDeleteClose}
            onDeleteSuccess={onDeleteSuccess}
            asyncApi={{
              loading: deleteCompanyIsLoading,
              error: deleteCompanyError,
              data: deleteCompanyData,
            }}
          />
        )}
      </ContentStack>
    </LayoutStack>
  );
};

export default Companies;
