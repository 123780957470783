import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) => apiGetAsync('user/profile');

export const useGetUserProfile = (props: any, options?: any) => {
  const key = ['GetUserProfile', Object.values(props)];
  const fn = () => api(props);

  return useQuery(key, fn, { cacheTime: 0, ...(options || {}) });
};
