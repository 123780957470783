// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { sumBy, times, mean } from 'lodash';
import { ChartStack } from '../content/styled';

const CranesWeeklyAverageUtilization = (props: any) => {
  const { records, project } = props;

  const daysCount = records.length;
  const cranesCount = Number(project.CranesCount);

  const seriesData = times(cranesCount, (crane) =>
    mean(
      times(daysCount, (day) => {
        const craneDayRecords = records[day]?.records[crane]?.records || [];

        if (craneDayRecords.length === 0) return 0;

        const idle = sumBy(craneDayRecords, (r) => r.idle.seconds);
        const load = sumBy(craneDayRecords, (r) => r.load.seconds);
        const unload = sumBy(craneDayRecords, (r) => r.unload.seconds);
        const transitionWithoutLoad = sumBy(
          craneDayRecords,
          (r) => r.transitionWithoutLoad.seconds,
        );

        const transitionWithLoad = sumBy(
          craneDayRecords,
          (r) => r.transitionWithLoad.seconds,
        );

        const transition = transitionWithLoad + transitionWithoutLoad;

        return +(
          ((load + unload + transition) / (load + unload + transition + idle)) *
          100
        ).toFixed(2);
      }),
    ),
  );

  const categories = records[0].records.map((record) => record.crane.name);

  const options = {
    chart: {
      type: 'column',
      height: 300,
    },
    exporting: { enabled: false },
    colors: ['#4572C4'],
    title: {
      text: 'Cranes Weekly Average Utilization [%]',
    },
    xAxis: {
      categories: [...categories, 'All Cranes'],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: { text: undefined },
      labels: {
        format: '{value}%',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}%',
        },
      },
    },
    series: [
      {
        name: 'Weekly Average Utilization',
        data: [...seriesData, mean(seriesData)],
      },
    ],
  };

  return (
    <ChartStack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartStack>
  );
};

export default CranesWeeklyAverageUtilization;
