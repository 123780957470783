import React, { useReducer } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { ErrorAlert } from '../../../../../../components/alerts';
import { usePostCraneAllowWeight } from '../../../../../../queries';
import { REGEX_VALIDATION } from '../../../../../../variables/constants';
import { FormControl } from '../../../../../../components/inputs';

const initialState = {
  Distance: '',
  Kg: '',
};
const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const CreateCraneAllowWeightDialog = ({ onClose, onSuccess, craneId }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = usePostCraneAllowWeight({
    onSuccess: () => {
      onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostCraneAllowWeight');
    },
  });

  const handleSubmit = async () => {
    mutate({ ...state, CraneId: craneId });
  };

  const valid = state.Distance.trim() !== '' && state.Kg.trim() !== '';

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Create Allow Weight</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <FormControl label="Distance" required>
            <TextField
              fullWidth
              value={state.Distance}
              autoFocus
              onChange={(e) => {
                if (REGEX_VALIDATION.distancePattern.test(e.target.value)) {
                  dispatch({ type: 'Distance', payload: e.target.value });
                }
              }}
            />
          </FormControl>
          <FormControl label="Kg" required>
            <TextField
              fullWidth
              value={state.Kg}
              onChange={(e) => {
                if (REGEX_VALIDATION.kgPattern.test(e.target.value)) {
                  dispatch({ type: 'Kg', payload: e.target.value });
                }
              }}
            />
          </FormControl>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!valid || isLoading}
        >
          {isLoading ? <CircularProgress size={16} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCraneAllowWeightDialog;
