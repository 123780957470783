import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { convertToTopicNotification } from '../../../../../utils/helpers';
import * as storageHelper from '../../../../../utils/storageHelper';
import { USER } from '../../../../../variables/constants';
import { UserNotificationTypes } from '../../../../../variables/enums';
import { FormGroup } from '../../../../inputs';

const ProjectNotification = (props: any) => {
  const {
    site,
    cranes,
    company,
    userNotifications,
    dispatchUserNotifications,
    setNotificationsData,
    notificationsData,
  } = props;
  const user = storageHelper.getItem(USER);

  const rows = useMemo(() => {
    return cranes.map((crane: any) => ({
      ...crane,
      windSpeed:
        notificationsData?.find(
          (notification: any) =>
            notification.companyId === site.companyId &&
            notification.siteId === site.id &&
            notification.craneId === crane.id &&
            notification.type === UserNotificationTypes.WIND_SPEED,
        )?.isActive || false,
      // windSpeed:
      //   userNotifications[
      //     convertToTopicNotification(
      //       company.id,
      //       site.id,
      //       crane.id,
      //       UserNotificationTypes.WIND_SPEED,
      //     )
      //   ] || false,
    }));
  }, [cranes, notificationsData]);

  if (cranes.length === 0) return null;

  // const user = storageHelper.getItem(USER);
  // const { CompanyId } = user;

  // const rows = cranes.map((crane: any) => ({
  //   ...crane,
  //   windSpeed:
  //     user.notifications?.find(
  //       (notification: any) =>
  //         notification.companyId === company.id &&
  //         notification.siteId === site.id &&
  //         notification.craneId === crane.id &&
  //         notification.notificationType === UserNotificationTypes.WIND_SPEED,
  //     )?.isActive || false,
  //   // windSpeed:
  //   //   userNotifications[
  //   //     convertToTopicNotification(
  //   //       company.id,
  //   //       site.id,
  //   //       crane.id,
  //   //       UserNotificationTypes.WIND_SPEED,
  //   //     )
  //   //   ] || false,
  // }));

  return (
    <FormGroup title={site.name} spacing={2}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="notification table">
          <TableHead>
            <TableRow>
              <TableCell width="140">
                <Typography variant="paragraphMedium">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="paragraphMedium">Wind Speed</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((crane: any) => (
              <TableRow
                key={crane.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {crane.name}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={crane.windSpeed}
                    onChange={(e) => {
                      const isCraneNotificationExist = notificationsData.find(
                        (notification: any) =>
                          notification.userId === user.id &&
                          notification.companyId === site.companyId &&
                          notification.siteId === site.id &&
                          notification.craneId === crane.id &&
                          notification.type ===
                            UserNotificationTypes.WIND_SPEED,
                      );
                      if (isCraneNotificationExist) {
                        setNotificationsData((prevNotifications: any) => {
                          return prevNotifications.map(
                            (notification: any, i: number) => {
                              if (notification.craneId === e.target.name) {
                                return {
                                  ...notification,
                                  isActive: e.target.checked,
                                };
                              }
                              return notification;
                            },
                          );
                        });
                      } else {
                        setNotificationsData((prev: any[]) => [
                          ...prev,
                          {
                            userId: user.id,
                            companyId: site.companyId,
                            siteId: site.id,
                            craneId: crane.id,
                            type: UserNotificationTypes.WIND_SPEED,
                            isActive: e.target.checked,
                          },
                        ]);
                      }

                      dispatchUserNotifications({
                        type: convertToTopicNotification(
                          site.companyId,
                          site.id,
                          crane.id,
                          UserNotificationTypes.WIND_SPEED,
                        ),
                        payload: e.target.checked,
                      });
                    }}
                    name={crane.id}
                    color="primary"
                    sx={{ padding: 1 }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormGroup>
  );
};

export default ProjectNotification;
