import React from 'react';
import moment from 'moment';
import { Avatar, Stack, Typography } from '@mui/material';
import WizLogo from '../../../../assets/icons/wiz-logo';
import { S3MMSITE_PATH } from '../../../../variables/endpoints';
import { DateFormatTypes } from '../../../../variables/enums';
import { Property } from './styled';

const ProjectReportHeaderSection = ({ isProjectReport, project }: any) => {
  const { LogoPath, CompanyName, ProjectName, CranesCount, reportDate } =
    project;

  return (
    <Stack sx={{ minHeight: 250 }}>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="row" spacing={4} alignItems="center">
          <Avatar sx={{ backgroundColor: 'backgrounds.system' }}>
            <WizLogo width="30" height="30" />
          </Avatar>
          <Typography variant="h1SemiBold">
            Daily {isProjectReport ? 'Project' : 'Crane'} Report
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          {LogoPath && (
            <Avatar
              sx={{ backgroundColor: 'backgrounds.system', width: 60 }}
              variant="square"
            >
              <img
                src={`${S3MMSITE_PATH}/${LogoPath}`}
                style={{ width: '50px' }}
                alt={CompanyName}
              />
            </Avatar>
          )}
        </Stack>
      </Stack>
      <Stack width="100%" sx={{ marginTop: 8 }} spacing={4}>
        <Stack direction="row" spacing={4}>
          <Property label="Company" value={CompanyName} />
          <Property label="Project" value={ProjectName} />
        </Stack>
        <Stack direction="row" spacing={4}>
          <Property label="Cranes" value={CranesCount} />
          <Property
            label="Published"
            value={moment(reportDate).format(DateFormatTypes.MOMENT_DATE)}
          />
        </Stack>
      </Stack>
      <Stack
        width="100%"
        sx={{
          backgroundColor: 'backgrounds.system',
          height: 16,
          marginTop: 6,
          marginBottom: 6,
        }}
      />
    </Stack>
  );
};

export default ProjectReportHeaderSection;
