import CallSplitIcon from '@mui/icons-material/CallSplit';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { lowerCase, snakeCase, upperFirst } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { formatTotalSecondsToDateFormat } from '../../../../../utils';
import { Grid } from '../../../../grid';
import { Property } from '../../content/styled';
import LiftModal from '../../../../LiftModal/LiftModal';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

const commonColumnWidth = 110;

const commonProps = {
  sortable: true,
  width: commonColumnWidth,
  minWidth: commonColumnWidth,
  maxWidth: commonColumnWidth,
};

const BooleanRenderer = ({ value, onChange }: any) => {
  if (value === undefined || value === null) return '';
  return value ? <CallSplitIcon /> : null;
};

const TypeRenderer = ({ value, onChange }: any) => {
  if (value === undefined || value === null) return '';
  return upperFirst(lowerCase(snakeCase(value)));
};

const ROW_HEIGHT = 48;
const maxVisibleRows = 10;

const LiftsPerHourModal = ({ item, onClose }: any) => {
  const { label, lifts: hourLifts } = item;
  const liftsByHour = useAppSelector(
    (state: any) => state.projectCrane.liftsByHour,
  );
  const allLifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const height = Math.min(item?.states?.length || 0, 7) * 48 + 190;
  const [lifts, setLift] = useState([]);
  const [nextHourLabel, setNextHourLabel] = useState('');
  const timezone =
    useAppSelector((state: any) => state.projectCrane.Timezone) ||
    'Asia/Jerusalem';
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const COLUMN_WIDTH = sm ? 100 : medium ? 100 : 170;

  const columnsDef = [
    {
      headerName: '',
      field: 'split',
      sortable: true,
      width: sm || medium ? 0 : 50,
      minWidth: sm || medium ? 0 : 50,
      maxWidth: sm || medium ? 0 : 50,
      cellRendererFramework: BooleanRenderer,
    },
    {
      headerName: '',
      children: [
        {
          field: '#',
          minWidth: COLUMN_WIDTH + 30,
          maxWidth: COLUMN_WIDTH + 50,
          cellRendererFramework: (gridData: any) => {
            const nextHourIndex =
              liftsByHour.findIndex((hour: any) => hour.label === label) + 1;
            const isLiftNotFinished = liftsByHour[nextHourIndex]?.lifts.find(
              (lift: any) => lift.id === gridData.data.id,
            );
            if (isLiftNotFinished) {
              return <Typography>In progress...</Typography>;
            }

            const liftNum =
              liftsByHour
                .find((hour: any) => hour.label === label)
                ?.lifts.findIndex((lift: any) => lift.id === gridData.data.id) +
              1;

            const previouseHourIndex =
              liftsByHour.findIndex((hour: any) => hour.label === label) - 1;

            const isStartedLastHour = liftsByHour[
              previouseHourIndex
            ]?.lifts.find((lift: any) => lift.id === gridData.data.id);

            return (
              <Typography>
                {liftNum}
                {(sm || medium) && isStartedLastHour && (
                  <span style={{ fontSize: '10px' }}>
                    {' '}
                    started previous hour
                  </span>
                )}
              </Typography>
            );
          },
          // ...defaultColumn,
        },
      ],
    },
    {
      headerName: 'Start',
      field: 'startTime',
      flex: true,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
      sort: 'asc',
      // ...commonProps,
    },
    {
      headerName: 'End',
      field: 'endTime',
      flex: true,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
      // ...commonProps,
    },
    {
      headerName: 'Duration (minutes)',
      field: 'duration',
      flex: true,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
      // ...commonProps,
    },
    {
      headerName: `Distance ${sm || medium ? '(M)' : '(Meters)'}`,
      field: 'distance',
      flex: true,
      minWidth: COLUMN_WIDTH,
      maxWidth: COLUMN_WIDTH,
      // ...commonProps,
    },
    {
      headerName: `Weight ${sm || medium ? '(T)' : '(Tons)'}`,
      field: 'weight',
      sortable: true,
      flex: true,
      minWidth: sm || medium ? COLUMN_WIDTH - 20 : COLUMN_WIDTH,
      maxWidth: sm || medium ? COLUMN_WIDTH - 20 : COLUMN_WIDTH,
      cellRendererFramework: ({ value }: any) => {
        return Number(value * 0.001).toFixed(2);
      },
    },
    {
      headerName: '',
      field: 'id',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: (data: any) => {
        const liftInHour = data.data;
        const currentLiftIndex = allLifts.findIndex(
          (l: any) => l.id === liftInHour.id,
        );
        if (currentLiftIndex === -1) return null;
        return LiftModal({ liftIndex: currentLiftIndex });
      },
    },
  ];

  useEffect(() => {
    if (!item || !Array.isArray(item?.lifts)) return;

    const liftsData = hourLifts.map((lift: any, i: number) => {
      return {
        ...lift,
        duration: formatTotalSecondsToDateFormat(lift.durationSec),
      };
    });

    setLift(liftsData);

    const currentHour = parseInt(label.split(':')[0]);
    const nextHour = currentHour + 1;
    setNextHourLabel(`${nextHour}:00`);
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: sm ? 300 : medium ? 700 : 1200,
          minWidth: sm ? 300 : medium ? 700 : 1200,
          minHeight: 650,
        },
      }}
    >
      <DialogTitle>
        {item.label} - {nextHourLabel}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 4,
          height,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" spacing={4} sx={{ pt: 8, pb: 0 }}>
          <Property
            label="Idle"
            value={formatTotalSecondsToDateFormat(item.idleSec)}
            textFontSize={14}
            valueFontSize={16}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          />
          <Property
            label="Operation"
            value={formatTotalSecondsToDateFormat(item.operationSec)}
            textFontSize={14}
            valueFontSize={16}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          />
        </Stack>
        {hourLifts.length === 0 ? (
          <Stack
            sx={{
              width: '100%',
              height: 300,
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 50,
            }}
          >
            <Property
              label="No lifts to display"
              value=""
              textFontSize={16}
              valueFontSize={22}
              sx={{ border: 'none' }}
            />
          </Stack>
        ) : (
          <Grid
            {...{
              className: 'grid-small',
              rows: lifts,
              columnsDef,
              ROW_HEIGHT,
              maxVisibleRows,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LiftsPerHourModal;
