import React from 'react';
import { Box } from '@mui/material';

const ProjectReportFooterSection = ({ pageNumber }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        bottom: '16px',
        padding: '0 64px',
      }}
    >
      <span />
      <span>All rights reserved to ©Ultrawis LTD.</span>
      <span>{pageNumber ?? ''}</span>
    </Box>
  );
};

export default ProjectReportFooterSection;
