import { useQuery } from 'react-query';
import { apiGetAsync } from '../api/axios';

const api = (props: any) =>
  apiGetAsync(`crane/${props.craneId}/video-recordings`);

export const useGetCraneVideoRecordings = (props: any, options?: any) => {
  const key = ['GetCraneVideoRecordings'];
  const fn = () => api(props);

  return useQuery(key, fn, {
    enabled: !!props.craneId,
    ...(options || {}),
  });
};
