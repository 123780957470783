import React, { useReducer } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { ErrorAlert } from '../../../../../components/alerts';
import { usePostProjectCrane } from '../../../../../queries';

const initialState = {
  name: '',
  type: '',
  ipAddress: '',
};
const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const CreateCraneDialog = ({ onClose, onSuccess, siteId }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [craneTypes, setCraneTypes] = React.useState<any[]>([
    {
      id: 0,
      label: 'HAMMER',
      value: 'HAMMER',
    },
    {
      id: 1,
      label: 'LUFFER',
      value: 'LUFFER',
    },
  ]);

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = usePostProjectCrane({
    onSuccess: () => {
      onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostProjectCrane');
    },
  });

  const valid = state.name.trim() !== '';

  const handleSubmit = async () => {
    const { name, type, ipAddress } = state;

    mutate({ siteId, name, type, ipAddress });
  };

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Create Crane</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Stack spacing={2}>
            <Typography variant="smallRegular">Crane Name</Typography>
            <TextField
              fullWidth
              value={state.name}
              autoFocus
              onChange={(e) =>
                dispatch({ type: 'name', payload: e.target.value })
              }
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="smallRegular">Type</Typography>
            <Select
              // error={validationResults.results['JobInterval'].error}
              fullWidth
              value={state.type || ''}
              onChange={(e) => {
                dispatch({
                  type: 'type',
                  payload: e.target.value,
                });
              }}
              sx={{
                '& .MuiSelect-select .notranslate::after': {
                  content: '"Select crane type"',
                  opacity: 0.42,
                },
              }}
            >
              {craneTypes.map((type: any) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="smallRegular">IP Address</Typography>
            <TextField
              fullWidth
              value={state.ipAddress}
              autoFocus
              onChange={(e) =>
                dispatch({ type: 'ipAddress', payload: e.target.value })
              }
            />
          </Stack>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!valid || isLoading}
        >
          {isLoading ? <CircularProgress size={16} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCraneDialog;
