// @ts-nocheck
import React, { Fragment, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { compact, filter, flatten, orderBy, times } from 'lodash';
import { splitDataInChunks } from '../../../../utils/helpers';
import { ProjectReportFooterSection, ProjectReportHeaderSection } from './';
import { Page, PageContainer } from './styled';
import {
  MaterialsTypeChart,
  DailyUtilizationChart,
  LiftsPerHourChart,
  LiftSchedulingGantChart,
  WindSpeedChart,
  DailyMaterialsChart,
  ProjectSafetyAntiCollisionCurrentZoneMap,
  JobsiteCranesIdleTime,
  JobsiteDailyConcreteChart,
  JobsiteDailyStructureChart,
  CranesWeeklyUtilizationChart,
  CranesDailyOperationalDuration,
  CranesWeeklyAverageUtilization,
  JobsiteWeeklyMaterialsLifts,
  JobsiteWeeklyStructureChart,
  JobsiteWeeklyConcreteChart,
} from '../charts';
import {
  JobsiteDailyConcreteTable,
  JobsiteDailyStructureTable,
} from '../tables';

export const ProjectReportContent = (props: any) => {
  const { reportData, isProjectReport } = props;
  const { records, project, safety } = reportData;
  const dailyRecords = records.find(
    (innerRecords) => innerRecords.date === project.reportDate,
  ).records;

  const pageNumber = useRef(0);

  // Reset pageNumber to initial value at each re-render
  pageNumber.current = 0;

  const mapComponents = (Component: any) =>
    dailyRecords.map((crane: any) => () => (
      <Component key={uuid()} data={crane} height={220} />
    ));

  const UtilizationComponents = [
    ...mapComponents(DailyUtilizationChart),
    ...mapComponents(LiftsPerHourChart),
  ];

  const UtilizationComponentsChunks = splitDataInChunks(
    flatten(UtilizationComponents),
    3,
    2,
    3,
  );

  const MaterialsComponents = [
    ...mapComponents(MaterialsTypeChart),
    ...mapComponents(LiftSchedulingGantChart),
    ...mapComponents(DailyMaterialsChart),
  ];

  const MaterialsComponentsChunks = splitDataInChunks(
    flatten(MaterialsComponents),
    3,
    3,
    3,
  );

  const WindSpeedComponents = [...mapComponents(WindSpeedChart)];

  const WindSpeedComponentsChunks = splitDataInChunks(
    flatten(WindSpeedComponents),
    2,
    2,
    2,
  );

  const renderUtilizationComponentsChunks = () =>
    UtilizationComponentsChunks.map((chunk, index) => {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            {pageNumber.current === 1 && index === 0 && (
              <ProjectReportHeaderSection
                project={project}
                isProjectReport={isProjectReport}
              />
            )}
            {chunk.map((Component) => (
              <Fragment key={uuid()}>
                <Component />
              </Fragment>
            ))}
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    });

  const renderWeeklyUtilizationsCharts = () => {
    if (!isProjectReport) return null;
    pageNumber.current = pageNumber.current + 1;
    return (
      <PageContainer>
        <Page sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <CranesWeeklyUtilizationChart records={records} project={project} />
          <CranesDailyOperationalDuration records={records} project={project} />
          <CranesWeeklyAverageUtilization records={records} project={project} />
          <ProjectReportFooterSection pageNumber={pageNumber.current} />
        </Page>
      </PageContainer>
    );
  };

  const renderJobsiteWeeklyMaterialsLifts = () => {
    if (!isProjectReport) return null;
    pageNumber.current = pageNumber.current + 1;

    return (
      <PageContainer>
        <Page sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <JobsiteWeeklyMaterialsLifts records={records} project={project} />
          <ProjectReportFooterSection pageNumber={pageNumber.current} />
        </Page>
      </PageContainer>
    );
  };

  const renderMaterialsComponentsChunks = () =>
    MaterialsComponentsChunks.map((chunk, index) => {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            {chunk.map((Component) => (
              <Fragment key={uuid()}>
                <Component />
              </Fragment>
            ))}
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    });

  const renderJobsiteDailyConcreteChart = () => {
    return dailyRecords.map((crane: any) => {
      const durations = orderBy(
        filter(
          compact(flatten(crane?.records?.map(({ durations }) => durations))),
          {
            cargoType: 'Concrete',
          },
        ),
        ['task.TimeLoadStart'],
      );

      if (durations.length === 0) {
        pageNumber.current = pageNumber.current + 1;
        return (
          <PageContainer key={uuid()}>
            <Page>
              <JobsiteDailyConcreteChart crane={crane.crane} data={[]} />
              <ProjectReportFooterSection pageNumber={pageNumber.current} />
            </Page>
          </PageContainer>
        );
      }

      const data = durations.map((lift, index) => {
        return {
          lifts: `Bucket ${index + 1}`,
          concreteWeight: lift.task?.LiftWeight,
          duration: +(lift.duration / 60).toFixed(2) || '0',
          startTime: lift.task?.TimeLoadStart,
        };
      });

      const tableDataChunks = splitDataInChunks(data, 12, 12, 19);

      return tableDataChunks.map((dataChunk, index) => {
        pageNumber.current = pageNumber.current + 1;

        if (index === 0)
          return (
            <PageContainer key={uuid()}>
              <Page>
                <JobsiteDailyConcreteChart
                  crane={crane.crane}
                  data={data}
                  tableData={dataChunk}
                />
                <ProjectReportFooterSection pageNumber={pageNumber.current} />
              </Page>
            </PageContainer>
          );

        return (
          <PageContainer key={uuid()}>
            <Page>
              <Stack
                sx={{
                  height: 1064,
                  alignItems: 'center',
                  marginBottom: 4,
                  borderWidth: 1,
                  borderColor: 'grey.400',
                  borderRadius: 4,
                  borderStyle: 'solid',
                  padding: 6,
                }}
              >
                <JobsiteDailyConcreteTable data={dataChunk} />
              </Stack>
              <ProjectReportFooterSection pageNumber={pageNumber.current} />
            </Page>
          </PageContainer>
        );
      });
    });
  };

  const renderJobsiteWeeklyConcreteChart = () => {
    if (!isProjectReport) {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            <JobsiteWeeklyConcreteChart records={records} craneIndex={0} />
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    }

    return times(Number(project.CranesCount), (craneIndex) => {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            <JobsiteWeeklyConcreteChart
              records={records}
              craneIndex={craneIndex}
            />
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    });
  };

  const renderJobsiteDailyStructureChart = () => {
    return dailyRecords.map((crane: any) => {
      const durations = orderBy(
        filter(
          compact(flatten(crane?.records?.map(({ durations }) => durations))),
          {
            cargoType: 'structure Install',
          },
        ),
        ['task.TimeLoadStart'],
      );

      if (durations.length === 0) {
        pageNumber.current = pageNumber.current + 1;
        return (
          <PageContainer key={uuid()}>
            <Page>
              <JobsiteDailyStructureChart crane={crane.crane} data={[]} />
              <ProjectReportFooterSection pageNumber={pageNumber.current} />
            </Page>
          </PageContainer>
        );
      }

      const data = durations.map((lift) => {
        return {
          lifts: 'Structure',
          duration: +(lift.duration / 60).toFixed(2) || 0,
          startTime: lift.task?.TimeLoadStart,
        };
      });

      const tableDataChunks = splitDataInChunks(data, 12, 12, 19);

      return tableDataChunks.map((dataChunk, index) => {
        pageNumber.current = pageNumber.current + 1;

        if (index === 0)
          return (
            <PageContainer key={uuid()}>
              <Page>
                <JobsiteDailyStructureChart
                  crane={crane.crane}
                  data={data}
                  tableData={dataChunk}
                />
                <ProjectReportFooterSection pageNumber={pageNumber.current} />
              </Page>
            </PageContainer>
          );

        return (
          <PageContainer key={uuid()}>
            <Page>
              <Stack
                sx={{
                  height: 1064,
                  alignItems: 'center',
                  marginBottom: 4,
                  borderWidth: 1,
                  borderColor: 'grey.400',
                  borderRadius: 4,
                  borderStyle: 'solid',
                  padding: 6,
                }}
              >
                <JobsiteDailyStructureTable data={dataChunk} />
              </Stack>
              <ProjectReportFooterSection pageNumber={pageNumber.current} />
            </Page>
          </PageContainer>
        );
      });
    });
  };

  const renderJobsiteWeeklyStructureChart = () => {
    if (!isProjectReport) {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            <JobsiteWeeklyStructureChart records={records} craneIndex={0} />
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    }

    return times(Number(project.CranesCount), (craneIndex) => {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            <JobsiteWeeklyStructureChart
              records={records}
              craneIndex={craneIndex}
            />
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    });
  };

  const renderJobsiteCranesIdleTime = () => {
    if (!isProjectReport) return null;
    pageNumber.current = pageNumber.current + 1;
    return (
      <PageContainer>
        <Page>
          <JobsiteCranesIdleTime records={dailyRecords} />
          <ProjectReportFooterSection pageNumber={pageNumber.current} />
        </Page>
      </PageContainer>
    );
  };

  const renderProjectSafetyAntiCollisionCurrentZoneMap = () => {
    pageNumber.current = pageNumber.current + 1;

    return (
      <PageContainer>
        <Page>
          <ProjectSafetyAntiCollisionCurrentZoneMap
            project={project}
            safety={safety}
          />
          <ProjectReportFooterSection pageNumber={pageNumber.current} />
        </Page>
      </PageContainer>
    );
  };

  const renderWindSpeedComponentsChunks = () =>
    WindSpeedComponentsChunks.map((chunk, index) => {
      pageNumber.current = pageNumber.current + 1;
      return (
        <PageContainer key={uuid()}>
          <Page>
            {pageNumber.current === 1 && index === 0 && (
              <ProjectReportHeaderSection
                project={project}
                isProjectReport={isProjectReport}
              />
            )}
            {chunk.map((Component, index) => (
              <Fragment key={uuid()}>
                <Component />
              </Fragment>
            ))}
            <ProjectReportFooterSection pageNumber={pageNumber.current} />
          </Page>
        </PageContainer>
      );
    });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontSize: '10px',
      }}
    >
      <div id="report-ready"></div>
      {renderUtilizationComponentsChunks()}
      {renderWeeklyUtilizationsCharts()}
      {renderJobsiteWeeklyMaterialsLifts()}
      {renderMaterialsComponentsChunks()}
      {renderJobsiteDailyConcreteChart()}
      {renderJobsiteWeeklyConcreteChart()}
      {renderJobsiteDailyStructureChart()}
      {renderJobsiteWeeklyStructureChart()}
      {renderJobsiteCranesIdleTime()}
      {renderProjectSafetyAntiCollisionCurrentZoneMap()}
      {renderWindSpeedComponentsChunks()}
    </Box>
  );
};
