import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Company {
  id: string;
  name: string;
  logoPath: string | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Site {
  id: string;
  name: string;
  companyId: string;
  companyName: string;
  originLatitude: number;
  originLongitude: number;
  timezone: string;
  cranes: Crane[];
}

export interface Crane {
  id: string;
  name: string;
}

export interface UserNotification {
  companyId: string;
  siteId: string;
  craneId: string;
  type: string;
  isActive: boolean;
}

export interface User {
  id: string;
  company: Company;
  companyId: string;
  sites: Site[];
  notifications: UserNotification[];
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  canReceiveMobileNotifications: boolean;
  receiveReports: boolean;
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserT {
  user: User;
}
const initialState: UserT = {
  user: {
    id: '',
    company: {
      id: '',
      name: '',
      logoPath: '',
      isActive: true,
      createdAt: '',
      updatedAt: '',
    },
    companyId: '',
    email: '',
    firstName: '',
    lastName: '',
    isActive: true,
    canReceiveMobileNotifications: true,
    receiveReports: true,
    role: '',
    createdAt: '',
    updatedAt: '',
    sites: [],
    notifications: [],
  },
};
const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      //  console.log (state.user);
    },
    resetUser: () => initialState,
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
