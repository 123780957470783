import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface TaskByCategory {
  hournum: number;
  General: number;
  Concrete: number;
  IronRodesPack: number;
  IronGridMesh: number;
  WoodPack: number;
  TrashContainer: number;
  Bricks: number;
  structureInstall: number;
  structureDismantle: number;
  NotIdentified: number;
}
export interface TasksByCategory {
  tasksbycategory: TaskByCategory[];
}

const initialState: TasksByCategory = {
  tasksbycategory: [
    {
      hournum: 0,
      General: 0,
      Concrete: 0,
      IronRodesPack: 0,
      IronGridMesh: 0,
      WoodPack: 0,
      TrashContainer: 0,
      Bricks: 0,
      structureInstall: 0,
      structureDismantle: 0,
      NotIdentified: 0,
    },
  ],
};

const tasksByCategorySlice = createSlice({
  name: 'tasksbycategory',
  initialState: initialState,
  reducers: {
    setTasksByCategory(state, action: PayloadAction<TaskByCategory[]>) {
      state.tasksbycategory = action.payload;
      //    console.log(state.idletimes);
    },
    resetTasksByCategory: () => initialState,
  },
});

export const tasksByCategoryActions = tasksByCategorySlice.actions;

export default tasksByCategorySlice.reducer;
