import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiInputBase'] = {
    styleOverrides: {
      input: {
        ...theme.typography.mediumRegular,
        minHeight: 39,
        maxHeight: 39,
      },
      root: {
        padding: 0,
        color: 'black',
        background: theme.palette.backgrounds.white,
        borderRadius: theme.spacing(1),
        '& .MuiOutlinedInput-input': {
          padding: '0 12px',
        },
      }
    },
  };
  return styleOverrides;
};
