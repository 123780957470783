import { Box, Stack, styled, Typography, Paper } from '@mui/material';
import React from 'react';

export const ReportContainer = styled(Box)``;

export const ReportDivider = styled('div')`
  background-color: #e2e1e1;
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;

export const PageContainer = styled('div')`
  position: relative;
  height: 1161px;
  padding: 16px 0 16px 32px;
  width: 826px;

  @media print {
    padding: 16px 0 16px 32px;
    height: 1161px;
    width: 826px;
  }
`;

export const Page = styled(Paper)`
  border: solid 1px #bdbdbd;
  padding: 24px;
  position: relative;
  height: 100%;
  border-radius: 8px;

  @media print {
    box-shadow: none;
  }
`;

export const ChartProperty = ({
  label,
  value,
  width,
  valueColor,
  fontSize,
  icon = null,
  ...rest
}: any) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      width={width || 200}
      {...rest}
    >
      <Typography
        variant="smallRegular"
        color="#878a99"
        sx={{ fontSize: fontSize || 10 }}
      >
        {icon && icon} {label}:
      </Typography>
      <Typography
        variant="smallMedium"
        color={valueColor}
        sx={{ fontSize: fontSize || 10 }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export const CraneHeader = ({ id, name, type, width }: any) => {
  return (
    <Stack direction="row" spacing={6}>
      <ChartProperty label="Name" value={name || '-'} width={width} />
      <ChartProperty label="Type" value={type || '-'} width={width} />
    </Stack>
  );
};

export const Property = ({
  label,
  value,
  textFontSize,
  sx,
  valueFontSize,
  minWidth = 100,
  ...rest
}: any) => {
  return (
    <Stack
      direction="column"
      sx={{
        padding: 2,
        borderRadius: 2,
        border: 'solid 1px',
        borderColor: '#e9ebec',
        flex: 1,
        minWidth,
        ...sx,
      }}
      {...rest}
    >
      <Typography
        variant="smallRegular"
        color="#878a99"
        sx={{
          fontSize: textFontSize || 10,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Typography>
      <Typography variant="h2Medium" sx={{ fontSize: valueFontSize || 10 }}>
        {value}
      </Typography>
    </Stack>
  );
};

export const ChartStack = ({ height = 340, children, onDashboard }: any) => {
  return (
    <Stack
      justifyContent={onDashboard ? 'space-between' : 'center'}
      sx={{
        height: height,
        marginBottom: onDashboard ? 0 : 4,
        borderWidth: onDashboard ? 0 : 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
      }}
    >
      {children}
    </Stack>
  );
};

export const ChartStackHeader = ({ children, onDashboard }: any) => {
  return (
    <Stack sx={{ padding: onDashboard ? 0 : '16px 16px 0px 16px' }} spacing={4}>
      {children}
    </Stack>
  );
};

export const ChartStackContent = ({ children, onDashboard, ...rest }: any) => {
  return (
    <Stack sx={{ padding: onDashboard ? 0 : 4 }} spacing={4} {...rest}>
      {children}
    </Stack>
  );
};
