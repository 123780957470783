import React from 'react';
import { Button, Stack, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useAppSelector } from '../../../store/hooks';

const ActionsToolbar = ({
  startDate,
  setCraneLiftImagesOpen,
  setCraneReportOpen,
  setProjectReportOpen,
  setDailyVideoOpen,
}: any) => {
  const showButtons = useMediaQuery('(max-width: 1100px)');
  const selectedProject = useAppSelector(
    (state: any) => state.projectCrane.Project,
  );
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: showButtons ? 'none' : 'flex' }}
    >
      {/* <Button
        variant="outlined"
        startIcon={<CheckCircleOutlineIcon />}
        disabled={!startDate}
      >
        Weight Check
      </Button> */}
      {/* <Button
        variant="outlined"
        onClick={() => setCraneLiftImagesOpen(true)}
        startIcon={<BarChartIcon />}
        disabled={
          !(startDate && selectedProject !== '' && selectedCrane !== '')
        }
      >
        Crane Lift Images
      </Button> */}
      {/* <Button
        variant="outlined"
        onClick={() => setCraneReportOpen(true)}
        startIcon={<BarChartIcon />}
        disabled={
          !(startDate && selectedProject !== '' && selectedCrane !== '')
        }
      >
        Crane Report
      </Button>
      <Button
        variant="outlined"
        onClick={() => setProjectReportOpen(true)}
        startIcon={<BarChartIcon />}
        disabled={!(startDate && selectedProject !== '')}
      >
        Project Report
      </Button> */}
      {/* <Button
        variant="outlined"
        onClick={() => setDailyVideoOpen(true)}
        startIcon={<SlideshowIcon />}
        disabled={!startDate}
      >
        Daily video
      </Button> */}
    </Stack>
  );
};

export default ActionsToolbar;
