// @ts-nocheck
import { Polygon } from '@react-google-maps/api';
import { useCallback, useRef } from 'react';
import SiteGoogleMaps, {
  GoogleMapPoint,
} from '../../../../../../../../components/SiteGoogleMaps/SiteGoogleMaps';
import { useAppSelector } from '../../../../../../../../store/hooks';

export interface Zone {
  name: string;
  height: number;
  isActive: boolean;
  points: GoogleMapPoint[];
}

export enum ZonesMapMode {
  view = 'view',
  edit = 'edit',
}

const ZonesMap = ({
  siteCenter,
  newZone = null,
  setNewZone = null,
  isCreateModeActive = false,
  zonesMapMode = ZonesMapMode.view,
}: any) => {
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const zones = useAppSelector((state: any) => state.projectCrane.Zones);

  const onUnmountNewZone = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onEditNewZone = useCallback(() => {
    if (zonesMapMode === ZonesMapMode.view) return;

    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setNewZone((prevNewZone) => {
        return {
          ...prevNewZone,
          points: nextPath,
        };
      });
    }
  }, [setNewZone]);

  const onLoadNewZone = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEditNewZone),
        path.addListener('insert_at', onEditNewZone),
        path.addListener('remove_at', onEditNewZone),
      );
    },
    [onEditNewZone],
  );

  const onMapClick = useCallback(
    (e) => {
      if (zonesMapMode === ZonesMapMode.view || !isCreateModeActive) return;
      const newPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      setNewZone((prevNewZone: Zone) => {
        return {
          ...prevNewZone,
          points: [...prevNewZone.points, newPoint],
        };
      });
    },
    [zonesMapMode, isCreateModeActive],
  );

  return (
    <SiteGoogleMaps
      siteCenter={siteCenter}
      onMapClick={onMapClick}
      width={zonesMapMode === ZonesMapMode.edit ? '70%' : '100%'}
      height={zonesMapMode === ZonesMapMode.edit ? '100%' : '500px'}
    >
      {zones &&
        zones.length > 0 &&
        zones.map((zone: any, index: number) => {
          if (zone.isActive && zone.points.length > 0)
            return (
              <Polygon
                key={index}
                path={zone.points.map((point: any) => {
                  return { lat: point.latitude, lng: point.longitude };
                })}
                options={{
                  fillColor: '#ff0000',
                  fillOpacity: 0.4,
                  strokeColor: '#ff0000',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            );
        })}

      {newZone && newZone.points.length > 0 && (
        <Polygon
          path={newZone.points}
          editable={true}
          draggable={true}
          onMouseUp={onEditNewZone}
          onLoad={onLoadNewZone}
          onUnmount={onUnmountNewZone}
          options={{
            fillColor: '#ff0000',
            fillOpacity: 0.4,
            strokeColor: '#ff0000',
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      )}
    </SiteGoogleMaps>
  );
};

export default ZonesMap;
