import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useHttp } from '../hooks/useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { RESET_PASSWORD_PATH } from '../variables/endpoints';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAsyncFunction } from '../hooks/useAsyncFunction';
import Eye from '../assets/icons/eye';
import EyeSlash from '../assets/icons/eyeslash';
import { PasswordInput } from '../components/inputs';

const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const ResetPassword = () => {
  const { id, code } = useParams();
  const { api } = useHttp();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [score, setScore] = useState<any>({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const { loading, error, setError, data, setData, call } = useAsyncFunction();

  const onChangeScore = (score: number, feedback: any) => {
    setScore({ score, feedback });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const HandleResetPassordRequest = async () => {
    call(() => {
      return api({
        method: ApiMethods.PUT,
        url: `${RESET_PASSWORD_PATH}/${id}`,
        data: {
          code,
          password,
        },
      });
    });
  };

  return (
    <RootStyle className="login-page">
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ width: '100%' }}>
          <ContentStyle>
            <HeadingStyle>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Forgot your password
              </Typography>
            </HeadingStyle>
            <Stack spacing={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Stack direction="row">
                  <PasswordInput
                    fullWidth
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Stack>
              </Box>
              <Stack sx={{ paddingBottom: 4 }}>
                <PasswordStrengthBar
                  password={password}
                  onChangeScore={onChangeScore}
                />
                <Typography variant="smallRegular" sx={{ color: 'error.main' }}>
                  {score?.feedback?.warning}
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Stack direction="row">
                  <PasswordInput
                    fullWidth
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Stack>
                {password !== confirmPassword ? (
                  <Typography
                    variant="smallRegular"
                    sx={{ color: 'error.main' }}
                  >
                    Password and Confirm Password do not match.
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 4 }}
                >
                  <Link
                    component={RouterLink}
                    variant="mediumMedium"
                    to="/login"
                    underline="hover"
                  >
                    Login to your account
                  </Link>
                </Stack>
                <Button
                  disabled={
                    loading ||
                    password.trim() === '' ||
                    confirmPassword.trim() === '' ||
                    password !== confirmPassword ||
                    score.score < 2
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  sx={{ my: 2, display: 'block', padding: 3 }}
                  variant="contained"
                  onClick={HandleResetPassordRequest}
                >
                  {loading ? <CircularProgress size={16} /> : 'Reset Password'}
                </Button>
              </Box>
            </Stack>
            <Collapse in={!!error}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setError(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {error?.message}
              </Alert>
            </Collapse>
            <Collapse in={!!data}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setData(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Success</AlertTitle>
                {data?.data?.message}
              </Alert>
            </Collapse>
          </ContentStyle>
        </Paper>
      </Container>
    </RootStyle>
  );
};

export default ResetPassword;
