import React from 'react';
import { ProjectCraneSelector } from '../../components/selectors';
import {
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridToolbar } from '../../components/styled';
import { useGetCargoType } from '../../queries';

const Toolbar = ({
  setSelectedProject,
  // setSelectedCrane,
  createdDate,
  setCreatedDate,
  selectedCargoType,
  onCargoTypeChange,
  onRefreshClick,
}: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const { isLoading: cargoTypesLoading, data: cargoTypesData } =
    useGetCargoType();

  return (
    <GridToolbar>
      <ProjectCraneSelector
        onProjectChange={setSelectedProject}
        // onCraneChange={setSelectedCrane}
        sx={{ justifyContent: 'flex-start', width: '100%' }}
      >
        <Stack
          spacing={2}
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">Date</Typography>
          <DatePicker
            sx={{
              width: sm ? '100%' : 220,
            }}
            format="DD/MM/YYYY"
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            value={createdDate}
            onChange={(date: any) => setCreatedDate(date)}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">Cargo type</Typography>
          <Select
            value={selectedCargoType}
            onChange={onCargoTypeChange}
            sx={{
              width: sm ? '100%' : 220,
              marginRight: sm ? 0 : 4,
              marginBottom: 4,
              '& .MuiSelect-select .notranslate::after': {
                content: '"Select cargo type"',
                opacity: 0.42,
              },
            }}
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {cargoTypesData?.data?.map((CargoType: any) => (
              <MenuItem key={CargoType.ID} value={CargoType.ID}>
                {CargoType.CargoTypeDesc}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack
          spacing={2}
          sx={{
            marginRight: sm ? 0 : 4,
            marginBottom: 4,
            width: sm ? '100%' : 'auto',
          }}
        >
          <Typography variant="smallRegular">&nbsp;</Typography>
          <Button
            variant="outlined"
            onClick={onRefreshClick}
            sx={{ height: 39 }}
          >
            <RefreshIcon />
          </Button>
        </Stack>
      </ProjectCraneSelector>
    </GridToolbar>
  );
};

export default Toolbar;
