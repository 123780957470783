import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiGetAsync,
  apiDeleteAsync,
  apiPostAsync,
  apiPutAsync,
} from '../api/axios';
import { createCrudSlice } from './utils';

export const fetchCargoTypes = createAsyncThunk(
  'cargotypes/fetch',
  async () => {
    const { data } = await apiGetAsync('cargo-type');
    return data;
  },
);

export const createCargoType = createAsyncThunk(
  'cargotypes/create',
  async (cargoType: any) => {
    return await apiPostAsync('cargo-type', cargoType);
  },
);

export const updateCargoType = createAsyncThunk(
  'cargotypes/update',
  async ({ name, cargoType }: { name: string | undefined; cargoType: any }) => {
    return await apiPutAsync(`cargo-type/${name}`, cargoType);
  },
);

export const deleteCargoTypes = createAsyncThunk(
  'cargotypes/delete',
  async (name: string | undefined) => {
    return await apiDeleteAsync(`cargo-type/${name}`);
  },
);

export interface CargoType {
  id: string;
  name: string;
  color: string;
}

const cargoTypeSlice = createCrudSlice<CargoType>('cargotypes');

export const cargoTypeActions = cargoTypeSlice.actions;

export default cargoTypeSlice.reducer;
