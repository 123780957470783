// @ts-nocheck
import { Stack, Typography, useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppSelector } from '../../../../../store/hooks';
import { formatTotalSecondsToDateFormat } from '../../../../../utils';
import {
  ChartStack,
  ChartStackContent,
  ChartStackHeader,
} from '../../content/styled';
import ChartBar from './ChartBar';
import ChartStats from './ChartStats';

const DailyUtilizationChart = ({ height, onDashboard }: any) => {
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  const liftsStats = useAppSelector(
    (state: any) => state.projectCrane.liftsStats,
  );

  const title = onDashboard
    ? 'Daily Utilization'
    : `${crane.name} - Daily Utilization`;

  const sm = useMediaQuery('(max-width: 600px)');
  const noLifts = lifts.length === 0;

  if (noLifts) {
    return (
      <ChartStack
        height={sm ? 'auto' : onDashboard ? 300 : 340}
        onDashboard={onDashboard}
      >
        <ChartStackHeader onDashboard={onDashboard}>
          <Typography
            sx={
              sm && onDashboard
                ? { paddingTop: 4, fontSize: '24px' }
                : { fontSize: '24px' }
            }
          >
            {title}
          </Typography>
        </ChartStackHeader>
        <ChartStackContent
          direction={sm ? 'column' : 'row'}
          alignContent="center"
          sx={{
            paddingTop: 4,
            gap: 2,
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: height - 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2Medium" color="#878a99">
              {'No data to display'}
            </Typography>
          </Stack>
        </ChartStackContent>
      </ChartStack>
    );
  }

  const totalLifts = lifts.length;
  const {
    startTime,
    endTime,
    totalTimeSec,
    shortestLiftTimeSec,
    longestLiftTimeSec,
    avgLiftTimeSec,
    idleSec,
    operationSec,
  } = liftsStats;

  const series = [
    {
      name: 'Time',
      colorByPoint: true,
      minPointSize: 10,
      innerSize: '50%',
      data: [
        {
          name: 'Idle',
          y: idleSec,
        },
        {
          name: 'Operation',
          y: operationSec,
        },
      ],
    },
  ];

  const options = {
    chart: {
      height: height - 20,
      // width: onDashboard ? (sm ? 300 : 500) : 500,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      margin: [20, 0, 0, 0],
      style: {
        overflow: 'visible',
      },
    },
    exporting: { enabled: false },
    title: {
      text: '',
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: <b>${formatTotalSecondsToDateFormat(
          this.point.y,
        )} (hours)</b>`;
      },
      hideDelay: 50,
      style: {
        fontSize: '12px',
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        size: sm ? '60%' : '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (
              this.point.name + '<br>' + this.point.percentage.toFixed(1) + '%'
            );
          },
          style: {
            fontSize: sm ? '12px' : '14px',
            textOverflow: 'ellipsis',
          },
          distance: 15,
          overflow: 'none',
          crop: false,
        },
        showInLegend: false, // Hide the legend
      },
    },
    // Commenting out legend configuration to ensure it doesn't show up
    // legend: {
    //   align: 'left',
    //   layout: 'vertical',
    //   verticalAlign: 'top',
    //   x: -16,
    //   y: 85,
    //   itemStyle: {
    //     fontSize: '12px',
    //   },
    // },
    series,
    colors: ['#00c1b6', '#ff6f00', '#F5B7B1', '#D7BDE2', '#005573'],
  };

  return (
    <ChartStack
      height={sm ? 'auto' : onDashboard ? 300 : 340}
      onDashboard={onDashboard}
    >
      <ChartStackHeader onDashboard={onDashboard}>
        <Typography
          sx={
            sm && onDashboard
              ? { paddingTop: 4, fontSize: '24px' }
              : { fontSize: '24px' }
          }
        >
          {title}
        </Typography>
        <ChartBar
          {...{
            // crane,
            noLifts,
            operationSec,
            idleSec,
            onDashboard,
            startTime,
            endTime,
          }}
        />
      </ChartStackHeader>
      <ChartStackContent
        direction={sm && onDashboard ? 'column' : 'row'}
        alignContent="center"
        sx={{
          paddingLeft: onDashboard ? 0 : 4,
          paddingRight: onDashboard ? 0 : 4,
          paddingTop: 4,
          gap: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ChartStats
          {...{
            onDashboard,
            totalLifts,
            shortestLiftTimeSec,
            longestLiftTimeSec,
            avgLiftTimeSec,
            totalTimeSec,
            startTime,
            endTime,
          }}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{
            style: { width: '50%', overflow: 'visible !important' },
          }}
        />
      </ChartStackContent>
    </ChartStack>
  );
};

export default DailyUtilizationChart;
