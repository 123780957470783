import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { GoogleMapPoint } from '../../components/SiteGoogleMaps/SiteGoogleMaps';
import { ErrorState } from '../../components/alerts';
import { ContentStack, LayoutStack, Loading } from '../../components/styled';
import {
  useGetCranebitTasks,
  usePostCranebitTask,
  usePutCranebitTask,
} from '../../queries';
import { useDeleteCranebitTask } from '../../queries/useDeleteCranebitTask';
import {
  convertXMeterToXMapPoint,
  convertYMeterToYMapPoint,
} from '../../utils/coordinatesHelper';
import {
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';
import { S3MMSITE_PATH } from '../../variables/endpoints';
import { TasksEmptyState } from '../CraneScheduler/components';
import CranebitTaskCard from './CranebitTaskCard';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CraneTask, craneTasksActions } from '../../store/craneTask';
import * as storageHelper from '../../utils/storageHelper';

const imageWidth = 500;

const CranebitTasks = ({
  craneId,
  isLoading,
  setIsLoading,
  createdTask,
  cranebitTasksError,
  cranebitTasksIsSuccess,
  refetchTasks,
}: any) => {
  // const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const [siteCenter, setSiteCenter] = useState<GoogleMapPoint>({
    lat: 0,
    lng: 0,
  });
  const [selectedCrane, setSelectedCrane] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  const { id } = useParams();
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
  // const Project = useAppSelector((state: any) => state.projectCrane.Project);
  // const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const { tasks, templates } = useAppSelector((state: any) => state.craneTasks);
  const User = storageHelper.getItem(USER);
  const Project = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);

  useEffect(() => {
    if (User && Project) {
      const site = User.sites.find((site: any) => site.id === Project);
      if (!site) return;
      setSiteCenter({
        lat: site.originLatitude,
        lng: site.originLongitude,
      });
    }
  }, [storageHelper]);

  useEffect(() => {
    const selectedProjectStorage = localStorage.getItem(
      ULTRAWIS_SELECTED_PROJECT,
    );
    const newSelectedProject = selectedProjectStorage?.replace(/['"]+/g, '');
    if (!newSelectedProject) return;
    setSelectedProject(newSelectedProject);

    const selectedCraneStorgae = localStorage.getItem(ULTRAWIS_SELECTED_CRANE);
    const newSelectedCrane = selectedCraneStorgae?.replace(/['"]+/g, '');
    if (!newSelectedCrane) return;
    setSelectedCrane(newSelectedCrane);
  }, [localStorage.getItem(ULTRAWIS_SELECTED_CRANE)]);

  useEffect(() => {
    if (
      (craneId && selectedCrane) ||
      (craneId && createdTask) ||
      (craneId && refetchTasks)
    ) {
      setIsLoading(true);
      // cranebitTasksRefetch();
    } else {
      setIsLoading(false);
    }
  }, [createdTask, refetchTasks, selectedCrane, selectedProject]);

  // useEffect(() => {
  //   if (craneId && createdTask) cranebitTasksRefetch();
  // }, [createdTask]);

  //#region react-query

  // const {
  //   error: cranebitTasksError,
  //   isLoading: cranebitTasksIsLoading,
  //   isSuccess: cranebitTasksIsSuccess,
  //   data: cranebitTasksData,
  //   refetch: cranebitTasksRefetch,
  // } = useGetCranebitTasks(
  //   {
  //     craneId,
  //   },
  //   {
  //     onSuccess: ({ data }: any) => {
  //       if (data.result && data.result.length > 0) {
  //         setSiteMap(`${S3MMSITE_PATH}/${data.result[0].SiteMap}`);
  //         setTasks(data.result);
  //       } else {
  //         setTasks([]);
  //         setNoRecords(true);
  //       }
  //       setIsLoading(false);
  //     },
  //   },
  // );

  // useEffect(() => {
  //   if (cranebitTasksData?.data?.projectData) {
  //     const { LatCraneRef, LongCraneRef } =
  //       cranebitTasksData?.data?.projectData || {};
  //     if (LatCraneRef && LongCraneRef) {
  //       setSiteCenter({
  //         lat: LatCraneRef,
  //         lng: LongCraneRef,
  //       });
  //     }
  //   }
  // }, [cranebitTasksData?.data?.projectData]);

  const {
    mutate: doneCranebitTaskMutate,
    isLoading: doneCranebitTaskIsLoading,
    error: doneCranebitTaskError,
  } = usePutCranebitTask({
    onSuccess: () => {
      setClickedJob(null);
      setIsLoading(true);
      // cranebitTasksRefetch();
    },
    onError: () => { },
    onSettled: () => {
      queryClient.invalidateQueries('PutCranebitTask');
    },
  });

  const {
    mutate: activateCranebitTaskMutate,
    isLoading: activateCranebitTaskIsLoading,
    error: activateCranebitTaskError,
  } = usePostCranebitTask({
    onSuccess: () => {
      setClickedJob(null);
      setIsLoading(true);
      // cranebitTasksRefetch();
    },
    onError: () => { },
    onSettled: () => {
      queryClient.invalidateQueries('PostCranebitTask');
    },
  });

  const {
    mutate: deleteCranebitTaskMutate,
    isLoading: deleteCranebitTaskIsLoading,
    error: deleteCranebitTaskError,
    data: deleteCranebitTaskData,
    reset: deleteCranebitTaskReset,
  } = useDeleteCranebitTask({
    onSuccess: (data: any) => {
      // setIsLoading(true);
      const deletedTask = data.data;
      // cranebitTasksRefetch();
      dispatch(
        craneTasksActions.setTasks(
          tasks
            .map((task: CraneTask) => {
              if (task.id === deletedTask.id) {
                return undefined;
              }
              return {
                ...task,
                order:
                  task.order > deletedTask.order ? task.order - 1 : task.order,
              };
            })
            .filter(Boolean),
        ),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries('DeleteCranebitTask');
    },
  });

  //#endregion

  //#region States

  const [expandedId, setExpandedId] = useState(-1);
  const [clickedJob, setClickedJob] = useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [imageInfo, setImageInfo] = useState<any>(null);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [noRecords, setNoRecords] = useState(false);

  const [SiteMap, setSiteMap] = useState('');
  const [xViewSource, setXViewSource] = useState<number>(0);
  const [yViewSource, setYViewSource] = useState<number>(0);
  const [xViewDest, setXViewDest] = useState<number | null>(null);
  const [yViewDest, setYViewDest] = useState<number | null>(null);

  //#endregion

  //#region Effects

  useEffect(() => {
    if (SiteMap.length > 0) {
      let img = new Image();
      img.src = SiteMap;
      img.onload = () => {
        setImageInfo({ width: img.width, height: img.height });
        setImageLoaded(true);
      };
      img.onerror = () => {
        setImageLoaded(true);
      };
    }
  }, [SiteMap]);

  useEffect(() => {
    if (!!doneCranebitTaskError || !!activateCranebitTaskError) {
      setOpenSnackbar(true);
    }
  }, [doneCranebitTaskError, activateCranebitTaskError]);

  const error = ((doneCranebitTaskError || activateCranebitTaskError) as Error)
    ?.message;

  //#endregion

  //#region Handlers

  const onDoneHandler = async (TypeId: number, id: string) => {
    setClickedJob({ id, action: 'done' });
    doneCranebitTaskMutate({ TypeId, RecId: id });
  };

  const onActivateHandler = async (TypeId: number, id: string) => {
    setClickedJob({ id, action: 'activate' });
    activateCranebitTaskMutate({ TypeId, RecId: id });
  };

  const onDeleteHandler = async (id: string) => {
    setClickedJob({ id, action: 'activate' });
    deleteCranebitTaskMutate({ id });
    // setIsLoading(true);
  };

  const onCardClick = (id: any, index: number) => {
    setExpandedId(expandedId === index ? -1 : index);
    // setXViewDest(null);
    // const relpxx = imageInfo?.width ? imageInfo.width / imageWidth : 0;
    // const {
    //   xPointCraneRef,
    //   yPointCraneRef,
    //   Pixel2Meter: meter2Pixel,
    // } = cranebitTasksData?.data?.projectData || {};
    // const { xSource, ySource, xDest, yDest } =
    //   cranebitTasksData?.data?.result?.find((rec: any) => rec.ID == id) || {};
    // if (meter2Pixel) {
    //   if (xSource && ySource) {
    //     setXViewSource(
    //       convertXMeterToXMapPoint(
    //         xSource,
    //         meter2Pixel,
    //         xPointCraneRef,
    //         relpxx,
    //       ),
    //     );
    //     setYViewSource(
    //       convertYMeterToYMapPoint(
    //         ySource,
    //         meter2Pixel,
    //         yPointCraneRef,
    //         relpxx,
    //       ),
    //     );
    //   } else {
    //     setXViewSource(0);
    //     setYViewSource(0);
    //   }
    //   if (xDest && yDest) {
    //     setXViewDest(
    //       convertXMeterToXMapPoint(xDest, meter2Pixel, xPointCraneRef, relpxx),
    //     );
    //     setYViewDest(
    //       convertYMeterToYMapPoint(yDest, meter2Pixel, yPointCraneRef, relpxx),
    //     );
    //   } else {
    //     setXViewDest(null);
    //     setYViewDest(null);
    //   }
    // }
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  //#endregion

  return (
    <LayoutStack
      height="100%"
      minHeight={'500px'}
      minWidth={sm || medium ? 'inherit' : '600px'}
    >
      <Typography
        variant="h2Medium"
        sx={{ textAlign: sm ? 'center' : 'inherit' }}
      >
        Pending Tasks <AccessTimeIcon />
      </Typography>
      {isLoading && <Loading />}
      {!isLoading && (
        <ContentStack>
          <Stack
            width="100%"
            height="100%"
            // minWidth={isLoading ? '500px' : medium || sm ? '100%' : '500px'}
            sx={{
              overflow: 'auto',
              padding: sm || medium ? '0 1px 0 0' : '0 16px 0 0',
              marginTop: -3,
              // visibility: isLoading ? 'hidden' : 'visible',
            }}
          >
            {cranebitTasksError && <ErrorState error={cranebitTasksError} />}
            {cranebitTasksIsSuccess &&
              (tasks.length === 0 ? (
                <TasksEmptyState
                  message={'There are no tasks scheduled for this crane'}
                />
              ) : (
                tasks.map((task: any, index: number) => {
                  // if (index > 0) return null;
                  const activateButtonLoading =
                    activateCranebitTaskIsLoading &&
                    clickedJob?.id === task.id &&
                    clickedJob?.action === 'activate';

                  const doneButtonLoading =
                    doneCranebitTaskIsLoading &&
                    clickedJob?.id === task.id &&
                    clickedJob?.action === 'done';

                  const xSource = 0; // item.xSource;
                  const ySource = 0; //item.ySource;
                  const xDest = 0; //item.xDest;
                  const yDest = 0; //item.yDest;

                  return (
                    <CranebitTaskCard
                      key={uuid()}
                      {...{
                        siteCenter,
                        activateButtonLoading,
                        doneButtonLoading,
                        task,
                        index,
                        expandedId,
                        imageInfo,
                        SiteMap,
                        xViewSource,
                        yViewSource,
                        xViewDest,
                        yViewDest,
                        onCardClick,
                        onActivateHandler,
                        onDeleteHandler,
                        onDoneHandler,
                        imageWidth,
                        xSource,
                        ySource,
                        xDest,
                        yDest,
                      }}
                    />
                  );
                })
              ))}
          </Stack>
        </ContentStack>
      )}
      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={error}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LayoutStack>
  );
};
export default CranebitTasks;
