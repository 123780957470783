import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useGetCrane, usePutCrane } from '../../../../queries';
import { Loading } from '../../../../components/styled';
import { Grid, IconButton, Paper, Snackbar, Stack, Tabs } from '@mui/material';
import ErrorState from '../../../../components/alerts/ErrorState';
import { Tab, TabPanel } from '../../../../components/Tabs';
import CloseIcon from '@mui/icons-material/Close';
import { CraneAllowWeight, CraneDevices, CraneParameters } from './components';

const Crane = () => {
  const { companyId, siteId, craneId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const queryClient = useQueryClient();

  const {
    error: getCraneError,
    isFetching: getCraneIsFetching,
    data: getCraneData,
    refetch: refetchCrane,
  } = useGetCrane(
    { craneId },
    {
      cacheTime: 0,
    },
  );

  const {
    mutate: putCraneMutate,
    isLoading: putCraneIsLoading,
    error: putCraneError,
  } = usePutCrane({
    onSuccess: () => {
      refetchCrane();
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutCrane');
    },
  });

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  const snackbarError = (putCraneError as Error)?.message;
  const pageError = getCraneError;
  const pageIsLoading = getCraneIsFetching || putCraneIsLoading;

  if (pageIsLoading) {
    return <Loading />;
  }

  if (pageError || !companyId || !siteId || !craneId) {
    return (
      <Paper
        sx={{
          padding: 8,
          backgroundColor: 'common.white',
          width: '100%',
          overflowY: 'auto',
          display: 'flex',
          flex: 1,
        }}
        elevation={1}
      >
        <ErrorState error={pageError} />
      </Paper>
    );
  }

  return (
    <Stack height="100%" flex={1} spacing={4}>
      <Tabs value={selectedTab} onChange={onTabChange}>
        <Tab label="Parameters" />
        <Tab label="Allow weight" />
        {/*<Tab label="Devices" />*/}
      </Tabs>
      <Grid container rowSpacing={4} flex={1}>
        <TabPanel value={selectedTab} index={0}>
          <CraneParameters
            {...{ craneData: getCraneData?.data, putCraneMutate }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <CraneAllowWeight />
        </TabPanel>
        {/*<TabPanel value={selectedTab} index={2}>*/}
        {/*  <CraneDevices />*/}
        {/*</TabPanel>*/}
      </Grid>
      <Snackbar
        open={openSnackbar && !!snackbarError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarError}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};

export default Crane;
