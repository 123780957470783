import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  StackProps,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RefreshIcon from '@mui/icons-material/Refresh';

interface NextPrevDateProps extends StackProps {
  defaultDate: Date;
  onDateChange: (date: Date) => void;
}

const NextPrevDate = ({ defaultDate, onDateChange }: NextPrevDateProps) => {
  const [date, setDate] = useState<Date>(defaultDate);

  useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate]);

  const onChange = (value: number) => {
    const newDate = moment(date).add(value, 'day').toDate();
    setDate(newDate);
    onDateChange(newDate);
  };

  const onNextDate = () => {
    onChange(1);
  };

  const onPrevDate = () => {
    onChange(-1);
  };

  return (
    <Stack direction="row" alignItems="center">
      <IconButton color="primary" component="label" onClick={onPrevDate}>
        <ArrowBackIosIcon />
      </IconButton>
      <Typography sx={{ padding: '0 6px' }}>
        {moment(date).format('LL')}
      </Typography>
      <IconButton color="primary" component="label" onClick={onNextDate}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>
  );
};

export default NextPrevDate;
