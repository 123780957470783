import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DownloadIcon from '@mui/icons-material/Download';
import PendingIcon from '@mui/icons-material/Pending';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { API_PATH } from '../../../../variables/endpoints';
import VideoRecordingPlayer from './VideoRecordingPlayer';
import { TOKEN } from '../../../../variables/constants';

const Recording = ({
  recording,
  selectedRecordings = [],
  setSelectedRecordings = () => {},
}: any) => {
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const sm = useMediaQuery('(max-width: 600px)');

  const handleDownload = () => {
    const craneId = selectedCrane;
    let accessToken = localStorage.getItem(TOKEN) || '';
    accessToken = accessToken?.replace(/['"]+/g, '');
    const path = recording.path;
    const url = `${API_PATH}/crane/${craneId}/video-recordings/download`;
    const params = new URLSearchParams({ token: accessToken, path }).toString();
    window.location.href = `${url}?${params}`;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginTop: '10px' }}
      sx={{
        padding: sm ? '10px' : '10px 40px',
        borderRadius: 2,
        border: 'solid 1px',
        flex: 1,
        borderColor: '#e9ebec',
        width: '100%',
      }}
    >
      {openVideoPlayer && (
        <VideoRecordingPlayer
          recording={recording}
          onClose={() => setOpenVideoPlayer(false)}
        />
      )}
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        width={sm ? '280px' : '180px'}
      >
        <Typography sx={{ fontSize: '16px' }}>
          <b>{recording.cameraType}</b>
        </Typography>
        <Typography sx={{ marginLeft: sm ? '8px' : '10px', fontSize: '16px' }}>
          {recording.time}
          <AccessTimeIcon sx={{ marginLeft: '5px' }} />
        </Typography>
      </Box>
      <Box sx={{ width: '220px', display: 'flex', justifyContent: 'end' }}>
        {recording.isRequested ? (
          <>
            {recording.isUploaded ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => setOpenVideoPlayer(true)}
                  sx={{ width: sm ? '40px' : '100px' }}
                >
                  {!sm && 'Play'}{' '}
                  <PlayArrowIcon sx={{ marginLeft: sm ? '0px' : '5px' }} />
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleDownload}
                  sx={{ width: sm ? '40px' : '150px', marginLeft: '10px' }}
                >
                  {!sm && 'Download'}{' '}
                  <DownloadIcon sx={{ marginLeft: sm ? '0px' : '5px' }} />
                </Button>
              </>
            ) : (
              <Typography sx={{ fontSize: '16px' }}>
                Pending
                <PendingIcon sx={{ marginLeft: '5px' }} />
              </Typography>
            )}
          </>
        ) : (
          <Checkbox
            checked={selectedRecordings.includes(recording.id)}
            onChange={(e) =>
              setSelectedRecordings((prev: any) =>
                e.target.checked
                  ? [...prev, recording.id]
                  : prev.filter((id: any) => id !== recording.id),
              )
            }
          />
        )}
      </Box>
    </Stack>
  );
};

export default Recording;
