import React, { useReducer } from 'react';
import '../../components/ObjList.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { ErrorAlert } from '../../components/alerts';
import { usePostCompany } from '../../queries';
import { useQueryClient } from 'react-query';
import { ImageUploader } from '../../components/ImageUploader';
import { toBase64 } from '../../utils/helpers';

interface CreateCompanyProps {
  onClose: () => void;
  onSuccess: () => void;
}

interface CreateCompany {
  name: string;
  imageData: any;
}

const initialState: CreateCompany = {
  name: '',
  imageData: null,
};

const reducer = (state: any, action: any) => ({
  ...state,
  [action.type]: action.payload,
});

const CreateCompany = ({ onClose, onSuccess }: CreateCompanyProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = usePostCompany({
    onSuccess: () => {
      onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostCompany');
    },
  });

  const onChangeImageData = (file: File) => {
    dispatch({ type: 'imageData', payload: file });
  };

  const valid = state.name.trim() !== '';

  const handleSubmit = async () => {
    if (!valid) return;
    const { imageData, name } = state;
    let imageEncoded = null;

    if (imageData) {
      const imageBase64: any = await toBase64(imageData);
      if (imageBase64) imageEncoded = JSON.stringify(imageBase64);
    }

    mutate({ name, imageEncoded });
  };

  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle>Create Company</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: '32px 8px' }} spacing={4}>
          <Stack spacing={2}>
            <Typography variant="smallRegular">Company name</Typography>
            <TextField
              fullWidth
              value={state.name}
              autoFocus
              onChange={(e) =>
                dispatch({ type: 'name', payload: e.target.value })
              }
            />
          </Stack>
          <Stack sx={{ alignItems: 'flex-start' }} spacing={2}>
            <Typography variant="smallRegular">Logo</Typography>
            <ImageUploader
              isCreateModal={true}
              uploaderId={'create-company-uploader'}
              imageData={state.imageData}
              onChangeImageData={onChangeImageData}
            />
          </Stack>
        </Stack>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!valid || isLoading}
        >
          {isLoading ? <CircularProgress size={16} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCompany;
