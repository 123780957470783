import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiTooltip'] = {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
      },
    },
  };
  return styleOverrides;
};
