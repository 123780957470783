import dayjs from 'dayjs';
import { FormGroup } from '../../../../components/inputs';
import Recording from './Recording';

const Recordings = ({
  recordings,
  selectedRecordings,
  setSelectedRecordings,
}: any) => {
  return recordings.map((recording: any, i: number) => {
    const { date, recordings: dateRecordings } = recording;
    const dateDayjs = dayjs(date, 'YYYY-MM-DD');
    const nameOfDateInEnglish = dateDayjs.format('dddd');
    return (
      <FormGroup
        title={`${date} - ${nameOfDateInEnglish}`}
        style={{
          width: '100%',
          marginTop: i !== 0 ? '50px' : '10px',
        }}
        key={date}
      >
        {dateRecordings.length > 0 &&
          dateRecordings.map((item: any) => (
            <Recording
              key={item.path}
              recording={item}
              selectedRecordings={selectedRecordings}
              setSelectedRecordings={setSelectedRecordings}
            />
          ))}
      </FormGroup>
    );
  });
};

export default Recordings;
