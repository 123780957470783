import React from 'react';
import * as cordovaHelper from '../../../../../utils/cordovaHelper';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const LogisticsPictureAndBarcode = (props: any) => {
  const { imageData, setImageData, barcode, setBarcode } = props;

  const sm = useMediaQuery('(max-width: 600px)');

  const handleTakePicture = async () => {
    try {
      const imgUri = await cordovaHelper.getPicture();

      cordovaHelper.getFileContentAsBase64(imgUri, setImageData);
    } catch (error) {
      console.error('Error taking picture:', error);
    }
  };

  const handleRemovePicture = () => setImageData(null);

  const handleScanQrCode = async () => {
    try {
      const result = await cordovaHelper.scanBarcode();
      setBarcode(result.text);
    } catch (error) {
      console.error('Scanning failed:', error);
    }
  };

  return (
    <Stack spacing={4} sx={{ paddingBottom: 4 }}>
      <Button
        sx={{
          width: sm ? '100%' : 320,
        }}
        variant="contained"
        onClick={handleScanQrCode}
      >
        Scan Barcode
      </Button>
      <Stack
        spacing={4}
        sx={{
          width: sm ? '100%' : 320,
        }}
      >
        <Typography variant="smallRegular">Barcode</Typography>
        <TextField
          fullWidth
          value={barcode}
          onChange={({ target: { value } }) => setBarcode(value)}
        />
      </Stack>

      <Button
        sx={{
          width: sm ? '100%' : 320,
        }}
        variant="contained"
        onClick={handleTakePicture}
      >
        Take a photo
      </Button>
      {imageData && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{ width: '100%' }}
            component="img"
            src={imageData}
            alt="Taken image"
          />
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              right: -8,
              top: -8,
              padding: 0,
            }}
            aria-label="remove"
            color="inherit"
            onClick={handleRemovePicture}
          >
            <HighlightOffIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

export default LogisticsPictureAndBarcode;
