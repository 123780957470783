// @ts-nocheck
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

export enum SiteGoogleMapsMode {
  view = 'view',
  edit = 'edit',
  come_here = 'come_here',
  go_there = 'go_there',
  create_asset = 'create_asset',
  create_source = 'create_source',
  create_destination = 'create_destination',
}

export interface GoogleMapPoint {
  lat: number;
  lng: number;
}

const SiteGoogleMaps = ({
  siteCenter,
  onMapClick = () => {},
  mode = SiteGoogleMapsMode.view,
  width = '100%',
  height = '100%',
  mapType = 'roadmap',
  children,
}: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'ultrawis-site-google-maps-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const containerStyle = {
    width: width,
    height: height,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={siteCenter}
      zoom={18}
      onClick={onMapClick}
      options={{
        maxZoom: 25,
        minZoom: 18,
        streetViewControl: false,
        mapTypeId: mapType,
        // draggable: false,
        // mapTypeId: 'roadmap', // This line sets the map type to roadmap, removing the terrain view
        styles: [
          {
            featureType: 'poi', // Points of interest
            elementType: 'labels',
            stylers: [{ visibility: 'off' }], // Hide labels
          },
          {
            featureType: 'transit', // Transit stations
            elementType: 'labels',
            stylers: [{ visibility: 'off' }], // Hide labels
          },
        ],
      }}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(SiteGoogleMaps);
