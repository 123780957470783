import { useDispatch } from 'react-redux';
import { calcSumTaskActions } from '../store/calcsumtask';
import { calcSumTaskRptActions } from '../store/calcsumtaskrpt';
import { useHttp } from './useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { TASK_PATHS } from '../variables/endpoints';

const useCalctask = () => {
  const dispatch = useDispatch();
  const { api } = useHttp();
  const setHandlerCalcSumTask = async (dateProp: string, craneId: number) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_PATHS.getCalculatedsumtask(dateProp, craneId.toString()),
      });
      dispatch(calcSumTaskActions.setCalcSumTask(data));
    } catch (err) {}
  };
  const setHandlerCalcSumTaskRpt = async (
    dateProp: string,
    craneId: number
  ) => {
    try {
      const { data } = await api({
        method: ApiMethods.GET,
        url: TASK_PATHS.getCalculatedsumtask(dateProp, craneId.toString()),
      });
      dispatch(calcSumTaskRptActions.setCalcSumTaskRpt(data));
    } catch (err) {}
  };
  return { setHandlerCalcSumTask, setHandlerCalcSumTaskRpt };
};

export default useCalctask;
