import { Box, Stack, styled } from '@mui/material';

import moment from 'moment/moment';
import { useAppSelector } from '../../../../store/hooks';
import { DateFormatTypes } from '../../../../variables/enums';
import { Property } from '../../projectReport/content/styled';
import CraneLiftImageChart from '../charts/CraneLiftImageChart';

export const Container = styled(Stack)`
  padding: 0 12px;
  position: relative;
  height: 100%;
  border-radius: 8px;
`;

export const CraneLiftImagesContent = (props: any) => {
  const { startDate } = props;
  // const { tasks } = data;
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);
  // const crane = useAppSelector((state: any) => state.projectCrane.Crane);
  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.selectedCrane,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontSize: '10px',
      }}
    >
      <Container>
        <Stack>
          <Stack width="100%" sx={{ marginTop: 8 }} spacing={4}>
            <Stack direction="row" spacing={4}>
              <Property
                label="Crane Name"
                value={selectedCrane.name}
                textFontSize={16}
                valueFontSize={22}
              />
              <Property
                label="Date"
                value={moment(startDate).format(DateFormatTypes.MOMENT_DATE)}
                textFontSize={16}
                valueFontSize={22}
              />
            </Stack>
          </Stack>
          <Stack
            width="100%"
            sx={{
              backgroundColor: 'backgrounds.system',
              height: 16,
              marginTop: 6,
              marginBottom: 6,
            }}
          />
        </Stack>

        {lifts.length === 0 ? (
          <Stack
            sx={{
              width: '100%',
              height: 200,
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 16,
            }}
          >
            <Property
              label="No data to display"
              value=""
              textFontSize={16}
              valueFontSize={22}
              sx={{ border: 'none' }}
            />
          </Stack>
        ) : (
          <>
            {lifts.map((lift: any, i: number) => (
              <CraneLiftImageChart {...lift} num={i + 1} />
            ))}
          </>
        )}
      </Container>
    </Box>
  );
};
