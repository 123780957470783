// @ts-nocheck
import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppSelector } from '../../store/hooks';
import { TaskByCategory } from '../../store/tasksbycategory';

interface TaskByCategoryChartProps {
  tasksByCategory: TaskByCategory[];
  Report: boolean;
}

const TaskByCategoryChart = ({
  tasksByCategory,
  Report,
}: TaskByCategoryChartProps) => {
  const mediaResLevel = useAppSelector(
    (state) => state.mediares.mediares.level,
  );
  // const tasksByCategory = useAppSelector((state) => state.tasksByCategory.tasksbycategory);
  const cargoTypes = useAppSelector((state) => state.cargoType.data);
  let showLegend = Report ? true : false;
  let fsNumber = mediaResLevel < 3 ? '7px' : '10px';
  // let LiftTitle = (mediaResLevel < 3) ? '<b>L:' :'<b> Lifts:';
  // let showLegend = false;//(mediaResLevel < 3) ? false :true;
  let objHeight = Report ? 300 : 130;
  let ticHeight = Report ? 100 : 70;
  var hours = tasksByCategory.map(function (el) {
    return el.hournum + ':00';
  });
  var Bricks = tasksByCategory.map(function (el) {
    return el.Bricks;
  });
  var Concrete = tasksByCategory.map(function (el) {
    return el.Concrete;
  });
  var General = tasksByCategory.map(function (el) {
    return el.General;
  });
  var IronGridMesh = tasksByCategory.map(function (el) {
    return el.IronGridMesh;
  });
  var IronRodesPack = tasksByCategory.map(function (el) {
    return el.IronRodesPack;
  });
  var NotIdentified = tasksByCategory.map(function (el) {
    return el.NotIdentified;
  });
  var TrashContainer = tasksByCategory.map(function (el) {
    return el.TrashContainer;
  });
  var WoodPack = tasksByCategory.map(function (el) {
    return el.WoodPack;
  });
  var structureDismantle = tasksByCategory.map(function (el) {
    return el.structureDismantle;
  });
  var structureInstall = tasksByCategory.map(function (el) {
    return el.structureInstall;
  });

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: objHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: hours,
    },
    yAxis: {
      min: 0,

      tickPositions: [0, ticHeight],
      title: {
        text: 'Minutes',
      },
      stackLabels: {
        enabled: true,
        // formatter: function() {
        //     return  lifts[this.x] + '</b>'
        // },
        style: {
          fontWeight: 'bold',
          fontSize: fsNumber,

          color:
            // theme
            //@ts-ignore
            (Highcharts.defaultOptions.title.style &&
              //@ts-ignore
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
    },
    legend: {
      align: 'right',
      x: -30,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
        //@ts-ignore
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      // headerFormat: '<b>{point.x}</b><br/>',
      // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      //@ts-ignore
      // headerFormat: '<b>{point.x}</b><br/>Lifts:lifts[{point.point.index}]} <br/>',
      // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
      formatter: function () {
        //@ts-ignore
        return this.points.reduce(function (s, point) {
          //@ts-ignore
          if (point.y > 0) {
            //@ts-ignore
            return (
              s +
              '<span style="color:' +
              //@ts-ignore
              point.series.color +
              '">' +
              point.series.name +
              '</span>: <b>' +
              point.y +
              '</b> <br/>'
            );
          } else {
            return s;
          }
        }, '');
      },
      shared: true,
      //    headerFormat: '<b>{point.x}</b><br/>',
      //    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',

        dataLabels: {
          enabled: false, //showLegend,
          style: {
            fontSize: fsNumber,
          },
        },
      },
    },

    series: [
      {
        name: 'General',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter((rec) => rec.CargoTypeDesc === 'General')[0]
                .Color
            : 'black',
        //@ts-ignore
        data: General,
        showInLegend: showLegend,
      },
      {
        name: 'Bricks',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter((rec) => rec.CargoTypeDesc === 'Bricks')[0]
                .Color
            : 'black',
        //@ts-ignore
        data: Bricks,
        showInLegend: showLegend,
      },
      {
        name: 'Concrete',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter((rec) => rec.CargoTypeDesc === 'Concrete')[0]
                .Color
            : 'black',
        //@ts-ignore
        data: Concrete,
        showInLegend: showLegend,
      },
      {
        name: 'Iron Rodes Pack',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Iron Rodes Pack',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: IronRodesPack,
        showInLegend: showLegend,
      },
      {
        name: 'Iron Grid Mesh',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Iron Grid Mesh',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: IronGridMesh,
        showInLegend: showLegend,
      },
      {
        name: 'Wood Pack',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter((rec) => rec.CargoTypeDesc === 'Wood Pack')[0]
                .Color
            : 'black',
        //@ts-ignore
        data: WoodPack,
        showInLegend: showLegend,
      },
      {
        name: 'Trash Container',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Trash Container',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: TrashContainer,
        showInLegend: showLegend,
      },
      {
        name: 'Structure Install',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Structure Install',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: structureInstall,
        showInLegend: showLegend,
      },
      {
        name: 'Structure Dismantle',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Structure Dismantle',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: structureDismantle,
        showInLegend: showLegend,
      },
      {
        name: 'Not Identified',
        color:
          cargoTypes.length > 1
            ? cargoTypes.filter(
                (rec) => rec.CargoTypeDesc === 'Not Identified',
              )[0].Color
            : 'black',
        //@ts-ignore
        data: NotIdentified,
        showInLegend: showLegend,
      },
    ],
  };
  return (
    <>
      {cargoTypes.length > 1 && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
};

export default TaskByCategoryChart;
