import React from 'react';
import { Stack, Typography } from '@mui/material';
import { InlineEdit } from '../../../../components/InlineEdit';

const Details = ({ name, onUpdate, loading, title }: any) => {
  return (
    <Stack width="100%" spacing={2}>
      <Typography
        variant="mediumMedium"
        sx={{
          backgroundColor: 'secondary.main',
          padding: 2,
          color: 'common.white',
          borderRadius: 1,
        }}
      >
        {title}
      </Typography>
      <Stack
        spacing={3}
        width="100%"
        sx={{
          display: 'flex',
        }}
      >
        <InlineEdit
          id={name}
          defaultValue={name}
          placeholder={'Name'}
          onUpdate={onUpdate}
          loading={loading}
          noEmpty
        />
      </Stack>
    </Stack>
  );
};

export default Details;
