import { Button, Paper, Stack, useMediaQuery } from '@mui/material';

export const SlideContent = ({
  imageSrc,
  getKeyDetails = () => {},
  handleClickOpen = () => {},
  imageStyle = {},
  time = null,
}: any) => {
  const sm = useMediaQuery('(max-width: 768px)');

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.click();
  };

  return (
    <Paper
      sx={{
        border: 'solid 1px #ff6f00',
        borderRadius: 4,
        overflow: 'hidden',
        height: '100%',
        // width: '100%',
      }}
    >
      <Stack
        height="100%"
        width="100%"
        sx={{
          backgroundImage: `url('${imageSrc}')`,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          ...imageStyle,
        }}
      />
      <Paper
        sx={{
          position: 'absolute',
          top: sm ? 16 : 24,
          left: sm ? 16 : 24,
          padding: 2,
          backgroundColor: '#faebd7b0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getKeyDetails(imageSrc)}&nbsp;&nbsp;
        {time && <span style={{ marginRight: '6px' }}>{time}</span>}
        <Button
          variant="contained"
          size="small"
          onClick={() => handleClickOpen(`${imageSrc}`)}
        >
          Open
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleDownload}
          style={{ marginLeft: '10px' }}
        >
          Download
        </Button>
      </Paper>
    </Paper>
  );
};
