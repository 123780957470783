import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) =>
  apiDeleteAsync(`site/${props.siteId}/zones/${props.zoneId}`);

export const useDeleteZone = (options: any) => {
  const key = ['DeleteZone'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
