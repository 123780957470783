// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  compact,
  concat,
  flatten,
  groupBy,
  map,
  orderBy,
  sum,
  sumBy,
  take,
  times,
} from 'lodash';
import { handleDurations, WEEKDAY } from './utils';
import { ChartStack } from '../content/styled';

const JobsiteWeeklyMaterialsLifts = (props: any) => {
  const { records, project } = props;

  const daysCount = records.length;
  const cranesCount = Number(project.CranesCount);

  // Take every material for each day with concatenated records for all cranes
  const handledData = times(daysCount, (day) => {
    const projectDayRecords = concat(
      ...times(cranesCount, (crane) => {
        return records[day]?.records[crane]?.records || [];
      }),
    );

    const projectDayRecordsDurations = compact(
      flatten(projectDayRecords.map(({ durations }) => durations)),
    );

    const durationsSum = sumBy(projectDayRecordsDurations, 'duration');

    const cargoTypesGroups = groupBy(projectDayRecordsDurations, 'cargoType');

    const cargoTypesMap = map(cargoTypesGroups, (items) => ({
      cargoType: items[0].cargoType,
      cargoTypeColor: items[0].cargoTypeColor,
    }));

    return cargoTypesMap.map(({ cargoType, cargoTypeColor }) => {
      return {
        name: cargoType,
        color: cargoTypeColor,
        y: +(
          (sum(compact(handleDurations(cargoType, projectDayRecords))) /
            (durationsSum / 60)) *
          100
        ).toFixed(2),
      };
    });
  });

  // Sum all materials from all days, order them desc by values and take top 5
  const topMaterialInWeek = take(
    orderBy(
      Object.values(
        concat(...handledData).reduce((acc, { name, y, color }) => {
          acc[name] = { name, color, y: (acc[name] ? acc[name].y : 0) + y };
          return acc;
        }, {}),
      ),
      'y',
      'desc',
    ),
    5,
  ).map(({ name, color }) => ({
    name,
    color,
  }));

  const colors = topMaterialInWeek.map(({ color }) => color);

  const series = topMaterialInWeek.map(({ name: material }) => {
    const data = times(
      daysCount,
      (day) => handledData[day]?.find(({ name }) => name === material)?.y || 0,
    );

    return {
      name: material,
      data,
    };
  });

  const days = times(daysCount, (day) => WEEKDAY[day]);

  const options = {
    chart: {
      type: 'column',
      height: 300,
    },
    exporting: { enabled: false },
    colors,
    title: {
      text: 'Weekly Materials Lifts',
    },
    xAxis: {
      categories: days,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: { text: undefined },
      labels: {
        format: '{value}%',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}%',
          allowOverlap: true,
        },
      },
    },
    series,
  };

  return (
    <ChartStack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartStack>
  );
};

export default JobsiteWeeklyMaterialsLifts;
