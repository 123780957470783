import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import b64toBlob from 'b64-to-blob';
import saveAs from 'file-saver';
import {
  Stack,
  Button,
  styled,
  CircularProgress,
  Collapse,
  Alert,
  IconButton,
  AlertTitle,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ProjectReportContent } from './content/ProjectReportContent';
import { Loading } from '../../styled';
import { EmptyState, ErrorState } from '../../alerts';
import useProjectReport from '../../../hooks/useProjectReport';
import { useAppSelector } from '../../../store/hooks';
import { apiPostAsync } from '../../../api/axios';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { actions as projectReportActions } from '../../../store/projectReport';
import moment from 'moment';
import { isArray } from 'lodash';

const ExportButton = styled(Button)`
  @media print {
    display: none;
  }
`;

interface ProjectReportPrintProps {
  startDate?: string;
  projectId?: number;
  craneIds?: number[];
}

const ProjectReport = (props: ProjectReportPrintProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const startDate = props.startDate || params.startDate;
  const projectId = props.projectId || params.projectId;
  const craneIds = props.craneIds || searchParams.get('craneIds')?.split(',');
  const isProjectReport = craneIds === undefined;

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<any>();
  const [dailyRecords, setDailyRecords] = useState<any[]>([]);
  const [noRecords, setNoRecords] = useState<boolean>(false);
  const { fetchTaskByHoursByProjectApi } = useProjectReport();
  const {
    error,
    loading,
    data: reportData,
  } = useAppSelector((state) => state.ProjectReport);

  useEffect(() => {
    if (projectId && startDate) {
      fetchTaskByHoursByProjectApi({
        ProjectId: projectId,
        DateLoad: startDate,
        CraneIds: craneIds,
        FirstDayOfWeek: moment(startDate)
          .subtract(moment(startDate).day(), 'd')
          .format('YYYY-MM-DD'),
      });
    }

    return () => {
      dispatch(projectReportActions.resetTaskByHoursByProject());
    };
  }, []);

  useEffect(() => {
    if (!reportData) return;
    const { records, project } = reportData;

    const newDailyRecords =
      records.find((innerRecords) => innerRecords.date === project.reportDate)
        ?.records || [];
    setDailyRecords(newDailyRecords);

    const newNoRecords = dailyRecords.length === 0;
    setNoRecords(newNoRecords);
  }, [reportData]);

  const onExportClick = async () => {
    setPdfError(undefined);
    setPdfLoading(true);
    const url = 'project-report';
    const name = !craneIds
      ? ''
      : isArray(craneIds)
      ? craneIds.join('_')
      : craneIds;
    const filename = `daily_${
      craneIds ? 'crane' : 'project'
    }_${name}_report__${startDate}.pdf`;
    const reportUrl = `/#/project-report/${projectId}/${startDate}${
      craneIds ? `?craneIds=${craneIds}` : ''
    }`;
    const data = {
      reportUrl,
      projectId,
      startDate,
      craneIds,
    };

    try {
      const response = await apiPostAsync(url, data);
      const blob = b64toBlob(response.data, 'application/pdf');
      saveAs(blob, filename);
      setPdfLoading(false);
    } catch (e) {
      setPdfError(e);
      setPdfLoading(false);
    }
  };

  return (
    <>
      {loading && !error && <Loading sx={{ minHeight: 320 }} />}
      {!loading && error && (
        <ErrorState sx={{ minHeight: 320 }} error={error} />
      )}
      {!loading && !error && noRecords && (
        <EmptyState sx={{ minHeight: 320 }} error={error} />
      )}
      {!loading && !error && dailyRecords.length > 0 && (
        <Stack sx={{ width: '100%' }}>
          <ExportButton
            disabled={pdfLoading}
            onClick={onExportClick}
            startIcon={
              pdfLoading ? <CircularProgress size={16} /> : <PictureAsPdfIcon />
            }
            variant="contained"
            sx={{ maxWidth: 220, marginLeft: '32px' }}
          >
            {pdfLoading ? 'Generating Report...' : 'Export to PDF'}
          </ExportButton>
          {!pdfLoading && pdfError && (
            <Collapse in={!!pdfError} sx={{ margin: '32px' }}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setPdfError(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {pdfError?.message}
              </Alert>
            </Collapse>
          )}
          <Stack>
            <ProjectReportContent
              reportData={reportData}
              isProjectReport={isProjectReport}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ProjectReport;
