// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { S3MMSITE_PATH } from '../../../../variables/endpoints';
import { Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

const Svgs = (props) => {
  const { zoneItems, polyPoints, relpxx, project } = props;

  let sPoints: string = '';
  let svgs: any = [];
  if (zoneItems) {
    zoneItems.forEach((element: any) => {
      if (element.ZoneTypeId == 2) {
        const left =
          ((element.xCenter - project.xMeterStart) * project.Pixel2Meter) /
          relpxx;
        const top =
          ((project.yMeterStart - element.yCenter) * project.Pixel2Meter) /
          relpxx;

        const r = (element.Radius * project.Pixel2Meter) / relpxx;
        if (isFinite(left) && isFinite(top) && isFinite(r)) {
          svgs.push(
            <svg
              key={uuid()}
              style={{
                position: 'absolute',
                overflow: 'visible',
                left,
                top,
              }}
            >
              <circle cx="0" cy="0" r="3" fill={'yellow'} />
            </svg>,
          );
          svgs.push(
            <svg
              key={uuid()}
              style={{
                position: 'absolute',
                overflow: 'visible',
                left,
                top,
              }}
            >
              <circle
                cx="0"
                cy="0"
                r={r}
                stroke={'yellow'}
                fill="transparent"
                strokeWidth="3"
              />
            </svg>,
          );
        }
      }
      if (element.ZoneTypeId == 1) {
        sPoints = '';
        if (polyPoints) {
          polyPoints
            .filter((rec: any) => rec.ZoneId === element.ID)
            .forEach((el: any) => {
              const xPoint =
                ((el.xPoint - project.xMeterStart) * project.Pixel2Meter) /
                relpxx;
              const yPoint =
                ((project.yMeterStart - el.yPoint) * project.Pixel2Meter) /
                relpxx;

              if (!isFinite(xPoint) || !isFinite(yPoint)) {
                sPoints = false;
              } else {
                sPoints = sPoints + ' ' + xPoint + ' ' + yPoint;
              }
            });

          if (sPoints)
            svgs.push(
              <svg
                key={uuid()}
                style={{
                  position: 'absolute',
                  overflow: 'visible',
                  left: 0,
                  top: 0,
                }}
              >
                <polygon
                  points={sPoints}
                  stroke={'yellow'}
                  fill="transparent"
                  strokeWidth="3"
                />
              </svg>,
            );
        }
      }
    });
  }
  return <Fragment>{svgs}</Fragment>;
};

const ProjectSafetyAntiCollisionCurrentZoneMap = (props) => {
  const {
    project,
    safety: { zoneItems, polyPoints },
  } = props;

  const [relpxx, setRelpxx] = useState<number>(0);

  useEffect(() => {
    if (project.SiteMap) {
      let img = new Image();
      img.src = `${S3MMSITE_PATH}/${project.SiteMap}`;
      img.onload = () => {
        setRelpxx(img.width / 700);
      };
    }
  }, [project.SiteMap]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        marginBottom: 4,
        borderWidth: 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
        padding: 6,
      }}
    >
      <Stack sx={{ padding: 4 }} spacing={4}>
        <Typography variant="h2Medium">
          Safety - Anti collision current zone map
        </Typography>
      </Stack>
      <Stack sx={{ padding: 4 }} spacing={4}>
        <Stack
          sx={{
            position: 'relative',
          }}
        >
          <img
            style={{
              position: 'relative',
              height: 'auto',
              width: 700,
            }}
            alt={project.ProjectName}
            src={`${S3MMSITE_PATH}/${project.SiteMap}`}
          />
          <Svgs
            zoneItems={zoneItems}
            polyPoints={polyPoints}
            relpxx={relpxx}
            project={project}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProjectSafetyAntiCollisionCurrentZoneMap;
