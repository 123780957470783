// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Collapse,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneIcon from '@mui/icons-material/Done';
import { SitemapWithStaticPoints } from '../../components/Sitemap';
import SiteGoogleMaps, {
  GoogleMapPoint,
  SiteGoogleMapsMode,
} from '../../components/SiteGoogleMaps/SiteGoogleMaps';
import { convertXYToLatLong } from '../../utils/coordinatesHelper';
import { Marker } from '@react-google-maps/api';
import { CraneTaskDirection, CraneTaskType } from '../../variables/enums';

const sourceImageSize = 24;
const scale = 1.7;

const CranebitTaskCard = (props: any) => {
  const {
    siteCenter,
    activateButtonLoading,
    doneButtonLoading,
    task,
    index,
    expandedId,
    imageInfo,
    SiteMap,
    xViewSource,
    yViewSource,
    xViewDest,
    yViewDest,
    onCardClick,
    onActivateHandler,
    onDeleteHandler,
    onDoneHandler,
    imageWidth,
    xSource,
    ySource,
    xDest,
    yDest,
  } = props;

  const [point, setPoint] = useState<GoogleMapPoint | null>(null);
  const [source, setSource] = useState<GoogleMapPoint | null>(null);
  const [destination, setDestination] = useState<GoogleMapPoint | null>(null);
  const sm = useMediaQuery('(max-width: 600px)');
  const medium = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  useEffect(() => {
    if (!task) return;
    const { sourceLat, sourceLong, destinationLat, destinationLong } = task;
    if (sourceLat && sourceLong) {
      setSource({ lat: sourceLat, lng: sourceLong });
    }
    if (destinationLat && destinationLong) {
      setDestination({ lat: destinationLat, lng: destinationLong });
    }
  }, [task]);

  // useEffect(() => {
  //   if (xSource && ySource) {
  //     if (isSignalerTask) {
  //       const { lat, lng } = convertXYToLatLong(siteCenter, xSource, ySource);
  //       setSource({ lat, lng });
  //     } else {
  //       setSource({ lat: xSource, lng: ySource });
  //     }
  //   }
  //   if (xDest && yDest) {
  //     if (isSignalerTask) {
  //       const { lat, lng } = convertXYToLatLong(siteCenter, xDest, yDest);
  //       setDestination({ lat, lng });
  //     } else {
  //       setDestination({ lat: xDest, lng: yDest });
  //     }
  //   }
  // }, [xSource, ySource, xDest, yDest]);

  const isSignalerTask =
    task.type === CraneTaskType.COME_HERE ||
    task.type === CraneTaskType.GO_THERE;

  const onClick = (itemId: number, index: number) => {
    onCardClick(itemId, index);
    if (index > 0) {
      setTimeout(() => {
        const element = document.getElementById(`task-${index - 1}`);
        element?.scrollIntoView();
      }, 1);
    }
  };

  const getCraneTaskTypeText = (type: CraneTaskType) => {
    switch (type) {
      case CraneTaskType.COME_HERE:
        return 'Come here';
      case CraneTaskType.GO_THERE:
        return 'Go there';
      case CraneTaskType.SOURCE_TO_DESTINATION:
        return 'Source to Destination';
      case CraneTaskType.TEMPLATE:
        return 'Template';
      default:
        return '';
    }
  };

  return (
    <Paper
      elevation={expandedId === index ? 5 : 1}
      key={uuid()}
      id={`task-${index}`}
      sx={{
        // backgroundColor: 'backgrounds.system',
        boxShadow: '0px 0px 3px 0px #000000',
        gap: 6,
        width: '100%',
        marginBottom: 6,
        marginTop: 1,
        padding: sm || medium ? '4px 8px' : '8px 16px',
        borderLeftColor: isSignalerTask
          ? '#ff6f00'
          : task.direction === CraneTaskDirection.UP
          ? '#16947D'
          : '#E73D3D',
        borderLeftStyle: 'solid',
        borderLeftWidth: '4px',
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick(task.id, index);
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2Medium">{index + 1}</Typography>
          <Stack
            width="100%"
            justifyContent="center"
            sx={{
              marginLeft: sm
                ? task.barcode
                  ? task.barcode.length > 10
                    ? 2
                    : 5
                  : 5
                : 5,
            }}
          >
            <Typography
              gutterBottom
              variant={sm ? 'mediumMedium' : 'h1SemiBold'}
              component="div"
              noWrap
              sx={{
                marginBottom: 0,
                fontWeight: 'bold',
                overflowX: 'hidden',
                fontSize:
                  sm || medium
                    ? task.barcode
                      ? task.barcode.length > 10
                        ? '10px'
                        : 'inherit'
                      : 'inherit'
                    : 'inherit',
              }}
            >
              {isSignalerTask ? 'Signaller' : task.barcode || '-'}
            </Typography>
            <Typography variant={sm ? 'smallMedium' : 'h2Medium'}>
              {/* {task.cargoType?.name ||  */}
              {getCraneTaskTypeText(task.type)}
              {/* } */}
              &nbsp;
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Stack direction={'row'}>
              <Stack
                direction={'column'}
                sx={{ padding: '0 16px' }}
                justifyContent="center"
                alignItems={'flex-end'}
                spacing={1}
              >
                {isSignalerTask ? null : task.direction ===
                  CraneTaskDirection.UP ? (
                  <ArrowUpwardIcon
                    fontSize={sm ? 'small' : 'large'}
                    sx={{ color: '#16947D' }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    fontSize={sm ? 'small' : 'large'}
                    sx={{ color: '#E73D3D' }}
                  />
                )}
                <Typography
                  variant="smallRegular"
                  noWrap
                  color="grey.500"
                  sx={{ fontSize: sm ? '8px' : medium ? '12px' : '14px' }}
                >
                  {moment(task.createdAt).format('LL')}&nbsp;
                </Typography>
              </Stack>
              <Stack
                direction="row"
                gap="8px"
                justifyContent="center"
                alignItems="center"
              >
                {/* <Button
                endIcon={<PlayArrowIcon />}
                disabled={activateButtonLoading}
                variant={
                  item.Active
                    ? activateButtonLoading
                      ? 'outlined'
                      : 'contained'
                    : 'outlined'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onActivateHandler(item.TypeId, item.SourceId);
                }}
                sx={
                  item.Active
                    ? activateButtonLoading
                      ? { width: 120, height: '100%' }
                      : {
                          color: '#ffffff !important',
                          backgroundColor: '#dc3545 !important',
                          width: 120,
                          height: '100%',
                        }
                    : { width: 120, height: '100%' }
                }
              >
                {activateButtonLoading ? (
                  <CircularProgress size={16} />
                ) : item.Active ? (
                  'Active'
                ) : (
                  'Activate'
                )}
              </Button> */}

                {/* <Button
                endIcon={<DoneIcon />}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  onDoneHandler(item.TypeId, item.SourceId);
                }}
                disabled={doneButtonLoading}
                sx={{ width: 120, height: '100%' }}
              >
                {doneButtonLoading ? <CircularProgress size={16} /> : 'Done'}
              </Button> */}
              </Stack>
            </Stack>
            <Button
              endIcon={<DangerousIcon />}
              disabled={activateButtonLoading}
              variant={
                task.isActive
                  ? activateButtonLoading
                    ? 'outlined'
                    : 'contained'
                  : 'outlined'
              }
              onClick={(e) => {
                e.stopPropagation();
                onDeleteHandler(task.id);
              }}
              sx={
                task.isActive
                  ? activateButtonLoading
                    ? { width: sm || medium ? 80 : 120, height: '100%' }
                    : {
                        color: '#ffffff !important',
                        backgroundColor: '#dc3545 !important',
                        width: sm || medium ? 80 : 120,
                        height: '100%',
                        fontSize: sm ? '10px' : medium ? '12px' : '14px',
                      }
                  : {
                      width: sm || medium ? 80 : 120,
                      height: '100%',
                      fontSize: sm ? '10px' : medium ? '12px' : '14px',
                    }
              }
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
        <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
          {/* <SitemapWithStaticPoints
            {...{
              imageInfo,
              imageWidth,
              item,
              SiteMap,
              scale,
              xViewSource,
              yViewSource,
              xViewDest,
              yViewDest,
              sourceImageSize,
              showDirection: true,
            }}
          /> */}
          <div onClick={(e) => e.stopPropagation()}>
            <SiteGoogleMaps
              siteCenter={siteCenter}
              mode={SiteGoogleMapsMode.view}
              width="100%"
              height="500px"
            >
              {source && (
                <Marker
                  position={source}
                  icon={{
                    url: require('../../assets/icons/source.svg').default,
                    // scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
              )}
              {destination && (
                <Marker
                  position={destination}
                  icon={{
                    url: require('../../assets/icons/destination.svg').default,
                    // scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
              )}
            </SiteGoogleMaps>
          </div>
        </Collapse>
      </Stack>
    </Paper>
  );
};

export default CranebitTaskCard;
