import { useMutation } from 'react-query';
import { apiDeleteAsync } from '../api/axios';

const api = (props: any) => apiDeleteAsync(`user/${props.ID}`);

export const useDeleteUser = (options?: any) => {
  const key = ['DeleteUser'];
  const fn = (props: any) => api(props);

  return useMutation(key, fn, {
    ...(options || {}),
  });
};
