export const API_PATH = process.env.REACT_APP_API_PATH ?? '';
export const S3MMSITE_PATH = process.env.REACT_APP_S3MMSITE ?? '';
export const S3_IMAGES_PATH = process.env.REACT_APP_S3_IMAGES_PATH ?? '';

// Login
export const LOGIN_PATH = `${API_PATH}/login`;

// Forgot password
export const FORGOT_PASSWORD_PATH = `${API_PATH}/password/forgot`;

// Cargo Type
export const CARGO_TYPE_PATH = `${API_PATH}/cargotype`;
export const CARGO_TYPE_PATHS = {
  getById: (id: string) => `${CARGO_TYPE_PATHS}/${id}`,
};
// Device Type
export const DEVICE_TYPE_PATH = `${API_PATH}/devicetype`;

// Task
const TASK_PATH = `${API_PATH}/tasks`;
export const TASK_PATHS = {
  getById: (id: string) => `${TASK_PATH}/${id}`,
  getVTasks: (dateLoad: string, craneId: string) =>
    `${TASK_PATH}/${dateLoad}/${craneId}`,
  getCalculatedsumtask: (dateLoad: string, craneId: string) =>
    `${TASK_PATH}/calculatedsumtask/${dateLoad}/${craneId}`,
  getCalculatedtask: (dateLoad: string, craneId: string) =>
    `${TASK_PATH}/calculatedtask/${dateLoad}/${craneId}`,
  getIdletime: (dateLoad: string, craneId: string) =>
    `${TASK_PATH}/idletime/${dateLoad}/${craneId}`,
};

// Tasks Rpt
const TASKS_RPT_PATH = `${API_PATH}/tasksrpt`;
export const TASKS_RPT_PATHS = {
  getTasksRpt: (dateLoad: string, craneId: string) =>
    `${TASKS_RPT_PATH}/${dateLoad}/${craneId}`,
};

// Gantt Tasks
const GANTT_TASKS_PATH = `${API_PATH}/gantttasks`;
export const GANTT_TASKS_PATHS = {
  getGanttTasks: (dateLoad: string, craneId: string) =>
    `${GANTT_TASKS_PATH}/${dateLoad}/${craneId}`,
};

// Tasks by category
const TASKS_BY_CATEGORY_PATH = `${API_PATH}/tasksbycategory`;
export const TASKS_BY_CATEGORY_PATHS = {
  getTasksByCategory: (dateLoad: string, craneId: string) =>
    `${TASKS_BY_CATEGORY_PATH}/${dateLoad}/${craneId}`,
};

// Tasks by hours
const TASK_BY_HOURS_PATH = `${API_PATH}/tasksbyhours`;
export const TASK_BY_HOURS_PATHS = {
  getTasksByHours: (dateLoad: string, craneId: string) =>
    `${TASK_BY_HOURS_PATH}/${dateLoad}/${craneId}`,
};

// Crane
export const CRANE_PATH = `${API_PATH}/crane`;
export const CRANE_PATHS = {
  getById: (id: string) => `${CRANE_PATH}/${id}`,
};

// Cranes
export const CRANES_PATH = `${API_PATH}/cranes`;

// Daily video
const DAILY_VIDEO_PATH = `${API_PATH}/dailyvideo`;
export const DAILY_VIDEO_PATHS = {
  getDailyVideo: (dateLoad: string, craneId: string) =>
    `${DAILY_VIDEO_PATH}/${dateLoad}/${craneId}`,
};

// Last tasks
const LAST_TASKS_PATH = `${API_PATH}/lasttasks`;
export const LAST_TASKS_PATHS = {
  getLastTasks: (dateLoad: string, craneId: string) =>
    `${LAST_TASKS_PATH}/${dateLoad}/${craneId}`,
};

// Last telemetry
const LAST_TELEMETRY_PATH = `${API_PATH}/lastTelemetry`;
export const LAST_TELEMETRY_PATHS = {
  getByCraneId: (craneId: string) => `${LAST_TELEMETRY_PATH}/${craneId}`,
};

// Report header
const REPORT_HEADER_PATH = `${API_PATH}/reportheader`;
export const REPORT_HEADER_PATHS = {
  getReportHeader: (craneId: string, dateRef: string) =>
    `${REPORT_HEADER_PATH}/${craneId}/${dateRef}`,
};

// Telemetries
const TELEMETRIES_PATH = `${API_PATH}/telemetries`;
export const TELEMETRIES_PATHS = {
  getTelemetries: (dateLoad: string, craneId: string) =>
    `${TELEMETRIES_PATH}/${dateLoad}/${craneId}`,
};

// Dynamic Tasks Query
export const DYNAMIC_TASKS_QUERY_PATH = `${API_PATH}/dynamictasksquery`;

// Asset Info
export const ASSET_INFO_PATH = `${API_PATH}/assetInfo`;
export const ASSET_INFO_PATHS = {
  getById: (id: string) => `${ASSET_INFO_PATH}/${id}`,
  getSplitById: (id: string) => `${ASSET_INFO_PATH}/split/${id}`,
};

// Add asset job
export const ADD_ASSET_JOB_PATH = `${API_PATH}/addassetjob`;

// Crane2Users
export const CRANE_2_USERS_PATH = `${API_PATH}/crane2users`;
export const CRANE_2_USERS_PATHS = {
  getById: (id: string) => `${CRANE_2_USERS_PATH}/${id}`,
};

// Dynamic assets query
export const DYNAMIC_ASSETS_QUERY_PATH = `${API_PATH}/dynamicassetsquery`;

// Job Time Closed
const JOB_TIME_CLOSED_PATH = `${API_PATH}/jobtimeclosed`;
export const JOB_TIME_CLOSED_PATHS = {
  getById: (id: string) => `${JOB_TIME_CLOSED_PATH}/${id}`,
};

// Gtctm
export const GTCTM_PATH = `${API_PATH}/gtctm`;
export const GTCTM_PATHS = {
  getById: (id: string) => `${GTCTM_PATH}/${id}`,
};

// cb task
export const CB_TASK_PATH = `${API_PATH}/cbtask`;

// Crane Scheduler
const CRANE_SCHEDULER_PATH = `${API_PATH}/cranescheduler`;
export const CRANE_SCHEDULER_PATHS = {
  getCraneScheduler: (dateLoad: string, craneId: string) =>
    `${CRANE_SCHEDULER_PATH}/${dateLoad}/${craneId}`,
};

// Company
export const COMPANY_PATH = `${API_PATH}/company`;
export const COMPANY_PATHS = {
  getCompanyById: (id: string) => `${COMPANY_PATH}/${id}`,
  getCompanyUsersById: (id: string) => `${COMPANY_PATH}/users/${id}`,
  getCompanyProjectsById: (id: string) => `${COMPANY_PATH}/projects/${id}`,
};

// Project
export const PROJECT_PATH = `${API_PATH}/project`;
export const PROJECT_PATHS = {
  getById: (id: string) => `${PROJECT_PATH}/${id}`,
  projectWorkHours: `${PROJECT_PATH}/workhours`,
  getProjectWorkHoursById: (id: string) => `${PROJECT_PATH}/workhours/${id}`,
  getProjectSupplementById: (id: string) => `${PROJECT_PATH}/supplement/${id}`,
  getProjectCraneById: (id: string) => `${PROJECT_PATH}/crane/${id}`,
};

// User
export const USER_PATH = `${API_PATH}/user`;
export const USER_PATHS = {
  getById: (id: string) => `${USER_PATH}/${id}`,
};

// Invite User
export const INVITE_USER_PATH = `${API_PATH}/inviteuser`;

// Project2Users
export const PROJECT_2_USERS_PATH = `${API_PATH}/project2users`;
export const PROJECT_2_USERS_PATHS = {
  getById: (id: string) => `${PROJECT_2_USERS_PATH}/${id}`,
};

// Crane Allowed Weight
export const CRANE_ALLOWED_WEIGHT_PATH = `${API_PATH}/craneallowedweight`;
export const CRANE_ALLOWED_WEIGHT_PATHS = {
  getById: (id: string) => `${CRANE_ALLOWED_WEIGHT_PATH}/${id}`,
};

// Crane Type
export const CRANE_TYPE_PATH = `${API_PATH}/craneType`;

// Crane Model
export const CRANE_MODEL_PATH = `${API_PATH}/craneModel`;

// Crane Brand
export const CRANE_BRAND_PATH = `${API_PATH}/craneBrand`;

// Crane Report
export const CRANE_REPORT_PATH = `${API_PATH}/craneReport`;
export const CRANE_REPORT_PATHS = {
  getById: (id: string) => `${CRANE_REPORT_PATH}/${id}`,
};

// Crane Device Address
export const CRANE_DEVICE_ADDRESS_PATH = `${API_PATH}/cranedeviceaddress`;
export const CRANE_DEVICE_ADDRESS_PATHS = {
  getById: (id: string) => `${CRANE_DEVICE_ADDRESS_PATH}/${id}`,
};

// Zone
export const ZONE_PATH = `${API_PATH}/site`;
export const ZONE_PATHS = {
  getById: (id: string) => `${ZONE_PATH}/${id}`,
};

// Project Zone Points
const PROJECT_ZONE_POINTS_PATH = `${API_PATH}/projectzonepoints`;
export const PROJECT_ZONE_POINTS_PATHS = {
  getById: (id: string) => `${PROJECT_ZONE_POINTS_PATH}/${id}`,
};

// Zone Type
export const ZONE_TYPE_PATH = `${API_PATH}/zoneType`;

// Zone Point
export const ZONE_POINT_PATH = `${API_PATH}/zonePoint`;

// Asset Job
const ASSET_JOB_PATH = `${API_PATH}/assetJob`;
export const ASSET_JOB_PATHS = {
  getById: (id: string) => `${ASSET_JOB_PATH}/${id}`,
  up: (id: string) => `${ASSET_JOB_PATH}/up/${id}`,
  down: (id: string) => `${ASSET_JOB_PATH}/down/${id}`,
};

// Change Password
export const CHANGE_PASSWORD_PATH = `${API_PATH}/password`;

// Reset Password
export const RESET_PASSWORD_PATH = `${API_PATH}/reset-password`;

// Last Hour Tasks
const LAST_HOUR_TASKS_PATH = `${API_PATH}/lasthourtasks`;
export const LAST_HOUR_TASKS_PATHS = {
  getById: (id: string) => `${LAST_HOUR_TASKS_PATH}/${id}`,
};

// Stream on demand
export const STREAM_ON_DEMAND_PATH = `${API_PATH}/streamondemand`;
