// @ts-nocheck
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Stack, Typography } from '@mui/material';
import { JobsiteDailyStructureTable } from '../tables';
import { map } from 'lodash';
import { ChartProperty } from '../content/styled';

const JobsiteDailyStructureChart = (props: any) => {
  const { data, crane, tableData } = props;

  if (data.length === 0) {
    return (
      <Stack
        sx={{
          height: 1064,
          alignItems: 'center',
          marginBottom: 4,
          borderWidth: 1,
          borderColor: 'grey.400',
          borderRadius: 4,
          borderStyle: 'solid',
          padding: 6,
        }}
      >
        <Stack
          sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
          spacing={4}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
            Jobsite daily structure
          </Typography>
          <Stack
            sx={{
              padding: 2,
              backgroundColor: 'backgrounds.system',
              alignItems: 'flex-start',
            }}
          >
            <Stack>
              <Stack direction="row" spacing={6}>
                <ChartProperty label="Name" value={crane.name} width={150} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
          <Stack
            sx={{
              padding: 4,
              width: '100%',
              height: '40%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="smallRegular" color="#878a99">
              {'No data to display'}
            </Typography>
          </Stack>
          <JobsiteDailyStructureTable data={[]} />
        </Stack>
      </Stack>
    );
  }

  const categories = map(data, (d) => d.startTime);
  const seriesData = map(data, (d) => d.duration);

  const options = {
    chart: {
      type: 'line',
      height: 400,
    },
    title: {
      text: 'Structure Lifts duration [Minutes]',
    },
    exporting: { enabled: false },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Duration [Minutes]',
        data: seriesData,
      },
    ],
  };

  return (
    <Stack
      sx={{
        height: 1064,
        alignItems: 'center',
        marginBottom: 4,
        borderWidth: 1,
        borderColor: 'grey.400',
        borderRadius: 4,
        borderStyle: 'solid',
        padding: 6,
      }}
    >
      <Stack
        sx={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}
        spacing={4}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h2Medium">
          Jobsite daily structure
        </Typography>
        <Stack
          sx={{
            padding: 2,
            backgroundColor: 'backgrounds.system',
            alignItems: 'flex-start',
          }}
        >
          <Stack>
            <Stack direction="row" spacing={6}>
              <ChartProperty label="Name" value={crane.name} width={150} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: '100%', width: '100%' }} spacing={4}>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <JobsiteDailyStructureTable data={tableData} />
      </Stack>
    </Stack>
  );
};

export default JobsiteDailyStructureChart;
