import React, { useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as storageHelper from '../../utils/storageHelper';
import { Box, IconButton, Snackbar } from '@mui/material';
import { ULTRAWIS_SELECTED_CRANE_DATA, USER } from '../../variables/constants';
import { usePostLogisticsAssetInfo } from '../../queries';
import {
  LogisticStepperHeader,
  LogisticsStepperNavigation,
  LogisticsForm,
} from './components';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleMapPoint } from '../../components/SiteGoogleMaps/SiteGoogleMaps';
import { convertLatLongToXY } from '../../utils/coordinatesHelper';
import { useNavigate } from 'react-router-dom';
import useAssets from '../../hooks/useAssets';
import { useAppSelector } from '../../store/hooks';

const steps = Array.from({ length: 4 }, () => '');

const initialState = {
  barcode: '',
  cargoTypeId: '',
  quantity: 200,
  positionCoords: null,
  xyUserLocation: null,
  xyDestinationLocation: null,
  imageData: null,
};

const reducer = (state: any, action: any) => {
  if (action.type === 'initial_state') return initialState;
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const Logistics = (props: any) => {
  //#region Stepper logic
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [source, setSource] = useState<GoogleMapPoint | null>(null); // asset location
  const [destination, setDestination] = useState<GoogleMapPoint | null>(null); // destination location
  const [siteCenter, setSiteCenter] = useState<GoogleMapPoint>({
    lat: 0,
    lng: 0,
  });
  const { createAssetApi, createAssetApiReset } = useAssets();

  const stepsLength = steps.length;
  const submitStep = stepsLength - 1;

  const isStepOptional = (step: number) => {
    if (step === 1) return state.imageData === null && state.barcode === '';
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const createAssetInfo = async () => {
    const {
      xyDestinationLocation,
      xyUserLocation,
      positionCoords,
      barcode,
      quantity,
      cargoTypeId,
      imageData,
    } = state;
    // const { latitude, longitude, altitude } = positionCoords;

    // const xDest = xyDestinationLocation ? xyDestinationLocation.x : 0;
    // const yDest = xyDestinationLocation ? xyDestinationLocation.y : 0;

    if (!destination || !source) return;
    // const { x: xDest, y: yDest } = convertLatLongToXY(
    //   siteCenter,
    //   destination.lat,
    //   destination.lng,
    // );

    // const xSource = xyUserLocation ? xyUserLocation.x : 0;
    // const ySource = xyUserLocation ? xyUserLocation.y : 0;

    // const { x: xSource, y: ySource } = convertLatLongToXY(
    //   siteCenter,
    //   source.lat,
    //   source.lng,
    // );

    const ImgFilename = imageData
      ? 'lu/' + selectedProject + '/img' + Date.now() + '.jpg'
      : null;

    const imageEncoded = imageData
      ? JSON.stringify(imageData.substring(23))
      : null;

    const WorkerId = user.ID;

    // createAssetInfoMutate({
    //   CraneId: selectedCrane,
    //   xDest: destination.lat,
    //   yDest: destination.lng,
    //   xSource: source.lat,
    //   ySource: source.lng,
    //   Lat: siteCenter.lat,
    //   Long: siteCenter.lng,
    //   Alt: 0, //altitude,
    //   Barcode: barcode,
    //   Quantity: quantity,
    //   CargoTypeId: cargoTypeId,
    //   WorkerId,
    //   ImgFilename,
    //   img: imageEncoded,
    // });

    const asset = {
      barcode: barcode,
      quantity: Number(quantity),
      sourceLat: Number(source.lat),
      sourceLong: Number(source.lng),
      destinationLat: Number(destination.lat),
      destinationLong: Number(destination.lng),
      craneId: crane.id,
      cargoTypeId: state.cargoTypeId,
      imagePath: ImgFilename,
    };

    await createAssetApi(asset);

    setSource(null);
    setDestination(null);
    navigate('../cranescheduler');
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === submitStep) {
      createAssetInfo();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    dispatch({ type: 'initial_state' });
  };

  //#endregion

  const user = storageHelper.getItem(USER);
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();

  const [selectedProject, setSelectedProject] = useState('');
  const [selectedCrane, setSelectedCrane] = useState('');
  const Project = useAppSelector((state: any) => state.projectCrane.Project);
  const crane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE_DATA);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [fetchLocationIsLoading, setFetchLocationIsLoading] = useState(true);

  const {
    mutate: createAssetInfoMutate,
    isLoading: createAssetInfoIsLoading,
    error: createAssetInfoError,
  } = usePostLogisticsAssetInfo({
    onSuccess: () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    onError: () => {
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries('PostLogisticsAssetInfo');
    },
  });

  const isNextButtonDisabled = () => {
    switch (activeStep) {
      case 0: {
        return (
          Project === '' ||
          crane === '' ||
          state.quantity === '' ||
          state.cargoTypeId === ''
        );
      }
      case 1: {
        return state.imageData === null && state.barcode === '';
      }
      case 2: {
        return source === null;
      }
      case submitStep: {
        return !state.xyDestinationLocation || createAssetInfoIsLoading;
      }
      case stepsLength:
      default: {
        return false;
      }
    }
  };

  const isNextButtonLoading = () => {
    if (activeStep === 4) {
      return fetchLocationIsLoading || state.positionCoords === null;
    }

    if (activeStep === submitStep) {
      return createAssetInfoIsLoading;
    }
  };

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const error = (createAssetInfoError as Error)?.message;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <LogisticStepperHeader {...{ activeStep, steps, isStepSkipped }} />
      <LogisticsForm
        {...{
          submitStep,
          activeStep,
          siteCenter,
          setSiteCenter,
          source,
          setSource,
          destination,
          setDestination,
          stepsLength,
          setSelectedProject,
          setSelectedCrane,
          imageData: state.imageData,
          setImageData: (fileEntry: any) => {
            dispatch({
              type: 'imageData',
              payload: fileEntry,
            });
          },
          barcode: state.barcode,
          setBarcode: (value: string) => {
            dispatch({
              type: 'barcode',
              payload: value,
            });
          },
          cargoTypeId: state.cargoTypeId,
          setCargoTypeId: (value: string) => {
            dispatch({
              type: 'cargoTypeId',
              payload: value,
            });
          },
          quantity: state.quantity,
          setQuantity: (value: string) => {
            dispatch({
              type: 'quantity',
              payload: value,
            });
          },
          selectedProject,
          selectedCrane,
          positionCoords: state.positionCoords,
          setPositionCoords: (coords: any) => {
            dispatch({
              type: 'positionCoords',
              payload: coords,
            });
          },
          fetchLocationIsLoading,
          setFetchLocationIsLoading,
          setXyUserLocation: (values: any) => {
            dispatch({
              type: 'xyUserLocation',
              payload: values,
            });
          },
          setXyDestinationLocation: (values: any) => {
            dispatch({
              type: 'xyDestinationLocation',
              payload: values,
            });
          },
        }}
      />
      <LogisticsStepperNavigation
        {...{
          activeStep,
          stepsLength,
          submitStep,
          disabledNextButton: isNextButtonDisabled(),
          loadingNextButton: isNextButtonLoading(),
          handleReset,
          handleBack,
          isStepOptional,
          handleSkip,
          handleNext,
        }}
      />
      <Snackbar
        open={openSnackbar && !!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={error}
        onClose={closeSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default Logistics;
