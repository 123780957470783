import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface Task {
  ID: number;
  num: number;
  CraneId: number;
  CranesId: number;
  DateLoad: string;
  TimeLoadStart: string;
  PicNameLoad: string;
  TimeUnloadEnd: string;
  PicNameUnload: string;
  TotalTimeLength: string;
  CargoTypeDesc: string;
}
export interface Tasks {
  tasks: Task[];
}
const initialState: Tasks = {
  tasks: [
    {
      ID: 0,
      num: 0,
      CraneId: 0,
      CranesId: 0,
      DateLoad: '',
      TimeLoadStart: '',
      PicNameLoad: '',
      TimeUnloadEnd: '',
      PicNameUnload: '',
      TotalTimeLength: '',
      CargoTypeDesc: '',
    },
  ],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {
    setTasks(state, action: PayloadAction<Task[]>) {
      state.tasks = action.payload;
      // console.log (state.tasks);
    },
    resetTasks: () => initialState,
  },
});

export const tasksActions = tasksSlice.actions;

export default tasksSlice.reducer;
