import {
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppDispatch } from '../../store/hooks';
import { projectCraneActions } from '../../store/projectcrane';
import * as storageHelper from '../../utils/storageHelper';
import {
  ULTRAWIS_SELECTED_CRANE,
  ULTRAWIS_SELECTED_PROJECT,
  USER,
} from '../../variables/constants';

const AppSiteSelector = () => {
  const sm = useMediaQuery('(max-width: 600px)');
  const [selectedProject, setSelectedProject] = useState<any>(
    storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT) || '',
  );
  const [projects, setProjects] = useState<any>([]);
  const dispatch = useAppDispatch();
  const user = storageHelper.getItem(USER);

  useEffect(() => {
    setProjects(user.sites);
    // dispatch(projectCraneActions.setCurrentProject(user.sites[0].id));
    // dispatch(projectCraneActions.setCurrentCrane(user.sites[0].cranes[0].id));
    // setCranes(user.Cranes);
    const project = storageHelper.getItem(ULTRAWIS_SELECTED_PROJECT);
    if (project) {
      dispatch(projectCraneActions.setCurrentProject(project));
      setSelectedProject(project);
    } else if (user?.sites[0]?.id) {
      const projectId = user.sites[0].id;
      dispatch(projectCraneActions.setCurrentProject(projectId));
      setSelectedProject(projectId);
      storageHelper.setItem(ULTRAWIS_SELECTED_PROJECT, projectId);
    }
  }, []);

  const handleSiteChange = (event: any) => {
    const { value } = event.target;
    dispatch(projectCraneActions.setCurrentProject(value));
    dispatch(projectCraneActions.setCurrentCrane(''));
    setSelectedProject(value);
    storageHelper.setItem(ULTRAWIS_SELECTED_PROJECT, value);
    storageHelper.setItem(ULTRAWIS_SELECTED_CRANE, '');
  };

  return (
    // <GridToolbar>
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{ marginLeft: 'auto', marginRight: 0 }}
    >
      <Stack
        spacing={2}
        sx={{
          marginRight: sm ? 0 : 4,
          // marginBottom: 4,
          width: sm ? '100%' : 'auto',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="smallRegular"
          sx={{ paddingRight: 2 }}
          // color={'typography.primary'}
          color={'#FFFAFA'}
        >
          Site
        </Typography>
        <Select
          value={selectedProject}
          onChange={handleSiteChange}
          style={{ marginTop: 0 }}
          sx={{ width: sm ? '100%' : 220 }}
        >
          {projects &&
            projects.length > 0 &&
            sortBy(projects, 'name').map((site: any) => (
              <MenuItem key={uuid()} value={site.id}>
                {site.name}
              </MenuItem>
            ))}
        </Select>
      </Stack>
    </Stack>

    // </GridToolbar>
  );
};

export default AppSiteSelector;
