import { useAppDispatch } from '../store/hooks';
import { dailyVideoActions } from '../store/dailyvideo';
import { useHttp } from './useHttp/useHttp';
import { ApiMethods } from '../variables/enums';
import { DAILY_VIDEO_PATHS } from '../variables/endpoints';

const useDailyVideo = () => {
  const dispatch = useAppDispatch();
  const { api } = useHttp();
  const setHandlerDailyVideo = async (dateProp: string, craneId: number) => {
    try {
      const url = DAILY_VIDEO_PATHS.getDailyVideo(dateProp, craneId.toString());
      const res = await api({
        method: ApiMethods.GET,
        url,
      });
      dispatch(dailyVideoActions.setDailyVideo(res.data[0]));
    } catch (err) {}
  };

  return { setHandlerDailyVideo };
};

export default useDailyVideo;
