import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { useAppSelector } from '../../../../store/hooks';
dayjs.extend(utc);
dayjs.extend(timezone);

const LiftModalTitle = ({
  currentLiftIndex,
  currentLift,
  handleClickBack,
  handleClickNext,
  setOpen,
}: any) => {
  const sm = useMediaQuery('(max-width: 600px)');
  const lifts = useAppSelector((state: any) => state.projectCrane.lifts);

  return (
    <DialogTitle>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
      >
        <Button
          variant="contained"
          onClick={handleClickBack}
          disabled={currentLiftIndex === 0}
          sx={{
            width: sm ? '30px' : '100px',
            marginRight: sm ? 0 : '10px',
            padding: sm ? 0 : '0px 60px',
            textAlign: 'center',
          }}
        >
          <ArrowBack sx={{ marginRight: sm ? 0 : '10px' }} />
          {!sm && 'Previous'}
        </Button>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <span style={{ fontSize: sm ? 20 : 26, fontWeight: 'bold' }}>
            Lift {currentLiftIndex + 1} / {lifts.length}
          </span>
          <span
            style={{
              fontSize: sm ? 14 : 22,
              color: 'grey',
              fontWeight: 'bold',
            }}
          >
            {currentLift.startTime} - {currentLift.endTime}
          </span>
        </Box>
        <Button
          variant="contained"
          onClick={handleClickNext}
          disabled={currentLiftIndex === lifts.length - 1}
          sx={{
            width: sm ? '30px' : '100px',
            marginRight: sm ? '30px' : '50px',
          }}
        >
          {!sm && 'Next'}
          <ArrowForward sx={{ marginLeft: sm ? 0 : '10px' }} />
        </Button>
      </Box>
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: sm ? 22 : 26 }} />
      </IconButton>
    </DialogTitle>
  );
};

export default LiftModalTitle;
